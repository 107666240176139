/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect } from 'react';
import ReactLoading from 'react-loading';

import { PermissionsProps } from '../../../store/user'
import useAppSelector from '../../../hooks/useAppSelector';
import { getBannerDesktop } from '../../../store/actions/bannerPopupActions';
import useAppDispatch from '../../../hooks/useAppDispatch';

const BannerDesktop: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user: { permissions, tipoAgenda }, token } = useAppSelector(state => state.user)
  const { bannerDesktop, loadingBannerDesktop } =  useAppSelector(state => state.bannersPopup)


  const permissionsBanner = useMemo(() => {
    if(permissions) {
        const userPermissions: PermissionsProps = JSON.parse(permissions)
        return userPermissions[tipoAgenda as keyof PermissionsProps]?.banner
    }
    return null;
  },[permissions, tipoAgenda]);

  useEffect(() => {
    if (!!permissionsBanner?.bannerId) {
      (async function () {
        await dispatch(getBannerDesktop({bannerId: permissionsBanner.bannerId!}))
        })()
    }
  },[permissionsBanner, token])

  return (
    <>
      {loadingBannerDesktop ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ReactLoading type="spin" color="#002868" height="fit-content" width="10%" />
        </div>
      ) : (
        <>
        {bannerDesktop?.imageUrl && (
          <img src={bannerDesktop?.imageUrl} alt="Banner Home" style={{ borderRadius: 15 }} />
        )}
        </>
      )}
    </>
  )
}

export default BannerDesktop;
