import styled from "styled-components";

export const ContainerMobile = styled.div`
  width: 100%;

  .switch-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 84px;
  }
`

export const ContainerList = styled.div`
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  background-color: #FFFFFF;
  padding: 15px;
  border: 0.5px solid rgba(199, 203, 209, 0.60);

  .content-filters {
    display: flex;
    position: relative;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
  }
`

export const ContentTable = styled.div`
  width: 100%;
  height: 50vh;
  overflow-y: auto;
  margin-bottom: 70px;
  scroll-behavior: smooth;
`

export const HeadTable = styled.div`
  background-color: rgba(0, 40, 104, 0.15);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px 0px;

  span {
    font-family: ${({theme}) => theme.font.family};
    font-size: ${({theme}) => theme.font.size14};
    text-align: center;
    color: #0033AB;
    font-weight: bold;
  }

  div {
    display: flex;
    gap: 5px
  }
`

export const BodyTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .row-sales {
    width: 100%;
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .details-sales {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-left: 10px;
    }
  }

  .content-sale {
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      font-family: ${({theme}) => theme.font.family};
      color: #002868;
    }

    .confirmation-hour {
      font-size: 12px;
      color: #969696;
    }

    .type-card {
      font-weight: 700;
      font-size: 12px;
    }

    .brand-card {
      color: #969696;
      font-size: 12px;
    }
  }

  .content-balance {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-family: ${({theme}) => theme.font.family};
      color: #002868;
      font-weight: bold;
      font-size: 18px;
    }
  }
`

export const ContainerDetails = styled.div`
  width: 100%;
  margin-top: 64px;
  margin-bottom: 70px;
  
  .content-card {
    padding: 15px;

  }
`

export const ContentInfo = styled.div<{$color?: string}>`
  width: 100%;
  padding: 15px 0px;
  background-color: ${({$color}) => $color ? $color : "#FFFFFF"};
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;

  span {
    padding: 0px 15px;
  }

  .row-infos:nth-child(even) {
    background-color: rgba(199, 203, 209, 0.10);
  }

  .row-infos {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .row-card {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0px 15px;

    div {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .row-infos-net-value {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-bottom: 0px;
    }
  }
`

export const Title = styled.span<{$bold?: boolean}>`
  font-family: ${({theme}) => theme.font.family};
  color: #002868;
  font-weight: ${({$bold}) => $bold ? "bold" : "normal"};
  font-size: 13px;
  margin-bottom: 5px;
`

export const Description = styled.span<{$color?: string, $bold?: boolean}>`
  font-family: ${({theme}) => theme.font.family};
  font-size: 13px;
  color: ${({$color}) => $color ? $color : "rgba(150, 150, 150, 1)"};
  text-align: justify;
  font-weight: ${({$bold}) => $bold ? "bold" : "normal"};

  display: flex;
  align-items: center;
  gap: 5px;
`

export const ContentLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

export const ContentPagination = styled.div`
  padding: 10px;
  ul {
    padding: 0;
  }
`

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;

  .message-not-found {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    img {
      width: 80px;
    }
    span {
      text-align: center;
    }
  }
`

export const ButtonBackToTop = styled.a`
`