/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs'
import React, { useEffect, useMemo } from 'react'
import { BiSolidCheckCircle } from 'react-icons/bi'
import { IoMdCloseCircle } from 'react-icons/io'
import ReactLoading from 'react-loading'
import { useLocation, useParams } from 'react-router-dom'
import Card from '../../components/Card/Card'
import useAppDispatch from '../../hooks/useAppDispatch'
import useAppSelector from '../../hooks/useAppSelector'
import { getTransactionsByTransaction } from '../../store/actions/transactionsActions'
import { ContainerDetails, ContentInfo, ContentLoading, Description, Title } from './styles'
import HeaderDetails from '../../components/Header/Components/HeaderDetails'
import { formatToCurrencyBRL, handleIconCard } from '../../utils/functions'

const Details = () => {
    const { id } = useParams()
    const { search, state } = useLocation()
    const [_, date] = search.split('=')
    const dispatch = useAppDispatch()
    const { loadingDataByTransaction, dataByTransaction } = useAppSelector(state => state.transactions)


    useEffect(() => {
        ;(async function() {
            await dispatch(getTransactionsByTransaction({startDate: date, endDate: date, offset: 0, limit: 1000}))
        })()
    }, [])

    const detailsSale = useMemo(() => {
        const saleOfTheDay = dataByTransaction?.filter((item) => item?.nsu === id)
        return {
            paymentType: saleOfTheDay[0]?.paymentType ?? "",
            netValue: saleOfTheDay[0]?.netValue ?? 0,
            installments: saleOfTheDay[0]?.installments ?? 0,
            grossValue: saleOfTheDay[0]?.grossValue ?? 0,
            mdr: saleOfTheDay[0]?.mdr || saleOfTheDay[0]?.mdr === 0 ? saleOfTheDay[0]?.mdr : "Processando",
            antecipation: saleOfTheDay[0]?.antecipation || saleOfTheDay[0]?.antecipation === 0 ? saleOfTheDay[0]?.antecipation : "Processando",
            sumRate: (saleOfTheDay[0]?.mdr + saleOfTheDay[0]?.antecipation) ?? 0,
            status: saleOfTheDay[0]?.transactionStatus ?? "",
            cardBand: saleOfTheDay[0]?.cardBrand ?? "",
            nsu: saleOfTheDay[0]?.nsu ?? "",
            authorizationCode: saleOfTheDay[0]?.authorizationCode ?? "",
            terminal: saleOfTheDay[0]?.pos ?? ""
        }
    },[dataByTransaction, id])

    return (
        <>
            <HeaderDetails title='Vendas Detalhadas' />
            <ContainerDetails>
                <div className='content-card'>
                    <Card
                        title="Valor da venda"
                        balance={detailsSale?.grossValue ? detailsSale?.grossValue : state?.saleValue}
                        description={`Realizado em ${dayjs(date).locale('pt-br').format('DD/MM/YYYY')} | ${state?.hour}`}
                        loading={loadingDataByTransaction}
                    >
                        <Description>
                            {state?.statusPayment === "CONFIRMADA" ? (
                                <BiSolidCheckCircle size={25} color='rgba(74, 174, 65, 1)' />

                            ) : (
                                <IoMdCloseCircle size={25} color='#eb001b' />
                            )}
                            {state?.statusPayment}
                        </Description>
                    </Card>
                </div>
                {!loadingDataByTransaction ? (
                    <>
                        <ContentInfo>
                            <Title $bold>Detalhamento da venda</Title>
                            <Description style={{marginBottom: "5px"}}>
                                As informações com status “Processando” podem levar até 24 horas para serem validadas e adicionadas baixo.
                            </Description>
                            <Title $bold>Taxas</Title>
                            <div className='row-infos'>
                                <Description>MDR</Description>
                                <Description $color={detailsSale?.mdr === "Processando" ? '#FF9400' : '#002868'}>{detailsSale?.mdr === "Processando" ? detailsSale?.mdr : formatToCurrencyBRL(detailsSale?.mdr)}</Description>
                            </div>
                            <div className='row-infos'>
                                <Description>Antecipação</Description>
                                <Description $color={detailsSale?.antecipation === "Processando" ? '#FF9400' : '#002868'}>{detailsSale?.antecipation === "Processando" ? detailsSale?.antecipation : formatToCurrencyBRL(detailsSale?.antecipation)}</Description>
                            </div>
                            <div className='row-infos'>
                                <Description>Somatório de taxas</Description>
                                <Description $bold $color={(detailsSale?.mdr === "Processando" || detailsSale?.antecipation === "Processando") ? '#FF9400' : '#002868'}>{(detailsSale?.mdr === "Processando" || detailsSale?.antecipation === "Processando") ? "Processando" : formatToCurrencyBRL(detailsSale?.sumRate)}</Description>
                            </div>
                        </ContentInfo>
                        <ContentInfo $color='rgba(0, 40, 104, 0.15)'>
                            <div className='row-infos-net-value'>
                                <Title $bold>Valor líquido (=)</Title>
                                <Title $bold={detailsSale?.netValue ? true : false}>{detailsSale?.netValue ? formatToCurrencyBRL(detailsSale?.netValue) : "Processando"}</Title>
                            </div>
                        </ContentInfo>
                        <ContentInfo>
                            <Title $bold>Forma de recebimento</Title>
                            {(detailsSale?.cardBand && detailsSale?.paymentType) ? (
                                <div className='row-card'>
                                    <img src={handleIconCard(detailsSale?.cardBand)} alt="Card Icon" style={{marginRight: "5px", maxWidth: "50px"}} />
                                    <div>
                                        <Description>Tipo de compra</Description>
                                        <Title $bold>{detailsSale?.paymentType}</Title>
                                    </div>
                                </div>
                            ) : (
                                <Description>Processando</Description>
                            )}
                            
                        </ContentInfo>
                        <ContentInfo>
                            <Title $bold>Resumo da movimentação</Title>
                            <div className='row-infos'>
                                <Description>Código NSU</Description>
                                <Description $color='#002868'>{detailsSale?.nsu ? detailsSale?.nsu : "Processando"}</Description>
                            </div>
                            <div className='row-infos'>
                                <Description>Autorização</Description>
                                <Description $color='#002868'>{detailsSale?.authorizationCode ? detailsSale?.authorizationCode : "Processando"}</Description>
                            </div>
                            <div className='row-infos'>
                                <Description>Terminal</Description>
                                <Description $color='#002868'>{detailsSale?.terminal ? detailsSale?.terminal : "Processando"}</Description>
                            </div>
                        </ContentInfo>
                    </>
                ) : (
                    <ContentLoading>
                        <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'15%'}/>
                    </ContentLoading>
                )}
            </ContainerDetails>
        </>
    )
}

export default Details