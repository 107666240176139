import styled, { css } from 'styled-components'

interface InputProps {
  width: string
  height?: string
}

export const Wrapper = styled.div<InputProps>`
  ${({ width, height, theme }) => css`
    width: ${width && width};
    position: relative;
    text-align: start;

    label {
      margin-left: 0.5rem;
      color: ${theme.colors.textBlue};
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.xxsmall};
    }

    p {
      position: absolute;
      z-index: 1;
      left: 0;
      padding: 18px;
      pointer-events: none;
      font-size: 14px;

      transition: 0.3s;
    }

    .react-select__control {
      height: ${height ? height : '56px'};
    }

    .react-select__placeholder {
      color: ${theme.colors.placeholder};
      font-family: ${({theme}) => theme.font.family};
      font-size: ${theme.font.sizes.xxsmall};
      text-align: start;
    }

    .react-select__indicator {
      color: #002868;
      cursor: pointer;

      :hover {
        color: #002868;
      }
    }

    .react-select__single-value {
      color: ${theme.colors.blue};
      font-family: ${({theme}) => theme.font.family};
      font-size: ${theme.font.sizes.xxsmall};
      font-weight: 400;
      text-align: left;
    }
  `}
`

