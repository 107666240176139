import React from 'react'
import { ColorPaymentStatusNotPaid, ColorPaymentStatusPaid } from '../../../theme/tokens';
// import Table from '../../../components/Table/Table';
import { PaymentsLinksProps } from '..';
import Table2 from '../../../components/Table2';
import dayjs from 'dayjs';

export const LinksTable: React.FC<{
  links: PaymentsLinksProps[],
  loading: boolean,
}> = ({links, loading}) => {
  return (
    <Table2
    loading={loading}
    data={links}
    columns={[
      {
        key: 'title',
        header: 'Título',
        format: ({title}) => title && title
      },
      {
        key: 'description',
        header: 'Descrição',
        format: ({description}) => description && description.replace(/^undefined$/g, "")
      },
      {
        key: 'dataCriacao',
        header: 'Data de Criação',
        format: ({dataCriacao}) => dataCriacao && dayjs((dataCriacao).toString().slice(0, 10)).format("DD/MM/YYYY")
      },
      {
        key: 'nsu',
        header: 'NSU',
        format: ({nsu}) => nsu && nsu
      },
      {
        key: 'value',
        header: 'Valor (R$)',
        format: ({value}) => value && value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      },
      {
        key: 'payments',
        header: 'Parcelas',
        format: ({payments}) => payments < 10 ? `0${payments}` : payments
      },
      {
        key: 'status',
        header: 'Status',
        format: ({status}) => status && status === 'EXPIRED' ? <span style={{fontWeight: 'bold', color: ColorPaymentStatusNotPaid}}>Expirado</span> : status === 'PAID' ? <span style={{fontWeight: 'bold', color: ColorPaymentStatusPaid}}>Pago</span> : <span style={{fontWeight: 'bold', color: 'gray'}}>Pendente</span>
      },
      {
        key: 'attemp',
        header: 'Tentativa',
        format: ({attemp}) => attemp && attemp ? <span style={{fontWeight: 'bold', color: ColorPaymentStatusPaid}}>Sim</span> : <span style={{fontWeight: 'bold', color: 'gray'}}>Não</span>
      },
    ]}
  />
  )
}

