import styled from "styled-components";

export const ContainerDesktop = styled.div`
  /* padding: 24px 30px; */
  display: flex;
  flex-direction: column;
  gap: 24px;

  .content-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  table {

  tr{
    td{
      .status-box{
          display: flex;
          align-items: center;
          gap: 5px;
      }

      .ball{
          border-radius: 25px;
          width: 14px;
          height: 14px;
      }

      &.confirmed{
          color: #1AB057;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;

          .ball{
              background-color: #1AB057;
          }
      }

      &.rejected{
          color: #DC3737;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          .ball{
              background-color: #DC3737;
          }
      }

      &.pending{
          color: #D48001;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;

          .ball{
              background-color: #D48001;
          }
      }
    }
  }

}
`

export const ContentCards = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`

export const ContentButtons = styled.div`
  display: flex;
  justify-content: space-between;

  .group-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: relative;
  }
`

export const ContentFilters = styled.div<{open: boolean}>`
  width: 50%;
  position: relative;
  
  .content-filters {
    width: 100%;
    position: absolute;
    margin-top: 5px;
    display: ${({open}) => open ? "flex" : "none"};
    padding: 10px;
    background-color: #d3d8e1;
    border-radius: 15px;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }

`

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  /* height: 100%; */
  
  table {

tr{
  td{
    .status-box{
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .ball{
        border-radius: 25px;
        width: 14px;
        height: 14px;
    }

    &.confirmed{
        color: #1AB057;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;

        .ball{
            background-color: #1AB057;
        }
    }

    &.rejected{
        color: #DC3737;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        .ball{
            background-color: #DC3737;
        }
    }

    &.pending{
        color: #D48001;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;

        .ball{
            background-color: #D48001;
        }
    }
  }
}

}
`

export const WrapperStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .ball{
      border-radius: 25px;
      width: 14px;
      height: 14px;
  }

  &.confirmed{
      color: #1AB057;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;

      .ball{
          background-color: #1AB057;
      }
  }

  &.rejected{
      color: #DC3737;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      .ball{
          background-color: #DC3737;
      }
  }

  &.pending{
      color: #D48001;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;

      .ball{
          background-color: #D48001;
      }
  }
`