import React, { useState, useEffect, useCallback, useMemo } from "react"
import dayjs from 'dayjs'
import { Container, Header, Body, LoadingContent, Info } from "./styles"
import ReactLoading from 'react-loading'
import { BsInfoCircle } from "react-icons/bs"
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io"
import { EventDayFormatedProps } from "../../pages/Recebimentos"
import useAppSelector from "../../hooks/useAppSelector"
import useAppDispatch from "../../hooks/useAppDispatch"
import { optionType } from "../../constants"
import { getReceivementsByDateAndNSU } from "../../store/actions/receivementsActions"
import { monthsString } from "../../utils/constants"
import { formatToCurrencyBRL } from "../../utils/functions"

type TableListProps = {
  title: string;
  balance: number;
  date: string;
  loading: boolean;
  data: Array<EventDayFormatedProps>
}

const TableList = ({title, balance, date, loading, data}: TableListProps) => {
  const { user: { profile }} = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const [openDetails, setOpenDetails] = useState(false)
  const [openDetailsDebit, setOpenDetailsDebit] = useState(false)

  useEffect(() => {
    setOpenDetails(false)
    setOpenDetailsDebit(false)
  },[data])

  function formatDate(date: any) {
    return `${dayjs(date).format("DD")} de ${monthsString[dayjs(date).format("MMMM")]} de ${dayjs(date).format("YYYY")}`
  }

  const option = useAppSelector(state => state.switchButtons.option)
  
  const isMoura = useMemo(() => {
    return option === optionType.MOURA
  },[option])

  const loadReceivementsByDayAndNSU = useCallback(async (date: string, schedule: 'LOJA' | 'MOURA', open: boolean) => {
    await dispatch(getReceivementsByDateAndNSU({
        date,
        schedule,
        open
    }))
}, [dispatch])

  const {
    receivementsByDayAndNSU
  } = useAppSelector(state => state.receivements)


  return (
    <Container>
      <Header>
        <p className="title">{title}</p>
        {/* <p className="balance">{balance}</p> */}
        <p className="date">{formatDate(date)}</p>
      </Header>
      <Body>
        {loading ? (
          <LoadingContent>
            <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'} />
          </LoadingContent>
        ) : (
          <>
            {data.map((item, index: number) => (
              <React.Fragment key={index}>
                {(((!openDetails && !openDetailsDebit) || (openDetails && ['Crédito em Saldo CREDMOURA', 'Valor Líquido Cliente'].includes(item?.title)) ) || (openDetailsDebit && ['Débito em Saldo CREDMOURA'].includes(item?.title))) && (
                  <>
                    <div className={`row-details ${item?.title === "Recebido do Dia" ? "background-gray" : ""}`}>
                      <p style={{color: item.colorTitle ?? "#737D8C"}}>{item.title}</p>
                      <div>
                        <span style={{color: item.colorAmount ?? "#737D8C"}}>{formatToCurrencyBRL(item.amount)}</span>
                        {(item?.operations && item?.operations?.length > 0) && ['Crédito em Saldo CREDMOURA', 'Valor Líquido Cliente'].includes(item?.title) && (
                          <>
                            {openDetails ?
                              <IoIosArrowDown color='#002868' onClick={() => {setOpenDetails(!openDetails) ; loadReceivementsByDayAndNSU('2099-01-01', isMoura ? 'MOURA' : 'LOJA', false)  }} />
                              :
                              <IoIosArrowForward color='#002868' onClick={() => {setOpenDetails(!openDetails); loadReceivementsByDayAndNSU(date, isMoura ? 'MOURA' : 'LOJA', true)} } />
                            }
                          </>
                        )}
                        {(item?.operations && item?.operations?.length > 0) && ['Débito em Saldo CREDMOURA'].includes(item?.title) && (
                          <>
                            {openDetailsDebit ?
                              <IoIosArrowDown color='#002868' onClick={() => {setOpenDetailsDebit(!openDetailsDebit)}} />
                              :
                              <IoIosArrowForward color='#002868' onClick={() => {setOpenDetailsDebit(!openDetailsDebit)} } />
                            }
                          </>
                        )}
                      </div>
                    </div>

                    {openDetailsDebit && (<>
                      <div className="table-details">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Código da fatura</th>
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(item as any).operations.map((debit: any, idx: number) => (
                                      <tr key={idx}>
                                        <td>{debit.description}</td>
                                        <td style={{fontWeight: 'bold'}}>{formatToCurrencyBRL(debit.value)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                </div>
                    </>)}

                    {openDetails && receivementsByDayAndNSU.find(receivements => receivements.schedule === (isMoura ? 'MOURA' : 'LOJA') && receivements.open === true) && (
                      <>
                      {
                        receivementsByDayAndNSU.find(receivements => receivements.schedule === (isMoura ? 'MOURA' : 'LOJA') && receivements.loading) ? (
                          <LoadingContent>
                              <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'} />
                          </LoadingContent>
                          ) : (
                            <>
                              {(item?.operations && item?.operations?.length > 0) && (
                                <div className="table-details">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>NSU</th>
                                      <th>Data da Venda</th>
                                      {profile === "flex" && (
                                        <th>Porcentagem</th>
                                      )}
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {receivementsByDayAndNSU.find(receivements => receivements.schedule === (isMoura ? 'MOURA' : 'LOJA') && receivements.open === true)?.receivements.filter(receivement => receivement.nsu !== '-').map((receivement, idx) => (
                                    <tr key={idx}>
                                      <td>{receivement.nsu}</td>
                                      <td>{receivement.transactionDate}</td>
                                      {profile === "flex" && (
                                          <td>{receivement.percentReceived}%</td>
                                      )}
                                      <td style={{fontWeight: 'bold'}}>{formatToCurrencyBRL(receivement.netValue)}</td>
                                    </tr>
                                  ))}
                                  </tbody>
                                </table>
                                </div>
                              )}
                            </>
                          )
                        
                      }
                        
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </Body>
      <Info>
        <BsInfoCircle color="#737D8C" />
        <span>
          Para dúvidas ou informações acima de 60 dias, fale conosco pelo número <strong>(81) 3299 -1248</strong>
        </span>
      </Info>
    </Container>
  )
}

export default TableList;