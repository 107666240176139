import React, { ReactNode } from "react"
import { IoIosArrowForward } from "react-icons/io"
import ReactLoading from "react-loading"
import Button from "../Button/Button"
import { Balance, ContentChildren, ContentTable, ContentTitle, Description, Icon, Title } from "./styles"
import { formatToCurrencyBRL } from "../../utils/functions"

type CardProps = {
  title: string,
  icon: any,
  loading: boolean,
  description: string,
  balance: number,
  children: ReactNode,
  onClick: () => void,
  style?: React.CSSProperties,
  contentStyle?: React.CSSProperties
}

export const CardWithTable = ({title, icon,description, balance, loading, children, onClick, style, contentStyle}: CardProps) => {
  return (
    <ContentTable>
      <ContentTitle>
          <div className='content-title'>
              <Icon>
                  <img src={icon} alt="icon-title" />
              </Icon>
              <Title>{title}</Title>
          </div>
          <Button
              icon={<IoIosArrowForward color='#002868' />}
              width="40px"
              typeButton="outline"
              noBorder
              backgroundColor='rgba(0, 48, 124, 0.116)'
              onClick={onClick}
          />
      </ContentTitle>
      <Description>{description}</Description>
      {!loading ? (
        <Balance>
          {formatToCurrencyBRL(balance)}
        </Balance>
      ) : (
        <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'5%'}/>
      )}
      <ContentChildren style={contentStyle}>
        {children}
      </ContentChildren>
    </ContentTable>
  )
}