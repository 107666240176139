import { styled } from "styled-components";

export const ContainerModal = styled.div`

  .content-head {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    p {
      color: ${({theme}) => theme.color.secondary};
      font-family: ${({theme}) => theme.font.family};
      font-weight: ${({theme}) => theme.font.weigth600};
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    input {
      width: 100%;
      background-color: #F6F6F6;
      border: none;
      height: 45px;
      padding-left: 10px;
      color: ${({theme}) => theme.color.secondary};
      font-family: ${({theme}) => theme.font.family};
      font-size: 16px;
      border-radius: 5px;

      &::placeholder {
        color: ${({theme}) => theme.color.secondary};
        font-family: ${({theme}) => theme.font.family};
        font-size: 16px;
      }
    }

    .input-password {
      width: 100%;
      position: relative;

      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        
        cursor: pointer;
      }
    }

    .message-error {
      width: 100%;
      color: ${({theme}) => theme.color.error};
      font-family: ${({theme}) => theme.font.family};
      font-size: 12px;
    }
  }
`