import React from "react"
import useAppSelector from "../../../hooks/useAppSelector"
import { formatToCurrencyBRL } from "../../../utils/functions"
import { Pagination } from "../../../components/Pagination/Pagination"
import { optionType } from "../../../utils/constants"
import Table2 from "../../../components/Table2"

interface Props {
  stepper: number
  setOffsetReceivementsByDay(arg: number): void
}

const TableReceivementsByDay: React.FC<Props> = ({
    stepper,
    setOffsetReceivementsByDay,
  }) => {

  const option = useAppSelector(state => state.switchButtons.option)
  const { receivementsByDay, loadingReceivementsByDay } = useAppSelector(state => state.receivements)

  return (
    <>
      {stepper === 1 && option === optionType.LOJA && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <Table2
            loading={loadingReceivementsByDay}
            data={receivementsByDay?.rows}
            columns={[
              {
                key: 'paymentDate',
                header: 'Data de Recebimento',
                format: ({paymentDate}) => paymentDate
              },
              {
                key: 'grossValue',
                header: 'Valor Bruto',
                format: ({grossValue}) => formatToCurrencyBRL(grossValue)
              },
              {
                key: 'mdr',
                header: 'MDR',
                children: [
                  {
                    key: 'mdr',
                    header: 'R$',
                    format: ({mdr, mdrPercent}) => Number(mdrPercent) > 50 ? "Em processamento" : formatToCurrencyBRL(mdr)
                  },
                  {
                    key: 'mdrPercent',
                    header: '%',
                    format: ({mdrPercent}) => Number(mdrPercent) > 50 ? "Em processamento" : Number(mdrPercent)
                  },
                ]
              },
              {
                key: 'netValue',
                header: 'Valor Líquido Total Cliente',
                format: ({clientNetValue, mdrPercent}) => Number(mdrPercent) > 15 ? "Em processamento" : formatToCurrencyBRL(clientNetValue)
              },
              {
                key: 'antecipation',
                header: 'Antecipação',
                children: [
                  {
                    key: 'antecipation',
                    header: 'R$',
                    format: ({mdrPercent, antecipation}) => Number(mdrPercent) > 50 ? "Em processamento" : formatToCurrencyBRL(antecipation)
                  },
                  {
                    key: 'antecipationPercent',
                    header: '%',
                    format: ({mdrPercent, antecipationPercent}) => Number(mdrPercent) > 50 ? "Em processamento" : antecipationPercent
                  },
                ]
              },
              {
                key: 'tecPercent',
                header: 'CET',
                format: ({tecPercent, mdrPercent}) => Number(mdrPercent) > 50 ? "Em processamento" : tecPercent
              },
            ]}
          />

          {receivementsByDay?.total > 0 && (
            <Pagination limit={receivementsByDay?.limit} offset={receivementsByDay?.offset} total={receivementsByDay?.total} setOffset={setOffsetReceivementsByDay}/>
          )}
        </div>
      )}
    </>
  )
}

export default TableReceivementsByDay