import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../services/api';

export const switchAgenda = createAsyncThunk(
  'switchButtons/switchAgenda',
  async (
    data: {
      tipoAgenda: string
    }
    , { rejectWithValue }) => {
    try {

      const response = await api.get(`/user/switchAgenda?tipoAgenda=${data.tipoAgenda}`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})