import React from "react";
import { css, styled } from "styled-components";

export const ContentDatePicker = styled.div<{$isMobile: boolean | null}>`
  position: absolute;
  top: 45px;
  right: ${({$isMobile}) => $isMobile ? "initial" : "0"};
  z-index: 9;
  left: ${({$isMobile}) => $isMobile ? "0" : "initial"};

  ${({theme}) => css`
    .react-datepicker {
      border: 1px solid rgba(199, 203, 209, 0.70);
      border-radius: 15px;
    }

    .react-datepicker__header {
      background-color: rgba(0, 40, 104, 0.15);
      border: none;
      border-radius: 15px 15px 0px 0px;

      .react-datepicker__current-month {
        font-size: 16px;
        font-family: ${theme.font.family};
        color: ${theme.color.secondary}
      }

      .react-datepicker__day-names {
        display: flex;
        .react-datepicker__day-name {
          width: 2.5rem;
          height: 2.5rem;
          font-family: ${theme.font.family};
          font-size: 14px;
          color: ${theme.color.secondary};
          margin: 0;
          font-weight: ${theme.font.weigth600};
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .react-datepicker__month {
      .react-datepicker__week {
        font-family: ${theme.font.family};
        color: ${theme.color.secondary};
        font-size: 14px;
        display: flex;

        .react-datepicker__day {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 0;
            margin: 0;
            padding: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${theme.color.secondary};
          }

          .react-datepicker__day--keyboard-selected {
            background-color: rgba(199, 203, 209, 0.50);
            color: ${theme.color.secondary};
          }

          .react-datepicker__day--selected {
            background-color: rgba(199, 203, 209, 0.50);
            color: ${theme.color.secondary};
          }

          .react-datepicker__day--in-selecting-range,
          .react-datepicker__day--in-range {
            background-color: rgba(199, 203, 209, 0.50);
            color: ${theme.color.secondary};
          }

          .react-datepicker__day--disabled {
            opacity: 0.5;
          }


      }
    }
  `}
`

export const ButtonDatePicker = styled.div`
  padding: 10px 15px;
  border-radius: 25px;
  background-color: rgba(0, 40, 104, 0.15);
  height: 40px;
  min-width: 230px;
  max-width: 230px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  .label {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #002868;
    span {
      font-size: 0.8rem;
      font-weight: bolder;
      font-family: Roboto;
    }
  }
`
