import React from 'react';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { DateTime } from 'luxon';

import { ContainerHeaderDesktop, ContentButtons, ContentProfile, LogoDesktop } from '../styles'
import logoPropigBlue from '../../../assets/logo-propig-blue.svg'
import logoCredMouraBlue from '../../../assets/logo-credmoura-blue.svg'
import { TbLogout } from "react-icons/tb"
import { BiDownArrow, BiHomeAlt, BiUpArrow } from "react-icons/bi"

import { logoutUser } from '../../../store/user';
import { optionType } from '../../../utils/constants';
import Button from '../../Button/Button';
import { formatCNPJ } from '../../../utils/functions';


interface Props {
  openModal: boolean
  toogleModal: () => void
}

const HeaderDesktop: React.FC<Props> = ({openModal, toogleModal}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const option = useAppSelector(state => state.switchButtons.option)
  const { user: { document: documento, loginType, name: longName, lastLogin }} = useAppSelector(state => state.user)

  const home = () => {
    navigate('/home', { replace: true })
  }

  const logout = () => {
    Swal.fire({
        text: 'Deseja realmente sair do app?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#002868',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        cancelButtonColor: '#FFD400'
    }).then((result) => {
        if (result.isConfirmed) {
            dispatch(logoutUser({ isAuthenticated: false, token: '' }))
            navigate(`/login`)
        }
    })
  }

  return (
    <ContainerHeaderDesktop $borderColor={option === optionType.LOJA}>
      <LogoDesktop
        src={option === optionType.LOJA ? logoPropigBlue : logoCredMouraBlue}
        alt='Logo'
      />
      <ContentButtons>
        <div className='content-buttons'>
          <Button
            width='100px'
            height="50px"
            onClick={home}
            label="Inicio"
            icon={<BiHomeAlt color='#002868' />}
            typeButton="outline"
            fontSize="14px"
            noBorder
          />
          <Button
            width='100px'
            height="50px"
            onClick={logout}
            label="Sair"
            icon={<TbLogout color='#002868' />}
            typeButton="outline"
            fontSize="14px"
            noBorder
          />
        </div>
        <ContentProfile>
          <div className='content-infos'>
            <p>{longName}</p>
            <span>{formatCNPJ(documento)}</span>
            {lastLogin && <span style={{fontSize: "10px"}}>Último acesso em {DateTime.fromJSDate(lastLogin).toFormat("dd/MM/yyyy 'ás' HH:mm:ss ")}</span>}
          </div>
          {loginType && loginType !== 'FILIAL' && (
            <Button
              icon={openModal ? <BiUpArrow color='#002868' /> : <BiDownArrow color='#002868' />}
              width="40px"
              typeButton='outline'
              noBorder
              onClick={toogleModal}
            />
          )}
        </ContentProfile>
      </ContentButtons>
    </ContainerHeaderDesktop>
  )
}

export default HeaderDesktop