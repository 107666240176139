import React from 'react'
import RequestContactModal from './Components/RequestContactModal'
import useAppSelector from '../../hooks/useAppSelector'

const Modals: React.FC = () => {
  const { title } = useAppSelector((state) => state.modals)

  return (
    <>
      {title === 'Solicite um contado' && (
        <RequestContactModal />
      )}
    </>
  )
}

export default Modals