/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { WrapperList, WrapperMobile } from '../styles'
import Item from './Item'
import HeaderDetails from '../../../components/Header/Components/HeaderDetails'
import { useNavigate } from 'react-router-dom'
import Footer from './Footer'
import ReactLoading from 'react-loading'

interface Props {
  loading: boolean
  orders: {
    nome: string
    documento: string
    pedido: string
  }[]
}

const RastreioMobile:React.FC<Props> = ({loading, orders}) => {
  const navigate = useNavigate()

  const redirectToDetails = (idSelected: string) => {
    navigate(`details/${idSelected}`)
  }

  return (
    <WrapperMobile>
      <HeaderDetails title='Rastreio' />
      <div className="content">
        <WrapperList>
          {loading ?
            <ReactLoading
              type={'spin'}
              color={'#002868'}
              height={'fit-content'}
              width={'15%'}
              className='loading'
            />
            :
            <>
              {orders?.map((item) => (
                <Item key={item?.pedido} item={item} setId={redirectToDetails} />
              ))}
              {orders?.length === 0 && (<span style={{margin: 'auto'}}>Não foram encontrados dados para rastreamento!</span>)}
            </>
          }
        </WrapperList>

        <Footer />
      </div>

    </WrapperMobile>
  )
}

export default RastreioMobile