import { createSlice } from '@reduxjs/toolkit'

export type boardReducerProps = {
  showBoard: boolean
}

export const initialState: boardReducerProps = {
  showBoard: true,
}

const slice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    changeShowBoard(state) {
      state.showBoard = !state.showBoard
    },
    openBoard(state) {
      state.showBoard = true
    },
  },
})

export const {
  changeShowBoard,
  openBoard,
} = slice.actions

export default slice.reducer
