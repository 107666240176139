/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import { CardWrapper, DisplayGrid, WrapperMobile } from '../styles'
import useAppDispatch from '../../../hooks/useAppDispatch'
import Table2 from '../../../components/Table2'
import StatusAnimation from './StatusAnimation'
import useAppSelector from '../../../hooks/useAppSelector'
import { getOrderById } from '../../../store/actions/trackingActions'
import HeaderDetails from '../../../components/Header/Components/HeaderDetails'
import Footer from './Footer'
import { useParams } from 'react-router-dom'
import { EventProps } from '../../../store/tracking'
import ReactLoading from 'react-loading'

const DetailsByIdMobile = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const {loadingOrder, order} = useAppSelector(state => state.tracking)

  const handleDate = (date: string) => {
    return date.split('/').reverse().join('-')
  }

  useEffect(() => {
    if(id) {
      ;(async function () {
        await dispatch(getOrderById({id: id}))    
      })()
    }
  },[id])

  const events = useMemo(() => {
    const objEventsFiltered: { [k: string]: EventProps } = {}

    const arrayFiltered: EventProps[] | undefined = order?.eventos?.reduce(
      (acc: EventProps[], curr) => {
        const key = curr?.evento

        if (
          !objEventsFiltered[key] ||
          new Date(handleDate(curr?.ocorrencia)) >
            new Date(handleDate(objEventsFiltered[key]?.ocorrencia))
        ) {
          objEventsFiltered[key] = curr
          acc.push(curr)
        }

        return acc
      },
      [],
    )

    return arrayFiltered || []
  }, [order])

  return (
    <WrapperMobile>
      <HeaderDetails title='Detalhes Rastreio' />
      <div className="content">
        <DisplayGrid>
          <CardWrapper>
            <div className='content-date'>
              <span>Previsão de Entrega: </span>
              {loadingOrder ?
                <ReactLoading
                  type={'spin'}
                  color={'#002868'}
                  height={'fit-content'}
                  width={'15%'}
                  className='loading'
                />
                :
                  <h3>{order?.dtSla && order?.dtSla?.substring(0, 10)}</h3>
                }
            </div>
          </CardWrapper>
          <CardWrapper>
            {loadingOrder ?
              <ReactLoading
                type={'spin'}
                color={'#002868'}
                height={'fit-content'}
                width={'10%'}
                className='loading'
              />
            :
              <StatusAnimation data={events} />
            }
          </CardWrapper>
        </DisplayGrid>

        <Table2
            loading={loadingOrder}
            data={events}
            columns={[
              {
                key: 'ocorrencia',
                header: 'Data',
                format: ({ocorrencia}) => ocorrencia && ocorrencia
              },
              {
                key: 'evento',
                header: 'Status',
                format: ({evento}) => evento && evento
              },
              {
                key: 'local',
                header: 'Local',
                format: ({local}) => local && local
              }
            ]}
          />

        <Footer />
      </div>
    </WrapperMobile>
  )
}

export default DetailsByIdMobile