import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../services/apiLinkPayment';
import { AddressNContact } from '../myData';

export const generatePaymentLink = createAsyncThunk(
  'linkPayment/generatePaymentLink',
  async (
    data: {
      amount: number,
      installments: number,
      title: string,
      amount_per_installment: number,
      client_document: string,
      nome_lojista: string,
      descricao_cliente: string,
      addressNContact: AddressNContact,
      mcc: number
    }
    , { rejectWithValue }) => {
    try {

      const body = {
        amount: data.amount,
        installments: data.installments,
        title: `PROPIG*${data.nome_lojista}`,
        amount_per_installment: data.amount_per_installment,
        client_document: data.client_document,
        nome_lojista: data.nome_lojista,
        descricao_cliente: data.descricao_cliente,
        endereco: data.addressNContact.address,
        cidade: data.addressNContact.city,
        uf: data.addressNContact.state,
        pais: data.addressNContact.country,
        cep: data.addressNContact.zipCode,
        telefone: data.addressNContact.phoneNumber,
        email: data.addressNContact.email,
        merchant_category_code: data.mcc
      }

      const response = await api.post(`/v1/links/`, body)

      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})