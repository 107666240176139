import styled from "styled-components"

export const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContainerDesktop = styled.div`
  /* padding: 24px 30px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const ContainerForm = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 20px 30px;
  /* box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); */

  display: flex;
  align-items: center;
`
export const ContentTitle = styled.div`
  width: 50%;
`

export const ContentForm = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    input, select {
      width: 100%;
      border: 1px solid rgba(0, 40, 104, 1);
      border-radius: 10px;
      padding: 5px 10px;
      font-family: ${({theme}) => theme.font.family};
      color: rgba(0, 40, 104, 1);

      &:disabled {
        background-color: rgba(199, 203, 209, 0.40);
        border: 1px solid rgba(199, 203, 209, 0.40);
        color: #8F97A3;
      }
    }

    .text-obs {
      font-family: ${({theme}) => theme.font.family};
      font-size: 14px;
      color: #8F97A3;
      padding-left: 10px;
    }

    .content-buttons {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }
`

export const Title = styled.p`
  font-size: 20px;
  color: #002868;
  font-weight: 700;
  margin-bottom: 0;
  font-family: ${({theme}) => theme.font.family};
`
export const Description = styled.span`
  font-size: 14px;
  color: #0033AB;
  font-family: ${({theme}) => theme.font.family};
`

export const ContentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .description {
    span:last-child {
      font-weight: bold;
    }
  }
`


export const ContainerModal = styled.div`

  .content-head {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    p {
      color: ${({theme}) => theme.color.secondary};
      font-family: ${({theme}) => theme.font.family};
      font-weight: ${({theme}) => theme.font.weigth600};
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    input {
      width: 100%;
      background-color: #F6F6F6;
      border: none;
      height: 45px;
      padding-left: 10px;
      color: ${({theme}) => theme.color.secondary};
      font-family: ${({theme}) => theme.font.family};
      font-size: 16px;
      border-radius: 5px;

      &::placeholder {
        color: ${({theme}) => theme.color.secondary};
        font-family: ${({theme}) => theme.font.family};
        font-size: 16px;
      }
    }

    .input-password {
      width: 100%;
      position: relative;

      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        
        cursor: pointer;
      }
    }

    .message-error {
      width: 100%;
      color: ${({theme}) => theme.color.error};
      font-family: ${({theme}) => theme.font.family};
      font-size: 12px;
    }
  }
`