import { createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import * as trackingActions from './actions/trackingActions'

export interface EventProps {
  ocorrencia: string
  eventoId: string
  evento: string
  arCorreios: string
  frq: string
  local: string
}

interface StateProps {
  loadingOrders: boolean
  orders: {
    nome: string
    documento: string
    pedido: string
  }[]

  loadingOrder: boolean
  order: {
    dtPost: string
    dtSla: string
    eventos: EventProps[]
  } | null
}

const initialState: StateProps = {
  loadingOrders: false,
  orders: [],
  loadingOrder: false,
  order: null
}

const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    clearOrder: (state) => {
      state.order = null
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(isPending(trackingActions.getTrackingOrdens),
    (state) => {
      state.loadingOrders = true
    }
    )
    builder.addMatcher(isFulfilled(trackingActions.getTrackingOrdens),
      (state, action) => {
        state.loadingOrders = false
        state.orders = action.payload ? action.payload : []
      }
    )
    builder.addMatcher(isRejected(trackingActions.getTrackingOrdens),
      (state) => {
        state.loadingOrders = false
      }
    )

    builder.addMatcher(isPending(trackingActions.getOrderById),
    (state) => {
      state.loadingOrder = true
    }
    )
    builder.addMatcher(isFulfilled(trackingActions.getOrderById),
      (state, action) => {
        state.loadingOrder = false
        state.order = action.payload
      }
    )
    builder.addMatcher(isRejected(trackingActions.getOrderById),
      (state) => {
        state.loadingOrder = false
      }
    )
  },
})

export const { clearOrder } = trackingSlice.actions

export default trackingSlice.reducer