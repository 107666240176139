import React, { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form";
import { ContainerForm, ContentForm, ContentTitle, Title } from "../styles"
import ReactLoading from "react-loading";
import Button from "../../../components/Button/Button";
import InputMask from "react-input-mask";
import { BsEye, BsEyeSlash } from "react-icons/bs";

type FormPersonalData = {
  name: string,
  cpf: string,
  phone: string,
  email: string
}

type PersonalDataFormType = {
  name: string,
  phone: string,
  email: string,
  loading: boolean

}

const PersonalDataForm = ({ name, phone, email, loading }: PersonalDataFormType) => {
  const [openForm, setOpenForm] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    control
  } = useForm({
    defaultValues: {
      name: "",
      cpf: "",
      phone: phone ?? "",
      email: email ?? ""
    }
  });

  const onSubmit = (data: FormPersonalData) => console.log(data);

  useEffect(() => {
    setValue('name', name)
    setValue('phone', phone)
    setValue('email', email)
  },[name, phone, email])


  return (
    <ContainerForm>
    <ContentTitle>
      <Title>Dados pessoais</Title>
      {/* <Description>Edite seus dados principais</Description> */}
    </ContentTitle>
    <ContentForm>
      {!openForm && (
        <>
          {loading ? (
            <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'5%'}/>
          ) : (
            <Button height="40px" width="35%" onClick={() => setOpenForm(true)} label="Visualizar" typeButton="outline" icon={<BsEye color="#002868" />}/>
          )}
        </>
      )}
      {openForm && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <input placeholder="Nome" disabled {...register("name")} />
          <Controller
            control={control}
            name="cpf"
            render={({field}) => <InputMask mask="999.999.999-99" disabled placeholder="CPF" {...field} />}
          />
          <Controller
            control={control}
            name="phone"
            render={({field}) => <InputMask mask="(99) 99999-9999" disabled placeholder="Contato" {...field} />}
          />
          <input placeholder="E-mail" {...register("email")} disabled />
          <span className="text-obs">Fale com a gente para editar o seu e-mail</span>
          <div className="content-buttons">
            <Button width="35%" height="40px" icon={<BsEyeSlash color="#002868" />} onClick={() => setOpenForm(false)} label="Fechar" typeButton="outline"/>
            {/* <Button type="submit" width="30%" label="Salvar" typeButton="primary" disabled/> */}
          </div>
        </form>
      )}

    </ContentForm>
  </ContainerForm>
  )
}

export default PersonalDataForm