import { configureStore, combineReducers } from '@reduxjs/toolkit'
import user from './user'
import switchButtons from './switchButtons'
import myData from './myData'
import sales from './sales'
import transactions from './transactions'
import receivements from './receivements'
import functionalities from './functionalities'
import board from './board'
import modals from './modals'
import tracking from './tracking'
import linkPayment from './linkPayment'
import bannersPopup from './bannersPopup'

const reducer = combineReducers({
  board,
  user,
  switchButtons,
  myData,
  transactions,
  sales,
  functionalities,
  receivements,
  modals,
  tracking,
  linkPayment,
  bannersPopup
})

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false
  }),
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
