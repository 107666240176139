import React from "react"
import useAppSelector from "../../../hooks/useAppSelector"
import { formatToCurrencyBRL, handleIconCard, handleTypeBuy } from "../../../utils/functions"
import { Pagination } from "../../../components/Pagination/Pagination"
import { optionType } from "../../../utils/constants"
import Table2 from "../../../components/Table2"

interface Props {
  stepper: number
  setOffsetByTransaction(arg: number): void
}

const TableReceivementsByTransaction: React.FC<Props> = ({
    stepper,
    setOffsetByTransaction,
  }) => {

  const option = useAppSelector(state => state.switchButtons.option)
  const { receivementsByTransaction, loadingReceivementsByTransaction } = useAppSelector(state => state.receivements)

  return (
    <>
      {stepper === 2 && option === optionType.LOJA && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <Table2
            loading={loadingReceivementsByTransaction}
            data={receivementsByTransaction?.rows}
            columns={[
              {
                key: 'transactionDate',
                header: 'Data de Transação',
                format: ({transactionDate}) => transactionDate ? transactionDate : "-"
              },
              {
                key: 'paymentDate',
                header: 'Data de Recebimento',
                format: ({paymentDate}) => paymentDate ? paymentDate : "-"
              },
              {
                key: 'nsu',
                header: 'Código NSU',
                format: ({nsu}) => nsu ? nsu : "-"
              },
              {
                key: 'cardBrand',
                header: 'Modalidade',
                format: ({cardBrand, paymentType, installment}) => cardBrand ?
                  <>
                    <img src={handleIconCard(cardBrand)} alt="Card Icon" style={{marginRight: "5px", maxWidth: "40px"}} />
                    {paymentType !== null ? handleTypeBuy(paymentType) : '-'} {installment !== null ? installment : "-"}
                  </>
                  :
                    "Ajuste Financeiro"
              },
              {
                key: 'grossValue',
                header: 'Valor Transação',
                format: ({grossValue}) => grossValue ? formatToCurrencyBRL(grossValue) : "-"
              },
              {
                key: 'mdr',
                header: 'MDR',
                children: [
                  {
                    key: 'mdr',
                    header: 'R$',
                    format: ({mdr, mdrPercent}) => mdr ? Number(mdrPercent) > 50 ? "Em processamento" : formatToCurrencyBRL(mdr) : "-"
                  },
                  {
                    key: 'mdrPercent',
                    header: '%',
                    format: ({mdrPercent}) => mdrPercent ? Number(mdrPercent) > 50 ? "Em processamento" : Number(mdrPercent) : "_"
                  },
                ]
              },
              {
                key: 'netValue',
                header: 'Valor Líquido Parcela',
                format: ({netValue, mdrPercent}) => netValue ? Number(mdrPercent) > 50 ? "Em processamento" : formatToCurrencyBRL(netValue) : "-"
              },
              {
                key: 'clientGrossValue',
                header: 'Recebimento Bruto Cliente',
                format: ({clientGrossValue, mdrPercent}) => clientGrossValue ? Number(mdrPercent) > 50 ? "Em processamento" : formatToCurrencyBRL(clientGrossValue) : "-"
              },
              {
                key: 'antecipation',
                header: 'Antecipação',
                children: [
                  {
                    key: 'antecipation',
                    header: 'R$',
                    format: ({mdrPercent, antecipation}) => antecipation ? Number(mdrPercent) > 50 ? "Em processamento" : formatToCurrencyBRL(antecipation) : "-"
                  },
                  {
                    key: 'antecipationPercent',
                    header: '%',
                    format: ({mdrPercent, antecipationPercent}) => antecipationPercent ? Number(mdrPercent) > 50 ? "Em processamento" : antecipationPercent : "-"
                  },
                ]
              },
              {
                key: 'clientNetValue',
                header: 'Valor líquido Cliente',
                format: ({clientNetValue, mdrPercent}) => clientNetValue ? Number(mdrPercent) > 50 ? "Em processamento" : formatToCurrencyBRL(clientNetValue) : "-"
              },
            ]}
          />

          {receivementsByTransaction?.total > 0 && (
            <Pagination limit={receivementsByTransaction?.limit} offset={receivementsByTransaction?.offset} total={receivementsByTransaction?.total} setOffset={setOffsetByTransaction} />
          )}
        </div>
      )}
    </>
  )
}

export default TableReceivementsByTransaction