import { createSlice } from '@reduxjs/toolkit'
import { optionType } from '../utils/constants'
import { isProfileAllowMoura } from '../utils/functions'

export type OptionsProps = 'MOURA' | 'LOJA'
type Profile = "multi-agenda" | "flex" | "cessao" | "maquina" | undefined


const profile = window.localStorage.getItem('profile') as OptionsProps ?? ''

interface SwitchButton {
  option: OptionsProps
}

const initialState: SwitchButton = {
  option: isProfileAllowMoura(profile as Profile) ? optionType.MOURA : optionType.LOJA
}

export const switchButtonsSlice = createSlice({
  name: 'switchButtons',
  initialState,
  reducers: {
    handleSwitchButton: (state, action) => {
        state.option = action.payload
    }
  }
})

export const { handleSwitchButton } = switchButtonsSlice.actions

export default switchButtonsSlice.reducer