import React, { ReactNode, useMemo } from "react";
import  {
  ButtonContainer,
  Label
} from "./styles"
import ReactLoading from "react-loading";

type ButtonProps = {
  onClick?: any,
  label?: string,
  icon?: ReactNode,
  fontSize?: string,
  color?: string,
  border?: string,
  noBorder?: boolean,
  backgroundColor?: string,
  width?: string,
  height?: string,
  padding?: string,
  typeButton?: "primary" | "secondary" | "outline",
  type?: "button" | "reset" | "submit",
  disabled?: boolean,
  style?: React.CSSProperties,
  loading?: boolean
}

const Button: React.FC<ButtonProps> = ({
  onClick, 
  label, 
  icon, 
  fontSize, 
  color, 
  border, 
  noBorder, 
  backgroundColor, 
  width,
  height,
  padding,
  typeButton = "primary",
  type = "button",
  disabled,
  style,
  loading
}) => {

  const colorLoading = useMemo(() => {
    switch (typeButton) {
      case 'primary':
        return '#FFFFFF'
      case 'outline':
        return '#002868'
      case 'secondary':
        return '#002868'
    
      default:
        return '#FFFFFF'
    }
  },[typeButton])

  return (
    <ButtonContainer
      type={type}
      $noBorder={noBorder}
      $backgroundColor={backgroundColor}
      width={width}
      height={height}
      $padding={padding}
      $typeButton={typeButton}
      onClick={onClick}
      $border={border}
      color={color}
      disabled={disabled}
      style={style}
    >
      {loading ? 
          <ReactLoading type={'spin'} color={colorLoading} height={'fit-content'} width={'5%'}/>
       :
       <>
          {label && (
            <Label fontSize={fontSize} $typeButton={typeButton} $color={color}>
              {label}
            </Label>
          )}
          {icon && (icon)}
       </>
       }
    </ButtonContainer>
  )
}

export default Button;