export const headTable = [
  {
    titleColumn: "Título",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Descrição",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "NSU",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Valor (R$)",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Parcelas",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Status",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Tentativa",
    tooltipText: "tooltip",
    subColumn: []
  },
]

export const optionsInstallments: Array<{label: string, value: string}> = [
  {
    label: 'À vista',
    value: '1',
  },
  {
    label: '2x',
    value: '2',
  },
  {
    label: '3x',
    value: '3',
  },
  {
    label: '4x',
    value: '4',
  },
  {
    label: '5x',
    value: '5',
  },
  {
    label: '6x',
    value: '6',
  },
  {
    label: '7x',
    value: '7',
  },
  {
    label: '8x',
    value: '8',
  },
  {
    label: '9x',
    value: '9',
  },
  {
    label: '10x',
    value: '10',
  },
]

export const status = {
  PAID: 'PAGO',
  EXPIRED: 'EXPIRADO',
  PENDING: 'PENDENTE'
}