export const headTablebyDay = [
  {
    titleColumn: "Data",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Nº de transações",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Valor Bruto",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "MDR",
    tooltipText: "tooltip",
    subColumn: ["R$", "%"]
  },
  {
    titleColumn: "Valor Crédito Líquido",
    tooltipText: "tooltip",
    subColumn: ["R$", "%"]
  },
  {
    titleColumn: "Valor Cliente",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Antecipação",
    tooltipText: "tooltip",
    subColumn: ["R$", "%"]
  },
  {
    titleColumn: "Valor Líquido Cliente",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "CET",
    tooltipText: "tooltip",
    subColumn: ["R$", "%"]
  },
]

export const headTablebyTransaction = [
  {
    titleColumn: "Data",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Autorização",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Código NSU",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Terminal",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Modalidade",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Valor Transação",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "MDR",
    tooltipText: "tooltip",
    subColumn: ["R$", "%"]
  },
  {
    titleColumn: "Transação Líquida",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Créditos Distribuidor",
    tooltipText: "tooltip",
    subColumn: ["R$", "%"]
  },
  {
    titleColumn: "Recebimento Bruto Cliente",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Antecipação",
    tooltipText: "tooltip",
    subColumn: ["R$", "%"]
  },
  {
    titleColumn: "Recebimento Líquido Cliente",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Valor Líquido Final",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "CET (%)",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Pagamento",
    tooltipText: "tooltip",
    subColumn: []
  },
]

export const headTableInstallmentsDetails = [
  {
    titleColumn: "Parcela",
    tooltipText: "tooltip",
    subColumn: []
  },
  {
    titleColumn: "Pagamento",
    tooltipText: "tooltip",
    subColumn: []
  },
]