/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react"
import HeaderDetails from "../../../components/Header/Components/HeaderDetails"
import { Infos, NewLinkMobileWrapper, WrapperMobile } from "../styles"
import Button from "../../../components/Button/Button"
import dayjs from "dayjs"
import { useLocation, useNavigate } from "react-router-dom"
import Card from "../../../components/Card/Card"
import { BiSolidCheckCircle } from "react-icons/bi"
import { useTheme } from "styled-components"
import { formatToCurrencyBRL } from "../../../utils/functions"
import { generatePaymentLink } from "../../../store/actions/linkPaymentActions"
import useAppDispatch from "../../../hooks/useAppDispatch"
import useAppSelector from "../../../hooks/useAppSelector"
import { AddressNContact } from "../../../store/myData"
import { toast } from "react-toastify"

interface StateProps {
  value: number
  installments: string
  sell_identity: string
  createdAt: Date
  addressNContact: AddressNContact
  mcc: number
  description: string
}

const NewLinkPageMobile: React.FC = () => {
  const theme = useTheme()
  const { state } = useLocation() as {state: StateProps}
  const navigate = useNavigate()
  
  const dispatch = useAppDispatch()
  const { user: { document: userCnpj, name } } = useAppSelector(state => state.user)
  const { loadingGeneratePaymentLink } = useAppSelector(state => state.linkPayment)

  const [linkCreated, setLinkCreated] = useState<string | null>(null)

  const handleGeneratePaymentLink = useCallback(async () => {
    const response = await dispatch(generatePaymentLink({
      title: state.sell_identity,
      amount: state.value,
      mcc: state.mcc,
      amount_per_installment: parseFloat(state.value.toString() || "0") / parseFloat(state.installments || "1"),
      client_document: userCnpj,
      addressNContact: state.addressNContact,
      installments: parseFloat(state.installments || "1"),
      descricao_cliente: state.description || "",
      nome_lojista: name
    }))

    if(response?.meta?.requestStatus === 'fulfilled') {
      setLinkCreated(response.payload?.link as string)
    }
    if(response?.meta?.requestStatus === 'rejected') {
      setLinkCreated(null)
      toast.error("Problema ocorrido durante geração de link de pagamento");
    }
  },[state, name, userCnpj])

  const copyURL = useCallback(() => {
    if (linkCreated) {
      toast.success("Link de Pagamento copiado com sucesso!");
      navigator.clipboard.writeText(linkCreated)
    }
  }, [linkCreated])

  return (
    <WrapperMobile>
      <HeaderDetails title='Novo Link de Pagamento' />
      <NewLinkMobileWrapper>
        <div style={{padding: '0 1rem'}}>
          {linkCreated ?
            <Card typeCard="fourth">
              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem'
              }}>
                <BiSolidCheckCircle size={70} color='rgba(74, 174, 65, 1)' />
                <h1 style={{
                  color: theme.colors.blue,
                  fontWeight: theme.font.bold,
                  margin: 0,
                  width: '60%',
                }}>
                  Criação de Link Concluída!
                </h1>
              </div>
            </Card>
          :
            <Card
                title='Valor Total do Link Gerado'
                balance={state.value}
                description={dayjs(state.createdAt).locale('pt-br').format('HH:mm | DD/MM/YYYY')}
                loading={false}
                typeCard="tertiary"
            />
          }
        </div>

        <div className="wrapper-details">
          <div>
            <div className="details-installments">
              <span>Número de Parcelas</span>
              <span>{state.installments}x</span>
            </div>
            <div className="details-installments">
              <span>Valor por Parcelas</span>
              <span>{formatToCurrencyBRL(parseFloat(state.value.toString() || "0") / parseFloat(state.installments || "1"))}</span>
            </div>
            <div className="details-installments" style={{display: linkCreated ? 'flex' : 'none'}}>
              <span>Valor Gerado</span>
              <span>{formatToCurrencyBRL(state.value)}</span>
            </div>
          </div>

          <div style={{padding: '0 1rem 3rem 1rem'}}>
            {!linkCreated && (
              <Infos>
                <h5>ATENÇÃO</h5>
                <p>
                  Por se tratar de uma venda não presencial, sem o uso do chip ou senha, o CLIQUE PAGUE aumenta o risco de CANCELAMENTO DA VENDA POR SOLICITAÇÃO DO PORTADOR DO CARTÃO junto ao seu emissor (chargeback).
                  <br/>
                  <br/>
                  Sempre que possível, utilize nossas maquininhas de forma presencial para ter garantia da conclusão da venda. 
                </p>
              </Infos>
            )}
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <Button
                onClick={linkCreated ? () => navigate('/home') : () => navigate(-1)}
                label={linkCreated ? "Voltar Para a Home" : "Cancelar"}
                width="45%"
                typeButton="outline"
                type="button"
                />
              <Button
                type="submit"
                onClick={linkCreated ? copyURL : handleGeneratePaymentLink}
                label={linkCreated ? "Copiar Link" : "Confirmar e Gerar Link"}
                width="45%"
                loading={loadingGeneratePaymentLink}
                />
            </div>
          </div>
        </div>
        
      </NewLinkMobileWrapper>
    </WrapperMobile>
  )
}

export default NewLinkPageMobile