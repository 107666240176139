import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../configureStore';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import api from '../../services/api';
import apiAdmin from '../../services/apiAdmin';
import jwt_decode from 'jwt-decode'
import { optionType, profiles } from '../../constants';
import { ReceivementsByTransactionItemProps } from '../types/TypesReceivements';
import { parseToFormattedDate } from '../../utils/functions';

export const getBalanceCredmoura = createAsyncThunk(
  'receivements/getBalanceCredmoura',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/affiliate-program/statements`)
      return response?.data
    } catch (error: any) {
      toast.error("Erro ao buscar saldo credmoura!")
      return rejectWithValue(error);
    }
})

export const getBalanceCreditsToRelease = createAsyncThunk(
  'receivements/getBalanceCreditsToRelease',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/affiliate-program/creditsToRelease?offset=0&limit=1000000&startDate=&endDate=`)
      return response?.data?.sum
    } catch (error: any) {
      // toast.error("Erro ao buscar saldo de créditos a liberar!")
      return rejectWithValue(error);
    }
})

export const getBalanceOpenBankSlip = createAsyncThunk(
  'receivements/getBalanceOpenBankSlip',
  async (_, { rejectWithValue }) => {
    try {

      const response = await api.get(`/parceiroMoura/boletosEmAberto`)
      return response?.data?.sum
    } catch (error: any) {
      // toast.error("Erro ao buscar saldo de boletos em aberto!")
      return rejectWithValue(error);
    }
})


export const getReceivements = createAsyncThunk(
  'receivements/getReceivements',
  async (
    data: {
      offset: number
      startDate: string,
      endDate: string
    }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/paymentsPerDay?offset=${data.offset}&limit=10&startDate=${data.startDate}&endDate=${data.endDate}`)
      return response?.data
    } catch (error: any) {
      // toast.error("Erro ao buscar recebimentos no período selecionando!")
      return rejectWithValue(error);
    }
})


export const getReceivementsOfTheDay = createAsyncThunk(
  'receivements/getReceivementsOfTheDay',
  async (_, { rejectWithValue }) => {
    try {

      const today = parseToFormattedDate(dayjs() as unknown as Date)

      const response = await api.get(`/totalizedPayments?&startDate=${today}&endDate=${today}`)
      return response?.data?.totals?.netValue
    } catch (error: any) {
      toast.error("Erro ao buscar recebimentos do dia!")
      return rejectWithValue(error);
    }
})

export const getReceivementsOfTheMonth = createAsyncThunk(
  'receivements/getReceivementsOfTheMonth',
  async (_, { rejectWithValue }) => {
    try {

      const date = new Date()
      const fistDayOfTheMonthFormated = parseToFormattedDate(new Date(date.getFullYear(), date.getMonth(), 1))
      const lastDayOfTheMonthFormated = parseToFormattedDate(new Date(date.getFullYear(), date.getMonth() + 1, 0))

      const response = await api.get(`/totalizedPayments?&startDate=${fistDayOfTheMonthFormated}&endDate=${lastDayOfTheMonthFormated}`)
      return response?.data?.totals?.netValue
    } catch (error: any) {
      toast.error("Erro ao buscar recebimentos do mês!")
      return rejectWithValue(error);
    }
})

export const getReceivementsByPeriod = createAsyncThunk(
  'receivements/getReceivementsByPeriod',
  async (
    data: {
    startDate: string,
    endDate: string
  }, { rejectWithValue }) => {
    try {

      const response = await api.get(`/totalizedPayments?&startDate=${data.startDate}&endDate=${data.endDate}`)
      return response?.data?.totals?.netValue
    } catch (error: any) {
      toast.error("Erro ao buscar recebimentos no período selecionando!")
      return rejectWithValue(error);
    }
})

export const getCredits = createAsyncThunk(
  'receivements/getCredits',
  async (
    data: {
      offset: number,
      startDate: string,
      endDate: string
    }, { rejectWithValue }) => {
    try {

      const response = await api.get(`/affiliate-program/credits?offset=${data.offset}&limit=10&startDate=${data.startDate}&endDate=${data.endDate}`)
      return response?.data
    } catch (error: any) {
      toast.error("Erro ao buscar dados de créditos a liberar!")
      return rejectWithValue(error);
    }
})

export const getEventsByDayReceivements = createAsyncThunk(
  'receivements/getEventsByDayReceivements',
  async (
    data: {
      startDate: string,
      endDate: string
    }, { rejectWithValue }) => {
    try {

      const response = await api.get(`/receivementsPerDay?startDate=${data.startDate}&endDate=${data.endDate}`)
      return response?.data
    } catch (error: any) {
      toast.error("Erro ao buscar eventos do calendário!")
      return rejectWithValue(error);
    }
})

export const getEvent = createAsyncThunk(
  'receivements/getEvent',
  async (
    data: {
      date: string
    }, { rejectWithValue }) => {
    try {

      const response = await api.get(`/receivementsFilter?&date=${data.date}`)
      return response?.data
    } catch (error: any) {
      toast.error("Erro ao buscar informações do dia selecionado!")
      return rejectWithValue(error);
    }
})

export const getReceivementsByDay = createAsyncThunk(
  'receivements/getReceivementsByDay',
  async (
    data: {
      offset: number,
      startDate: string,
      endDate: string
    }, { rejectWithValue }) => {
    try {

      const response = await api.get(`/paymentsPerDay?offset=${data.offset}&limit=10&startDate=${data.startDate}&endDate=${data.endDate}`)
      return response?.data
    } catch (error: any) {
      toast.error("Erro ao buscar dados de recebimentos por dia!")
      return rejectWithValue(error);
    }
})

export const getReceivementsByTransaction = createAsyncThunk(
  'receivements/getReceivementsByTransaction',
  async (
    data: {
      offset: number,
      startDate: string,
      endDate: string
    }, { rejectWithValue }) => {
    try {

      const response = await api.get(`/payments?offset=${data.offset}&limit=10&startDate=${data.startDate}&endDate=${data.endDate}`)
      return response?.data
    } catch (error: any) {
      toast.error("Erro ao buscar dados de recebimentos por transação!")
      return rejectWithValue(error);
    }
})

export const getLastFiveReceivements = createAsyncThunk(
  'receivements/getLastFiveReceivements',
  async (
    data: {
      schedule: 'LOJA' | 'MOURA',
      cacheLayer: '1' | '2'
    }, { rejectWithValue, getState, signal }) => {
    try {

      let state = getState() as RootState

      const response = await apiAdmin.get(`/receivements/lastfive?schedule=${data.schedule}`)
      if (state.switchButtons.option !== data.schedule) {
        throw new Error();
      }
      return response?.data
    } catch (error: any) {
      // toast.error("Erro ao buscar recebimentos no período selecionando!")
      return rejectWithValue(error);
    }
})

export const getReceivementsMobileLoja = createAsyncThunk(
  'receivements/getReceivementsMobileLoja',
  async (
    data: {
      startDate: string,
      endDate: string
    }, { rejectWithValue }) => {
    try {

      const response = await api.get(`/receivementsPerDay?startDate=${data.startDate}&endDate=${data.endDate}`)
      return response?.data
    } catch (error: any) {
      toast.error("Erro ao buscar recebimentos!")
      return rejectWithValue(error);
    }
})


export const getNsuReceivements = createAsyncThunk(
  'receivements/getNsuReceivements',
  async (
    data: {
      date: string,
    }, { rejectWithValue, getState }) => {
    try {

      const state = getState() as RootState
      const { token, user: { profile } } = state.user
      const { option } = state.switchButtons
      const { percentual_split } = jwt_decode<any>(token).user


      const response = await api.get(`/payments?offset=0&limit=500&startDate=${data.date}&endDate=${data.date}`)
      const arrayNsu = response?.data?.rows?.map((item: ReceivementsByTransactionItemProps) => {
        return {
          nsu: item?.nsu,
          percentage: profile === profiles.FLEX ? (option === optionType.MOURA ? 100 - percentual_split : percentual_split) : null,
          value: item?.clientNetValue
        }
      })
      return arrayNsu
    } catch (error: any) {
      toast.error("Erro ao buscar dados de recebimentos!")
      return rejectWithValue(error);
    }
})

export const getReceivementsByDateAndNSU = createAsyncThunk(
  'receivements/getNsuReceivementsByDay',
  async (
    data: {
      schedule: 'LOJA' | 'MOURA',
      date: string,
      open: boolean
    }, { getState}
  ) => {
    const state = getState() as RootState

    if (state.receivements.receivementsByDayAndNSU.some(receivements => receivements.day === data.date && receivements.loading === false && receivements.schedule === data.schedule)) {
      return;
    }

    const response = await apiAdmin.get(`/receivements/receivementsbydategroupedbysale/${data.date}?schedule=${data.schedule}`)

    return response.data;
  }
)

export const exportTransactionPerTransactions = createAsyncThunk(
  'receivements/exportTransactionPerTransactions',
  async (
    data: {
      startDate: string,
      endDate: string,
    }, {rejectWithValue}
  ) => {
    try {

      const response = await api.get(`/exportSalesPerTransaction?startDate=${data.startDate}&endDate=${data.endDate}`)
  
      return response?.data;
      
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
)

export const exportTransactionPerDay = createAsyncThunk(
  'receivements/exportTransactionPerDay',
  async (
    data: {
      startDate: string,
      endDate: string,
    }, {rejectWithValue}
  ) => {
    try {
  
      const response = await api.get(`/exportSalesPerDay?startDate=${data.startDate}&endDate=${data.endDate}`)
  
      return response?.data;
      
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
)

export const exportSpreadsheet = createAsyncThunk(
  'receivements/exportSpreadsheet',
  async (
    data: {
      url: string,
    }, {rejectWithValue}
  ) => {
    try {
  
      const response = await api.get(`${data.url}`)
  
      return response?.data;
      
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
)