import React, { useEffect }  from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import propigIcon from '../../assets/cred-moura-icon.svg'

import './styles.scss'

const Cover = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { search } = useLocation()
    const [_, showCover] = search.split('=')

    useEffect(() => {
        if (showCover !== 'true') navigate('/login')
    }, [])

    return (
        <div className='cover-container'>
            <div className='cover-opacity'/>
            <div className='cover-img'></div>
            <div className='cover-content text-center'>
                <div className='row'>
                    <div className='col'>
                        <img src={propigIcon} alt='' className='cover-logo-img'/>
                    </div>
                </div>
                <div className='row text-left cover-title'>
                    <div className='col typography-lg'>
                        <p>Olá,</p>
                        <p>Seja</p>
                        <p>bem-vindo</p>
                        <p>ao seu App</p>
                        <p>CRED<span style={{ fontWeight: '100'} }>MOURA</span></p>
                    </div>
                </div>
                <div className='row cover-button'>
                    <div className='col col-md-4'>
                        <button type='button' className='btn-propig-secondary typography-md' onClick={() => navigate('/login')}>Iniciar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cover