import React, { useMemo, useState, useEffect } from "react"
import HeaderDetails from "../../../components/Header/Components/HeaderDetails"
import { BodyTable, ContentTable, HeadTable, WrapperButtons, WrapperFooter, WrapperMobile } from "../styles"
import { PaymentsLinksProps, Props } from ".."
import Button from "../../../components/Button/Button"
import CreatePaymentLinkMobile from "./CreatePaymentLinkMobile"
import { FaCircle } from "react-icons/fa6"
import { allowSwitchButtons, formatToCurrencyBRL } from "../../../utils/functions"
import SwitchButtons from "../../../components/SwitchButtons"
import useAppSelector from "../../../hooks/useAppSelector"
import linkIconWhite from '../../../assets/linkpagamento-icon-white.svg'
import noItemsIcon from '../../../assets/no-items.svg'
import ReactLoading from "react-loading"
import dayjs from "dayjs"
import { months } from "../../../utils/constants"
import { status } from "./contants"

const LinkPagamentoMobile: React.FC<Props> = ({
  data,
  loading,
  currentPage,
  totalItems,
  setCurrentPage,
  statusFilter,
  setStatusFilter,
  exportS,
  fetchData
  }) => {
  const { user: { profile }} = useAppSelector(state => state.user)
  const [open, setOpen] = useState(false)

  const [groupedData, setGroupedData] = useState<PaymentsLinksProps[]>([])

  const totalPagination = useMemo(() => {
      return Math.ceil(totalItems / 10) - 1
  },[totalItems])

  useEffect(() => {
    setGroupedData((oldData) => [...oldData, ...data])
  },[data])

  const paymentsGroupedFormated: Array<{day: string; value: number; items: PaymentsLinksProps[]}> = useMemo(() => {
    return Object.values(groupedData?.reduce((acc: any, curr) => {
      const day = curr?.dataCriacao.substring(0, 10)
      if(!acc[day]) {
        acc[day] = { day, value: 0, items: [] }
      }
  
      acc[day].items.push(curr)
      acc[day].value += curr?.value
  
      return acc
    },{}))

  },[groupedData])

  const handleStatus = (status: 'PAID' | 'NOTPAID') => {
    setGroupedData([])
    setStatusFilter(status)
  }

  return (
    <WrapperMobile>
      <HeaderDetails title='Link de Pagamento' />
      <div className='switch-button-container'>
        {allowSwitchButtons(profile) && <SwitchButtons/>}
      </div>
      <div style={{
        padding: '20px'
      }}>
        <Button
          onClick={() => setOpen(true)}
          label="Criar Link de Pagamento"
          icon={<img src={linkIconWhite} alt='Ícone Link de Pagamento'/>}
          width="100%"
        />
      </div>
      <WrapperButtons>
        <Button
          width='40%'
          onClick={() => handleStatus('PAID')}
          label="Links pagos"
          typeButton={statusFilter === "PAID" ? 'primary' : 'outline'}
        />
        <Button
          width='40%'
          onClick={() => handleStatus('NOTPAID')}
          label="Links não pagos"
          typeButton={statusFilter === "NOTPAID" ? 'primary' : 'outline'}
        />

      </WrapperButtons>
      <ContentTable>
        {paymentsGroupedFormated?.map((item, idx) => (
          <React.Fragment key={idx}>
            <HeadTable id={`list-item-${idx}`}>
              <span><strong>{`${dayjs(item?.day).format("DD")} de ${months[Number(dayjs(item?.day).format("M"))]}.`}</strong></span>
              <span>Valor Bruto: <strong>{formatToCurrencyBRL(item?.value)}</strong></span>
            </HeadTable>
            <BodyTable>
              {item?.items?.map((children, idxChildren) => (
                <div key={idxChildren} className="wrapper-details">
                  <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
                    <FaCircle className={children?.status.toLocaleLowerCase()} />
                    <div className="wrapper-status">
                      <span className='confirmation-hour'>{dayjs(children?.dataAtualizacao).format('HH:mm')} | {status[children?.status]}</span>
                      <span><strong>{children?.title}</strong></span>
                    </div>
                  </div>
                  <span className="balance">{formatToCurrencyBRL(children?.value)}</span>
                </div>
              ))}
            </BodyTable>
          </React.Fragment>
        ))}
        <WrapperFooter>
            {loading ? 
                <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'}/>
              :
              <>
                {paymentsGroupedFormated?.length === 0 ? 
                    <div className='message-not-found'>
                        <img src={noItemsIcon} alt=""/>
                        <span>Você não possui registros no período selecionado!</span>
                    </div>
                  :
                  <>
                    {!(currentPage === totalPagination) && 
                        <Button
                            label='Carregar mais resultados'
                            onClick={() => setCurrentPage(currentPage + 1)}
                        />
                    }
                    {groupedData?.length >= 6 && (
                      <a href="#list-item-0">Voltar para o topo!</a>
                    )}
                  </>
                  }
              </>
              }
        </WrapperFooter>
      </ContentTable>

      <CreatePaymentLinkMobile open={open} setOpen={setOpen} />
    </WrapperMobile>
  )
}

export default LinkPagamentoMobile