import React from 'react';
import Router from './Router'
import ReactLoading from 'react-loading'
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAppSelector from './hooks/useAppSelector';
import Modals from './components/Modals';

// Define o elemento raiz da sua aplicação
Modal.setAppElement('#root');
const App = () => {
    const { isAuthenticated } = useAppSelector(state => state.user)
    const { loadingPopupPermission, loadingShortToken } = useAppSelector(state => state.functionalities)
    const { loadingExportSales } = useAppSelector(state => state.sales)

    return (
        <>
            {(loadingPopupPermission || loadingShortToken || loadingExportSales) && (
                <div className='container-loading'>
                    <ReactLoading type={'spin'} color={'#002868'} width={'10%'}/>
                </div>
            )}
            <Router isAutheticated={isAuthenticated}/>
            <ToastContainer />
            <Modals />
        </>
    )
}

export default App