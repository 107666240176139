import React, { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form";
import { ContainerForm, ContentForm, ContentTitle, Row, Title } from "../styles"
import ReactLoading from "react-loading";
import Button from "../../../components/Button/Button";
import InputMask from "react-input-mask";
import { BsEye, BsEyeSlash } from "react-icons/bs";

type FormDistributor = {
  cnpj: string,
  address: string,
  city: string,
  uf: string,
  cep: string,
  name: string
}

type DistributorType = {
  cnpj: string,
  address: string,
  city: string,
  uf: string,
  cep: string,
  phone: string
  razaoSocial: string
  loading: boolean
}

const DistributorForm = ({
  cnpj,
  address,
  city,
  cep,
  phone,
  uf,
  razaoSocial,
  loading
 }: DistributorType) => {
  const [openForm, setOpenForm] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      cnpj: "",
      address: "",
      city: "",
      uf: "",
      cep: "",
      phone: "",
      name: ""
    }
  });

  const onSubmit = (data: FormDistributor) => console.log(data);

  useEffect(() => {
    setValue('address', address ?? "")
    setValue('city', city ?? "")
    setValue('cnpj', cnpj ?? "")
    setValue('cep', cep ?? "")
    setValue('phone', phone ?? "")
    setValue('uf', uf ?? "")
    setValue('name', razaoSocial ?? "")
  },[
    cnpj,
    address,
    city,
    cep,
    phone,
    uf,
    razaoSocial
  ])

  return (
    <ContainerForm>
    <ContentTitle>
      <Title>Meu distribuidor</Title>
    </ContentTitle>
    <ContentForm>
      {!openForm && (
        <>
          {loading ? (
            <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'5%'}/>
          ) : (
            <Button width="35%" height="40px" onClick={() => setOpenForm(true)} label="Visualizar" typeButton="outline" icon={<BsEye color="#002868" />}/>
          )}
        </>
      )}
      {openForm && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <input placeholder="Nome" disabled {...register("name")} />
          <Controller
            control={control}
            name="cnpj"
            render={({field}) => <InputMask disabled placeholder="CNPJ" mask="99.999.999/9999-99" {...field} />}
          />
          <input placeholder="Endereço" disabled {...register("address")} />
          <Row>
            <input placeholder="Cidade" disabled {...register("city")} />
            <input placeholder="UF" disabled {...register("uf")} />
            <Controller
              control={control}
              name="cep"
              render={({field}) => <InputMask placeholder="CEP" disabled mask="99999-999" {...field} />}
            />
          </Row>
          <Row>
          <Controller
              control={control}
              name="phone"
              render={({field}) => <InputMask placeholder="Telefone" disabled mask="(99) 9999-9999" {...field} />}
            />
          </Row>
          <div className="content-buttons">
            <Button width="35%" height="40px" icon={<BsEyeSlash color="#002868" />} onClick={() => setOpenForm(false)} label="Fechar" typeButton="outline"/>
            {/* <Button type="submit" width="30%" label="Salvar" typeButton="primary" disabled/> */}
          </div>
        </form>
      )}

    </ContentForm>
  </ContainerForm>
  )
}

export default DistributorForm