import axios from 'axios'
import { DateTime } from 'luxon'
import config from '../config'

export const rents = async (startDate: Date, endDate: Date, offset: any, token: any) => {
  const response = await axios.get(`${config.BASE_URL_ADMIN}/charges/rents/find?limit=10&startDate=${DateTime.fromJSDate(startDate).toFormat('dd-MM-yyyy')}&endDate=${DateTime.fromJSDate(endDate).toFormat('dd-MM-yyyy')}&page=${offset}`, {
      headers: {
          'x-api-key': config.API_KEY, 
          'authorization': `Bearer ${window.localStorage.getItem('token')}`
      },
  })

  return response?.data
}

export const rentsContact = async (dataPagamento: Date, valorBrutoMensal: number, status: string, id: string) => {
  const token = window.localStorage.getItem('token')

  await axios.post(`${config.BASE_URL}/rental/solicitarInformacoes`, {
    dataPagamento: dataPagamento.toISOString(),
    valorBrutoMensal,
    Status: status === "PAID" ? "Pago" : "Pendente",
    id: id
  }, {
    headers: {
        'x-api-key': config.API_KEY, 
        'authorization': `Bearer ${token}`
    },
  })
}