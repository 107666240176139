/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import MenuMobile from './Components/Mobile'
import MenuDesktop from './Components/Desktop'
import useMedia from '../../hooks/useMedia'

const Menu: React.FC<any> = ({ showSharedComponent }) => {
    const mobile = useMedia('(max-width: 40rem)')

    return (
        <>
            {mobile === true && <MenuMobile />}
            {mobile === false && <MenuDesktop />}
        </>
    )
}

export default Menu