import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type Titles = 'Solicite um contado' | ''

interface InitialStateProps {
  title: Titles
  open: boolean
}


const initialState: InitialStateProps = {
  title: '',
  open: false
}

export const modalsSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    handleOpenModal: (state, action: PayloadAction<Titles>) => {
      state.title = action.payload
      state.open = true
    },
    handleCloseModal: (state, action) => {
      state.title = action.payload
      state.open = false
    },
  }
})

export const { handleOpenModal, handleCloseModal } = modalsSlice.actions


export default modalsSlice.reducer