/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import ReactModal from 'react-modal';

import Swal from 'sweetalert2'

import { optionType } from '../../constants'
import ReactLoading from 'react-loading'
import jwt_decode from 'jwt-decode'
import Button from '../Button/Button';
import { RxMagnifyingGlass } from "react-icons/rx"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { TbLogout } from "react-icons/tb"
import useMedia from '../../hooks/useMedia';
import {
    ContainerModal,
    ContentInput,
    ContentUsers,
    Divider,
    UserInfo } from './styles';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { handleSwitchButton } from '../../store/switchButtons';
import { TokenType, authenticator, logoutUser, saveUser } from '../../store/user';
import { getUserByDocument, updateLogin, onDemand } from '../../store/actions/userActions';
import { clearNotNumber, formatCNPJ, getProfile, isProfileAllowMoura } from '../../utils/functions';
import HeaderDesktop from './Components/Desktop';
import HeaderMobile from './Components/Mobile';

const modalEstilo = {
    content: {
        width: "80%",
        maxWidth: "350px",
        maxHeight: '560px',
        height: "max-content",
        left: "auto",
        top: "80px",
        margin: "none",
        zIndex: 9999,
        padding: "0px 10px 10px 10px",
        borderRadius: "20px",
        boxShadow: "0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.212)",
        borderColor: "#F6F6F6",

    }
};

type Profile = "multi-agenda" | "flex" | "cessao" | "maquina" | undefined

const Navbar: React.FC<any> = () => {
    const mobile = useMedia('(max-width: 40rem)')
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { user: { username }, token, loadingGetUserByDocument} = useAppSelector(state => state.user)

    const user = jwt_decode<{user: TokenType}>(token).user

    const [modalOpen, setModalOpen] = useState(false);
    const [inputValor, setInputValor] = useState('');

    const toogleModal = useCallback(() => setModalOpen(!modalOpen), [modalOpen])

    const [usersByDocument, setUsersByDocument] = useState<Array<{nome: string, documento: string}>>([])


    const getUser = useCallback(async () => {
        const response = await dispatch(getUserByDocument({documento: inputValor}))
        if(response.meta.requestStatus === "fulfilled") {
            setUsersByDocument(response?.payload?.rows)
        }
    }, [inputValor])

    const updateUser = async (document: string) => {
        const response = await dispatch(updateLogin({documento: document}))
        if(response.meta.requestStatus === "fulfilled") {
            const user = jwt_decode<any>(response?.payload?.token).user
            dispatch(saveUser({...user, username}))
            dispatch(authenticator({ isAuthenticated: true, token:response?.payload?.token }))
            dispatch(onDemand());

            const profile = getProfile(user)
            dispatch(handleSwitchButton(isProfileAllowMoura(profile as Profile) ? optionType.MOURA : optionType.LOJA))
            
            setModalOpen(false);
            window.location.reload()
        }
    }

    const logout = () => {
        Swal.fire({
            text: 'Deseja realmente sair do app?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#002868',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            cancelButtonColor: '#FFD400'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(logoutUser({ isAuthenticated: false, token: '' }))
                navigate(`/login`)
            }
        })
    }

    const relatedUsers = useMemo(() => {
        if(usersByDocument.length > 0) {
            return usersByDocument?.filter((item) => item?.documento.includes(inputValor))
        }
        return user?.heranca?.filter((item) => item?.documento.includes(inputValor))
    },[inputValor, user?.heranca, usersByDocument])

    return (
        <>
            {mobile === true  && <HeaderMobile toogleModal={toogleModal} />}
            {mobile === false && <HeaderDesktop openModal={modalOpen} toogleModal={toogleModal} />}
            <ReactModal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                contentLabel="Buscar Cliente"
                style={modalEstilo}
            >
                <ContainerModal>
                    <Button
                        icon={<AiOutlineCloseCircle color='#002868' />}
                        typeButton="outline"
                        noBorder
                        width='40px'
                        onClick={() => setModalOpen(false)}
                    />
                    <ContentInput>
                        <input value={inputValor} className="form-control mb-3" type="text" placeholder="Digite o CNPJ" onChange={(e) => setInputValor(clearNotNumber(e.target.value))} />
                        <Button
                            icon={<RxMagnifyingGlass color='#002868' />}
                            onClick={() => getUser()}
                            typeButton="outline"
                            noBorder
                            width='40px'
                        />
                    </ContentInput>
                    <ContentUsers>
                        {loadingGetUserByDocument ? (
                            <div className='content-loading'>
                                <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'}/>
                            </div>
                        ) : (
                            <>
                                {relatedUsers?.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <UserInfo
                                            $active={item?.documento === user?.documento}
                                            onClick={() => updateUser(item?.documento)}
                                        >
                                            <p>{item?.nome}</p>
                                            <span>{formatCNPJ(item?.documento)}</span>
                                        </UserInfo>
                                        <Divider />
                                    </React.Fragment>
                                ))}                       
                            </>
                        )}
                    </ContentUsers>
                    <Button
                        onClick={logout}
                        label="Sair"
                        icon={<TbLogout color='#002868' />}
                        typeButton="outline"
                        fontSize="14px"
                        noBorder
                    />
                </ContainerModal>
            </ReactModal>
        </>
    )
}

export default Navbar