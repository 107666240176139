/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { 
  BodyTable,
  ButtonBackToTop,
  ContainerList,
  ContainerMobile,
  HeadTable,
  WrapperButton } from "../styles"
import HeaderDetails from "../../../components/Header/Components/HeaderDetails"
import {
  allowSwitchButtons,
  formatToCurrencyBRL,
  getLastUpdate,
  parseToFormattedDate } from "../../../utils/functions"
import SwitchButtons from "../../../components/SwitchButtons"
import Card from "../../../components/Card/Card"
import Button from "../../../components/Button/Button"
import DatePickerRange from "../../../components/DatePickerRange/DatePickerRange"
import ReactLoading from "react-loading"
import noItemsIcon from '../../../assets/no-items.svg'
import useAppSelector from "../../../hooks/useAppSelector"
import { optionType, profiles } from "../../../utils/constants"
import dayjs from "dayjs"
import { IoIosArrowForward } from "react-icons/io"
import useAppDispatch from "../../../hooks/useAppDispatch"
import { useNavigate } from "react-router-dom"
import { 
  getBalanceCredmoura,
  getEventsByDayReceivements,
  getReceivementsByPeriod,
  getReceivementsMobileLoja } from "../../../store/actions/receivementsActions"
import { Events } from "../../../store/receivements"
import jwt_decode from 'jwt-decode'

interface Props {
  lastEventCalendarLoja: { value: number, date: string }
  dateLastEvent: string
}


const RecebimentosMobile: React.FC<Props> = ({
  lastEventCalendarLoja,
  dateLastEvent
  }) => {

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  
  const { token, user: { profile }} = useAppSelector(state => state.user)
  const option = useAppSelector(state => state.switchButtons.option)
  const { percentual_split } = jwt_decode<any>(token).user


  const {
    balanceCredmoura,
    loadingBalanceCredmoura,
    operacoesArray,
    loadingOperacoes,
    balanceReceivementsByPeriod,
    loadingEventsReceivementsByDay,
    operacoesObject,
    loadingReceivementsMobileLoja,
  } = useAppSelector(state => state.receivements)

  const [dataTableMobile, setDataTableMobile] = useState<any>([])
  const [pageMobile, setPageMobile] = useState(1)
  const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState(dayjs().locale('pt-br').subtract(1, 'day').toDate());
  const [dateOpen, setDateOpen] = useState(false)

  const lastUpdate = useMemo(() => {
    return getLastUpdate(operacoesObject)
  },[operacoesObject])

  const toogleDate = useCallback(() =>{
    setDateOpen(!dateOpen)
  },[dateOpen])

  useEffect(() => {
    setDataTableMobile([])
    handleSearch()
  },[option])

  useEffect(() => {
    if(option === optionType.LOJA) {
      //LOJA: EVENTOS DO CALENDARIO
      const dateForThreeMonthsAgo = dayjs().locale('pt-br').startOf('month').subtract(12, 'month').toDate()
      fetchReceivementsByMonthLoja(parseToFormattedDate(dateForThreeMonthsAgo), parseToFormattedDate(endDate), token)
    }
  }, [token, option])

  //LOJA: RECEBIMENTOS POR PERÍODO
  const fetchReceivementsByPeriod = async (startDate: any, endDate: any) => {
    await dispatch(getReceivementsByPeriod({startDate: startDate, endDate: endDate}))
  }
  //LOJA: EVENTOS DO CALENDARIO
  const fetchReceivementsByMonthLoja = async (startDate: any, endDate: any, token: any) => {
    await dispatch(getEventsByDayReceivements({startDate: startDate, endDate: endDate}))
  }
  
  const receivementsByPeriodMoura = useMemo(() => {
    const extract: Array<any> = []
    const operationsFiltered = operacoesArray?.filter(
    (operation: any) => operation.date >= startDate && operation.date <= endDate
    )

    operationsFiltered?.forEach((item: any) => {
        extract.push(...item?.items)
    })

    return extract.reduce((acc: any, curr: any) => {
        if(curr?.Type === "Entrada") {
            return acc + curr?.Valor
        }
        return acc 
    }, 0)
    
  },[operacoesArray, startDate, endDate])

  const totalPaginationMobile = useMemo(() => {
    return Math.ceil(dataTableMobile?.length / 10)
  },[dataTableMobile])

  const dataTableMobileWithPagination = useMemo(() => {
    return dataTableMobile?.slice(0, pageMobile * 10)
  },[pageMobile, dataTableMobile])

  const onChangeMobile = useCallback((dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
        toogleDate()
    }
  }, [dateOpen])

  const handleSearch = async () => {
    setPageMobile(1)
    setDataTableMobile([])
    if(option === optionType.MOURA) {
        const response = await dispatch(getBalanceCredmoura())
        if(response?.meta?.requestStatus === "fulfilled") {
            const operacoes = response?.payload?.Operacoes
            const transactions = !operacoes ? [] : Object.keys(operacoes).map(key => {
                const value = operacoes[key].reduce((acc: any, cur: any) => {
                    if (cur.Valor > 0) return acc + cur.Valor
                    return acc
                }, 0)
                return {
                    date: dayjs(key).locale('pt-br').format('DD/MM/YYYY'),
                    value,
                    percentage: profile === profiles.FLEX ? 100 - percentual_split : null
                }
            }, [])

            .sort(
                (a: any, b: any) =>
                    new Date(b["Data da Transação"]).getTime() -
                    new Date(a["Data da Transação"]).getTime()
                )

            const transactionsRange = transactions?.filter(transaction => {
                const splitedDate = transaction.date.split('/')
                const formatedDate = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}T00:00:00`
                return (new Date(formatedDate) >= new Date(startDate) && new Date(formatedDate) <= new Date(endDate))
            }).sort((a, b) => {
                const dateA = a.date.split('/')
                const dateB = b.date.split('/')
                const formatedDateA = new Date(`${dateA[2]}-${dateA[1]}-${dateA[0]}T00:00:00`)
                const formatedDateB = new Date(`${dateB[2]}-${dateB[1]}-${dateB[0]}T00:00:00`)
                return  formatedDateB.getTime() - formatedDateA.getTime()
            })
            setDataTableMobile(transactionsRange)
        }
    } else {
        //LOJA: RECEBIMENTOS POR PERÍODO
        fetchReceivementsByPeriod(parseToFormattedDate(startDate), parseToFormattedDate(endDate))

        const response = await dispatch(getReceivementsMobileLoja({startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate)}))
        if(response?.meta?.requestStatus === "fulfilled") {
            const transactions = response?.payload?.map((item: Events) => ({
                date: item.receivementDate,
                value: item.dailyReceivement,
                percentage: profile === profiles.FLEX ? percentual_split : null
            })).sort((a: any, b: any) => {
                const dateA = a.date.split('/')
                const dateB = b.date.split('/')
                const formatedDateA = new Date(`${dateA[2]}-${dateA[1]}-${dateA[0]}T00:00:00`)
                const formatedDateB = new Date(`${dateB[2]}-${dateB[1]}-${dateB[0]}T00:00:00`)
                return  formatedDateB.getTime() - formatedDateA.getTime()
            })
            setDataTableMobile(transactions)
        }
    }
  }

  const redirectToDetails = (date: string) => {
    const [day, month, year] = date.split('/')
    const formatedDate = `${parseToFormattedDate(new Date(Number(year), Number(month) - 1, Number(day)))}T00:00:00`
    navigate(`details?date=${formatedDate}`)
  }

  return (
    <ContainerMobile>
      <HeaderDetails title='Relatório de Recebimentos' />
      <div className='switch-button-container'>
          {allowSwitchButtons(profile) && <SwitchButtons/>}
      </div>
      <div style={{margin: "20px"}}>
          {option === optionType.MOURA ? (
              <Card
                  title={"Saldo CREDMOURA"}
                  description={`Última atualização: ${lastUpdate}`}
                  balance={balanceCredmoura}
                  loading={loadingBalanceCredmoura}
              />
          ) : (
              <Card
                  title={"Último recebimento"}
                  description={dateLastEvent}
                  balance={lastEventCalendarLoja.value}
                  loading={loadingEventsReceivementsByDay}
              />
          )}
      </div>
      <ContainerList>
          <div className='content-filters'>
              <DatePickerRange startDate={startDate} endDate={endDate} toogleDate={toogleDate} onChange={onChangeMobile} dateOpen={dateOpen} onClickOutside={() => {setDateOpen(false)}} />
              <Button onClick={() => handleSearch()} label="Filtrar" width="30%" typeButton="primary"/>
          </div>
          <Card
              borderNone
              title={"Recebimentos no período"}
              description={`De ${startDate ? dayjs(startDate).locale('pt-br').format('DD/MM/YYYY') : ''} Até ${endDate ? dayjs(endDate).locale('pt-br').format('DD/MM/YYYY') : ''}`}
              balance={option === optionType.MOURA ? receivementsByPeriodMoura : balanceReceivementsByPeriod}
              loading={option === optionType.MOURA ? loadingOperacoes : loadingEventsReceivementsByDay}
          />
      </ContainerList>
      <HeadTable>
          <span>Data</span>
          {profile === "flex" && (
              <span>Percentual</span>
          )}
          <span>Valor</span>
      </HeadTable>
      <BodyTable>
          <>
              {dataTableMobileWithPagination?.map((item: any, index: number) => (
                  <div className='row-table' key={index} id={`list-item-${index}`}>
                      <span>{item?.date}</span>
                      {profile === "flex" && (
                          <span>{item?.percentage}%</span>
                      )}
                      <span>
                          {formatToCurrencyBRL(item?.value)}
                          <Button
                              onClick={() => redirectToDetails(item?.date)}
                              typeButton='outline'
                              width='40px'
                              noBorder
                              icon={<IoIosArrowForward color='#002868' />}
                          />
                      </span>
                  </div>

              ))}
              <WrapperButton>
                  {(loadingOperacoes || loadingReceivementsMobileLoja) ? 
                      <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'}/>
                      :
                      <>
                      {dataTableMobileWithPagination.length === 0 ? 
                          <div className='message-not-found'>
                              <img src={noItemsIcon} alt=""/>
                              <span>Você não possui recebimentos no período selecionado!</span>
                          </div>
                          :
                          <>
                          {!(pageMobile === totalPaginationMobile) && 
                              <Button
                                  label='Carregar mais resultados'
                                  onClick={() => setPageMobile((oldPage) => oldPage + 1)}
                              />
                          }
                          {dataTableMobileWithPagination.length > 10 && (
                              <ButtonBackToTop href="#list-item-0">Voltar para o topo!</ButtonBackToTop>
                          )}
                          </>
                          }
                      </>
                      }
                  </WrapperButton>
          </>
      </BodyTable>
    </ContainerMobile>
  )
}

export default RecebimentosMobile