export const optionType: {MOURA : 'MOURA' , LOJA: 'LOJA'} = {
    MOURA: 'MOURA',
    LOJA: 'LOJA'
}

export const optionLabel: any = {
    MOURA: '(Moura)',
    LOJA: '(Loja)',
}

export const profiles: {[k: string] : 'multi-agenda' | 'flex' | 'cessao' | 'maquina'} = {
    MULTI_AGENDA: 'multi-agenda',
    FLEX: 'flex',
    CESSAO: 'cessao',
    MAQUINA: 'maquina'
}

export const allowProfilesSwitchButtons = [ 'multi-agenda', 'flex']

export const daysOptions = [
    {
        value: 30,
        label: '30 dias'
    },
    {
        value: 60,
        label: '60 dias'
    },
    {
        value: 90,
        label: '90 dias'
    },
    {
        value: 120,
        label: '4 meses'
    },
    {
        value: 150,
        label: '5 meses'
    },
    {
        value: 180,
        label: '6 meses'
    }

]

export const terminalOptions =[
    {
        value: '',
        label: 'Terminal'
    },
    {
        value: 'ES000001',
        label: 'Link de pagamento'
    },
    {
        value: '5B663125',
        label: 'PAX D200 - cod: 5B663125'
    }
]

export const months: {[k: number]: string} = {
    1: 'Jan',
    2: 'Fev',
    3: 'Mar',
    4: 'Abr',
    5: 'Mai',
    6: 'Jun',
    7: 'Jul',
    8: 'Ago',
    9: 'Set',
    10: 'Out',
    11: 'Nov',
    12: 'Dez'
}

export const monthsString: {[k: string]: string} = {
  January: "Janeiro",
  February: "Fevereiro",
  March: "Março",
  April: "Abril",
  May: "Maio",
  June: "Junho",
  July: "Julho",
  August: "Agosto",
  September: "Setembro",
  October: "Outubro",
  November: "Novembro",
  December: "Dezembro",
}

export const timeRangeLabel: {[k: number]: string} = {
    1: 'do dia',
    2: 'da semana',
    3: 'do mês'
}
export const timeRange: {[k: number]: string} = {
    1: 'day',
    2: 'week',
    3: 'month'
}

export const headTableByDay = [
    {
      titleColumn: "Data de Recebimento",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Valor Bruto",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "MDR",
      tooltipText: "tooltip",
      subColumn: ["R$", "%"]
    },
    {
      titleColumn: "Valor Líquido total",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Antecipação",
      tooltipText: "tooltip",
      subColumn: ["R$", "%"]
    },
    {
      titleColumn: "CET",
      tooltipText: "tooltip",
      subColumn: []
    },
  ]
  
  export const headTableByTransaction = [
    {
      titleColumn: "Data de Transação",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Data de Recebimento",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Código NSU",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Modalidade",
      tooltipText: "tooltip",
      subColumn: []
    },
    
    {
      titleColumn: "Valor Transação",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "MDR",
      tooltipText: "tooltip",
      subColumn: ["R$", "%"]
    },
    {
      titleColumn: "Valor Líquido Parcela",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Recebimento Bruto cliente",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Antecipação",
      tooltipText: "tooltip",
      subColumn: ["R$", "%"]
    },
    {
      titleColumn: "Valor líquido cliente",
      tooltipText: "tooltip",
      subColumn: []
    },
  ]
  
  export const headTableCredits = [
    {
      titleColumn: "Data de venda",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Modalidade",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Status",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Código NSU",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Autorização",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Terminal",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Valor Bruto",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Valor Líquido",
      tooltipText: "tooltip",
      subColumn: []
    },
  ]
  
  export const headTableExtract = [
    {
      titleColumn: "Data de Lançamento",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Data de Transação",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Modalidade",
      tooltipText: "tooltip",
      subColumn: []
    },
    {
      titleColumn: "Valor",
      tooltipText: "tooltip",
      subColumn: []
    },
  ]
  
  export const mockPaymentsByDayLoja = [
    {
        title: "Valor Líquido Cliente",
        amount: 0,
        colorAmount: "#0033AB"
    },
    {
        title: "Ajuste a Crédito",
        amount: 0
    },
    {
        title: "Ajuste a Débito",
        amount: 0
    },
    {
        title: "Aluguel de Máquinas",
        amount: 0,
        colorAmount: "#DC3737"
    },
    {
        title: "Recebido do Dia",
        amount: 0,
        colorTitle: "#0033AB",
        colorAmount: "#0033AB"
    }
  ]