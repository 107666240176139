import * as linkPaymentActions from './actions/linkPaymentActions'
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit'

export interface LinkPaymentProps {
  loadingGeneratePaymentLink: boolean
}

const initialState: LinkPaymentProps = {
  loadingGeneratePaymentLink: false
}

export const linkPaymentSlice = createSlice({
  name: 'linkPayment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isPending(linkPaymentActions.generatePaymentLink),
      (state) => {
        state.loadingGeneratePaymentLink = true
      }
    )
    builder.addMatcher(isFulfilled(linkPaymentActions.generatePaymentLink),
      (state, action) => {
        state.loadingGeneratePaymentLink = false
        console.log(action.payload)
      }
    )
    builder.addMatcher(isRejected(linkPaymentActions.generatePaymentLink),
      (state) => {
        state.loadingGeneratePaymentLink = false
      }
    )

  }
})

export default linkPaymentSlice.reducer