import { createAsyncThunk } from '@reduxjs/toolkit'
import config from '../../config'
import api from '../../services/api'
import apiAdmin from '../../services/apiAdmin';

export const getDetails = createAsyncThunk(
  "myData/getDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiAdmin.get(`/client-details/details`);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getClientAddress = createAsyncThunk(
  "myData/getClientAddress",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiAdmin.get(`/client-details/addresses`);
      return response?.data[0];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getBanks = createAsyncThunk(
  "myData/getBanks",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiAdmin.get(`/client-details/banks`);

      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const updateBankData = createAsyncThunk(
  "myData/updateBankData",
  async (
    bankData: {
      bankName: string;
      accountType: string;
      accountNumber: string;
      accountDigit: string;
      agency: string;
      document: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append("bankName", bankData.bankName);
      formData.append("accountType", bankData.accountType);
      formData.append("accountNumber", bankData.accountNumber);
      formData.append("accountDigit", bankData.accountDigit);
      formData.append("agency", bankData.agency);
      formData.append("document", bankData.document);

      await apiAdmin.post(`/tickets/update-bank-info`, formData, {
        headers: {
            'x-api-key': config.API_KEY, 
            "Content-Type": "multipart/form-data"
        },
      })
      
      return
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getAddressNContact = createAsyncThunk(
  "myData/getAddressNContact",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/client/shopkeeperAddressAndContact`)
      
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getShopkeeperMCC = createAsyncThunk(
  'myData/getShopkeeperMCC',
  async (_, { rejectWithValue }) => {
    try {

      const response = await api.get(`/client/shopkeeperMccCode`)
      
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
