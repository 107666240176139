import React from 'react'
import { Wrapper } from './styles'
import Select, { createFilter } from 'react-select'

interface AutocompleteProps {
  placeholder: string
  options: OptionsType[]
  onChange: (e: any) => void
  width: string
  height?: string
  isClearable?: boolean
  value?: OptionsType | null | string
  defaultValue?: OptionsType
  disabled?: boolean
  noMenuPortalTarget?: boolean
  loading?: boolean
  showValueName?: boolean,
  isMulti?: boolean,
  borderRadius?: string
  label?: string
}

type OptionsType = {
  label: React.ReactNode
  value: string | any
}

export const SelectComponent = ({
  options,
  placeholder,
  onChange,
  width,
  height,
  isClearable,
  value,
  defaultValue,
  disabled,
  noMenuPortalTarget,
  loading,
  showValueName,
  isMulti,
  borderRadius,
  label
}: AutocompleteProps) => {
  let realValue: any = value

  if (typeof value === 'string') {
    realValue = options.find((option) => {
      return option.value === value
    })
    if (!realValue) {
      realValue = null
    }
  }
  const loadingMessage = [{ label: 'Carregando', value: '' }]

  return (
    <Wrapper
      width={width}
      height={height}
      >
      {label && <label>{label}</label>}
      {/* {value && <p>{placeholder}</p>} */}

      <Select
        placeholder={placeholder}
        onChange={(event) => onChange(event || { label: '', value: '' })}
        options={options}
        value={
          showValueName
            ? [{ label: value, value: '' }]
            : loading
            ? loadingMessage
            : realValue
        }
        filterOption={createFilter({ ignoreAccents: false })}
        defaultValue={defaultValue}
        isClearable={isClearable}
        isDisabled={disabled || loading}
        isMulti={isMulti}
        classNamePrefix="react-select"
        menuPortalTarget={noMenuPortalTarget ? null : document.body}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused
              ? "#002868"
              : "#002868",
            boxShadow: 'none',
            fontSize: "14px",
            fontFamily: "Roboto",
            height: height ? height : '56px',
            borderRadius: borderRadius || '25px',
            // backgroundColor: '#f8f7f7',
            opacity: state.isDisabled ? 0.7 : 1,

            '&:hover': {
              borderColor: "#002868",
              boxShadow: `none`
            }
          }),
          option: (styles, { isSelected }) => ({
            ...styles,
            fontFamily: "Roboto",
            fontSize: "14px",
            textAlign: 'left',
            fontWeight: '400',
            color: "#002868",
            cursor: "pointer",
            backgroundColor: isSelected
              ? "#E3E5E8"
              : "white",
            '&:hover': {
              backgroundColor: "#E3E5E8",
              color: "#002868"
            }
          })
        }}
      />
    </Wrapper>
  )
}
