import React from 'react';

import parceiroMoura from '../../../assets/parceiro_moura_menu.png'

const ParceiroMouraBanner: React.FC = () => {
  return (
    <div className="m-4 rounded-4" style={{ backgroundColor: '#C7CBD14D' }}>
        <div className="p-4">
            <img src={parceiroMoura} className="w-100 mb-2" alt=''/>
            <p className="mb-0" style={{ textAlign: 'justify', fontSize: '14px' }}>
                O Parceiro Moura é uma plataforma de autosserviço com objetivo de levar facilidade, agilidade e transparência para os nossos Parceiros Revendedores.
            </p>
        </div>
    </div>
  );
}

export default ParceiroMouraBanner;