import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react"
import useMedia from "../../hooks/useMedia";
import exportIcon from '../../assets/export-icon.svg'
import dayjs from "dayjs";
import { Cobranca, CobrancasTable } from "./CobrancasTable";
import { rents } from "../../services/rents";
import DatePickerRange from "../../components/DatePickerRange/DatePickerRange";
import Button from "../../components/Button/Button";
import { SlReload } from "react-icons/sl";
import TitlePage from "../../components/TitlePage";
import { Pagination } from "../../components/Pagination/Pagination";

export const Cobrancas: React.FC<any> = () => {
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
  const mobile = useMedia('(max-width: 40rem)')

  const [currentPage, setCurrentPage] = useState(0);


    // <-- date-picker

    const toogleDate = ()=>{
      setDateOpen(!dateOpen);
    }

    const [dateOpen, setDateOpen] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().startOf('month').subtract(2, "months").toDate());
    const [endDate, setEndDate] = useState(dayjs().toDate());
    const [loading, setLoading] = useState(false)

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            toogleDate()
            setDateOpen(false);
            // fetchReceivementsByPeriod(parseToFormattedDate(start), parseToFormattedDate(end), token)
        }
    };

    // --> date-picker

  const fetchData = useCallback(async () => {
    if (!startDate || !endDate) {
      return
    }
    setLoading(true)
    const {results, rents: rentsData} = await rents(new Date(startDate.toDateString()), new Date(endDate.toDateString()), currentPage + 1, undefined)
    setCharges(rentsData.map((rent: any) => ({
      value: rent.value,
      paymentDate: new Date(rent.paymentDate),
      status: rent.status,
      type: rent.type
    })))
    setRawCharges(rentsData);
    setLastUpdate(new Date());
    setTotalItems(results);
    setLoading(false)
  }, [currentPage, endDate, startDate])

  const [charges, setCharges] = useState<Cobranca[]>([]);
  const [rawCharges, setRawCharges] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    fetchData()
  }, [currentPage, fetchData, endDate, startDate])

  const exportS = useCallback(() => {
    const rows = [
    [
      'Data de pagamento',
      'Valor descontado',
      'Status',
      'Motivo de cobrança'
    ],
    ...rawCharges.map((charge: any) => {
        return [
            DateTime.fromISO(charge.paymentDate).toFormat("dd/MM/yyyy"),
            charge.value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }).replace(',', '.'),
            charge.status === 'PAID' ? 'Pago' : 'Pendente',
            'Aluguel'
        ];
      })
    ];

    let csvContent = 'data:text/csv;charset=utf-8,';

    rows.forEach(function (rowArray) {
    const row = rowArray.join(',');
    csvContent += row + '\r\n';
    });
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);

}, [rawCharges])


  return <div className={mobile ? 'linkspagamento-container-mobile' : 'linkspagamento-container-desktop'}>
  <div className='row headeroptionsrow'>
      <div className='col-md-6 title-container' style={{display: "flex"}}>
        <TitlePage title={"Cobranças"} lastUpdate={lastUpdate ? DateTime.fromJSDate(lastUpdate).toFormat('hh:mm:ss') : ''}/>
      </div>

{/* ------------ HEADER -> CONTROLES ------------ */}

      <div className='col-md-6 title-container text-end' style={{justifyContent: 'flex-end', display: "flex", alignItems: "center"}}>
          <div style={{ display: "inline-block", marginRight: "5px" }} ><Button width="40px" icon={<SlReload color='#002868' />} onClick={() => {fetchData()}} typeButton='secondary' /></div>
          <div style={{ display: "inline-block", marginRight: "5px" }} ><Button label="" icon={<div><img src={exportIcon} alt="" /> Exportar </div> } typeButton="secondary" onClick={() => {exportS()}} /></div>
              <div className='date-picker-boxx' style={{ position: "relative", "display": "inline-block" }}>
              <div><DatePickerRange startDate={startDate} endDate={endDate} toogleDate={toogleDate} onChange={onChange} dateOpen={dateOpen} onClickOutside={() => {setDateOpen(false)}} /></div>
          </div>
      </div>
    </div>

  {/* ------------ CONTENT -> TABELA ------------ */}

  {charges.length > 0 &&  <div>
        <div className='row mt-5'>
            <div className='col-md-12 table-responsive'>
                <CobrancasTable cobrancas={charges} loading={loading} />
            </div>
        </div>
    </div>}
    {charges.length === 0 && lastUpdate !== null && <div style={{textAlign: "center", marginTop: "50px"}}>
      Nenhum resultado encontrado para o período especificado
    </div>}

{/* ------------ CONTENT -> PAGINAÇÃO ------------ */}

  {charges.length > 0 && <div className='row mt-5'>
        <div style={{display: "flex", justifyContent: "center"}}>
            <Pagination
              limit={10}
              total={totalItems}
              offset={currentPage}
              setOffset={setCurrentPage}
            />
        </div>
    </div>}

  </div>
}