import styled, { css } from "styled-components";
import { ColorBaseBlueDark2 } from "../../theme/tokens";

export const WrapperDesktop = styled.div`
  /* padding: 24px 30px; */
  display: flex;
  flex-direction: column;
  gap: 24px;

  .content-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const WrapperBox = styled.div`
  ${({theme}) => css`
    width: 100%;
    border-radius: 25px;
    height: 100%;
    background-color: ${theme.colors.white};
    border: 0.5px solid ${theme.colors.gray};

    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    .content-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  `}
`

export const TextInput = styled.input`
  width: 100%;
  color: ${ColorBaseBlueDark2};
  border: 1px solid ${ColorBaseBlueDark2};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  ::-webkit-input-placeholder {
    color: red;
  }
  :-ms-input-placeholder {
     color: red;
  }
`

export const SelectInput = styled.select`
  ${({theme}) => css`
    width: 100%;
    height: 48px;
    color: ${theme.colors.blue};
    border: 1px solid ${theme.colors.blueBorder};
    border-radius: 8px;
    padding: 5px;
    background-color: ${theme.colors.white};
  `}
`

export const TextAreaInput = styled.textarea`
  ${({theme}) => css`
    width: 100%;
    color: ${theme.colors.blue};
    border: 1px solid ${theme.colors.blueBorder};
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 10px;
    background-color: ${theme.colors.white};
    padding-left: 14px;
    outline: none;
  `}
`

export const WrapperLinkGenerator = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 1rem;

  .form-link-generator {
    width: calc(65%);
    font-family: Roboto;
  }

  .resume {
    width: 35%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;

    .form-link-generator {
      width: 100%;
    }

    .resume {
      width: 100%;
    }
  }
`

export const WrapperMobile = styled.div`
  width: 100%;
  overflow: hidden;

  .switch-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 84px;
  }

  
  .content {
    padding: 5rem 1rem 8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;


    thead tr th {
      min-width: 100px !important;
    }
  }

  .content-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`

export const WrapperButtons = styled.div`
  ${({theme}) => css`
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    background-color: ${theme.colors.white};
    border: 0.5px solid ${theme.colors.borderGray};

    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 1rem;
  `}
`

export const ContentForm = styled.div<{$open: boolean}>`
  ${({theme, $open}) => css`
    position: fixed;
    width: 100%;
    z-index: 9;
    bottom: 0;

    .wrapper-content{
      background-color: ${theme.colors.white};
      width: 100%;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      ${$open ? css`
        border: 1px solid ${theme.colors.gray};
        ` : css`
        border: none;
      `}

      .wrapper-form {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 1rem;
        overflow: auto;
        height: 100%;

        h3 {
          color: ${theme.colors.blue};
        }

        form {
          display: flex;
          flex-direction: column;
          text-align: center;
          gap: 1rem;
          padding-bottom: 1rem;
        }
      }
    }

    .grab-container{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .grab{
      width: 40px;
      height: 4px;
      background: grey;
      border-radius: 9999px;
      margin-top: 4px;
    }
  `}
`

export const ContentTable = styled.div`
  width: 100%;
  height: 60vh;
  overflow-y: auto;
  margin-bottom: 70px;
  scroll-behavior: smooth;
`

export const HeadTable = styled.div`
  background-color: rgba(0, 40, 104, 0.15);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px 0px;

  span {
    font-family: ${({theme}) => theme.font.family};
    font-size: ${({theme}) => theme.font.size14};
    text-align: center;
    color: #0033AB;
    /* font-weight: bold; */
  }

  div {
    display: flex;
    gap: 5px
  }
`

export const BodyTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .wrapper-details {
    width: 100%;
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    min-height: 84px;

    .paid {
      color: #1AB057;
    }
    .expired {
      color: #DC3737;
    }
    .pending {
      color: #D48001;
    }
  }

  .wrapper-status {
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      font-family: ${({theme}) => theme.font.family};
      color: #002868;
    }

    .confirmation-hour {
      font-size: 12px;
      color: #969696;
    }
  }

  .balance {
    font-weight: bold;
    color: #002868;
    font-size: 18px;
  }
`

export const WrapperFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;

  .message-not-found {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    img {
      width: 80px;
    }
    span {
      text-align: center;
    }
  }
`

export const Infos = styled.div`
  ${({theme}) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    color: ${theme.colors.textBlue};

    p {
      font-size: ${theme.font.sizes.xxlsmall};
    }
  `}
`

export const NewLinkMobileWrapper = styled.div`
  ${({theme}) => css`
    width: 100%;
    height: calc(100vh - 64px);
    padding-top: 5.5rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .wrapper-details {
      width: 100%;
      height: 100%;
      background-color: ${theme.colors.white};
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      border: 1px solid ${theme.colors.gray};
      padding: 1rem 0;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .details-installments:nth-child(even) {
        background-color: rgba(199, 203, 209, 0.10);
      }

      .details-installments {
        display: flex;
        justify-content: space-between;
        padding: 0.3rem 1rem;

        span {
          color: ${theme.colors.blue};
        }

        span:first-child {
          color: ${theme.colors.placeholder};
        }
      }

      .details-installments:last-child {
        background-color: rgba(0, 40, 104, 0.15);
        padding: 1rem;
        margin-top: 1rem;

        span {
          color: ${theme.colors.textBlue};
          font-weight: ${theme.font.bold};
        }
      }
    }
  `}
`