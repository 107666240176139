import React, { useMemo } from 'react'
import { ButtonMenu, ContainerMobile, Label } from '../styles'
import { CiPhone } from 'react-icons/ci'
import { useLocation, useNavigate } from 'react-router-dom'
import homeWhiteIcon from '../../../assets/home-icon-2.svg'
import homeIcon from '../../../assets/home-icon-gray.svg'
import salesWhiteIcon from "../../../assets/sales-icon.svg"
import recebimentosIcon from '../../../assets/recebimentos-icon-menu.svg'
import recebimentosWhiteIcon from '../../../assets/recebimentos-white-icon.svg'
import vendasIcon from '../../../assets/vendas-new-icon.svg'

const MenuMobile: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const handleRedirect = (url: string) => {
    navigate(url)
  }

  const menu = useMemo(() => {
    return [
      {
        url: '/home',
        title: 'Home',
        icon: null,
        imageIcon: <img src={location.pathname === '/home' ? homeWhiteIcon : homeIcon} alt='Ícone Home' />,
        active: location.pathname.includes('/home')
      },
      {
        url: '/vendas',
        title: 'Vendas',
        icon: null,
        imageIcon: <img src={location.pathname.includes('/vendas') ? salesWhiteIcon : vendasIcon} alt='Ícone Vendas' />,
        active: location.pathname.includes('/vendas')
      },
      {
        url: '/recebimentos',
        title: 'Recebimentos',
        icon: null,
        imageIcon: <img src={location.pathname.includes('/recebimentos') ? recebimentosWhiteIcon : recebimentosIcon} alt='Ícone Recebimentos' />,
        active: location.pathname.includes('/recebimentos')
      },
      {
        url: '/home',
        title: 'Contatos',
        icon: <CiPhone color={location.pathname === '/contatos' ? '#FFFFFF' : '#737D8C'} />,
        imageIcon: null,
        active: location.pathname.includes('/contatos')
      }
    ]
  },[location.pathname])

  return (
    <ContainerMobile>
      {menu.map((item) => (
        <ButtonMenu key={item?.title} onClick={() => handleRedirect(item?.url)}>
            {item?.icon ? item?.icon : item?.imageIcon}
            <Label $active={item?.active}>{item?.title}</Label>
        </ButtonMenu>
      ))}
    </ContainerMobile>
  )
}

export default MenuMobile