import React, { useEffect, useState } from 'react'
import linkSmall  from '../../assets/link-small.png'
import { DateTime } from 'luxon';
import { rentsContact } from '../../services/rents';
import checkIcon from '../../assets/check.svg'
import ReactModal from 'react-modal';
import Button from '../../components/Button/Button';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ContainerModal } from '../MeusDados/styles';
import Table2 from '../../components/Table2';

export type Cobranca = {
  value: number;
  paymentDate: Date;
  status: string,
  type: string,
  id: string
}

export const CobrancasTable: React.FC<{
  cobrancas: Cobranca[],
  loading: boolean,
}> = ({cobrancas, loading}) => {

  const [currentCharge, setCurrentCharge] = useState<Cobranca>({
    value: 0,
    paymentDate: new Date(),
    status: '',
    type: '',
    id: ""
  }); 

  const [rentsWithContactRequest, setRentsWithContactRequest] = useState<Cobranca[]>([])
  const [modalDoubts, setModalDoubts] = useState(false);

  const setCharge = (charge: Cobranca) => {
    setCurrentCharge(charge);
  }

  const openModalDoubts = (charge: Cobranca) =>{
    setCharge(charge);
    setModalDoubts(true);
  }

  async function sendEmail() {
    await rentsContact(currentCharge.paymentDate, currentCharge.value, currentCharge.status, currentCharge.id)

    setRentsWithContactRequest([...rentsWithContactRequest, currentCharge])
  }

  useEffect(() => {
    setRentsWithContactRequest([])
  }, [cobrancas])

  return (
    <>
      <Table2
        loading={loading}
        data={cobrancas}
        columns={[
          {
            key: 'paymentDate',
            header: 'Data do Pagamento',
            format: ({paymentDate}) => DateTime.fromJSDate(paymentDate).setLocale('UTC').setZone('UTC').toFormat("dd/MM/yyyy")
          },
          {
            key: 'value',
            header: 'Valor Descontado',
            format: ({value}) => value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
          },
          {
            key: 'status',
            header: 'Status',
            format: ({status}) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  color: status === 'PAID' ? '#1AB057' : '#D48001'
                }}
              >
                <div
                  style={{
                    marginRight: '5px',
                    marginTop: '0px',
                    width: '14px',
                    height: '14px',
                    display: 'inline-block',
                    borderRadius: '20px',
                    backgroundColor: status === 'PAID' ? '#1AB057' : '#D48001'
                  }}
                >
                </div>
                  {status === 'PAID' ? 'Pago' : 'Pendente'}
              </div>
            )
          },
          {
            key: 'type',
            header: 'Motivo da Cobrança',
            format: ({type}) => type === 'RENT' && 'Aluguel'
          },
          {
            key: 'id',
            header: 'E-mail para Dúvidas',
            format: (row) => (
              <button
                onClick={() => openModalDoubts(row)}
                style={{
                  border: 'none',
                  outline: 'none',
                  backgroundColor: 'transparent'
                }}
              >
                {rentsWithContactRequest.some(r => r === row) ?
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      height: "32px"
                    }}
                  >
                    <img style={{ marginRight: "5px" }} src={checkIcon} alt="" /> E-mail enviado
                  </div>
                    : 
                  <img style={{ cursor: "pointer" }} src={linkSmall} alt="" />}
              </button>
            )
          },
        ]}
      />
      <ReactModal
        isOpen={modalDoubts}
        onRequestClose={() => setModalDoubts(false)}
        contentLabel="Buscar Cliente"
        style={{
          content: {
            width: "18rem",
            height: "max-content",
            margin: "auto"
          }
        }}
      >
        <ContainerModal>
           <div className='content-head'>
            <div></div>
          <Button
                      icon={<AiOutlineCloseCircle color='#002868' />}
                      typeButton="outline"
                      noBorder
                      width='40px'
                      onClick={() => {setModalDoubts(false)}}
                  />
          </div>
          {!rentsWithContactRequest.some(r => r === currentCharge) && <>
            <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
              <h4 style={{ color : '#002868'}}>     
                <b>Confirmação</b>
              </h4>
              <br />
                <h6 style={{textAlign: 'left'}} >
                  <p>
                    Ao clicar no botão "Solicitar" será pedido à Propig mais informações
                    sobre esta cobrança.
                  </p>
                  <p>Por favor, fique de olho no seu e-mail. Você receberá uma confirmação dessa solicitação, 
                    logo retornaremos com essas informações.
                  </p>
                </h6>
              </div>
            <div style={{display: "flex"}}>
                <Button width="50%" type='submit' label="Cancelar" typeButton="outline" onClick={() => setModalDoubts(false)}/>
                <Button width="50%" type='submit' label="Solicitar" typeButton="primary" onClick={() => {sendEmail()}}/>
            </div>
          </>}

          {rentsWithContactRequest.some(r => r === currentCharge) && <>
            <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
              <h4 style={{ color : '#002868'}}>     
                <b>Enviado</b>
              </h4>
              <br />
                <h6 style={{textAlign: 'left'}} >
                  <p>
                    Sua solicitação foi encaminhada ao nosso time responsável. Eles responderão ao seu e-mail dentro de até 1 dia útil
                  </p>
                </h6>
              </div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Button width="50%" type='submit' label="Ok" typeButton="primary" onClick={() => {setModalDoubts(false)}}/>
            </div>
          </>
          }
        </ContainerModal>
      </ReactModal>
    </>
  )
}

