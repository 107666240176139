import styled, { css } from "styled-components"

type ButtonProps = {
  $noBorder?: boolean,
  $typeButton?: string,
  $border?: string,
  $backgroundColor?: string,
  width?: string,
  height?: string,
  $padding?: string
}

export const ButtonContainer = styled.button<ButtonProps>`
  border-radius: 25px;
  height:${({height}) => height ? height : "min-content"};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  transform: scale(0.97);

  /* box-shadow: ${({$noBorder}) => $noBorder ? "none" : "0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)"}; */

  cursor: pointer;

  ${({$typeButton, $noBorder, $border, $backgroundColor, width, $padding}) => css`
    ${$typeButton === "primary" && css`
      border: ${$noBorder ? "none" : `1px solid ${$border ?? '#002868'}`};
      background-color: ${$backgroundColor ?? '#002868'};
      min-width: ${width ?? "max-content"};
      padding: ${$padding ?? "10px 0.5rem"};

      font-family: ${({theme}) => theme.font.family};
      font-weight: ${({theme}) => theme.font.weigth700};
      font-size: 14px;
      color: #ffffff;
    `}

    ${$typeButton === "secondary" && css`
      border: none;
      background-color: ${$backgroundColor ?? 'rgba(0, 40, 104, 0.15)'};
      min-width: ${width ?? "max-content"};
      padding: ${$padding ?? "10px 0.5rem"};

      font-family: ${({theme}) => theme.font.family};
      font-weight: ${({theme}) => theme.font.weigth700};
      font-size: 14px;
      color: #002868;
    `}

    ${$typeButton === "outline" && css`
      border: ${$noBorder ? "none" : `1px solid ${$border ?? "#002868"}`};
      background-color: ${$backgroundColor ?? '#ffffff'};
      min-width: ${width ?? "max-content"};
      padding: ${$padding ?? "10px 0.5rem"};

      font-family: ${({theme}) => theme.font.family};
      font-weight: ${({theme}) => theme.font.weigth700};
      font-size: 14px;
      color: #002868;
    `}
  `}

  svg {
    width: 20px;
    height: auto;
  }

  &:hover {
    filter: brightness(0.9);
    transform: scale(1);
    transition: 0.2s all ease;
  }

  &:disabled {
    opacity: 0.3;
    cursor: initial;
  }
`

const labelModifier: any = {
  primary: () => css`
    color:#ffffff;
  `,
  secondary: () => css`
    color: #002868;
  `,
  outline: () => css`
    color: #002868;
  `
}

type LabelProps = {
  fontSize?: string,
  $typeButton?: string,
  $color?: string
}

export const Label = styled.span<LabelProps>`
  font-family: ${({theme}) => theme.font.family};
  font-weight: ${({theme}) => theme.font.weigth700};
  font-size: ${({fontSize}) => fontSize ?? "14px"};

  ${({$typeButton, $color}) => css`
    ${$typeButton && labelModifier[$typeButton]}
    ${$color && css` color: ${$color}`}
  `}
`