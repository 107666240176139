import React, { useCallback, useMemo, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from '@fullcalendar/daygrid'
import ptbrLocale from '@fullcalendar/core/locales/pt-br';
import interactionPlugin from "@fullcalendar/interaction"
import dayjs from "dayjs";
import useAppSelector from "../../../hooks/useAppSelector";
import { formatToCurrencyBRL, parseToFormattedDate } from "../../../utils/functions";
import { optionType } from "../../../utils/constants";

interface Props {
  setDaySelect(arg: string): void
  firstDayOfMonthSelected: Date
  setFirstDayOfMonthSelected(arg: Date): void
  lastDayOfMonthSelected: Date
  setLastDayOfMonthSelected(arg: Date): void
}

const Calendar: React.FC<Props> = ({
  setDaySelect,
  firstDayOfMonthSelected,
  setFirstDayOfMonthSelected,
  lastDayOfMonthSelected,
  setLastDayOfMonthSelected}) => {
  const option = useAppSelector(state => state.switchButtons.option)
  const {
    balanceCredmoura,
    operacoesArray,
    eventsReceivementsByDay,
  } = useAppSelector(state => state.receivements)

  const calendarRef = useRef<any>(null)

  const [currentMonth, setCurrentMonth] = useState(dayjs().month() + 1)
  const [currentYear, setCurrentYear] = useState(dayjs().year())

  const isDeadlineForEvents = useMemo(() => {
    const currentDateCalendar = dayjs(`${currentYear}-${currentMonth}-01`).startOf('month').toDate()
    const deadlineForEvents = dayjs().startOf('month').subtract(option === optionType.MOURA ? 2 : 12, 'month').toDate()

    return !(deadlineForEvents <= currentDateCalendar)
  },[currentMonth, currentYear, option])

  const isDeadlineForEventsInTheFuture = useMemo(() => {
    const currentDateCalendar = dayjs(`${currentYear}-${currentMonth}-01`).startOf('month').toDate()
    const deadlineForEvents = dayjs().startOf('month').add(10, 'month').toDate()

    return (currentDateCalendar >= deadlineForEvents)
  },[currentMonth, currentYear])

  const gotoDate = (dateString: any) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(dateString);
  };

  const nextMonth = () => {
    let valueMonth = currentMonth
    let valueYear = currentYear
    valueMonth++
    setCurrentMonth(valueMonth)
    if(valueMonth === 13) {
        valueMonth = 1
        valueYear++
        setCurrentMonth(valueMonth)
        setCurrentYear(valueYear)
    }

    const monthString = valueMonth > 9 ? valueMonth.toString() : "0" + valueMonth
    const lastDayOfMonth = dayjs(`${valueYear}-${monthString}`).endOf("month").format("DD")
    const startDate = `${valueYear}-${monthString}-01`
    const endDate = `${valueYear}-${monthString}-${lastDayOfMonth}`

    setFirstDayOfMonthSelected(dayjs(startDate).startOf("month").toDate())
    setLastDayOfMonthSelected(dayjs(endDate).endOf("month").toDate())
    setDaySelect(startDate)

    // fetchReceivementsByMonthLoja(startDate, endDate, token)
    gotoDate(`${valueYear}-${monthString}`)
  }

  const prevMonth = () => {
    let valueMonth = currentMonth
    let valueYear = currentYear
    valueMonth--
    setCurrentMonth(valueMonth)
    if(valueMonth === 0) {
        valueMonth = 12
        valueYear--
        setCurrentMonth(valueMonth)
        setCurrentYear(valueYear)
    }

    const monthString = valueMonth > 9 ? valueMonth.toString() : "0" + valueMonth
    const lastDayOfMonth = dayjs(`${currentYear}-${monthString}`).endOf("month").format("DD")
    const startDate = `${valueYear}-${monthString}-01`
    const endDate = `${valueYear}-${monthString}-${lastDayOfMonth}`

    setFirstDayOfMonthSelected(dayjs(startDate).startOf("month").toDate())
    setLastDayOfMonthSelected(dayjs(endDate).endOf("month").toDate())
    setDaySelect(startDate)

    // fetchReceivementsByMonthLoja(startDate, endDate, token)
    gotoDate(`${valueYear}-${monthString}`)
  }

  //FUNÇÃO QUE FORMATA OS VALORES DE CRÉDITO OU DÉBITO POR DIA NO MÊS
  const formatedEventsMonthMoura = useCallback((events: any) => {
    let extratoResumidoPerMonth: any = [];

    events?.forEach((operation: any) => {
      let debito = operation.items
        .filter((item: any) => item.Type === "Saída")
        .map((item: any) => item.Valor);
      if (debito.length > 0) {
        debito = debito.reduce((total: any, item: any) => total + item);
      } else {
        debito = 0;
      }

      let credito = operation.items
        .filter((item: any) => item.Type === "Entrada")
        .map((item: any) => item.Valor);
      if (credito.length > 0) {
        credito = credito.reduce((total: any, item: any) => total + item);
      } else {
        credito = 0;
      }

      extratoResumidoPerMonth.push({
        date: operation.date,
        debito: debito,
        credito: credito,
      });
    });

    return extratoResumidoPerMonth
  }, [])

  //MOURA: EVENTOS DO CALENDARIO
  const eventsCalendarMoura = useMemo(() => {
    return operacoesArray?.filter(
        (operation: any) => operation.date >= firstDayOfMonthSelected && operation.date <= lastDayOfMonthSelected
      ).reduce((accumlator: any, operation: any) => {
        const content = { ...operation };

        content.balance = content.items.reduce((acc: any, item: any) => {
          if (item.Type === "Entrada") {
            acc -= item.Valor;
          } else {
            acc += item.Valor * 1;
          }
          return acc;
        }, balanceCredmoura);
  
        accumlator.push(content);
  
        return accumlator;
      }, [])
  },[firstDayOfMonthSelected, lastDayOfMonthSelected, operacoesArray, balanceCredmoura])

  //MOURA: EVENTOS DO CALENDARIO FORMATADOS
  const eventsCalendarMouraFormated: Array<{title: string, date: string, backgroundColor: string}> = useMemo(() => {
    const array: any = []
    formatedEventsMonthMoura(eventsCalendarMoura)?.forEach((item: any) => {
        if(item?.credito) {
            array.push(
                {
                    title: formatToCurrencyBRL(item?.credito),
                    date: parseToFormattedDate(item?.date),
                    backgroundColor: "#0033AB"
                },
            )
        }
        if(item?.debito) {
            array.push(
                {
                    title: formatToCurrencyBRL(item?.debito),
                    date: parseToFormattedDate(item?.date),
                    backgroundColor: "#DC3737"
                }
            )
        }
    })
    return array
  }, [eventsCalendarMoura])

  // LOJA
  const eventsReceivementsLoja = useMemo(() => {
    return eventsReceivementsByDay?.filter((event) => {
        const formatedDate = new Date(`${event.date}T00:00:00`)
        return formatedDate >= firstDayOfMonthSelected && formatedDate <= lastDayOfMonthSelected
    })
  },[eventsReceivementsByDay, firstDayOfMonthSelected, lastDayOfMonthSelected])

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[ dayGridPlugin, interactionPlugin ]}
      initialView="dayGridMonth"
      events={option === optionType.MOURA ? eventsCalendarMouraFormated : eventsReceivementsLoja}
      themeSystem="standard"
      headerToolbar={{
              left: isDeadlineForEvents ? '' : 'prev',
              center: 'title',
              right: isDeadlineForEventsInTheFuture ? '' : 'next',
          }}
      customButtons={{
          next: {
              click: () => nextMonth(),
          },
          prev: {
              click: () => prevMonth(),
          }
      }}
      dateClick={(e) => setDaySelect(e.dateStr)}
      eventClick={(e) => setDaySelect(e.event.startStr)}
      locale={ptbrLocale}
      height={420}
  />
  )
}

export default Calendar