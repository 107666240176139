import { styled } from "styled-components";

export const ContainerDesktop = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  @media (max-width: 1200px) {
    align-items: center;
    justify-content: center;
  }
`

export const Background = styled.div`
  background: radial-gradient(69.01% 69.01% at 50.00% 50.00%, #005988 0%, #002768 100%);
  width: 60%;
  height: 100vh;
  border-radius: 0px 70px 70px 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;


  .content-logo {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content-logo-propig {
    width: 85%;
    display: flex;
    gap: 10px;
    align-items: baseline;

    img {
      width: 15%;
    }

    .divider {
      width: 3px;
      height: 100%;
      background-color: #E7BD2D;
      border: none;
    }
  }

  @media (max-width: 1200px) {
    display: none;
  }
`

export const LogoPropig = styled.img`
  width: 50%;
`

export const Text = styled.span<{$bold?: boolean, $fontSize?: string}>`
  font-family: Poppins;
  color: ${({theme}) => theme.color.white};
  font-weight: ${({theme, $bold}) => $bold ? theme.font.weigth700 : "300"};
  font-size: ${({$fontSize}) => $fontSize ? $fontSize : "22px"};

  strong {
    font-weight: ${({theme}) => theme.font.weigth700};
  }
`

export const Row = styled.div<{$gap?: string}>`
  display: flex;
  gap: ${({$gap}) => $gap ? $gap : "0"};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const PlatformsText = styled.span`
  font-family: Poppins;
  color: #002768;
  background-color: #E7BD2D;
  font-weight: bold;
  width: max-content;
  font-size: 14px;
  padding: 5px 10px;
  margin: 10px 0px;
`

export const LogoApp = styled.img`
  width: 30%;
`

export const ContentForm = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    min-width: 320px;
  }
`

export const WrapperForm = styled.div`
  width: 80%;
  background-color: ${({theme}) => theme.color.white};
  box-shadow: 0 .5rem .5rem rgba(0,0,0,.075);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px 30px;

  position: relative;

  img {
    width: 40%;
    max-width: 140px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;

    input {
      width: 100%;
      background-color: #F6F6F6;
      border: none;
      height: 45px;
      padding-left: 10px;
      color: ${({theme}) => theme.color.secondary};
      font-family: ${({theme}) => theme.font.family};
      font-size: 16px;
      border-radius: 5px;

      &::placeholder {
        color: ${({theme}) => theme.color.secondary};
        font-family: ${({theme}) => theme.font.family};
        font-size: 16px;
      }
    }

    .input-password {
      width: 100%;
      position: relative;

      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        
        cursor: pointer;
      }
    }

    .message-error {
      width: 100%;
      color: ${({theme}) => theme.color.error};
      font-family: ${({theme}) => theme.font.family};
      font-size: 12px;
    }
  }

  .divider {
    width: 100%;
    height: 3px;
    background-color: #F6F6F6;
    border: none;
  }

  .text-help {
    color: ${({theme}) => theme.color.primary};
    font-family: ${({theme}) => theme.font.family};
    font-size: ${({theme}) => theme.font.size14};
  }

  .content-loading {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99;

      background-color: rgba(255, 255, 255, 0.70);
      border-radius: 20px;
    }

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 400px;
    padding: 40px 20px;
  }
`

export const ContentSwitch = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    all: unset;
  }

  .forgot-password {
    color: #002868;
    font-family: ${({theme}) => theme.font.family};
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }

  .switch-button {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      color: #002868;
      font-family: ${({theme}) => theme.font.family};
      font-size: 12px;
    }

    .SwitchRoot {
      width: 42px;
      height: 25px;
      background-color: #F6F6F6;
      border-radius: 9999px;
      position: relative;
      /* box-shadow: 0 2px 10px blue; */
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .SwitchRoot:focus {
      /* box-shadow: 0 0 0 2px black; */
    }
    .SwitchRoot[data-state='checked'] {
      background-color: #F6F6F6;
    }
    .SwitchThumb {
      display: block;
      width: 21px;
      height: 21px;
      background-color: #002868;
      border-radius: 9999px;
      /* box-shadow: 0 2px 2px red; */
      transition: transform 100ms;
      transform: translateX(2px);
      will-change: transform;
    }
    .SwitchThumb[data-state='checked'] {
      transform: translateX(19px);
    }
  }

`

export const ContainerModal = styled.div`

  .content-head {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    p {
      color: ${({theme}) => theme.color.secondary};
      font-family: ${({theme}) => theme.font.family};
      font-weight: ${({theme}) => theme.font.weigth600};
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    input {
      width: 100%;
      background-color: #F6F6F6;
      border: none;
      height: 45px;
      padding-left: 10px;
      color: ${({theme}) => theme.color.secondary};
      font-family: ${({theme}) => theme.font.family};
      font-size: 16px;
      border-radius: 5px;

      &::placeholder {
        color: ${({theme}) => theme.color.secondary};
        font-family: ${({theme}) => theme.font.family};
        font-size: 16px;
      }
    }
  }
`