import styled from "styled-components"

export const ContentTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 15px;
  border: 0.5px solid rgba(199, 203, 209, 0.60);
  background-color: #FFF;
  padding: 18px;
  /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075); */
`

export const ContentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content-title {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`

export const Icon = styled.div`
  background-color: #002868;
  border-radius: 50%;
  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    margin: auto;
    width: 60%;
  }
`
export const Title = styled.p`
  font-family: ${({theme}) => theme.font.family};
  margin: 0;
  color: ${({theme}) => theme.color.titleCard};
  font-weight: ${({theme}) => theme.font.weigth700};
  font-size: ${({theme}) => theme.font.size24};
`

export const Description = styled.p`
  color: ${({theme}) => theme.color.secondary};
  font-family: ${({theme}) => theme.font.family};
  margin: 0;
  font-size: ${({theme}) => theme.font.size14};
  font-weight: ${({theme}) => theme.font.weigth400};
`

export const Balance = styled.p`
  font-family: ${({theme}) => theme.font.family};
  margin: 0;
  color: ${({theme}) => theme.color.primary};
  font-weight: bold;
  font-size: 30px;
`
export const ContentChildren = styled.div`
  width: 100%;
`