import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../services/api'

export const getTransactionsByDay = createAsyncThunk(
  'transactions/getTransactionsByDay',
  async (
    data: {
      offset: number,
      startDate: string,
      endDate: string,
    }
    , { rejectWithValue }) => {
    try {

      const response = await api.get(`/salesPerDay?offset=${data.offset}&limit=10&startDate=${data.startDate}&endDate=${data.endDate}`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})

export const getTransactionsByTransaction = createAsyncThunk(
  'transactions/getTransactionsByTransaction',
  async (
    data: {
      offset: number,
      startDate: string,
      endDate: string,
      limit?: number,
    }
    , { rejectWithValue }) => {
    try {

      const response = await api.get(`/sales?offset=${data.offset}&limit=${data?.limit ? data?.limit : 10}&startDate=${data.startDate}&endDate=${data.endDate}`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})