/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import ReactModal from 'react-modal';

import Button from '../../../components/Button/Button';
import { PermissionsProps } from '../../../store/user'
import useAppSelector from '../../../hooks/useAppSelector';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { dismissWelcomePopup } from '../../../store/user';

import { ContentModal, Video } from '../styles';
import { getPopupDesktop } from '../../../store/actions/bannerPopupActions';

const WelcomeModalDesktop: React.FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { user: { permissions, tipoAgenda }, token } = useAppSelector(state => state.user)
  const { welcomePopupDismissed } = useAppSelector(state => state.user);
  const { popupDesktop } =  useAppSelector(state => state.bannersPopup)

  const permissionsPopup = useMemo(() => {
    if(permissions) {
        const userPermissions: PermissionsProps = JSON.parse(permissions)
        return userPermissions[tipoAgenda as keyof PermissionsProps]?.popup
    }
    return null;
  },[permissions, tipoAgenda]);

  const closeModal = () => {
    setOpen(false);
    dispatch(dismissWelcomePopup());
  };

  const handleClickButtonLink = (link: string) => {
    window.open(link, "_blank");
  }

  const handleClickButtonEmail = (email: string) => {
    window.location.href = `mailto:${email}`
  }

  useEffect(() => {
    if (!!permissionsPopup?.popupId && !welcomePopupDismissed) {
      (async function () {
      const response = await dispatch(getPopupDesktop({popupId: permissionsPopup?.popupId!}))
      if(response.meta.requestStatus === 'fulfilled') {
        setOpen(true)
      }
      })()
    }
  },[permissionsPopup, welcomePopupDismissed, token])

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      style={{
        content: {
          width: "60%",
          height: "max-content",
          margin: "auto",
          padding: "20px"
        }
      }}
    >
      <ContentModal>
        {popupDesktop?.fileType === 'video' ?
          <Video src={popupDesktop?.imageUrl} width='100%' controls autoPlay>
            Não foi possível carregar o vídeo. <a href={popupDesktop?.imageUrl} target='_blank' rel="noreferrer">Clique aqui</a>
          </Video>
        :
          <img src={popupDesktop?.imageUrl} alt="Imagem Popup" style={{ marginBottom: '10px' }} />
        }
        <div style={{
          display: 'flex',
          gap: '1rem',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {popupDesktop?.buttons?.map((item, index) => (
            <Button
              key={index}
              onClick={() => item?.type === 'L' ? handleClickButtonLink(item?.link!) : handleClickButtonEmail(item?.email!)}
              width={"15%"}
              label={item?.text_button || 'Clique aqui'}
            />
          ))}
          <Button
            onClick={closeModal}
            typeButton="outline"
            width={"15%"}
            label="Fechar"
          />
        </div>
      </ContentModal>
    </ReactModal>
  );
}

export default WelcomeModalDesktop;
