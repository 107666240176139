import React, { useCallback, useMemo } from 'react'
import {
  BsBox,
  BsTruck,
  BsPersonFillCheck,
  BsCheckCircleFill,
} from 'react-icons/bs'
import { CircleIcon, LabelIcon, WrapperIcons } from '../styles'
import { EventProps } from '../../../store/tracking'

interface Props {
  data: EventProps[]
}

const StatusAnimation: React.FC<Props> = ({ data }) => {
  const findPhase = useCallback(
    (label: string) => {
      const index = data.findIndex((item) => item?.evento === label)
      return index !== -1
    },
    [data],
  )

  const icons = useMemo(() => {
    return [
      {
        showIcon: true,
        animation: false,
        label: 'Faturado',
        icon: findPhase('Faturado') ? (
          <BsCheckCircleFill size={60} />
        ) : (
          <BsCheckCircleFill size={50} />
        ),
      },
      {
        showIcon: data.length >= 1,
        animation: !findPhase('Em preparação'),
        label: 'Em preparação',
        icon: findPhase('Em preparação') ? (
          <BsCheckCircleFill size={60} />
        ) : (
          <BsBox size={30} />
        ),
      },
      {
        showIcon: data.length >= 2,
        animation: !findPhase('A caminho'),
        label: 'A caminho',
        icon: findPhase('A caminho') ? (
          <BsCheckCircleFill size={60} />
        ) : (
          <BsTruck size={30} />
        ),
      },
      {
        showIcon: data.length >= 3,
        animation: !findPhase('Entregue'),
        label: 'Entregue',
        icon: findPhase('Entregue') ? (
          <BsCheckCircleFill size={60} />
        ) : (
          <BsPersonFillCheck size={30} />
        ),
      },
    ]
  }, [findPhase, data])

  return (
    <WrapperIcons>
      {icons?.map((item, idx) => (
        <div
          key={idx}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <CircleIcon
            $hasAnimation={item?.animation}
            $showIcon={item?.showIcon}
          >
            {item?.icon}
          </CircleIcon>
          <LabelIcon>{item?.label}</LabelIcon>
        </div>
      ))}
    </WrapperIcons>
  )
}

export default StatusAnimation
