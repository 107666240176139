import styled, { css } from "styled-components"

export const ContainerHeaderDesktop = styled.div<{$borderColor: boolean}>`
  ${({theme, $borderColor}) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 100px;
    padding: 0px 24px 0px 40px;
    background-color: white;
    box-shadow: 0 0.5px 10px ${theme.colors.shadow};

    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;

    border-bottom: 12px solid ${$borderColor ? theme.colors.yellow : theme.colors.blue};
  `}
`

export const ContainerDesktop = styled.div<{$borderColor: string}>`
  background-color: white;
  width: 100%;
  display: flex;
  /* height: 80px; */
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  position: fixed;
  top: 0;
  z-index: 99;

  border-bottom: 12px solid ${({$borderColor}) => $borderColor};
`

export const LogoDesktop = styled.img`
  width: 10%;
  height: auto;
  max-width: 130px;
`

export const ContentButtons = styled.div`
  display: flex;
  gap: 30px;

  .content-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .container-profile {
    display: flex;
    align-items: end;
    flex-direction: column;

    p, span {
        font-size: 15px;
        font-family: ${({theme}) => theme.font.family};
    }

    p {
        margin: 0;
        color: #002868;
        font-weight: 700;
        text-align: end;
    }

    span {
        color: #737D8C;
    }
  }
`

export const ContentProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .content-infos {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p, span {
        font-size: 15px;
        font-family: ${({theme}) => theme.font.family};
    }
  
    p {
        margin: 0;
        color: #002868;
        font-weight: ${({theme}) => theme.font.weigth700};
        text-align: end;
    }
  
    span {
        color: #737D8C;
        font-weight: ${({theme}) => theme.font.weigth400};
    }
  }
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;

  button:first-child {
    margin-left: auto;
  }

  button:nth-child(4) {
    margin: 10px auto 0 auto;
  }
`

export const ContentInput = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #F6F6F6;

  input {
    border: 1px solid #002868;
    height: 45px;
    font-family: ${({theme}) => theme.font.family};
    color: #737D8C;
  }

  button {
    position: absolute;
    right: 5px;
    top: 3px;
  }
`
export const ContentUsers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: 380px;
  margin-top: 10px;

  .content-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
`

export const UserInfo = styled.div<{$active?: boolean}>`
  background-color: ${({$active}) => $active ? "rgba(0, 51, 171, 0.15)" : "#FFFFFF"};
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 51, 171, 0.15);
  }

  p, span {
    font-size: 15px;
    font-family: ${({theme}) => theme.font.family};
  }

  p {
    margin: 0;
    color: #002868;
    font-weight: 700;
  }

  span {
    color: #737D8C;
  }
`

export const Divider = styled.div`
  width: 100%;
  min-height: 1px;
  background-color: #F6F6F6;
`

export const ContainerMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1.5rem 2rem 1rem 2rem;
  margin: 0;
  background-color: #002868;
  border-radius: 0 0 30px 30px;
  position: fixed;
  top: 0;
  z-index: 9;

  .content-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content-user {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`

export const LogoMobile = styled.img`
  width: 20%;
  height: 30px;
`

export const NameUser = styled.span<{$bolder?: boolean}>`
  color: #FFFFFF;
  font-weight: ${({$bolder}) => $bolder ? "bold" : "normal"};
`

export const ContentSales = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  .content-sales {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-family: ${({theme}) => theme.font.family};
      color: #FFFFFF;
      font-size: 28px;
      font-weight: bold;
      margin: 0;
    }
  }
`

export const ContentSSwitchButtons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`

export const ContainerHeaderDetails = styled.div`
  ${({theme}) => css`
    display: flex;
    width: 100%;
    background-color: ${theme.colors.blue};
    border-radius: 0px 0px 18px 18px;
    max-height: 64px;
    padding: 20px;

    position: fixed;
    top: 0;
    z-index: 9;

    button {
      outline: none;
      background-color: transparent;
      border: none;
    }
  `}
`

export const Title = styled.span`
  ${({theme}) => css`
    font-family: Poppins;
    color: ${theme.colors.white};
    width: 100%;
    font-weight: 700;
    font-size: ${theme.font.sizes.xxsmall};
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      width: 37px;
    }
  `}
`