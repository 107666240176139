import styled from "styled-components"

export const ContainerPagination = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  list-style: none;

  padding: 0;
  margin: 0;
`

export const Button = styled.button<{disabled: boolean}>`
  width: 44px;
  height: 44px;
  background-color: transparent;
  color: #737D8C;
  border: none;
  border-radius: 50%;

  cursor: pointer;

  opacity: ${({disabled}) => disabled ? "0.5" : "1"};
  transition: 0.3s all ease;

  &:hover {
    background-color: #D3D8E2;
  }
`

export const ButtonPagination = styled.button<{$active: boolean}>`
  width: 44px;
  height: 44px;
  background-color: ${({$active}) => $active ? "#D3D8E2" : "transparent"};
  color: #737D8C;
  border: none;
  border-radius: 50%;

  &:hover {
    background-color: #D3D8E2;
  }
`