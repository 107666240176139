import { createAsyncThunk } from '@reduxjs/toolkit'
import config from '../../config'
import { toast } from 'react-toastify';
import api from '../../services/api';
import apiAdmin from '../../services/apiAdmin';
import * as CryptoJS from 'crypto-js'

export const updateLogin = createAsyncThunk(
  "user/updateLogin",
  async (
    data: {
      documento: string
    }
    , { rejectWithValue }) => {
    try {
      const response = await api.post(`/user/matrizChangeUser`, data)
      return response?.data
    } catch (error: any) {
      toast.error("Ocorreu um erro ao tentar trocar de usuário!");
      return rejectWithValue(error);
    }
  }
);

export const getUserByDocument = createAsyncThunk(
  "user/getUserByDocument",
  async (
    data: {
      documento: string
    }
    , { rejectWithValue }) => {
    try {
      const response = await api.get(`/user/getheranca?quantidade=15&filtro=${data?.documento}`)
      return response?.data
    } catch (error: any) {
      toast.error("Usuário não encontrado!");
      return rejectWithValue(error);
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/login",
  async (
    data: {
      documento: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiAdmin.post(`/auth/signin`, data);
      return response?.data;
    } catch (error: any) {
      toast.error("Ocorreu um erro ao tentar fazer o login, tente novamente!");
      return rejectWithValue(error);
    }
  }
);

export const loginUserPropig = createAsyncThunk(
  "user/loginUserPropig",
  async (
    data: {
      documento: string;
      usuario: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiAdmin.post(`/auth/signinPropig`, data)
      return response?.data
    } catch (error: any) {
      toast.error("Ocorreu um erro ao tentar fazer o login, tente novamente!");
      return rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (
    data: {
      documento: string;
      usuario: string;
      email: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(`/user/changePasswordWithEmail`, data)
      return response?.data
    } catch (error: any) {
      // toast.error("Ocorreu um erro ao tentar recuperar senha, tente novamente!")
      return rejectWithValue(error);
    }
  }
);

export const requestContact = createAsyncThunk(
  "user/requestContact",
  async (
    data: {
      name: string;
      phone: string | undefined;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.get(`/telefonia/callMe?nomeCliente=${data.name}&numero=${data.phone}`)
      return response?.data
    } catch (error: any) {
      // toast.error("Ocorreu um erro ao tentar recuperar senha, tente novamente!")
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (
    data: {
      oldPassword: string,
      newPassword: string,
    }
    , { rejectWithValue }) => {
    const SALT = config.SALT

    const cryptoData = {
      oldPassword: CryptoJS.AES.encrypt(data.oldPassword, SALT).toString(),
      newPassword: CryptoJS.AES.encrypt(data.newPassword, SALT).toString()
    }

    try {
      const response = await api.post(`/user/updateNewPassword`, cryptoData)
      toast.success("Senha atualizada com sucesso!")
      return response.data
    } catch (error: any) {
      toast.error(error?.response?.data.message);
      return rejectWithValue(error);
    }
  }
);

export const onDemand = createAsyncThunk(
  'user/onDemand',
  async (
    _
    , { rejectWithValue }) => {

    try {
      const response = await api.post(`/credmoura/onDemand`, {})
      return response.data
    } catch (error: any) {
      toast.error(error?.response?.data.message);
      return rejectWithValue(error);
    }
  }
);
