import axios from 'axios'
import { toast } from 'react-toastify'
import { logoutUser } from '../store/user'
import useAppDispatch from '../hooks/useAppDispatch'
import configApi from '../config'


const api = axios.create({
  baseURL: configApi.BASE_URL_ADMIN
})

api.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('token')
    if(token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers['x-api-key'] = configApi.API_KEY
    return config
  },
  (error) => {
    if (error?.response && error?.response?.data?.message === 'jwt expired') {
      const dispatch = useAppDispatch()
      dispatch(logoutUser({ isAuthenticated: false, token: '' }))
    }

    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  (error) => {
    if (error?.response?.data?.message === 'jwt expired') {
      window.localStorage.removeItem('profile')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('isAuthenticated')
      window.localStorage.removeItem('name')
      window.localStorage.removeItem('loginType')
      window.localStorage.removeItem('documento')
      window.localStorage.removeItem('permissions')
      
      toast.info('Sua sessão expirou. Por favor, faça o login novamente.')

      setTimeout(() => {
        window.location.href = '/login'
      }, 2000)
      return null
    }

    return Promise.reject(error)
  }
)

export default api
