import {css, styled} from "styled-components"

const cardModifier: any = {
  primary: () => css`
    flex-direction: column;
    gap: 5px;
  `,
  secondary: () => css`
    justify-content: space-between;
    align-items: center;
  `,
  tertiary: () => css`
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
}

export const ContainerCard = styled.div<{$noBorder?: boolean, $typeCard?: string}>`
  display: flex;
  width: 100%;
  border-radius: 18px;
  border: ${({$noBorder}) => $noBorder ? "none" : "0.5px solid rgba(199, 203, 209, 0.60)"};
  background-color: #FFF;
  padding: 18px;
  
  ${({$typeCard}) => css`
    ${$typeCard && cardModifier[$typeCard]}
  `}

  p {
    font-family: ${({theme}) => theme.font.family};
    margin: 0;
  }
`

export const Title = styled.p<{$fontSize: string}>`
  font-size: ${({$fontSize}) => $fontSize};
  font-weight: ${({theme}) => theme.font.weigth700};
  text-transform: none;
  text-align: left;
  color: ${({theme}) => theme.color.titleCard};
`

export const Description = styled.span`
  font-size: 12px;
  font-weight: ${({theme}) => theme.font.weigth400};
  color: ${({theme}) => theme.color.secondary};
`

export const Balance = styled.p<{$fontSize: string}>`
  font-size: ${({$fontSize}) => $fontSize};
  font-weight: ${({theme}) => theme.font.weigth700};
  color: ${({theme}) => theme.color.primary}
`