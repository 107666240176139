import React, { useMemo, useCallback } from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Cover from './pages/Cover/Cover'
import Login from './pages/Login/Login'
import Vendas from './pages/Vendas/Vendas'
import TransacoesDetalhadas from './pages/TransacoesDetalhadas/TransacoesDetalhadas'
import Recebimentos from './pages/Recebimentos'
import VendaDetails from './pages/Vendas/Details'
import RecebimentoDetails from './pages/Recebimentos/Components/Details'
import MeusDados from './pages/MeusDados/MeusDados'
import LinksDePagamento from './pages/LinksPagamento/index'
import { Cobrancas } from './pages/Cobrancas'
import useAppSelector from './hooks/useAppSelector'
import { PermissionsProps, TokenType } from './store/user'
import jwt_decode from 'jwt-decode'
import RouteGuard from './utils/routeGuard'
import RastreioPage from './pages/Rastreio'
import DetailsByIdMobile from './pages/Rastreio/Components/DetailsByIdMobile'
import NewLinkPageMobile from './pages/LinksPagamento/Components/NewLinkPageMobile'
import ResumoVendas from './pages/ResumoVendas'

const Router: React.FC<any> = (isAutheticated) => {
  const option = useAppSelector(state => state.switchButtons.option)
  const { user: { permissions, tipoAgenda }, token } = useAppSelector(state => state.user)
  const user = isAutheticated.isAutheticated ? jwt_decode<{user: TokenType}>(token).user : ''



  const functionalities = useMemo(() => {
    if(permissions) {
        const userPermissions: PermissionsProps = JSON.parse(permissions)
        return userPermissions[tipoAgenda as keyof PermissionsProps]?.funcionalidades
    }
    return null;
  },[permissions, tipoAgenda]);

  const hasPermision = useCallback((functionalitie: string) => {
    if(!!user) {
      if(option === 'MOURA' && functionalities?.includes(functionalitie)){
        return true
      } else if(((user.documento === user['distribuidor.cnpj'] || user['distribuidor.cnpj'] === null) && functionalities?.includes(functionalitie)) || (tipoAgenda === 'livre' && functionalities?.includes(functionalitie))) {
        return true
      }
    }
    return false
  },[option, user, functionalities, tipoAgenda])

  return (
    <Routes>
      <Route path='' element={<Cover/>} />
      <Route path='login' element={<Login/>} />
      <Route
        path='home'
        element={
          <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
            <Home/>
          </RouteGuard>
        }
      />
      <Route
        path='vendas'
      >
        <Route
          index 
          element={
            <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
              <Vendas/>
            </RouteGuard>
          }
        />
        <Route
          path='details/:id'
          element={
            <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
              <VendaDetails/>
            </RouteGuard>
          }
        />
      </Route>
      <Route
        path='resumo-vendas'
        element={
          <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
            <ResumoVendas/>
          </RouteGuard>
        }
      />
      <Route path='links-de-pagamento'>
        <Route
          index
          element={
            <RouteGuard isAutheticated={isAutheticated.isAutheticated} havePermission={hasPermision('Link de Pagamento')} redirectPath='/home'>
              <LinksDePagamento/>
            </RouteGuard>
          }
        />
        <Route
          path='novo-link-de-pagamento'
          element={
            <RouteGuard isAutheticated={isAutheticated.isAutheticated} havePermission={hasPermision('Link de Pagamento')} redirectPath='/home'>
              <NewLinkPageMobile />
            </RouteGuard>
          }
        />
      </Route>
      <Route
        path='cobrancas'
        element={
          <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
            <Cobrancas/>
          </RouteGuard>
        }
      />
      <Route
        path='transacoes-detalhadas'
        element={
          <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
            <TransacoesDetalhadas/>
          </RouteGuard>
        }
      />
      <Route
        path='recebimentos'
      >
        <Route
          index
          element={
            <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
              <Recebimentos/>
            </RouteGuard>
          }
        />
        <Route
          path='details'
          element={
            <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
              <RecebimentoDetails/>
            </RouteGuard>
          }
        />
      </Route>
      <Route
        path='meus-dados'
        element={
          <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
            <MeusDados/>
          </RouteGuard>
        }
      />
      <Route
        path='rastreio-maquina'
      >
        <Route
          index
          element={
            <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
              <RastreioPage/>
            </RouteGuard>
          }
        />
        <Route
          path='details/:id'
          element={
            <RouteGuard isAutheticated={isAutheticated.isAutheticated}>
              <DetailsByIdMobile/>
            </RouteGuard>
          }
        />
      </Route>
    </Routes>
  )
}

export default Router