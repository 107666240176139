/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { TextAreaInput, Wrapper } from './styles'

export type InputProps = {
  placeHolder: string
  label?: string
  width?: string
  id: string
  onChange: any
  value: string
  disabled?: boolean
  rows?: number
}

const TextArea = ({
  placeHolder,
  width,
  id,
  onChange,
  value,
  disabled,
  label,
  rows = 4
}: InputProps) => {
  return (
    <Wrapper
      width={width}
      value={value}
    >
      {label && <label>{label}</label>}
      <TextAreaInput
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
        rows={rows}
        disabled={disabled}
      />

    </Wrapper>
  )
}

export default TextArea
