import React from 'react'
import { optionType } from '../../constants'
import { authenticator } from '../../store/user'
import Button from '../Button/Button'
import useAppSelector from '../../hooks/useAppSelector'
import useMedia from '../../hooks/useMedia'
import { useLocation } from 'react-router-dom'
import { handleSwitchButton } from '../../store/switchButtons'
import { switchAgenda } from '../../store/actions/switchActions'
import useAppDispatch from '../../hooks/useAppDispatch'

interface Props {
    view?: 'LOJA' | 'MOURA' | 'BOTH'
}

const SwitchButtons: React.FC<Props> = ({view = 'BOTH'}) => {
    const dispatch = useAppDispatch()
    const mobile = useMedia('(max-width: 40rem)')
    const location = useLocation();
    const option = useAppSelector(state => state.switchButtons.option)
    const { user: { profile } } = useAppSelector(state => state.user)

    const handleSwitch = async (option: any) => {
        if(!(profile === "flex")) {
            const response = await dispatch(switchAgenda({tipoAgenda: option.toLowerCase()}))
            if(response.meta.requestStatus === 'fulfilled') {
                dispatch(authenticator({ isAuthenticated: true, token: response?.payload?.token }))
            }
        }
        dispatch(handleSwitchButton(option))
    }

    return (
        <>
            {['MOURA', 'BOTH'].includes(view) && (
                <Button
                    width="40%"
                    typeButton={"outline"}
                    backgroundColor={option === optionType.MOURA ? "#FDD400" : "transparent"}
                    border="#FDD400"
                    label="MOURA"
                    onClick={() => handleSwitch(optionType.MOURA)}
                    color={(mobile && location.pathname === "/home" && option === optionType.LOJA) ? "#FFFFFF" : "#002868"}
                />

            )}
            {['LOJA', 'BOTH'].includes(view) && (
                <Button
                    width="40%"
                    typeButton={"outline"}
                    backgroundColor={option === optionType.LOJA ? "#FDD400" : "transparent"}
                    border="#FDD400"
                    label="LOJA"
                    onClick={() => handleSwitch(optionType.LOJA)}
                    color={(mobile && location.pathname === "/home" && option === optionType.MOURA) ? "#FFFFFF" : "#002868"}
                />
            )}
        </>
    )
}

export default SwitchButtons