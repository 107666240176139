import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form";
import { ContainerForm, ContentForm, ContentTitle, Row, Title } from "../styles"
import ReactLoading from "react-loading";
import Button from "../../../components/Button/Button";
import InputMask from "react-input-mask";
import { BsEye, BsEyeSlash } from "react-icons/bs";

type FormAddress = {
  address: string,
  city: string,
  uf: string,
  cep: string
}

type AddressType = {
  loading: boolean

}

const AddressForm = ({ loading }: AddressType) => {
  const [openForm, setOpenForm] = useState(false)

  const {
    register,
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      address: "",
      city: "",
      uf: "",
      cep: ""
    }
  });

  const onSubmit = (data: FormAddress) => console.log(data);


  return (
    <ContainerForm>
        <ContentTitle>
          <Title>Endereço</Title>
          {/* <Description>Edite seus dados de endereço</Description> */}
        </ContentTitle>
        <ContentForm>
          {!openForm && (
            <>
              {loading ? (
                <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'5%'}/>
              ) : (
                <Button width="35%" height="40px" onClick={() => setOpenForm(true)} label="Visualizar" disabled typeButton="outline" icon={<BsEye color="#002868" />}/>
              )}
            </>
          )}
          {openForm && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <input placeholder="Endereço" disabled {...register("address")} />
              <Row>
                <input placeholder="Cidade" disabled {...register("city")} />
                <input placeholder="UF" disabled {...register("uf")} />
                <Controller
                  control={control}
                  name="cep"
                  render={({field}) => <InputMask mask="99999-999" disabled placeholder="CEP" {...field} />}
                />
              </Row>
              <div className="content-buttons">
                <Button width="35%" height="40px" icon={<BsEyeSlash color="#002868" />} onClick={() => setOpenForm(false)} label="Fechar" typeButton="outline"/>
                {/* <Button type="submit" width="30%" label="Salvar" typeButton="primary" disabled/> */}
              </div>
            </form>
          )}

        </ContentForm>
      </ContainerForm>
  )
}

export default AddressForm