/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { ContentForm } from "../styles"
import FormLinkPaymentMobile from "./FormLinkPaymentMobile"
import { IoMdArrowDropdown } from "react-icons/io"
import { useTheme } from "styled-components"

interface Props {
  open: boolean
  setOpen(arg: boolean): void
}

const CreatePaymentLinkMobile: React.FC<Props> = ({open, setOpen}) => {
  const theme = useTheme()

  const [height, setHeight] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [initialY, setInitialY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const sectionRef = useRef(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  //touch events
  const handleTouchStart = (e: any) => {

    const divElement = document.getElementById('form-scroll')
    if (divElement?.scrollTop === 0) {
        setInitialY(e.touches[0].clientY);
        setIsDragging(true);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleTouchMove = (e: any) => {
    if (!isDragging) return;
    setCurrentY(e.touches[0].clientY);
    const diff = initialY - currentY;
    setHeight(height + diff);
    setInitialY(currentY);
  };

  useEffect(() => {
    if (isDragging && height < 300) {
      setIsTransitioning(true);
      setOpen(false)
    }
  }, [height, isDragging]);

  useEffect(() => {
    if (open && !isDragging) {
      setHeight(window.innerHeight - 100)
      setIsTransitioning(false);
    }
  }, [open, isDragging]);

  const handleClose = () => {
    setOpen(false)
    setIsTransitioning(true)
  }

  return (
    <ContentForm $open={open}>
      <div
        ref={sectionRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        style={{
          height: `${isTransitioning ? 0 : height}px`,
          position: "relative",
          maxHeight: `${window.innerHeight - 100}px`,
          transition: `${isTransitioning && "height 0.6s ease-in-out"}`,
        }}
        className="wrapper-content"
      >
          <div className="grab-container">
            {/* <span className="grab"></span> */}
            <IoMdArrowDropdown color={theme.colors.blue} size={30} onClick={handleClose} />
          </div>

          <div className="wrapper-form" id="form-scroll">
            <FormLinkPaymentMobile setIsTransitioning={setIsTransitioning} setOpen={setOpen} />
          </div>
      </div>
    </ContentForm>
  )
}

export default CreatePaymentLinkMobile