import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import {
  ContainerPagination,
  Button,
  ButtonPagination
} from "./styles"

const MAX_ITEMS = 3;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;

export type PaginationProps = {
  limit: number,
  total: number,
  offset: number,
  setOffset: any,
  manualPagination?: boolean,
  setPage?: any
}


//setPage é um função que so deve ser usada se for uma paginação feita apenas no front
export const Pagination = ({limit, total, offset, setOffset, manualPagination, setPage}: PaginationProps) => {

  const current = manualPagination ? (offset ? offset / limit + 1 : 1) : (offset ? offset + 1 : 1)
  const pages = Math.ceil(total / limit);
  const maxFirst = Math.max(pages - (MAX_ITEMS - 1), 1);
  const first = Math.min(
    Math.max(current - MAX_LEFT, 1),
    maxFirst
  );

  const onChangePage = (page: number) => {
  if(manualPagination) {
    setOffset((page - 1) * limit)
    setPage(page)
  } else {
    setOffset((page - 1));
  }
  };

  return (
    <ContainerPagination>
      <li>
        <Button onClick={() => onChangePage(current - 1)} disabled={current === 1}><IoIosArrowBack /></Button>
      </li>
      {Array.from({length: Math.min(MAX_ITEMS, pages)})
        .map((_, index) => index + first)
        .map((page) => (
          <li key={page}>
            <ButtonPagination
              $active={page === current}
              onClick={() => onChangePage(page)}
            >
              {page}
            </ButtonPagination>
          </li>
        ) )
      }
      <li>
        <Button onClick={() => onChangePage(current + 1)} disabled={current === pages}><IoIosArrowForward /></Button>
      </li>
    </ContainerPagination>
  )
}