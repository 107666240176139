/* eslint-disable no-empty-pattern */
import * as transactionsActions from './actions/transactionsActions'
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit'

export interface TransactionByDayItemType {
  antecipation: number,
  antecipationPercent: string,
  clientGrossValue: number,
  clientNetValue: number,
  credit: number,
  creditPercent: string,
  grossValue: number,
  mdr: number,
  mdrPercent: string,
  netValue: number,
  sub: string,
  tec: number,
  tecPercent: string,
  transactionDate: string,
  transactionStatus: string,
  transactions: string,
}

export interface ByTransactionItemType {
  antecipation: number,
  antecipationPercent: string,
  authorizationCode: string,
  cardBrand: string,
  clientGrossValue: number,
  clientNetValue: number,
  credit: number,
  creditPercent: string,
  date: string,
  grossValue: number,
  installments: number,
  mdr: number,
  mdrPercent: string,
  netValue: number,
  nsu: string,
  paymentType: string,
  pos: string,
  sub: string,
  tec: number,
  tecPercent: string,
  transactionStatus: string,
  installmentsDetails: {
    totalInstallments: number,
    installments: {
      installment: number,
      paymentDate: string | null
    }[]
  } | null
}

export interface SalesProps {
  loadingDataByDay: boolean,
  loadingDataByTransaction: boolean,
  transactionsByDay: Array<TransactionByDayItemType>
  totalByDay: number,
  dataByTransaction: Array<ByTransactionItemType>
  totalByTransaction: number
}

const initialState: SalesProps = {
  loadingDataByDay: false,
  loadingDataByTransaction: false,
  transactionsByDay: [],
  totalByDay: 0,
  dataByTransaction: [],
  totalByTransaction: 0
}

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addMatcher(isPending(transactionsActions.getTransactionsByDay),
      (state) => {
        state.loadingDataByDay = true
      }
    )
    builder.addMatcher(isFulfilled(transactionsActions.getTransactionsByDay),
      (state, action) => {
        state.loadingDataByDay = false
        state.transactionsByDay = action.payload?.rows
        state.totalByDay = Number(action.payload?.total)
      }
    )
    builder.addMatcher(isRejected(transactionsActions.getTransactionsByDay),
      (state) => {
        state.loadingDataByDay = false
      }
    )

    builder.addMatcher(isPending(transactionsActions.getTransactionsByTransaction),
      (state) => {
        state.loadingDataByTransaction = true
      }
    )
    builder.addMatcher(isFulfilled(transactionsActions.getTransactionsByTransaction),
      (state, action) => {
        state.loadingDataByTransaction = false
        state.dataByTransaction = action.payload?.rows
        state.totalByTransaction = Number(action.payload?.total)
      }
    )
    builder.addMatcher(isRejected(transactionsActions.getTransactionsByTransaction),
      (state) => {
        state.loadingDataByTransaction = false
      }
    )

  }
})

export const {  } = transactionsSlice.actions

export default transactionsSlice.reducer