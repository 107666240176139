import React, { useMemo, useState } from  'react'
import useAppSelector from '../../../hooks/useAppSelector'
import { useNavigate } from 'react-router-dom'

import { CardMenu, ContainerMobile, ContentImage, ContentMenu, ContentModalNewFeature, Label } from '../styles'
import Card from '../../../components/Card/Card'
import BannerMobile from './BannerMobile'
import WelcomeModalMobile from './WelcomeModalMobile'
import { optionType } from '../../../utils/constants'

import salesIcon from "../../../assets/sales-icon.svg"
import boletosIcon from "../../../assets/boletos-icon.svg"
import linkPagamentoIcon from "../../../assets/link-pagamento-icon.svg"
import extratoIcon from "../../../assets/extrato-icon.svg"
import recebimentosIcon from '../../../assets/recebimentos-white-icon.svg'
import cobrancasIcon from '../../../assets/cobrancas-icon.svg'
import ClockImg from '../../../assets/clock-icon.svg'

import { allowSwitchButtons, getLastUpdate } from '../../../utils/functions'
import { PermissionsProps } from '../../../store/user'
import ReactModal from 'react-modal'
import Button from '../../../components/Button/Button'
import { IoLocationOutline } from 'react-icons/io5'

const modalStyles = {
  content: {
    width: "80%",
    height: "max-content",
    margin: "auto",
    padding: "10px",
    borderRadius: "15px",
    boxShadow: "0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.212)",
    borderColor: "#dbdbdb",
  }
}

interface Props {
  handleOpenBankSlip: () => void
}

const ALLOWED_CNPJ = ['07242818000160', '32392800000191']

const HomeMobile: React.FC<Props> = ({handleOpenBankSlip}) => {
  const navigate = useNavigate()
  const option = useAppSelector(state => state.switchButtons.option)
  const { user: { profile, permissions, document: cnpj } } = useAppSelector(state => state.user)
  const {
    balanceCredmoura,
    balanceCreditToRelease,
    balanceOpenBankSlip,
    loadingBalanceCredmoura,
    loadingBalanceCreditToRelease,
    loadingBalanceOpenBankSlip,
    balanceReceivementsOfTheMonth,
    loadingBalanceReceivementsOfTheMonth,
    operacoesObject,
  } = useAppSelector(state => state.receivements)

  const [modalNewFeature, setModalNewFeature] = useState(false)

  const isMoura = useMemo(() => {
    return option === optionType.MOURA
  },[option])

  const permissionsLivre = useMemo(() => {
    if(permissions) {
        const userPermissions: PermissionsProps = JSON.parse(permissions)
        return userPermissions?.livre?.funcionalidades
    }
    return []
  },[permissions])

  const permissionsMoura = useMemo(() => {
      if(permissions) {
          const userPermissions: PermissionsProps = JSON.parse(permissions)
          return userPermissions?.moura?.funcionalidades
      }
      return []
  },[permissions])

  const lastUpdate = useMemo(() => {
    return getLastUpdate(operacoesObject)
  },[operacoesObject])

  const redirectToPage = (url: string) => {
    navigate(url)
  }

  return (
    <>
      <ContainerMobile $marginTop={allowSwitchButtons(profile) ? "240px" : "180px"}>
        <div className='content-cards-mobile'>
          {isMoura ? (
            <>
              <Card title={"Saldo CREDMOURA"} description={`Última atualização: ${lastUpdate}`} balance={balanceCredmoura} loading={loadingBalanceCredmoura}/>
              <div className='row-cards'>
                <Card style={{padding: "10px 18px"}} title={"Créditos a Liberar"} balance={balanceCreditToRelease} loading={loadingBalanceCreditToRelease}/>
                {permissionsMoura?.includes("Parceiro Moura") && (
                  <Card style={{padding: "10px 18px"}} title={"Boletos em aberto"} balance={balanceOpenBankSlip} loading={loadingBalanceOpenBankSlip}/>
                )}
              </div>
            </>
          ) : (
            <Card title={"Recebimentos do Mês"} balance={balanceReceivementsOfTheMonth} loading={loadingBalanceReceivementsOfTheMonth}/>
          )}
        </div>
        <ContentMenu>
          <div className='wrapper-cards'>
            <CardMenu onClick={() => redirectToPage("/recebimentos")}>
                <ContentImage>
                    <img src={recebimentosIcon} alt="icone recebimentos" />
                </ContentImage>
                <Label>Recebimentos</Label>
            </CardMenu>
            <CardMenu onClick={() => redirectToPage("/vendas")}>
                <ContentImage>
                    <img src={salesIcon} alt="icone vendas" />
                </ContentImage>
                <Label>Vendas</Label>
            </CardMenu>
            {isMoura && (
                <>
                  <CardMenu onClick={() => setModalNewFeature(true)}>
                    <ContentImage $disabled>
                      <img src={extratoIcon} alt="icone extrato" />
                    </ContentImage>
                    <Label $disabled>Extrato</Label>
                  </CardMenu>
                  {permissionsMoura?.includes("Link de Pagamento") && (
                    <CardMenu
                      onClick={
                        ALLOWED_CNPJ.includes(cnpj) ?
                          () => redirectToPage("/links-de-pagamento") :
                          () => setModalNewFeature(true)
                        }
                    >
                      <ContentImage $disabled={!ALLOWED_CNPJ.includes(cnpj)}>
                        <img src={linkPagamentoIcon} alt="Ícone Link de Pagamento" />
                      </ContentImage>
                      <Label $disabled={!ALLOWED_CNPJ.includes(cnpj)}>Link de Pagamento</Label>
                    </CardMenu>
                  )}
                  <CardMenu onClick={() => setModalNewFeature(true)}>
                    <ContentImage $disabled>
                      <img src={recebimentosIcon} alt="Ícone Recebimentos" />
                    </ContentImage>
                    <Label $disabled>Créditos a Liberar</Label>
                  </CardMenu>
                  {permissionsMoura?.includes("Parceiro Moura") && (
                    <CardMenu onClick={handleOpenBankSlip}>
                      <ContentImage>
                        <img src={boletosIcon} alt="Ícone Boletos Moura" />
                      </ContentImage>
                      <Label>Parceiro Moura</Label>
                    </CardMenu>
                  )}
                </>
            )}
              {permissionsLivre?.includes("Link de Pagamento") && (
                <CardMenu
                  onClick={
                    ALLOWED_CNPJ.includes(cnpj) ?
                      () => redirectToPage("/links-de-pagamento") :
                      () => setModalNewFeature(true)
                    }
                >
                  <ContentImage $disabled={!ALLOWED_CNPJ.includes(cnpj)}>
                    <img src={linkPagamentoIcon} alt="Ícone Link de Pagamento" />
                  </ContentImage>
                  <Label $disabled={!ALLOWED_CNPJ.includes(cnpj)}>Link de Pagamento</Label>
                </CardMenu>
              )}
              
              {!isMoura && (
                <CardMenu onClick={() => setModalNewFeature(true)}>
                  <ContentImage>
                    <img src={cobrancasIcon} alt="Ícone Cobranças" />
                  </ContentImage>
                  <Label>Cobranças</Label>
                </CardMenu>
              )}
              {/* <CardMenu onClick={() => redirectToPage("/rastreio-maquina")}>
                <ContentImage>
                    <IoLocationOutline color='#FFFFFF' size={35} />
                </ContentImage>
                <Label>Rastreio Máquina</Label>
            </CardMenu> */}
          </div>
        </ContentMenu>
        <BannerMobile />
        <WelcomeModalMobile />
      </ContainerMobile>
      <ReactModal
        isOpen={modalNewFeature}
        onRequestClose={() => setModalNewFeature(false)}
        style={modalStyles}
      >
        <ContentModalNewFeature>
          <img src={ClockImg} alt="imagem do relógio" />
          <p className='shortly'>EM BREVE</p>
          <p className='new-feature'>Mais uma nova ferramenta para te auxiliar no dia-a-dia</p>
          <Button onClick={() => setModalNewFeature(false)} typeButton="primary" width={"35%"} label="Fechar" />
        </ContentModalNewFeature>
      </ReactModal>
    </>
  )
}

export default HomeMobile