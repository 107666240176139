import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../services/apiAdmin';

export const getTrackingOrdens = createAsyncThunk(
  'tracking/getTrackingOrdens',
  async (_, { rejectWithValue }) => {
    try {

      const response = await api.get(`/tracking/orders`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})

export const getOrderById = createAsyncThunk(
  'tracking/getOrderById',
  async (
    data: {
      id: string
    }, { rejectWithValue }) => {
    try {

      const response = await api.get(`/tracking/findById/${data.id}`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})