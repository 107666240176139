/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useMemo } from 'react'
import { Wrapper, TableWrapper, TableHead, TableBody } from './styles'
import ReactLoading from 'react-loading'
import { useTheme } from 'styled-components'

type ColumnDefinitionType<T, K extends keyof T> = {
  key: K
  header: string
  format?: (row: T) => any
  children?: Array<{
    key: K
    header: string
    format?: (row: T) => any
  }>
}

type TableProps<T, K extends keyof T> = {
  columns: Array<ColumnDefinitionType<T, K>>
  data: Array<T>
  loading?: boolean
  isSelectable?: boolean
  onClick?(arg: T): void
}

const Table = <T, K extends keyof T>({
  columns,
  data,
  loading,
  isSelectable = false,
  onClick,
}: TableProps<T, K>) => {
  const theme = useTheme()

  const countColumns = useMemo(() => {
    const count = columns?.reduce((acc, curr) => {
      if (curr?.children?.length) {
        return acc += curr?.children?.length
      }
      return acc += 1
    }, 0)
    return count
  },[columns])

  return (
    <Wrapper>
      <TableWrapper>
        <TableHead>
          <tr>
            {columns.map((column, index) => (
              <React.Fragment key={`headCell-${index}`}>
                {column?.children?.length ?
                  <th scope='col' colSpan={2} style={{minWidth: columns?.length > 8 ? '130px' : 'initial'}}>{column.header}</th>
                :
                  <th scope='row' rowSpan={2} style={{minWidth: columns?.length > 8 ? '130px' : 'initial'}}>{column.header}</th>
                }
              </React.Fragment>
            ))}
          </tr>
          
          <tr>
            {columns.map((column, index) => (
              <React.Fragment key={index}>
                {column?.children?.length &&
                  <>
                    {column?.children?.map((children, indexChildren) => (
                      <th scope='col' key={`headCell-${indexChildren}`}>{children?.header}</th>
                    ))}
                  </>
                }
              </React.Fragment>
            ))}
          </tr>
        </TableHead>
        <TableBody $selectable={isSelectable && !loading}>
          {loading ? (
            <tr>
              <td colSpan={columns.length}>
                <ReactLoading
                  type={'spin'}
                  color={theme.colors.blue}
                  height={'fit-content'}
                  width={'5%'}
                  className='loading'
                />
              </td>
            </tr>
          ) : (
            <Fragment>
              {data.length ? (
                data.map((row, index) => (
                  <tr
                    key={`row-${index}`}
                    onClick={() => {
                      if (isSelectable && onClick) {
                        onClick(row)
                      }
                    }}
                  >
                    {columns.map((column, index2) => (
                      <React.Fragment key={`cell-${index2}`}>
                        {column?.children?.length ?
                          <>
                            {column?.children?.map((children, indexChildren) => (
                              <td key={`cell-${indexChildren}`}>
                                {children.format ? children.format(row) : row[children.key]}
                              </td>
                            ))}
                          </>
                        :
                          <td>
                            {column.format ? column.format(row) : row[column.key]}
                          </td>
                        }
                      </React.Fragment>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={countColumns} style={{height: '150px', fontSize: '16px'}}>Não foram encontrados registros!</td>
                </tr>
              )}
            </Fragment>
          )}
        </TableBody>
      </TableWrapper>
    </Wrapper>
  )
}

export default Table
