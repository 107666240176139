import dayjs from "dayjs";
import { optionType } from "../../../utils/constants";
import { OptionsProps } from "../../../store/switchButtons";
import { changeDecimalNumber } from "../../../utils/functions";

export const filteredRows = (data: any, fromDate: Date, toDate: Date) => {
  const filteredRows = data.rows
    .filter(
    (operation: any) =>{
        //usando replace para remover milesimos da data de transação
        //por estar influenciando
        //na conversão para objeto de data.
        // operation["Data da Transação"] = operation["Data da Transação"].replace('.000Z','')
        return new Date(operation["Data de Lançamento"]) >= fromDate && new Date(operation["Data de Lançamento"]) <= toDate
    }
    )
    .sort(
    (a: any, b: any) =>
        new Date(b["Data da Transação"]).getTime() -
        new Date(a["Data da Transação"]).getTime()
    )
    .map((result: any) => ({
    ...result,
    "Data da Transação": dayjs(result["Data da Transação"]).format(
        "DD/MM/YYYY"
    ),
    "Data de Lançamento": dayjs(result["Data de Lançamento"]).format(
        "DD/MM/YYYY"
    ),
    }));
  return filteredRows
}

export const handleDataPaymentsPerTransaction = (data: any, option: OptionsProps) => {
  const installmentsString = option === optionType.LOJA ? 'Quantidade de parcelas' : 'Parcela';

  for (let index = 0; index < data["rows"].length; index++) {
      data["rows"][index]["Data Transação"] = data["rows"][index]["Data Transação"] == null ? '-' : data["rows"][index]["Data Transação"];
      data["rows"][index]["Data Recebimento"] = data["rows"][index]["Data Recebimento"] == null ? '-' : data["rows"][index]["Data Recebimento"];
      data["rows"][index]["Código NSU"]  = data["rows"][index]["Código NSU"] == null ? '-' : data["rows"][index]["Código NSU"];
      if(data["rows"][index]["Autorização"]) {
          data["rows"][index]["Autorização"] = data["rows"][index]["Autorização"] == null ? '-' : data["rows"][index]["Autorização"];
      }
      data["rows"][index]["Modalidade"] = data["rows"][index]["Modalidade"] == null ? '-' : data["rows"][index]["Modalidade"];
      if(data["rows"][index][installmentsString]) {
          data["rows"][index][installmentsString] = data["rows"][index][installmentsString] === '/' ? '-' : data["rows"][index][installmentsString];
      }
      data["rows"][index]["Bandeira"] = data["rows"][index]["Bandeira"] == null ? '-' : data["rows"][index]["Bandeira"];
      data["rows"][index]["Valor transação (A)"] = data["rows"][index]["Valor transação (A)"] == null ? '-' : data["rows"][index]["Valor transação (A)"].toFixed(2).replace('.', ',');
      data["rows"][index]["Taxa adminst. MDR (B)"] = data["rows"][index]["Taxa adminst. MDR (B)"] == null ? '-' : data["rows"][index]["Taxa adminst. MDR (B)"].toFixed(2).replace('.', ',');
      data["rows"][index]["Taxa adminst. MDR %"] = data["rows"][index]["Taxa adminst. MDR %"] == null ? '-' : data["rows"][index]["Taxa adminst. MDR %"].toFixed(2).replace('.', ',');
      if (option !== optionType.LOJA) {
      data["rows"][index]["Valor líquido parcela (A-B)"] = data["rows"][index]["Valor líquido parcela (A-B)"] == null ? '-' : data["rows"][index]["Valor líquido parcela (A-B)"].toFixed(2).replace('.', ',');
      }
      if(data["rows"][index]["Taxa antecipação (D)"]) {
          data["rows"][index]["Taxa antecipação (D)"] = data["rows"][index]["Taxa antecipação (D)"] == null ? '-' : data["rows"][index]["Taxa antecipação (D)"].toFixed(2).replace('.', ',');

      }
      data["rows"][index]["Taxa antecipação %"] = data["rows"][index]["Taxa antecipação %"] == null ? '-' : data["rows"][index]["Taxa antecipação %"].toFixed(2).replace('.', ',');
      if(data["rows"][index]["Recebimento bruto cliente (C)"]) {
          data["rows"][index]["Recebimento bruto cliente (C)"] = data["rows"][index]["Recebimento bruto cliente (C)"] == null ? '-' : data["rows"][index]["Recebimento bruto cliente (C)"].toFixed(2).replace('.', ',');
      }
      if(data["rows"][index]["Recebimento líquido cliente (C-D)"]) {
          data["rows"][index]["Recebimento líquido cliente (C-D)"] = data["rows"][index]["Recebimento líquido cliente (C-D)"] == null ? '-' : data["rows"][index]["Recebimento líquido cliente (C-D)"].toFixed(2).replace('.', ',');
      }
      if(data["rows"][index]["Recebimento líquido cliente (A-B-C)"]) {
          data["rows"][index]["Recebimento líquido cliente (A-B-C)"] = data["rows"][index]["Recebimento líquido cliente (A-B-C)"] == null ? '-' : data["rows"][index]["Recebimento líquido cliente (A-B-C)"].toFixed(2).replace('.', ',');
      }
  }

  return data
}

export const handleExportCreditsToRelease = (data: any) => {
  for (let i = 0; i < data.rows.length; i++) {
    data.rows[i]["Valor líquido"] = parseFloat(
      changeDecimalNumber(
        data.rows[i]["Valor líquido"]
      )
    );
  }

  return data
}