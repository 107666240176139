import React, { ReactNode } from 'react'
import ReactLoading from 'react-loading'
import Button from '../Button/Button'
import { Balance, ContainerCard, Description, Title } from './styles'
import { IoIosArrowForward } from 'react-icons/io'
import useMedia from '../../hooks/useMedia'
import { formatToCurrencyBRL } from '../../utils/functions'

type CardProps = {
  title?: string,
  balance?: number,
  description?: string,
  borderNone?: boolean,
  loading?: boolean,
  onClick?: any,
  children?: ReactNode,
  typeCard?: "primary" | "secondary" | "tertiary" | "fourth",
  style?: React.CSSProperties
}


const Card = ({
  title,
  balance,
  description,
  borderNone,
  loading,
  onClick,
  children,
  typeCard = "primary",
  style
}: CardProps) => {
  const mobile = useMedia('(max-width: 40rem)')

  return (
    <>
    {typeCard === "primary" && (
      <ContainerCard $typeCard={typeCard} $noBorder={borderNone} style={style}>
        <Title $fontSize={mobile ? "12px" : "16px"}>{title}</Title>
        {loading ? (
          <div><ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'5%'}/></div>
        ) : (
          <>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Balance $fontSize={mobile ? "22px" : "30px"}>{formatToCurrencyBRL(balance)}</Balance>
            {children && (
              <>{children}</>
            )}
            {onClick && (
              <Button onClick={onClick} typeButton="outline" noBorder width="40px" icon={<IoIosArrowForward color='#002868' />} />
            )}
  
          </div>
            <Description>{description}</Description>
          </>
        )}
      </ContainerCard>
    )}
    {typeCard === "secondary" && (
      <ContainerCard $typeCard={typeCard} $noBorder={borderNone}>
        <div>
          <Title $fontSize={mobile ? "12px" : "16px"}>{title}</Title>
          <Description>{description}</Description>
        </div>
        {loading ? (
          <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'5%'}/>
          ) : (
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Balance $fontSize={mobile ? "22px" : "30px"}>{formatToCurrencyBRL(balance)}</Balance>
              {children && (
                <>{children}</>
              )}
              {onClick && (
                <Button onClick={onClick} typeButton="outline" noBorder width="40px" icon={<IoIosArrowForward color='#002868' />} />
              )}

            </div>
        )}
      </ContainerCard>
    )}
    {typeCard === "tertiary" && (
      <ContainerCard $typeCard={typeCard} $noBorder={borderNone}>
        <Title $fontSize={mobile ? "14px" : "16px"}>{title}</Title>
        {loading ? (
          <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'5%'}/>
          ) : (
            <>
              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Balance $fontSize={mobile ? "22px" : "30px"}>{formatToCurrencyBRL(balance)}</Balance>
                {children && (
                  <>{children}</>
                )}
                {onClick && (
                  <Button onClick={onClick} typeButton="outline" noBorder width="40px" icon={<IoIosArrowForward color='#002868' />} />
                )}
              </div>
              <Description>{description}</Description>
            </>
        )}
      </ContainerCard>
    )}
    {typeCard === "fourth" && (
      <ContainerCard $typeCard={typeCard} $noBorder={borderNone}>
        {loading ? (
          <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'5%'}/>
          ) : (
            <>
              {children}
            </>
        )}
      </ContainerCard>
    )}
    </>
  )
}

export default Card;