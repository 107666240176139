/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { months } from '../../constants'

import './styles.scss'
import { BodyTable, ButtonBackToTop, ContainerList, ContainerMobile, ContentTable, HeadTable, WrapperButton } from './styles'
import useAppSelector from '../../hooks/useAppSelector'
import SwitchButtons from '../../components/SwitchButtons'
import Card from '../../components/Card/Card'
import DatePickerRange from '../../components/DatePickerRange/DatePickerRange'
import Button from '../../components/Button/Button'
import dayjs from 'dayjs'
import { getSales, getSalesByPeriod, getSalesOfTheDay } from '../../store/actions/salesActions'
import useAppDispatch from '../../hooks/useAppDispatch'
import { SalesItemType } from '../../store/sales'
import { BiSolidCheckCircle } from 'react-icons/bi'
import { IoIosArrowForward, IoMdCloseCircle } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'
import noItemsIcon from '../../assets/no-items.svg'
import HeaderDetails from '../../components/Header/Components/HeaderDetails'
import { allowSwitchButtons, formatToCurrencyBRL, parseToFormattedDate } from '../../utils/functions'

type GroupedSalesType = {
    day: string,
    sales: Array<SalesItemType>,
    value: number
}

const Vendas = () => {

    const { user: { profile }} = useAppSelector(state => state.user)
    const { option } = useAppSelector(state => state.switchButtons)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
    const [endDate, setEndDate] = useState(dayjs().toDate());
    const [dateOpen, setDateOpen] = useState(false)

    const {
        loadingSales,
        totalPagination,
        loadingBalanceSalesOfTheDay,
        balanceSalesOfTheDay,
        totalTransactionsSalesOfTheDay,
        loadingTotalSalesByPeriod,
        totalSalesByPeriod,
        amountSalesByPeriod
    } = useAppSelector(state => state.sales)

    const [offset, setOffset] = useState(0)
    const [objSales, setObjSales] = useState<any>({})

    const totalPaginationMemo = useMemo(() => {
        return Math.ceil(totalPagination / 10) - 1
    },[totalPagination])

    useEffect(() => {
        handleSales(offset === 0 ? {} : objSales)
    },[offset, option])

    useEffect(() => {
        ;(async function() {
            await dispatch(getSalesOfTheDay())
        })()
        handleSalesByPeriod()
    },[option])

    const handleSalesByPeriod = useCallback(async () => {
        await dispatch(getSalesByPeriod({startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate), terminal: null}))
    },[startDate, endDate])

    const handleSales = useCallback(async (salesObj: any) => {
        const response = await dispatch(getSales({offset: offset, limit: 10,startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate), terminal: null}))
        if(response?.meta?.requestStatus === "fulfilled") {

            const sales = response?.payload?.rows?.reduce((acc: any, cur: SalesItemType) => {
                const key = dayjs(cur.confirmationDate).locale('pt-br').format('MM/DD/YYYY')
                if (acc[key]) {
                    acc[key].sales.push(cur)
                } else {
                    acc = {
                        ...acc,
                        [key]: {
                            sales: [cur]
                        }
                    }
                }

                return acc
            }, {})

            setObjSales(() => {
                Object.keys(sales)?.forEach(key => {
                    if(salesObj.hasOwnProperty(key)) {
                        salesObj[key].sales = [...salesObj[key].sales, ...sales[key].sales]
                    } else {
                        salesObj[key] = {
                            sales: [...sales[key].sales]
                        }
                    }
                })
                return {...salesObj}
            })
        }
    },[startDate, endDate, offset])


    const salesGroupedFormated: Array<GroupedSalesType> = useMemo(() => {
        const groupedSales = Object.keys(objSales)?.map(key => {
            const [month, day, year] = key.split('/')

            return {
                day: `${day} ${(months as any)[Number(month)]} de ${year}`,
                value: objSales[key].sales.reduce((acc: number, cur: SalesItemType) => {
                    if (cur.value > 0) {
                        return acc + cur.value
                    }
                    return acc
                }, 0),
                sales: objSales[key].sales
            }
        })
        return groupedSales
    },[objSales])


    const toogleDate = () =>{
        setDateOpen(!dateOpen)
    }

    const onChange = useCallback((dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            // toogleDate()
            setDateOpen(false)
        }
    }, [dateOpen])

    const handleSearch = useCallback(() => {
        setObjSales({})
        setOffset(0)
        handleSales({})
        handleSalesByPeriod()
    },[startDate, endDate])

    const redirectToDetails = (
        acquirerNsu: string,
        startDate: any,
        confirmationHour: string,
        statusPayment: string,
        saleValue: number
    ) => {
        navigate(`details/${acquirerNsu}?date=${parseToFormattedDate(startDate)}`, {state: {statusPayment: statusPayment, hour: confirmationHour, saleValue: saleValue}})
    }

    return (
        <ContainerMobile>
            <HeaderDetails title='Resumo de Vendas' />
            <div className='switch-button-container'>
                {allowSwitchButtons(profile) && <SwitchButtons/>}
            </div>
            <div style={{margin: "20px"}}>
                <Card
                    title={"Vendas do dia"}
                    description={`${totalTransactionsSalesOfTheDay} vendas`}
                    balance={balanceSalesOfTheDay}
                    loading={loadingBalanceSalesOfTheDay}
                />
            </div>
            <ContainerList>
                <div className='content-filters'>
                    <DatePickerRange startDate={startDate} endDate={endDate} toogleDate={toogleDate} onChange={onChange} dateOpen={dateOpen} onClickOutside={() => {setDateOpen(false);}} />
                    <Button onClick={() => handleSearch()} label="Filtrar" width="30%" typeButton="primary"/>
                </div>
                <Card
                    borderNone
                    title={"Total de vendas no período"}
                    description={`${amountSalesByPeriod} vendas`}
                    balance={totalSalesByPeriod}
                    loading={loadingTotalSalesByPeriod}
                />
            </ContainerList>
            <ContentTable>
                    <>
                        {salesGroupedFormated?.map((item: GroupedSalesType, index: number) => (
                            <React.Fragment key={index}>
                                <HeadTable id={`list-item-${index}`}>
                                    <span>{item?.day?.substring(0, 6)}</span>
                                    <div>
                                        <span style={{fontWeight: "normal"}}>Valor bruto:</span>
                                        <span>{formatToCurrencyBRL(item?.value)}</span>
                                    </div>
                                </HeadTable>
                                <BodyTable >
                                    {item?.sales?.map((sale, indexSale) => (
                                        <div className='row-sales' key={indexSale}>
                                            <div className='details-sales'>
                                                {sale?.value > 0 ? (
                                                    <BiSolidCheckCircle size={25} color='rgba(74, 174, 65, 1)' />
                                                ) : (
                                                    <IoMdCloseCircle size={25} color='#eb001b' />
                                                )}
                                                <div className='content-sale'>
                                                    <span className='confirmation-hour'>{sale?.confirmationHour} | {sale?.status}</span>
                                                    <span className='type-card'>{sale?.productName.includes("Credito")?"Crédito "+sale?.parcels+"x":"Débito"}</span>
                                                    <span className='brand-card'>{sale?.brand}</span>
                                                </div>
                                            </div>
                                            <div className='content-balance'>
                                                <span>{formatToCurrencyBRL(sale?.value)}</span>
                                                <Button
                                                    typeButton='outline'
                                                    width='40px'
                                                    noBorder
                                                    icon={<IoIosArrowForward color='#002868' />}
                                                    onClick={() => redirectToDetails(sale.acquirerNsu, sale.startDate, sale?.confirmationHour, sale?.status, sale?.value)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </BodyTable>
                            </React.Fragment>
                        ))}
                        <WrapperButton>
                            {loadingSales ? 
                                <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'}/>
                             :
                             <>
                                {salesGroupedFormated.length === 0 ? 
                                    <div className='message-not-found'>
                                        <img src={noItemsIcon} alt=""/>
                                        <span>Você não possui vendas no período selecionado!</span>
                                    </div>
                                 :
                                 <>
                                    {!(offset === totalPaginationMemo) && 
                                        <Button
                                            label='Carregar mais resultados'
                                            onClick={() => setOffset((oldOffset) => oldOffset + 1)}
                                        />
                                    }
                                    <ButtonBackToTop href="#list-item-0">Voltar para o topo!</ButtonBackToTop>
                                 </>
                                 }
                             </>
                             }
                        </WrapperButton>
                    </>
                
            </ContentTable>
        </ContainerMobile>
    )
}

export default Vendas