import dayjs from "dayjs"
import { allowProfilesSwitchButtons, headTableByDay, headTableByTransaction, headTableCredits, headTableExtract, optionType, profiles } from "./constants"
import { ProfileProps } from "../store/user"
import VisaImg from '../assets/visa-icon.svg'
import MasterImg from '../assets/master2-icon.svg'
import EloImg from '../assets/logo-elo-card.svg'
import OutrosImg from '../assets/outras-bandeiras.svg'
import AmexIcon from '../assets/american-icon.svg'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AddressNContact } from "../store/myData"

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


export const getProfile = (user: any): ProfileProps => {
  const { tipoAgenda, permissions, cessao } = user
  if (permissions.moura.funcionalidades?.includes('Multiagenda')) {
      return profiles.MULTI_AGENDA
  }

  if (tipoAgenda === 'livre') {
      return profiles.MAQUINA
  }

  if (cessao) {
      return profiles.CESSAO
  }

  if (!cessao) {
      return profiles.FLEX
  }
  return ''
}

export const allowSwitchButtons = (profile: string | undefined) => {
    if(profile) {
        if(allowProfilesSwitchButtons.includes(profile)) return true
    }
    return false
}

export const getLastUpdate = (operations: any) => {
    if(operations) {
        const keys = Object.keys(operations)
        const sortedKeys = keys.sort((a: any, b: any) => a - b)
        const lastKey = sortedKeys[0]
        return dayjs(lastKey).locale('pt-br').format('DD/MM/YYYY')
    }
    return dayjs(new Date()).locale('pt-br').format('DD/MM/YYYY')
}

export const formatToCurrencyBRL = (value: any) => {
    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(value || 0);
}

export const parseToFormattedDate = (date = new Date()) => {
    const options = {year: "numeric", month: "2-digit", day: "2-digit"}

    let currentDate = date
    if (typeof date === 'string') {
        currentDate = new Date(date)
    }

    const formattedDate =  new Intl.DateTimeFormat("pt-BR", options as any).format(currentDate)

    return parseToUSDate(formattedDate)
}

export const parseToUSDate = (date: any) => {
    const [day, month, year] = date.split('/')

    return `${year}-${month}-${day}`
}

export const handleHeadTable = (
    profile: ProfileProps | undefined,
    option: 'MOURA' | 'LOJA',
    step: number
    ) => {
        if(profile === profiles.MULTI_AGENDA) {
            if(option === optionType.MOURA) {
                if(step === 1) return headTableExtract
                if(step === 2) return headTableCredits
            }
            if(option === optionType.LOJA) {
                if(step === 1) return headTableByDay
                if(step === 2) return headTableByTransaction
            }
        }
        if(profile === profiles.FLEX) {
            if(option === optionType.MOURA) {
                if(step === 1) return headTableExtract
                if(step === 2) return headTableCredits
            }
            if(option === optionType.LOJA) {
                if(step === 1) return headTableByDay
                if(step === 2) return headTableByTransaction
            }
        }

        if(profile === profiles.MAQUINA) {
            if(step === 1) return headTableByDay
            if(step === 2) return headTableByTransaction
        }

        if(profile === profiles.CESSAO) {
            if(step === 1) return headTableExtract
            if(step === 2) return headTableCredits
        }
    return []
}

export const handleIconCard = (card: string) => {
    if(!card) { return };
    const cardString = card.toUpperCase()
    if(cardString === "VISA" || cardString.includes("VISA")) {
        return VisaImg
    } else if (cardString === "MASTER" || cardString.includes("MASTER") || cardString === "MAESTRO") {
        return MasterImg
    } else if (cardString === "ELO" || cardString.includes("ELO")) {
        return EloImg
    } else if(cardString === "AMEX" || cardString.includes("AMEX")) {
        return AmexIcon
    }

    return OutrosImg
}

export const extractAmountOfInstallments = (texto: string) => {
    const resultados = texto.match(/\d+x/g);
    if (resultados && resultados.length > 0) {
        return resultados[0];
    } else {
        return "";
    }
}

export const handleTypeBuy = (type: string) => {
    const creditStrings = [
        "CREDITO PARCELADO",
        "CRÉDITO PARCELADO",
        "CREDITO",
        "Crédito",
        "CRÉDITO"
    ]
    if(creditStrings.includes(type)) {
        return "Crédito"
    }

    return "Débito"
}

export const handleLabelFirstButton = (
        profile: ProfileProps | undefined,
        option: 'MOURA' | 'LOJA'
    ) => {
    if(profile === profiles.MULTI_AGENDA) {
        if(option === optionType.MOURA) return "Extrato"
        if(option === optionType.LOJA) return "Por dia"
    }
    if(profile === profiles.FLEX) {
        if(option === optionType.MOURA) return "Extrato de créditos"
        if(option === optionType.LOJA) return "Por dia"
    }

    if(profile === profiles.MAQUINA) {
        return "Por dia"
    }

    if(profile === profiles.CESSAO) {
        return "Extrato"
    }
    return ""
}

export const handleLabelSecondButton = (
        profile: ProfileProps | undefined,
        option: 'MOURA' | 'LOJA'
    ) => {
    if(profile === profiles.MULTI_AGENDA) {
        if(option === optionType.MOURA) return "Análise de créditos"
        if(option === optionType.LOJA) return "Por transação"
    }
    if(profile === profiles.FLEX) {
        if(option === optionType.MOURA) return "Análise de créditos"
        if(option === optionType.LOJA) return "Por transação"
    }

    if(profile === profiles.MAQUINA) {
        return "Por transação"
    }

    if(profile === profiles.CESSAO) {
        return "Análise de créditos"
    }
    return ""
}

export const changeDecimalNumber = (number: string) => {
    number = number.replace('.', '').replace(',', '.');
    return number;
}

export const exportAsExcelFile = (json: any, excelFileName: any) => {
    const worksheet = XLSX.utils.json_to_sheet(json['rows']);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, excelFileName);
}

export const saveAsExcelFile = (buffer: any, fileName: any) => {
    const data = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
}

export const formatCNPJ = (arg?: string) => {
    if (!arg) return ''
    return arg
        .padStart(14, '0')
        .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export const formatCurrencyInput = (value: string): string => {
    // Converte para número
    const numericValue = parseInt(value, 10) / 100
  
    // Formata como valor monetário
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numericValue)
  
    return formattedValue
}

export const formatStringToNumber = (value: string) => {
    value = value.replace(/[^\d.,]/g, '')
    value = value.replace(/\./g, '')
    value = value.replace(',', '.')
    return parseFloat(value)
}

export const maxValue = (addressNContact: AddressNContact | null, userCnpj: string) => {
    const DEFAULT_MAX_VALUE = 3000;
    const cpnjLiberados = ["26164580000146", "31808592000104"]

    if (addressNContact === null) {
      return DEFAULT_MAX_VALUE;
    }

    if (addressNContact.distributor) {
      return 1000000000000000000000000;
    }

    if (cpnjLiberados.includes(userCnpj || "")) {
      return 20000;
    }

    return DEFAULT_MAX_VALUE;
}

export const isProfileAllowMoura = (profile: 'multi-agenda' | 'flex' | 'cessao' | 'maquina' | undefined) => {
    if(profile) {
        if([profiles.MULTI_AGENDA, profiles.FLEX, profiles.CESSAO].includes(profile)) return true
    }
    return false
}

export  function formatDate(date: any) {
    return date.split("/").reverse().join("-")
}

export function parseOperationsToArray(operationsPerDate: any) {
    if(!operationsPerDate) return []
    if (operationsPerDate) {
      const keys = Object.keys(operationsPerDate);

      return keys.reduce((accumulator: any, key: any) => {
        const item = {
          date: new Date(key),
          items: operationsPerDate[key],
        };
        accumulator.push(item);
        return accumulator;
      }, []);
    }
  }

export const clearNotNumber = (valor: string) => {
    return valor.replace(/\D/g, '');
};