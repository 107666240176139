/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import useMedia from '../../hooks/useMedia'
import RastreioDesktop from './Components/Desktop'
import RastreioMobile from './Components/Mobile'
import useAppDispatch from '../../hooks/useAppDispatch'
import { getTrackingOrdens } from '../../store/actions/trackingActions'
import useAppSelector from '../../hooks/useAppSelector'

const RastreioPage = () => {
  const mobile = useMedia('(max-width: 40rem)')
  const dispatch = useAppDispatch()
  const {loadingOrders, orders} = useAppSelector(state => state.tracking)

  useEffect(() => {
    ;(async function () {
      await dispatch(getTrackingOrdens())    
    })()
  },[])

  return (
    <>
      {mobile === true && <RastreioMobile loading={loadingOrders} orders={orders} />}
      {mobile === false && <RastreioDesktop loading={loadingOrders} orders={orders} />}
    </>
  )
}

export default RastreioPage