import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';

import { Background, Column, LogoApp, LogoPropig, PlatformsText, Row, Text } from './styles';
import imageHandWithPhone from '../../assets/image-login.png';
import imageAppStore from '../../assets/appstore.svg';
import imageGooglePlay from '../../assets/googleplay.svg';
import logoPropig from '../../assets/logo-propig-white.svg';
import logoCredmoura from '../../assets/logo-credmoura-white.svg';
import config from '../../config';

import api from '../../services/api';

interface Response {
  // tem outros...
  imageUrl: string;
}

const CoverImage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await api.get<Response>(`${config.BASE_URL_ADMIN}/cover/findActive`);
        if (!data.imageUrl) throw new Error();
        setImage(data.imageUrl);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  if (loading) {
    return (
      <Background style={{ alignItems: 'center' }}>
        <ReactLoading type="spin" color="#002868" height="fit-content" width="10%" />
      </Background>
    )
  }

  return (
    <>
      {image ? (
        <Background style={{ alignItems: 'unset'}}>
          <img src={image} alt="" style={{ height: '100%', borderRadius: '0px 70px 70px 0', objectFit: 'cover' }} />
        </Background>
      ) : (
        <Background>
          <div className='content-logo'>
            <Column>
              <Text>CHEGOU O <strong>APP</strong></Text>
              <Text $fontSize='45px'><strong>CRED</strong>MOURA</Text>
              <Text><strong>ACOMPANHE</strong> AS SUAS <strong>VENDAS</strong></Text>
              <Text>DE MANEIRA PRÁTICA E SEGURA</Text>
              <PlatformsText>DISPONÍVEL NAS PRINCIPAIS LOJAS DE APPS</PlatformsText>
              <Row $gap='10px'>
                <LogoApp src={imageAppStore} alt='Logo Apple Store' />
                <LogoApp src={imageGooglePlay} alt='Logo Google Play' />
              </Row>
            </Column>
            <LogoPropig src={imageHandWithPhone} alt='Mão segurando celular' />
          </div>
          <div className='content-logo-propig'>
            <LogoApp src={logoPropig} alt='Logo Propig' />
            <div className='divider'></div>
            <LogoApp src={logoCredmoura} alt='Logo Credmoura' />
          </div>
        </Background>
      )}
    </>
  );
}

export default CoverImage;
