import React, { useState } from "react"
import { AiOutlineCloseCircle } from "react-icons/ai"
import ReactModal from "react-modal"
import useAppSelector from "../../../hooks/useAppSelector"
import useAppDispatch from "../../../hooks/useAppDispatch"
import { handleCloseModal } from "../../../store/modals"
import Button from "../../Button/Button"
import { Controller, useForm } from "react-hook-form"
import { requestContact } from "../../../store/actions/userActions"
import { toast } from "react-toastify"
import InputMask from "react-input-mask";
import { ContainerModal } from "../styles"


const modalEstilo = {
  content: {
    width: '80%', // Defina a largura desejada do modal
    maxWidth: "500px",
    maxHeight: 'min-content', // Defina a altura máxima desejada do modal
    margin: 'auto', // Centralize o modal horizontalmente
    borderRadius: "15px",
    boxShadow: "0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.212)",
    borderColor: "#F6F6F6",
  }
};

type FormRequestContact = {
  nome: string;
  telefone: string;
}

const RequestContactModal = () => {
  const dispatch = useAppDispatch()
  const { open } = useAppSelector((state) => state.modals)

  const [stepper, setStepper] = useState(1)

  const {
    register,
    handleSubmit,
    control,
    reset
    } = useForm({
    defaultValues: {
        nome: "",
        telefone: "",
    }
  });

  const onSubmit = async (data: FormRequestContact ) => {
    const body = {
        name: data?.nome,
        phone: data?.telefone?.match(/\d+/g)?.join("")
    }
    const response = await dispatch(requestContact(body))

    if(response?.meta?.requestStatus === "fulfilled") {
      setStepper(2)
    } else {
      toast.error("Problema ao atender solicitação")
    }
  }

  const closeModal = () => {
    dispatch(handleCloseModal(''))
    reset()
    setStepper(1)
  }

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      style={modalEstilo}
    >
      <ContainerModal>
          {stepper === 1 && <>
            <div className='content-head'>
              <p>Preencha seus dados abaixo que entraremos em contato com você!</p>
              <Button
                  icon={<AiOutlineCloseCircle color='#002868' />}
                  typeButton="outline"
                  noBorder
                  width='40px'
                  onClick={closeModal}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input placeholder="Nome" required {...register("nome")} />
                <Controller
                    control={control}
                    name="telefone"
                    render={({field}) => <InputMask mask="(99) 99999-9999" placeholder="Telefone" {...field} />}
                />
                <Button width="50%" type='submit' label="Confirmar" typeButton="primary"/>
            </form>
          </>}

          {stepper === 2 && <>
            <div className='content-head'>
              <span>
              </span>
              <Button
                  icon={<AiOutlineCloseCircle color='#002868' />}
                  typeButton="outline"
                  noBorder
                  width='40px'
                  onClick={closeModal}
              />
            </div>
            <span>
                Logo logo estaremos entrando em contato com você!
              </span>
            <div style={{display: "flex", justifyContent: "center", marginTop: "2rem"}}>
              
              <Button width="50%" label="Ok" typeButton="primary" onClick={closeModal} />
            </div>
          </>}
      </ContainerModal>
    </ReactModal>
  )
}

export default RequestContactModal