/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt';
import dayjs from "dayjs"
import jwt_decode from 'jwt-decode'
import { ContainerDesktop, ContentButtons, ContentCards, ContentModal, Label, PeriodValues } from './styles'
import TitlePage from '../../components/TitlePage'
import Card from '../../components/Card/Card'
import Button from '../../components/Button/Button'
import DatePickerRange from '../../components/DatePickerRange/DatePickerRange'
import { SlReload } from 'react-icons/sl'
import { HiOutlineDownload } from 'react-icons/hi'
import { FiMaximize2 } from 'react-icons/fi'
import Table from '../../components/Table/Table'
import { headTableInstallmentsDetails, headTablebyDay, headTablebyTransaction } from './constants'
import useAppDispatch from '../../hooks/useAppDispatch'
import useAppSelector from '../../hooks/useAppSelector'
import { getTransactionsByDay, getTransactionsByTransaction } from '../../store/actions/transactionsActions'
import { ByTransactionItemType, TransactionByDayItemType } from '../../store/transactions'
import { Pagination } from '../../components/Pagination/Pagination'
import { getSalesByPeriod, getSalesOfTheDay } from '../../store/actions/salesActions'
import ReactModal from 'react-modal'
import questionIcon from '../../assets/question-icon.svg'
import { exportTransactionPerTransactions, exportTransactionPerDay } from '../../store/actions/receivementsActions';
import { exportAsExcelFile, formatToCurrencyBRL, handleIconCard, parseToFormattedDate } from '../../utils/functions';
import { monthsString } from '../../utils/constants';

registerLocale('pt', pt)


const TransacoesDetalhadas = () => {
  const dispatch = useAppDispatch()
  const { token } = useAppSelector(state => state.user)
  const { option } = useAppSelector(state => state.switchButtons)
  const { totalByDay, transactionsByDay, loadingDataByDay, loadingDataByTransaction, totalByTransaction, dataByTransaction } = useAppSelector(state => state.transactions)
  const { balanceSalesOfTheDay, loadingBalanceSalesOfTheDay, loadingTotalSalesByPeriod, totalSalesByPeriod, netTotalSalesByPeriod } = useAppSelector((state) => state.sales)
  const user = jwt_decode<any>(window.localStorage.getItem('token')!).user
  const [dateOpen, setDateOpen] = useState(false)
  const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState(dayjs().toDate());
  const today = parseToFormattedDate(dayjs() as unknown as Date)
  const [stepper, setStepper] = useState(1)

  const [offsetByDay, setOffsetByDay] = useState(0)
  const [offsetByTransaction, setOffsetByTransaction] = useState(0)

  const [open, setOpen] = useState(false);



  useEffect(() => {
    if(stepper === 1) {
      ;(async function() {
        await dispatch(getTransactionsByDay({offset: offsetByDay, startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate)}))
      })()
    } else {
      ;(async function() {
        await dispatch(getTransactionsByTransaction({offset: offsetByTransaction, startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate)}))
      })()
    }
  },[token, offsetByDay, offsetByTransaction, option, stepper])

  useEffect(() => {
    ;(async function() {
        await dispatch(getSalesOfTheDay())
    })()
  },[token, today, option])

  useEffect(() => {
    ;(async function() {
        await dispatch(getSalesByPeriod({startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate), terminal: null}))
    })()
  },[token, option])

  const onChange = useCallback((dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setDateOpen(false)
      ;(async function() {
        await dispatch(getSalesByPeriod({startDate: parseToFormattedDate(start), endDate: parseToFormattedDate(end), terminal: null}))
      })()
      if(stepper === 1) {
        ;(async function() {
          await dispatch(getTransactionsByDay({offset: offsetByDay, startDate: parseToFormattedDate(start), endDate: parseToFormattedDate(end)}))
        })()
      } else {
        ;(async function() {
          await dispatch(getTransactionsByTransaction({offset: offsetByTransaction, startDate: parseToFormattedDate(start), endDate: parseToFormattedDate(end)}))
        })()
      }
    }
  },[offsetByDay, offsetByTransaction, stepper])


  const toogleDate = () => {
    setDateOpen(!dateOpen)
  }

  const exportS = async () => {
    let startFormated = dayjs(startDate?.toString()).format('YYYY-MM-DD')
    let endFormated = dayjs(endDate?.toString()).format('YYYY-MM-DD')
    if (stepper === 1) {
      const response = await dispatch(exportTransactionPerDay({startDate: startFormated, endDate: endFormated}))
      if( response.meta.requestStatus === 'fulfilled' ) {
        exportAsExcelFile(
          response.payload,
          `vendas-por-dia-${startFormated}-${endFormated}`
        );
      }
    } else {
      const response = await dispatch(exportTransactionPerTransactions({startDate: startFormated, endDate: endFormated}))
      if( response.meta.requestStatus === 'fulfilled' ) {
        exportAsExcelFile(
          response.payload,
          `vendas-por-transação-${startFormated}-${endFormated}`
        );
      }
    }
  }

  const closeModal = () => {
    setOpen(false);
  };

  const headTable = useMemo(() => {
    if(stepper === 1) {
      if(user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre') {
        return headTablebyDay
      }
      return headTablebyDay.filter((item) => !['Valor Crédito Líquido', 'Valor Cliente'].includes(item.titleColumn))
    }
    if(stepper === 2) {

      if(user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre') {
        if (option === "LOJA") {
          return headTablebyTransaction.filter((item) => !["Pagamento"].includes(item.titleColumn))
        }
    
        return headTablebyTransaction
      }
      return headTablebyTransaction.filter((item) => !['Pagamento', 'Créditos Distribuidor', 'Recebimento Bruto Cliente', 'Recebimento Líquido Cliente'].includes(item.titleColumn))
    }
    return []
  },[stepper, user])

  const hasData = useMemo(() => {
    if(stepper === 1 && transactionsByDay.length > 0) return true
    if(stepper === 2 && dataByTransaction.length > 0) return true
    return false
  },[transactionsByDay, dataByTransaction, stepper])

  const [nsuInstallmentsDetails, setNsuInstallmentsDetails] = useState<string | null>(null)

  return (
    <>
     <ContainerDesktop>
       <TitlePage title={"Vendas"}/>
       <ContentButtons>
         <Button width="20%" label="Por dia" typeButton={stepper === 1 ? "primary" : "outline"} onClick={() => setStepper(1)}/>
         <Button width="20%" label="Por transação" typeButton={stepper === 2 ? "primary" : "outline"} onClick={() =>  setStepper(2)}/>
         <div className='group-buttons'>
             <Button width="40px" icon={<SlReload color='#002868' />} typeButton='secondary'/>
             <Button width="20%" label="Exportar" icon={<HiOutlineDownload color='#002868' />} typeButton='secondary' onClick={exportS}/>
             <DatePickerRange startDate={startDate} endDate={endDate} toogleDate={toogleDate} onChange={onChange} dateOpen={dateOpen} onClickOutside={() => {setDateOpen(false)}} />
             <Button width="20%" label="Maximizar" icon={<FiMaximize2 color='#002868' />} typeButton='secondary' onClick={() => setOpen(true)}/>
         </div>
       </ContentButtons>
       <ContentCards>
         <Card
          title={"Total de vendas no período"}
          description={`De ${startDate ? dayjs(startDate).locale('pt-br').format('DD/MM/YYYY') : ''} Até ${endDate ? dayjs(endDate).locale('pt-br').format('DD/MM/YYYY') : ''}`}
          balance={totalSalesByPeriod}
          loading={loadingTotalSalesByPeriod}
          typeCard="secondary"
        />
        <Card
         title={"Vendas do dia"}
         description={`Dia ${dayjs().locale('pt-br').format('DD')} de ${monthsString[dayjs().format("MMMM")]}`}
         balance={balanceSalesOfTheDay}
         loading={loadingBalanceSalesOfTheDay}
         typeCard="secondary"
        />
       </ContentCards>
       <Table headList={headTable} hasData={hasData} loading={loadingDataByDay || loadingDataByTransaction}>
        {stepper === 1 ? (
          <>
            {
              transactionsByDay?.map((item: TransactionByDayItemType, index: number) =>
                <tr key={index}>
                  <td>{item?.transactionDate}</td>
                  <td>{item?.transactions}</td>
                  <td colSpan={item?.transactionStatus === 'Em Processamento' ? (user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' ? 11 : 8) : 1}>
                    {item?.transactionStatus === 'Em Processamento' ? item?.transactionStatus : formatToCurrencyBRL(item?.grossValue)}
                  </td>
                  {!(item?.transactionStatus === "Em Processamento") && (
                    <>
                      <td>{formatToCurrencyBRL(item?.mdr)}</td>
                      <td>{item?.mdrPercent}</td>
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td
                        >
                        <span>{
                          item?.credit ? formatToCurrencyBRL(item?.credit) : ''
                        }</span>
                      </td>}
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td
                        >
                        <span>{
                          item?.creditPercent ? item?.creditPercent : ''
                        }</span>
                      </td>}
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' &&
                        <td
                          >
                          <span>{
                            item?.clientGrossValue ? formatToCurrencyBRL(item?.clientGrossValue) : ''
                          }</span>
                        </td>}
                      <td>{formatToCurrencyBRL(item?.antecipation)}</td>
                      <td>{item?.antecipationPercent}</td>
                      <td>{item?.clientNetValue ? formatToCurrencyBRL(item?.clientNetValue) : ''}</td>
                      <td>{formatToCurrencyBRL(item?.tec)}</td>
                      <td>{item?.tecPercent}</td>
                    </>
                  )}
                </tr>
              )}
          </>
        ) : (
          <>
            {
              dataByTransaction?.map((item: ByTransactionItemType, index: number) =>
                <tr key={index}>
                  <td>{item?.date}</td>
                  <td>{item?.authorizationCode}</td>
                  <td>{item?.nsu}</td>
                  <td>{item?.pos}</td>
                  <td>
                    <img src={handleIconCard(item?.cardBrand)} alt="Card Icon" style={{marginRight: "5px", maxWidth: "40px"}} />
                      {item?.paymentType.toUpperCase().includes("Credito".toUpperCase()) ? "Crédito " + item?.installments + "x" : "Débito"}
                  </td>
                  <td>{formatToCurrencyBRL(item?.grossValue)}</td>
                  <td colSpan={item?.transactionStatus === 'Em Processamento' ? (user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' ? 11 : 7) : 1}>
                    {item?.transactionStatus === 'Em Processamento' ? item?.transactionStatus : formatToCurrencyBRL(item?.mdr)}
                  </td>
                  {!(item?.transactionStatus === "Em Processamento") && (
                    <>
                      <td>{item?.mdrPercent}</td>
                      <td>{formatToCurrencyBRL(item?.grossValue - item?.mdr)}</td>
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td>{item?.credit ? formatToCurrencyBRL(item?.credit) : ''}</td>}
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td>{item?.creditPercent ? item?.creditPercent : ''}</td>}
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td>{item?.clientGrossValue ? formatToCurrencyBRL(item?.clientGrossValue) : ''}</td>}
                      <td>{formatToCurrencyBRL(item?.antecipation)}</td>
                      <td>{item?.antecipationPercent}</td>
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td>{item?.clientNetValue ? formatToCurrencyBRL(item?.clientNetValue) : ''}</td>}
                      <td>{formatToCurrencyBRL(item?.grossValue - (item?.mdr + item?.antecipation))}</td>
                      <td>{item?.tecPercent}</td>
                      {option !== "LOJA" && (
                        <td
                          style={{
                            padding: '10px 8px',
                            fontSize: '11px'
                          }}
                        >
                          {item?.installmentsDetails ?
                            (item?.installmentsDetails.totalInstallments === 1 ?
                              (item?.installmentsDetails.installments[0].paymentDate || "Pendente") :
                                <button
                                  style={{
                                    color: "unset",
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    textDecoration: 'underline'
                                  }}
                                  onClick={() => {setNsuInstallmentsDetails(item.nsu)}}
                                >
                                  Ver Detalhes
                                </button>)
                                :
                                "-"
                                }
                        </td>
                      )}
                    </>
                  )}
                </tr>
              )}
          </>
        )}
       </Table>
       {hasData && (
        <>
          {stepper === 1 ? (
            <Pagination limit={10} offset={offsetByDay} total={totalByDay} setOffset={setOffsetByDay} />
            ) : (
            <Pagination limit={10} offset={offsetByTransaction} total={totalByTransaction} setOffset={setOffsetByTransaction} />
          )}
        </>
       )}
     </ContainerDesktop>
     <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      style={{
        content: {
          width: "95%",
          height: "max-content",
          maxHeight: '90%',
          margin: "auto",
          padding: "10px"
        }
      }}
    >
      <ContentModal>

      <ContentButtons style={{width: "100%"}}>
         <Button width="20%" label="Por dia" typeButton={stepper === 1 ? "primary" : "outline"} onClick={() => setStepper(1)}/>
         <Button width="20%" label="Por transação" typeButton={stepper === 2 ? "primary" : "outline"} onClick={() =>  setStepper(2)}/>
         <PeriodValues>
            <div>
              <div style={{display: "flex"}}>
                <div><Label color='#002868' fontSize='13px'>Valor bruto: </Label><span>{formatToCurrencyBRL(totalSalesByPeriod)}</span></div>
                <div><Label color='#002868' fontSize='13px'>Valor líquido: </Label><span>{formatToCurrencyBRL(netTotalSalesByPeriod)}</span></div>
              </div>
            </div>
            <span><img className='me-1' src={questionIcon} alt="" />Total do período</span>
         </PeriodValues>
         <div className='group-buttons'>
             <Button width="40px" icon={<SlReload color='#002868' />} typeButton='secondary'/>
             <Button width="20%" label="Exportar" icon={<HiOutlineDownload color='#002868' />} typeButton='secondary' onClick={exportS}/>
             <DatePickerRange startDate={startDate} endDate={endDate} toogleDate={toogleDate} onChange={onChange} dateOpen={dateOpen} />
         </div>
       </ContentButtons>

      <Table maximize headList={headTable} hasData={hasData} loading={loadingDataByDay || loadingDataByTransaction}>
        {stepper === 1 ? (
          <>
            {
              transactionsByDay?.map((item: TransactionByDayItemType, index: number) =>
                <tr key={index}>
                  <td style={{padding: '10px'}}>{item?.transactionDate}</td>
                  <td style={{padding: '10px'}}>{item?.transactions}</td>
                  <td style={{padding: '10px'}} colSpan={item?.transactionStatus === 'Em Processamento' ? (user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' ? 11 : 8) : 1}>
                    {item?.transactionStatus === 'Em Processamento' ? item?.transactionStatus : formatToCurrencyBRL(item?.grossValue)}
                  </td>
                  {!(item?.transactionStatus === "Em Processamento") && (
                    <>
                      <td style={{padding: '10px'}}>{formatToCurrencyBRL(item?.mdr)}</td>
                      <td style={{padding: '10px'}}>{item?.mdrPercent}</td>
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td
                        >
                        <span>{
                          item?.credit ? formatToCurrencyBRL(item?.credit) : ''
                        }</span>
                      </td>}
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td style={{padding: '10px'}}
                        >
                        <span>{
                          item?.creditPercent ? item?.creditPercent : ''
                        }</span>
                      </td>}
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' &&
                        <td style={{padding: '10px'}}
                          >
                          <span>{
                            item?.clientGrossValue ? formatToCurrencyBRL(item?.clientGrossValue) : ''
                          }</span>
                        </td>}
                      <td style={{padding: '10px'}}>{formatToCurrencyBRL(item?.antecipation)}</td>
                      <td style={{padding: '10px'}}>{item?.antecipationPercent}</td>
                      <td style={{padding: '10px'}}>{item?.clientNetValue ? formatToCurrencyBRL(item?.clientNetValue) : ''}</td>
                      <td style={{padding: '10px'}}>{formatToCurrencyBRL(item?.tec)}</td>
                      <td style={{padding: '10px'}}>{item?.tecPercent}</td>
                    </>
                  )}
                </tr>
              )}
          </>
        ) : (
          <>
            {
              dataByTransaction?.map((item: ByTransactionItemType, index: number) =>
                <tr key={index}>
                  <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.date}</td>
                  <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.authorizationCode}</td>
                  <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.nsu}</td>
                  <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.pos}</td>
                  <td style={{padding: '10px 8px', fontSize: '11px'}}>
                    <img src={handleIconCard(item?.cardBrand)} alt="Card Icon" style={{marginRight: "5px", maxWidth: "40px"}} />
                      {item?.paymentType.toUpperCase().includes("Credito".toUpperCase()) ? "Crédito " + item?.installments + "x" : "Débito"}
                  </td>
                  <td style={{padding: '10px 8px', fontSize: '11px'}}>{formatToCurrencyBRL(item?.grossValue)}</td>
                  <td style={{padding: '10px 8px', fontSize: '11px'}} colSpan={item?.transactionStatus === 'Em Processamento' ? (user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' ? 11 : 7) : 1}>
                    {item?.transactionStatus === 'Em Processamento' ? item?.transactionStatus : formatToCurrencyBRL(item?.mdr)}
                  </td>
                  {!(item?.transactionStatus === "Em Processamento") && (
                    <>
                      <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.mdrPercent}</td>
                      <td style={{padding: '10px 8px', fontSize: '11px'}}>{formatToCurrencyBRL(item?.grossValue - item?.mdr)}</td>
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.credit ? formatToCurrencyBRL(item?.credit) : ''}</td>}
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.creditPercent ? item?.creditPercent : ''}</td>}
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.clientGrossValue ? formatToCurrencyBRL(item?.clientGrossValue) : ''}</td>}
                      <td style={{padding: '10px 8px', fontSize: '11px'}}>{formatToCurrencyBRL(item?.antecipation)}</td>
                      <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.antecipationPercent}</td>
                      {user.tipoAgenda !== 'loja' && user.tipoAgenda !== 'livre' && <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.clientNetValue ? formatToCurrencyBRL(item?.clientNetValue) : ''}</td>}
                      <td style={{padding: '10px 8px', fontSize: '11px'}}>{formatToCurrencyBRL(item?.grossValue - (item?.mdr + item?.antecipation))}</td>
                      <td style={{padding: '10px 8px', fontSize: '11px'}}>{item?.tecPercent}</td>
                      {option !== "LOJA" && (
                        <td
                          style={{
                            padding: '10px 8px',
                            fontSize: '11px'
                          }}
                        >
                          {item?.installmentsDetails ?
                            (item?.installmentsDetails.totalInstallments === 1 ?
                              (item?.installmentsDetails.installments[0].paymentDate || "Pendente") :
                                <button
                                  style={{
                                    color: "unset",
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    textDecoration: 'underline'
                                  }}
                                  onClick={() => {setNsuInstallmentsDetails(item.nsu)}}
                                >
                                  Ver Detalhes
                                </button>)
                                :
                                "-"
                                }
                        </td>
                      )}
                    </>
                  )}
                </tr>
              )}
          </>
        )}
       </Table>
       {hasData && (
        <>
          {stepper === 1 ? (
            <Pagination limit={10} offset={offsetByDay} total={totalByDay} setOffset={setOffsetByDay} />
            ) : (
            <Pagination limit={10} offset={offsetByTransaction} total={totalByTransaction} setOffset={setOffsetByTransaction} />
          )}
        </>
       )}
        <Button
          onClick={closeModal}
          typeButton="outline"
          width={"15%"}
          label="Fechar"
        />
      </ContentModal>
    </ReactModal>
    <ReactModal
      isOpen={nsuInstallmentsDetails !== null}
      onRequestClose={() => {setNsuInstallmentsDetails(null)}}
      style={{
        content: {
          width: "350px",
          height: "max-content",
          maxHeight: '90%',
          margin: "auto",
          padding: "10px"
        }
      }}
    >
      <ContentModal>
        <Table headList={headTableInstallmentsDetails} hasData={true}>
          {dataByTransaction.find(data => data.nsu === nsuInstallmentsDetails)?.installmentsDetails?.installments.map(installment => <tr key={installment.installment}>
            <td>{installment.installment}</td>
            <td>{installment.paymentDate || "pendente"}</td>
          </tr>)}
        </Table>
        <Button
          onClick={() => {setNsuInstallmentsDetails(null)}}
          typeButton="outline"
          width={"50%"}
          label="Fechar"
        />
      </ContentModal>
      </ReactModal>
    </>
  )
}

export default TransacoesDetalhadas