/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import propigIcon from '../../assets/logo.png'
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
import dayjs from "dayjs"
import { findSaleTicket } from '../../services/sales'
import { jsPDF } from "jspdf";

import { ContainerDesktop, ContentButtons, ContentCards, ContentFilters, ContentModal } from './styles'
import TitlePage from '../../components/TitlePage'
import Card from '../../components/Card/Card'
import Button from '../../components/Button/Button'
import { SlReload } from 'react-icons/sl'
import { HiOutlineDownload } from 'react-icons/hi'
import DatePickerRange from '../../components/DatePickerRange/DatePickerRange'
import useAppDispatch from '../../hooks/useAppDispatch'
import { exportSales, getBrands, getSales, getSalesByPeriod, getSalesOfTheDay, getTerminals } from '../../store/actions/salesActions'
import useAppSelector from '../../hooks/useAppSelector'
import { SelectComponent } from '../../components/Select/Select'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import ReactModal from 'react-modal'
import { FiMaximize2 } from 'react-icons/fi'
import ResumeSalesTable from './components/ResumeSalesTable';
import { exportAsExcelFile, formatToCurrencyBRL, parseToFormattedDate } from '../../utils/functions';
import { monthsString } from '../../utils/constants';

registerLocale('pt', pt)

const ResumoVendas = () => {
    const dispatch = useAppDispatch()
    const { token } = useAppSelector(state => state.user)
    const { option } = useAppSelector(state => state.switchButtons)

    const [dateOpen, setDateOpen] = useState(false)
    const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
    const [endDate, setEndDate] = useState(dayjs().toDate());
    const today = parseToFormattedDate(dayjs() as unknown as Date)

    const [ticket, setTicket] = useState<any>(null)

    const {
        sales,
        balanceSalesOfTheDay,
        loadingBalanceSalesOfTheDay,
        loadingTotalSalesByPeriod,
        totalSalesByPeriod,
        brands,
        terminals
    } = useAppSelector((state) => state.sales)

    const [offset, setOffset] = useState(0)

    const [openFilters, setOpenFilters] = useState(false)
    const [filterTerminal, setFilterTerminal] = useState(null)
    const [filterBrands, setFilterBrands] = useState<Array<{label: string, value: string}>>([])
    const [filterModalidade, setFilterModalidade] = useState(null)

  const [open, setOpen] = useState(false);


    useEffect(() => {
        ;(async function() {
            await dispatch(getSalesOfTheDay())
        })()
    },[token, today, option])

    useEffect(() => {
        ;(async function() {
            await dispatch(getSalesByPeriod({startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate), terminal: null}))
        })()
        ;(async function() {
            await dispatch(getTerminals())
        })()
        ;(async function() {
            await dispatch(getBrands())
        })()
    },[token, option])

    useEffect(() => {
        ;(async function() {
            await dispatch(getSales({offset: offset, limit: 10,startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate), terminal: filterTerminal, brands: brandsSelected, productName: filterModalidade}))
        })()
    },[token, offset, option, filterTerminal, filterBrands, filterModalidade])


    const onChange = useCallback((dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            // toogleDate()
            setDateOpen(false)
            ;(async function() {
                await dispatch(getSales({offset: offset, limit: 10, startDate: parseToFormattedDate(start), endDate: parseToFormattedDate(end), terminal: filterTerminal, brands: brandsSelected, productName: filterModalidade}))
                await dispatch(getSalesByPeriod({startDate: parseToFormattedDate(start), endDate: parseToFormattedDate(end), terminal: null}))
            })()
        }
    },[offset, filterTerminal, filterBrands, filterModalidade])

    const downloadAsPDF = async (nsu: any, sale: any) => {
    
        findSaleTicket(nsu, sale.authorizatioNumber, sale.confirmationDate)
          .then(async (result) => {
            if (result.length > 0) {
                result[0].tipoTransacao = result[0].tipoTransacao.split(" ")[0];
                setTicket(result[0]);
                await delay(1);

                const pdf = new jsPDF("p", "px", [499, 656]);

                pdf.html(document.getElementById("pdfTable")!, {
                callback: (pdf) => {
                    window.open(pdf.output("bloburl").toString());
                },
                });
            }
          });
    }

    const delay= (n: any)=> {
        return new Promise(function (resolve) {
          setTimeout(resolve, n * 1000);
        });
    }

    const brandsSelected = useMemo(() => {
        const filter =  filterBrands?.map((item) => item?.value)
        return filter?.join(",")
    },[filterBrands])

    const refreshDataTable = useCallback(async() => {
        await dispatch(getSales({offset: offset, limit: 10, startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate), terminal: filterTerminal, brands: brandsSelected, productName: filterModalidade}))
    }, [
        option,
        token,
        offset,
        startDate,
        endDate,
        filterTerminal,
        filterModalidade,
        brandsSelected
    ])


    const toogleDate = ()=>{
        setDateOpen(!dateOpen)
    }

    const exportS = useCallback(async () =>{
        let startFormated = dayjs(startDate?.toString()).format('YYYY-MM-DD')
        let endFormated = dayjs(endDate?.toString()).format('YYYY-MM-DD')
        const response = await dispatch(exportSales({startDate: startFormated, endDate: endFormated, brands: brandsSelected, terminal: filterTerminal, productName: filterModalidade}))
        if(response.meta.requestStatus === "fulfilled") {
            if (response.payload) {
              exportAsExcelFile(
                response.payload,
                `vendas-por-dia-${startFormated}-${endFormated}.xlsx`
              );
            }
        }
    },[startDate, endDate, filterTerminal, brandsSelected, filterModalidade])

    const terminalOptions = useMemo(() => {
        const arrayOptions = [
            {
                label: "Link de pagamento",
                value: "ES000001"
            }
        ]
        const arrayTerminals = terminals?.map((item) => {
            return {
                label: `${item?.terminalModel} - cod: ${item?.terminalCode}`,
                value: item?.terminalCode
            }
        })
        return [...arrayOptions, ...arrayTerminals]
    }, [terminals])

    const brandsOptions = useMemo(() => {
        return brands?.map((item) => {
            return {
                label: item?.name,
                value: item?.name
            }
        })
    },[brands])

    const modalidadeOptions = [
        {
            label: 'Crédito',
            value: 'Credito'
        },
        {
            label: 'Débito',
            value: 'Debito'
        },
    ]

    const closeModal = () => {
        setOpen(false);
      };

    return (
        <>
            <ContainerDesktop>
                <div className='content-head'>
                    <TitlePage title={"Vendas"}/>
                    {/* <Button width='30%' label="Ver vendas detalhadas" typeButton='primary' onClick={() => navigate(`/transacoes-detalhadas`)} /> */}
                </div>
                <ContentButtons>
                    <ContentFilters open={openFilters}>
                        <Button width='50%' label="Filtrar" typeButton='secondary' onClick={() => setOpenFilters(!openFilters)} />
                        <div className='content-filters'>
                            <Button
                                typeButton='outline'
                                noBorder
                                backgroundColor='transparent'
                                padding='0'
                                icon={<AiOutlineCloseCircle color='#002868' />}
                                onClick={() => setOpenFilters(!openFilters)}
                            />
                            <SelectComponent
                                placeholder='Selecione o terminal'
                                onChange={(e) => setFilterTerminal(e.value)}
                                isClearable
                                options={terminalOptions}
                                width="100%"
                                height='38px'
                            />
                            <SelectComponent
                                placeholder='Selecione as bandeiras'
                                onChange={(e) => setFilterBrands(e)}
                                isClearable
                                options={brandsOptions}
                                width="100%"
                                height='38px'
                                isMulti
                            />
                            <SelectComponent
                                placeholder='Selecione a modalidade'
                                onChange={(e) => setFilterModalidade(e.value)}
                                isClearable
                                options={modalidadeOptions}
                                width="100%"
                                height='38px'
                            />
                        </div>
                    </ContentFilters>
                    <div className='group-buttons'>
                        <Button width="40px" icon={<SlReload color='#002868' />} onClick={refreshDataTable} typeButton='secondary' />
                        {sales.length > 0 && (<Button width="20%" label="Exportar" icon={<HiOutlineDownload color='#002868' />} typeButton='secondary' onClick={exportS}/>)}
                        <DatePickerRange startDate={startDate} endDate={endDate} toogleDate={toogleDate} onChange={onChange} dateOpen={dateOpen} onClickOutside={() => {setDateOpen(false)}} />
                        <Button width="20%" label="Maximizar" icon={<FiMaximize2 color='#002868' />} typeButton='secondary' onClick={() => setOpen(true)}/>
                    </div>
                </ContentButtons>
                <ContentCards>
                    <Card
                        title={"Total de vendas no período"}
                        description={`De: ${startDate ? dayjs(startDate).locale('pt-br').format('DD/MM/YYYY') : ''} Até ${endDate ? dayjs(endDate).locale('pt-br').format('DD/MM/YYYY') : ''}`}
                        balance={totalSalesByPeriod}
                        loading={loadingTotalSalesByPeriod}
                        typeCard="secondary"
                    />
                    <Card
                        title={"Vendas do dia"}
                        description={`Dia ${dayjs().locale('pt-br').format('DD')} de ${monthsString[dayjs().format("MMMM")]}`}
                        balance={balanceSalesOfTheDay}
                        loading={loadingBalanceSalesOfTheDay}
                        typeCard="secondary"
                    />
                </ContentCards>
                <ResumeSalesTable download={downloadAsPDF} setOffset={setOffset} offset={offset} />
                
                <div style={{display:'none'}}>
                    <div id="pdfTable" style={{width:'499px', height:'655px', background: '#F5EBC2 0% 0% no-repeat padding-box'}}>
                        <div style={{margin:'0px 30px 30px 30px'}}>
                            <img style={{display:'block', margin:'auto',paddingTop:'20px'}} src={propigIcon} width="181px" alt='ticket'></img>
                            <br />
                            <br />
                            <br />
                            <div>
                                <p style={{fontSize:'17px'}}>
                                Loja: {ticket?.loja} <br />
                                </p>
                                <p style={{fontSize:'17px'}}>
                                CPF/CNPJ: {ticket?.cnpj} <br />
                                Tp. trans: {ticket?.tipoTransacao} <br />
                                Terminal: {ticket?.terminal} <br />
                                Data: {dayjs(ticket?.paymentDate).format('DD/MM/YYYY - HH:mm')} <br />
                                </p>
                                <br />
                                <p style={{fontSize:'19px', textAlign:'center'}}>
                                {ticket?.brand.toUpperCase()}<br />
                                {formatToCurrencyBRL(ticket?.value)}<br />
                                </p>
                                <br />
                                <p style={{fontSize:'17px'}}>
                                Comprov: {ticket?.acquirerNsu}<br />
                                Parcela: {ticket?.parcels}x<br />
                                Cod. Autorizacao: {ticket?.authorizatioNumber}<br />
                                </p>
                                <br />
                                <br />

                                {/* <p style={{fontSize:'17px'}}>
                                TRANSAÇÃO AUTORIZADA MEDIANTE USO DE SENHA PESSOAL
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </ContainerDesktop>

            <ReactModal
                isOpen={open}
                onRequestClose={closeModal}
                style={{
                    content: {
                    width: "95%",
                    height: "max-content",
                    maxHeight: '90%',
                    margin: "auto",
                    padding: "10px"
                    }
                }}
                >
                <ContentModal>

                <ContentButtons style={{width: '100%'}}>
                    <ContentFilters open={openFilters}>
                        <Button width='50%' label="Filtrar" typeButton='secondary' onClick={() => setOpenFilters(!openFilters)} />
                        <div className='content-filters'>
                            <Button
                                typeButton='outline'
                                noBorder
                                backgroundColor='transparent'
                                padding='0'
                                icon={<AiOutlineCloseCircle color='#002868' />}
                                onClick={() => setOpenFilters(!openFilters)}
                            />
                            <SelectComponent
                                placeholder='Selecione o terminal'
                                onChange={(e) => setFilterTerminal(e.value)}
                                isClearable
                                options={terminalOptions}
                                width="100%"
                                height='38px'
                            />
                            <SelectComponent
                                placeholder='Selecione as bandeiras'
                                onChange={(e) => setFilterBrands(e)}
                                isClearable
                                options={brandsOptions}
                                width="100%"
                                height='38px'
                                isMulti
                            />
                            <SelectComponent
                                placeholder='Selecione a modalidade'
                                onChange={(e) => setFilterModalidade(e.value)}
                                isClearable
                                options={modalidadeOptions}
                                width="100%"
                                height='38px'
                            />
                        </div>
                    </ContentFilters>
                    <div className='group-buttons'>
                        <Button width="40px" icon={<SlReload color='#002868' />} onClick={refreshDataTable} typeButton='secondary' />
                        {sales.length > 0 && (<Button width="20%" label="Exportar" icon={<HiOutlineDownload color='#002868' />} typeButton='secondary' onClick={exportS}/>)}
                        <DatePickerRange startDate={startDate} endDate={endDate} toogleDate={toogleDate} onChange={onChange} dateOpen={dateOpen} onClickOutside={() => {setDateOpen(false)}} />
                    </div>
                </ContentButtons>

                <ResumeSalesTable download={downloadAsPDF} setOffset={setOffset} offset={offset} />

                <Button
                    onClick={closeModal}
                    typeButton="outline"
                    width={"15%"}
                    label="Fechar"
                />
                </ContentModal>
                </ReactModal>
        </>
    )
}

export default ResumoVendas