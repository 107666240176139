export default {
  color: {
    primary: "#002868",
    secondary: "#737D8C",
    white: "#FFFFFF",
    titleCard: "#0033AB",
    error: "#E56A6A",
  },
  font: {
    family: "Roboto",

    weigth700: "700",
    weigth600: "600",
    weigth400: "400",

    size14: "14px",
    size16: "16px",
    size24: "24px",

    light: 300,
    normal: 400,
    bold: 600,
    sizes: {
      xxlsmall: '0.8rem',
      xxsmall: '1rem',
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
      huge: '5.2rem',
    }
  },

  colors: {
    white: '#FFFFFF',
    blue: '#002868',
    yellow: '#FFD400',
    gray: 'rgba(199, 203, 209, 0.40)',
    danger: '#F6555F',
    placeholder: '#737D8C',
    blueBorder: 'rgba(0, 40, 104, 1)',
    backgrond: '#F8F7F7',
    shadow: 'rgba(0, 0, 0, 0.5)',
    textBlue: '#0033AB',
    blueNeon: '#21d4fd',
    borderGray: 'rgba(199, 203, 209, 0.60)'
  }
} as const