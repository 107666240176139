import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import api from '../../services/api';

export const getCustomizedPopupPermission = createAsyncThunk(
  'functionalities/getCustomizedPopupPermission',
  async (_, { rejectWithValue }) => {
    try {

      const response = await api.get(`/user/customizedPopup/permission`)
      return response?.data
    } catch (error: any) {
      toast.error("Usuário não encontrado!")
      return rejectWithValue(error);
    }
})

export const generateShortToken = createAsyncThunk(
  'functionalities/generateShortToken',
  async (
    data: {
      originUrl: string
    }
    , { rejectWithValue }) => {
    try {

      const response = await api.post(`/parceiroMoura/shortToken`, data)
      return response?.data
    } catch (error: any) {
      toast.error("Usuário não encontrado!")
      return rejectWithValue(error);
    }
})