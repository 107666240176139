import styled, { css, keyframes } from "styled-components"

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
  }
`

export const ContainerMobile = styled.div`
  width: 100%;
  background-color: #002868;
  margin: 0;
  position: fixed;
  bottom: 0;
  z-index: 9;

  padding: 10px 5px;
  border-radius: 15px 15px 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ButtonMenu = styled.button`
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 26px;
    height: auto;
  }

  img {
    width: 26px;
    height: auto;
  }
`

export const Label = styled.span<{$active: boolean}>`
  font-family: ${({theme}) => theme.font.family};
  color: ${({$active}) => $active ? "#FFFFFF" : "#737D8C"};
  font-size: 12px;
`

export const ContainerDesktop = styled.div<{$showBoard: boolean}>`
  ${({theme, $showBoard}) => css`
    height: calc(100vh - 100px);
    background-color: white;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;

    .container-switch-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding-top: 35px;

      .label-switch-buttons {
        font-family: ${({theme}) => theme.font.family};
        color: ${({theme}) => theme.color.primary};
        font-weight: ${({theme}) => theme.font.weigth600};
        font-size: ${({theme}) => theme.font.size14};
      }

      .content-switch-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
      }
    }
  `}
`

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
`

const FadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
  }
  to {
    opacity: 1;
    height: auto;
    width: auto;

  }
`

const FadeOut = keyframes`
  from {
    opacity: 1;
    height: auto;
    width: auto;
  }
  to {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
  }
`

export const ButtonMenuDesktop = styled.button<{$active?: boolean, $isSubButton?: boolean, $hide: boolean}>`
  ${({theme, $isSubButton, $hide, $active}) => css`
    text-decoration: none;
    border: none;

    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    gap: 15px;

    padding: ${$isSubButton ? "0px 60px" : "0px 30px"};
    border-bottom: 1px solid rgba(199, 203, 209, 0.3);

    background-color: ${$active ? theme.color.primary : "transparent"};

    span {
      font-family: ${theme.font.family};
      color: ${$active ? theme.color.white : theme.color.secondary};
      font-weight: ${theme.font.weigth700};
      font-size: ${theme.font.size16};

      ${!$hide
        ? css`
            display: none;
            animation: ${FadeOut} 0.3s forwards;
          `
        : css`
            animation: ${FadeIn} 0.3s none;
      `}
    }

    img {
      width: 20px;
      max-width: 20px;
    }

    .arrow {
      ${!$hide
        ? css`
            animation: ${FadeOut} 0.3s forwards;
          `
        : css`
            animation: ${FadeIn} 0.3s none;
      `}
    }

    &:hover {
      background-color: ${$active ? theme.color.primary : "rgba(0, 51, 171, 0.15)"};
    }
  `}
`


interface NavProps {
  $showBoard: boolean
}

export const NavContainer = styled.nav<NavProps>`
  ${({theme, $showBoard}) => css`
    width: ${$showBoard ? '300px' : '100px'};
    padding-top: 100px;

    flex-shrink: 0;
    transition: all 0.3s;
    background: ${theme.colors.white};
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;

    .button {
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;

      right: -1.2rem;
      top: 120px;
      cursor: pointer;

      background: white;
      border: 0.14rem solid rgba(0, 0, 0, 0.12);
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;

      svg {
        width: 2.4rem;
      }
      transform: scaleX(${$showBoard ? '' : '-1'});
      transition: all 0.3s;
    }

    &:hover {
      .button {
        opacity: 1;
      }
    }

    .container-switch-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding-top: 35px;

      .label-switch-buttons {
        font-family: ${({theme}) => theme.font.family};
        color: ${({theme}) => theme.color.primary};
        font-weight: ${({theme}) => theme.font.weigth600};
        font-size: ${({theme}) => theme.font.size14};
      }

      .content-switch-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
      }
    }
  `}
`