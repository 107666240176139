/* eslint-disable @typescript-eslint/no-explicit-any */
import { IoEye, IoEyeOff } from "react-icons/io5";
import React, { ReactNode, useState } from 'react'

import { MessageError, MessagegObservation, Wrapper } from './styles'
import { useTheme } from "styled-components";

export type InputProps = {
  placeHolder: string
  label?: string
  width?: string
  height?: string
  type?: 'text' | 'number' | 'password'
  icon?: ReactNode
  id: string
  register?: any
  onChange?: any
  value?: any
  isPassword?: boolean
  disabled?: boolean
  textExample?: string
  numberMin?: string
  numberMax?: string
  extraIcon?: ReactNode
  onClickExtraIcon?: any
  required?: boolean
  errorMessage?: string
}

const Input = ({
  placeHolder,
  width,
  height,
  type = 'text',
  id,
  register,
  onChange,
  value,
  icon,
  isPassword,
  disabled,
  textExample,
  numberMin,
  numberMax,
  extraIcon,
  required = false,
  errorMessage,
  label,
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const onClickPassword = () => setShowPassword(!showPassword)
  const theme = useTheme()

  return (
    <Wrapper
      width={width}
      height={height}
      value={value}
      $error={!!(errorMessage && !value)}
    >
      {label && <label>{label}</label>}
      <div className="content-input">
        {onChange ? (
          <input
            type={isPassword ? (showPassword ? 'text' : 'password') : type}
            required={required}
            id={id}
            onChange={onChange}
            value={value}
            disabled={disabled}
            max={numberMax}
            min={numberMin}
            placeholder={placeHolder}
          />
        ) : (
          <input
            type={isPassword ? (showPassword ? 'text' : 'password') : type}
            required={required}
            id={id}
            {...register(id)}
            max={numberMax}
            min={numberMin}
            disabled={disabled}
            placeholder={placeHolder}
          />
        )}
        {icon}
        {isPassword && (
          <>
            {!showPassword ? (
              <IoEyeOff color={theme.colors.blue} size={20} onClick={onClickPassword} />
            ) : (
              <IoEye size={20} color={theme.colors.blue} onClick={onClickPassword} />
            )}
          </>
        )}
        {extraIcon && extraIcon}

      </div>
      {textExample && <MessagegObservation><strong>Obs: </strong>{textExample}</MessagegObservation>}
      {!!errorMessage && <MessageError>{errorMessage}</MessageError>}
    </Wrapper>
  )
}

export default Input
