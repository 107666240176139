import React, { useEffect } from 'react'

const useMedia = (media: string) => {
  const [match, setMatch] = React.useState<null | boolean>(null);
  useEffect(() => {
    function changeMatch() {
      const { matches } = window.matchMedia(media)
      setMatch(matches)
    }
    changeMatch();
    window.addEventListener('resize', changeMatch)
    return () => {
      window.removeEventListener('resize', changeMatch)
    }
  }, [media])
  return match;
}

export default useMedia;