/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useCallback, useMemo } from  'react'
import useAppSelector from '../../../hooks/useAppSelector'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import { ContainerDesktop, Divider, TableBody, TableHead } from '../styles'
import BannerDesktop from './BannerDesktop'
import WelcomeModalDesktop from './WelcomeModalDesktop'
import { optionType } from '../../../utils/constants'
import Card from '../../../components/Card/Card'
import { PermissionsProps } from '../../../store/user'
import { formatToCurrencyBRL, getLastUpdate } from '../../../utils/functions'
import { CardWithTable } from '../../../components/CardWithTable/CardWithTable'
import ReactLoading from 'react-loading'

import salesIcon from "../../../assets/sales-icon.svg"
import recebimentosIcon from '../../../assets/recebimentos-white-icon.svg'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { getReceivementsByDateAndNSU } from '../../../store/actions/receivementsActions'
import TableReceivements from './TableReceivements'
import TableDetailsReceivement from './TableDetailsReceivement'


interface Props {
  handleOpenBankSlip: () => void
}

const HomeDesktop: React.FC<Props> = ({handleOpenBankSlip}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const option = useAppSelector(state => state.switchButtons.option)
  const { user: { permissions } } = useAppSelector(state => state.user)
  const {
    balanceCredmoura,
    balanceCreditToRelease,
    balanceOpenBankSlip,
    loadingBalanceCredmoura,
    loadingBalanceCreditToRelease,
    loadingBalanceOpenBankSlip,
    loadingLastFiveReceivements,
    balanceReceivementsOfTheMonth,
    loadingBalanceReceivementsOfTheMonth,
    operacoesObject,
    lastFiveReceivements,
    receivementsByDayAndNSU
  } = useAppSelector(state => state.receivements)
  const { loadingBalanceSalesOfTheMonth, balanceSalesOfTheMonth, lastFiveSales, loadingLastFiveSales } = useAppSelector((state) => state.sales)

  
  const isMoura = useMemo(() => {
    return option === optionType.MOURA
  },[option])

  const permissionsMoura = useMemo(() => {
    if(permissions) {
        const userPermissions: PermissionsProps = JSON.parse(permissions)
        return userPermissions?.moura?.funcionalidades
    }
    return []
  },[permissions])

  const lastUpdate = useMemo(() => {
    return getLastUpdate(operacoesObject)
  },[operacoesObject])

  const totalLastFiveSales = useMemo(() => {
    return lastFiveSales?.reduce((acc, curr) => acc + curr?.clientGrossValue, 0)
  },[lastFiveSales])

  const totalLastFiveReceivements = useMemo(() => {
      return lastFiveReceivements?.reduce((acc, curr) => acc + curr?.netValue, 0)
  },[lastFiveReceivements])

  const loadReceivementsByDayAndNSU = useCallback(async (date: string, schedule: 'LOJA' | 'MOURA', open: boolean) => {
    await dispatch(getReceivementsByDateAndNSU({
        date,
        schedule,
        open
    }))
  }, [])

  const handleClickCreditsToRelease = () => {
    navigate(`/recebimentos`, {state: {toCreditsToRelease: true}})
  }

  const detailsReceivements = useMemo(() => {
    return receivementsByDayAndNSU.find(receivements => (receivements.schedule === (isMoura ? 'MOURA' : 'LOJA') && receivements.open === true))
  },[receivementsByDayAndNSU, isMoura])

  return (
    <ContainerDesktop>
      <BannerDesktop />
      <WelcomeModalDesktop />
      <div className='content-cards'>
        {isMoura ? (
          <>
            <Card title={"Saldo CREDMOURA"} description={`Última atualização: ${lastUpdate}`} balance={balanceCredmoura} loading={loadingBalanceCredmoura}/>
            <Card title={"Créditos a Liberar"} balance={balanceCreditToRelease} loading={loadingBalanceCreditToRelease} onClick={handleClickCreditsToRelease}/>
            {permissionsMoura?.includes("Parceiro Moura") && (
              <Card title={"Boletos Moura"} balance={balanceOpenBankSlip} loading={loadingBalanceOpenBankSlip} onClick={handleOpenBankSlip}/>
            )}
          </>
        ) : (
          <>
            <Card title={"Recebimentos do Mês"} balance={balanceReceivementsOfTheMonth} loading={loadingBalanceReceivementsOfTheMonth}/>
            <Card title={"Vendas do mês"} balance={balanceSalesOfTheMonth} loading={loadingBalanceSalesOfTheMonth}/>
          </>
        )}
      </div>
      <div className='content-card-table'>
        <CardWithTable
          title='Recebimentos'
          icon={recebimentosIcon}
          description={isMoura ? "Total do período em saldo CREDMOURA" : "Total do período na sua conta"}
          balance={totalLastFiveReceivements}
          loading={loadingLastFiveReceivements}
          onClick={() => navigate("/recebimentos")}
          contentStyle={{position: "relative"}}
        >
          {!detailsReceivements && (
            <TableReceivements
              loading={loadingLastFiveReceivements}
              lastFiveReceivements={lastFiveReceivements}
              receivementsByDayAndNSU={receivementsByDayAndNSU}
              loadReceivementsByDayAndNSU={loadReceivementsByDayAndNSU}
            />
          )}
          
          {detailsReceivements && (
            <TableDetailsReceivement
              loading={receivementsByDayAndNSU.find(receivements => receivements.schedule === option && receivements.open === true && receivements.loading) ? true : false}
              detailsReceivements={detailsReceivements}
              loadReceivementsByDayAndNSU={loadReceivementsByDayAndNSU}
            />
          )}
          </CardWithTable>
          <CardWithTable
              title='Vendas'
              icon={salesIcon}
              description="Total das últimas transações"
              balance={totalLastFiveSales}
              loading={loadingLastFiveSales}
              onClick={() => navigate("/resumo-vendas")}
          >
              <TableHead>
                  <span>Data</span>
                  <Divider />
                  <span>NSU</span>
                  <Divider />
                  <span>Valor bruto</span>
              </TableHead>
              <TableBody>
                  {loadingLastFiveSales ? (
                      <ReactLoading type={'spin'} className="loading" color={'#002868'} height={'fit-content'} width={'10%'}/>
                  ) : (
                      <>
                          {lastFiveSales?.map((item, index: number) => (
                              <div className='row-table' key={index}>
                                  <span>{dayjs(item?.date).format('DD/MM')}</span>
                                  <Divider />
                                  <span>{item?.nsu}</span>
                                  <Divider />
                                  <span>{formatToCurrencyBRL(item?.clientGrossValue)}</span>
                              </div>
                          ))}
                      </>
                  )}
              </TableBody>
          </CardWithTable>
      </div>
    </ContainerDesktop>
  )
}

export default HomeDesktop