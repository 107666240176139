import { css, styled } from "styled-components";

export const ContainerDesktop = styled.div`
  /* padding: 24px 30px; */
  display: flex;
  flex-direction: column;
  gap: 24px;

  .content-cards {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  .content-card-table {
    display: flex;
    gap: 24px;
    justify-content: space-between;
  }
`

export const TableHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${({theme}) => theme.color.secondary};
    font-family: ${({theme}) => theme.font.family};
    font-weight: ${({theme}) => theme.font.weigth700};
    font-size: ${({theme}) => theme.font.size14};
  }
`
export const Divider = styled.div`
  width: 1px;
  height: 25px;
  background-color: ${({theme}) => theme.color.secondary};
`

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .loading {
    margin: auto;
    margin-top: 20px;
  }

  .row-table {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 5px;
      color: #737D8C;
      font-family: ${({theme}) => theme.font.family};
      font-weight: ${({theme}) => theme.font.weigth400};
      font-size: ${({theme}) => theme.font.size14};
    }
  }
`

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 80vh;

  img {
    width: 100%;
    height: auto;
    overflow-y: auto;
  }
`

export const ContainerMobile = styled.div<{$marginTop: string}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  margin-top: ${({$marginTop}) => $marginTop};
  margin-bottom: 70px;

  .content-cards-mobile {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .row-cards {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }
`

export const ContentMenu = styled.div`

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper-cards {
    max-width: 380px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  @media (max-width: 410px) {

    .wrapper-cards {
      max-width: 100%;
      gap: 10px 10px;
      button {
        width: calc(50% - 5px);
      }
    }
  }
`

export const CardMenu = styled.button`
  text-decoration: none;
  cursor: pointer;

  background-color: #FFFFFF;
  width: 120px;
  min-width: 120px;
  height: 120px;
  border: 0.5px solid rgba(199, 203, 209, 0.60);
  border-radius: 15px;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
`
export const ContentImage = styled.div<{$disabled?: boolean}>`
  background-color: ${({$disabled}) => $disabled ? "rgba(199, 203, 209, 0.60)" : "#002868"};
  border-radius: 50%;
  width: 54px;
  height: 54px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
  }
`
export const Label = styled.span<{$disabled?: boolean}>`
  color: ${({$disabled}) => $disabled ? "rgba(199, 203, 209, 0.60)" : "#002868"};
  font-family: ${({theme}) => theme.font.family};
  font-size: 12px;
  text-align: center;
  max-height: 30%;
`

export const ContentModalNewFeature = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;

  p {
    text-align: center;
  }

  .shortly {
    font-size: 20px;
    color: #002868;
    font-weight: 700;
  }

  .new-feature {
    font-size: 16px;
    color: #8F97A3;
    font-weight: 400;
  }
`

export const Video = styled.video`
  width: 100%;
  margin-bottom: 10px;
`