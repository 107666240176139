import styled from 'styled-components'

interface TableBodyProps {
  $selectable?: boolean
}

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  /* box-shadow: 0 0.1rem 0.3rem ${({ theme }) => theme.colors.shadow}; */
  border-radius: 5px;

  border-radius: 20px 20px 0px 0px;

`

export const TableWrapper = styled.table`
  width: 100%;
  border-radius: 5px;
  /* box-shadow: 0 0.1rem 0.3rem ${({ theme }) => theme.colors.shadow}; */

  /* background-color: ${({ theme }) => theme.colors.white}; */

  border-bottom: 0;
  border-collapse: separate;
  border-spacing: 0px;
`
export const TableHead = styled.thead`
  height: 60px;
  tr {
    /* height: 50px; */

    th {
      /* border-bottom: 1px solid rgba(0,0,0,.12); */
      /* border-collapse: separate; */
      /* border-spacing: 5px 5px; */

      font-size: ${({ theme }) => theme.font.sizes.xxlsmall};
      padding: 5px 10px;

      /* min-width: 130px; */
    }
  }
`
export const TableBody = styled.tbody<TableBodyProps>`
  tr {
    height: 50px;

    td {
      text-align: center;
      font-size: ${({ theme }) => theme.font.sizes.xxlsmall};
      padding: 5px 10px;

      /* border-top: 1px solid rgba(0, 0, 0, 0.12); */
      /* max-width: 10rem; */

      .loading {
        margin: 3rem auto;
      }
    }

    &:hover {
      background-color: ${({ $selectable }) =>
        $selectable ? '#ddd' : 'inherit'};
      cursor: ${({ $selectable }) => ($selectable ? 'pointer' : 'inherit')};
    }
  }
`
