import styled from "styled-components"

export const Layout = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
`

export const RightBoard = styled.div<{$showBoard?: boolean}>`
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: ${({ theme }) => theme.colors.backgrond};

  width: ${({ $showBoard }) =>
    $showBoard ? 'calc(100% - 300px)' : 'calc(100% - 100px)'};

  margin-left: ${({ $showBoard }) => ($showBoard ? '300px' : '100px')};

  padding: 8rem 2.4rem 5rem 2.4rem;

  transition: all 0.2s;

  & > div:not(:first-child) {
    margin-top: 3.6rem;
  }

  @media screen and (max-width: 900px) {
    padding: 10rem 2rem 0 4.2rem;

    width: ${({ $showBoard }) =>
      $showBoard ? 'calc(100% - 300px)' : 'calc(100% - 100px)'};
  }
`

export const Container = styled.div`
  max-width: 144rem;
  width: 100%;
`
