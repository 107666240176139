import React from 'react'
import { useNavigate } from 'react-router-dom'

import { FaArrowLeft } from "react-icons/fa6";
import { ContainerHeaderDetails, Title } from '../styles'

interface Props {
  title: string
}

const HeaderDetails: React.FC<Props> = ({title}) => {
  const navigate = useNavigate()

  return (
    <ContainerHeaderDetails>
      <button onClick={() => navigate(-1)}>
        <FaArrowLeft color='#FFFFFF' size={25} />
      </button>
      <Title>
        {title}
      </Title>
    </ContainerHeaderDetails>
  )
}

export default HeaderDetails