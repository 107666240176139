/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import ReactLoading from 'react-loading'
import { useNavigate } from 'react-router-dom'
import * as CryptoJS from 'crypto-js'
import jwt_decode from 'jwt-decode'
import ReactModal from 'react-modal';
import * as Switch from '@radix-ui/react-switch';
import InputMask from "react-input-mask";
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { authenticator, saveUser} from '../../store/user'
import logoCredmouraBlue from '../../assets/logo-credmoura-blue.svg'
import config from '../../config'
import { saveCredentials, getCredentials } from '../../flutter_app'
import { ContainerDesktop, ContainerModal, ContentForm, ContentSwitch, LogoApp, WrapperForm } from './styles';
import Button from '../../components/Button/Button';
import { CiPhone } from 'react-icons/ci';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import useAppDispatch from '../../hooks/useAppDispatch';
import { forgotPassword, loginUser, loginUserPropig, onDemand, requestContact } from '../../store/actions/userActions';
import useAppSelector from '../../hooks/useAppSelector';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { handleSwitchButton } from '../../store/switchButtons';
import { optionType } from '../../constants';
import './styles.scss'
import CoverImage from './CoverImage';
import { getProfile, isProfileAllowMoura } from '../../utils/functions'

type FormLogin = {
    documento: string;
    password: string;
    usuario: string;
}

type FormForgotPassword = {
    documento: string;
    usuario: string;
    email: string;
}

type FormRequestContact = {
    nome: string;
    telefone: string;
}

type Profile = "multi-agenda" | "flex" | "cessao" | "maquina" | undefined

// const usersAllowed = [
//     '03671459000105',
//     '35008324000150',
//     '39505582000101',
//     '32861009000183',
//     '08062606000163',
//     '3582062000147',
//     '34083056000179',
//     '31486061000304',
//     '43083094000112',
//     '18391338000187',
//     '29875834000122',
//     '51413202000104',
//     '69924017000131',
//     '31892274000166',
//     '34624186000171',
//     '39442141000108',
//     '41753235000131',
//     '46535375000157',
//     '21380927000174',
//     '49488192000116',
//     '36552236000188',
//     '29875834000122',
//     '11390584000175',
//     '26495131000180',
//     '20683310000165',
//     '51413202000104',
//     '28984911000110',
//     '31262783000105',
//     '27200284000116',
//     '26932042000154',
//     '50828655000120',
//     '33552612000146',
//     '42723855000190',
//     '33765399000150',
//     '14424184000121',
//     '31824395000170',
//     '47317810000130',
//     '55278311000109',
//     '65359689000190',
//     '01048975000152',
//     '25399880000141',
//     '42750445000139',
//     '27619572000100',
//     '11357950000194',
//     '20849183000121',
//     '42046464000189',
//     '27936019000109',
//     '10489081000199',
//     '39453482000170',
//     '33531851000110',
//     '24547328000190',
//     '37068838000127',
//     '46219371000160',
//     '16967871000119',
//     '29303808000120',
//     '41676697000100',
//     '32771405000110',
//     '27963563000131',
//     '45444111000125',
//     '43047761000101',
//     '44764978000103',
//     '30736955000172',
//     '26167675000113',
//     '20620489000101',
//     '19035020000126',
//     '32468769000206',
//     '44810675000171',
//     '20649549000119',
//     '24900644000102',
//     '50835525000115',
//     '46219371000160',
//     '50965220000128',
//     '20625966000121',
//     '34425308000109',
//     '37266638000189',
//     '42114369000175',
//     '50678387000108',
//     '32359826000138',
//     '49488192000116',
//     '41753235000131',
//     '20683310000165',
//     '14424184000121',
//     '32145684000106',
//     '28937701000170',
//     '26125941000145',
//     '34919776000121',
//     '27200284000116',
//     '49767862000133',
//     '35195218000123',
//     '37839165000161',
//     '32468769000206',
//     '27753547000114',
//     '31934624000100',
//     '39430189000198',
//     '32145684000106',
//     '34279009000103',
//     '11626121000160',
//     '28937701000170',
//     '33629009000115',
//     '28403748000154',
//     '30611487000100',
//     '45604716000136',
//     '07075386000220',
// ]

const Login = () => {
    const SALT = config.SALT
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { loadingLogin, loadingLoginPropig, loadingForgotPassword, user: {
        document: documentoUserLogged, loginType, username
    } } = useAppSelector(state => state.user)

    const [viewPassword, setViewPassword] = useState(false)
    const [stepper, setStepper] = useState(1)
    const [modalRequestContact, setModalRequestContact] = useState(false)

    const [_saveCredentials, _setSaveCredentials] = useState(false)

    // useEffect(() => {
    //     if (token) {
    //         toast.success('Usuário já logado, redirecionando...', {
    //             autoClose: 2000
    //         })
    //         setTimeout(() => {
    //             navigate('/home')
    //         }, 1000)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [navigate])
    

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        watch,
        formState: {errors}
      } = useForm({
        defaultValues: {
          documento: "",
          password: "",
          usuario: ""
        }
      });

    const {
        register: registerForgotPassword,
        handleSubmit: handleSubmitForgotPassword,
        watch: watchForgotPassword,
        setError: setErrorForgotPassword,
        clearErrors: clearErrorsForgotPassword,
        formState: {errors: errorsForgotPassword}
        } = useForm({
        defaultValues: {
            documento: "",
            usuario: "",
            email: "",
        }
    });

    const {
        register: registerRequestContact,
        handleSubmit: handleSubmitRequestContact,
        control: controlRequestContact,
        reset: resetRequestContact
        } = useForm({
        defaultValues: {
            nome: "",
            telefone: "",
        }
    });

    useEffect(() => {
        if(loginType === 'PROPIG') {
            setValue('documento', 'propig')
            setValue('usuario', username)
            return
        } else {
            if(documentoUserLogged) {
                setValue('documento', documentoUserLogged)
            }
        }
    },[loginType, username, documentoUserLogged])

      const [documento, password, usuario] = watch(["documento", "password", "usuario"])
    
      const onSubmit = async (data: FormLogin) => {
        // REMOVER ESSA VERIFICAÇÃO QUANDO SUBIR PRA PROD
        // if(!usersAllowed.includes(data.documento) && data.documento.toLocaleLowerCase() !== 'propig') {
        //     setError('documento', {message: 'CNPJ não habilitado!'})
        //     return
        // }
        if (documento.toLocaleLowerCase() !== "propig" && documento !== "32392800000191" && process.env.REACT_APP_STAGE !== "prod") {
            toast.error("Acesso não permitido para este ambiente.", {autoClose: 2000})
            return
        }


        const body = {
            documento: data.documento.toLocaleLowerCase() === "propig" ? "propig" : data.documento,
            password: CryptoJS.AES.encrypt(data.password, SALT).toString()
        }
        const response = data.documento.toLocaleLowerCase() !== "propig" ?
        (await dispatch(loginUser(body))) :
        (await dispatch(loginUserPropig({
            usuario: data.usuario, 
            ...body,
        })))
        if(response.meta?.requestStatus === "fulfilled") {
            if (_saveCredentials) {
                const json = {
                    documento: data.documento,
                    password: data.password
                }
                saveCredentials(JSON.stringify(json))
            }

            const token = response?.payload?.token
            const user = jwt_decode<any>(token).user
            dispatch(saveUser({...user, username: data.documento.toLocaleLowerCase() === "propig" ? data.usuario : data.documento}))
            dispatch(authenticator({ isAuthenticated: true, token }))
            dispatch(onDemand())

            const profile = getProfile(user)
            dispatch(handleSwitchButton(isProfileAllowMoura(profile as Profile) ? optionType.MOURA : optionType.LOJA))
            
            toast.success("Login realizado com sucesso! Redirecionando...", {autoClose: 2000})
            setTimeout(() => {
                navigate(`/home`, { state: { isAuthenticated: true } })
            }, 1000)
        

        } else {
            setError("usuario", {message: response?.payload?.response?.data?.message})
        }
      };

      useEffect(() => {
        clearErrors('usuario')
      },[documento, password, usuario])


    const [documentoForgotPassword, emailForgotPassword, usuarioForgotPassword] = watchForgotPassword(["documento", "email", "usuario"])

    useEffect(() => {
        clearErrorsForgotPassword('usuario')
      },[documentoForgotPassword, emailForgotPassword, usuarioForgotPassword])

    const onSubmitForgotPassword = async (data: FormForgotPassword ) => {
        const body = {
            documento: data.documento.toLocaleLowerCase() === "propig" ? "propig" : data.documento,
            usuario: data.documento.toLocaleLowerCase() === "propig" ? data.usuario : data.documento,
            email: data.email
        }

        const response = await dispatch(forgotPassword(body))
        if(response?.meta?.requestStatus === "fulfilled") {
            toast.success("Troca de senha solicitada")
        } else {
            setErrorForgotPassword("usuario", {message: response?.payload?.response?.data?.message})
        }

    }

    
    const onSubmitRequestContact = async (data: FormRequestContact ) => {
        const body = {
            name: data?.nome,
            phone: data?.telefone?.match(/\d+/g)?.join("")
        }
        const response = await dispatch(requestContact(body))

        if(response?.meta?.requestStatus === "fulfilled") {
            toast.success("Logo logo estamos entrando em contato com você!!")
        }

    }

    

    useEffect(() => {
        (window as any).fillCredentials = fillCredentials
        getCredentials()

    }, [])

    const fillCredentials = async (credentials: any) => {
        var jsonCredentials = JSON.parse(credentials);
        var cnpj = jsonCredentials.cnpj;
        var loginPropig = jsonCredentials.loginPropig;
        var password = jsonCredentials.password;
        if (cnpj && password) {
            _setSaveCredentials(true)
            const toggle: any = document.querySelector('input#flexSwitchCheckDefault.form-check-input')
            toggle!.checked = true

            const cnpjInput: any = document.querySelector('input#cnpj.form-control.login-input')
            cnpjInput!.value = cnpj

            const loginPropigInput: any = document.querySelector('input#loginPropig.form-control.login-input')
            loginPropigInput!.value = loginPropig

            const passwordInput: any = document.querySelector('input#password.form-control.login-input')
            passwordInput.value = password
        }
    }

    const modalEstilo = {
        content: {
            width: '80%', // Defina a largura desejada do modal
            maxWidth: "500px",
            maxHeight: 'min-content', // Defina a altura máxima desejada do modal
            margin: 'auto', // Centralize o modal horizontalmente
            borderRadius: "15px",
            boxShadow: "0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.212)",
            borderColor: "#F6F6F6",
        }
    };

    const handleSaveCredentias = (checked: boolean) => {
        _setSaveCredentials(checked)
    }

    const isLoginPropig = useMemo(() => {
        return documento.toLocaleLowerCase() === "propig"
    },[documento])

    const isLoginPropigForgotPassword = useMemo(() => {
        return documentoForgotPassword.toLocaleLowerCase() === "propig"
    },[documentoForgotPassword])

    const handleViewPassword = useCallback(() => {
        setViewPassword(!viewPassword)
    },[viewPassword])

    const handleCLoseModalRequestContact = () => {
        setModalRequestContact(false)
        resetRequestContact({
            nome: "",
            telefone: ""
        })
    }

    return (
        <ContainerDesktop>
            <CoverImage />
            <ContentForm>
                {stepper === 1 && (
                    <WrapperForm>
                        {(loadingLogin || loadingLoginPropig) && (
                            <div className='content-loading'>
                                <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'15%'}/>
                            </div>
                        )}
                        <LogoApp src={logoCredmouraBlue} alt='Logo Credmoura' />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input placeholder="Informe seu CNPJ" required {...register("documento")} />
                            {/* REMOVER ESSA LINHA DE BAIXO QUANDO SUBIR PRA PROD */}
                            {errors.documento?.message && <span className='message-error'>{errors.documento?.message}</span>}
                            {isLoginPropig && (<input placeholder="Informe seu nome de usuário" {...register("usuario")} />)}
                            <div className='input-password'>
                                <input placeholder="Informe sua Senha" autoComplete="off" required type={viewPassword ? "text" : "password"} {...register("password")} />
                                {viewPassword ? <BsEyeSlash color="#002868" onClick={handleViewPassword} /> : <BsEye color="#002868" onClick={handleViewPassword} />}
                            </div>
                            {errors && <span className='message-error'>{errors.usuario?.message}</span>}
                            <ContentSwitch>
                                <span className='forgot-password' onClick={() => setStepper(2)}>Esqueci a senha</span>
                                <div className='switch-button'>
                                    <label htmlFor="airplane-mode">Lembrar meus dados</label>
                                    <Switch.Root className="SwitchRoot" id="airplane-mode" onCheckedChange={(e) => handleSaveCredentias(e)}>
                                        <Switch.Thumb className="SwitchThumb" />
                                    </Switch.Root>
                                </div>
                            </ContentSwitch>
                            <Button width="50%" type='submit' label="Entrar" typeButton="primary"/>
                        </form>
                        <div className='divider'></div>
                        <span className='text-help'>Fale conosco pelo número <strong>(81) 3299-1248</strong></span>
                        <Button
                            typeButton="outline"
                            label="Solicite um contato agora"
                            icon={<CiPhone color='#002868' />}
                            onClick={() => setModalRequestContact(true)}
                        /> 
                    </WrapperForm>
                )}
                {stepper === 2 && (
                    <WrapperForm>
                        {loadingForgotPassword && (
                            <div className='content-loading'>
                                <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'15%'}/>
                            </div>
                        )}
                        <LogoApp src={logoCredmouraBlue} alt='Logo Credmoura' />
                        <form onSubmit={handleSubmitForgotPassword(onSubmitForgotPassword)}>
                            <input placeholder="Informe seu CNPJ" required {...registerForgotPassword("documento")} />
                            {isLoginPropigForgotPassword && (<input placeholder="Informe seu nome de usuário" {...registerForgotPassword("usuario")} />)}
                            <input placeholder="Informe seu email" type="email" required {...registerForgotPassword("email")} />
                            {errorsForgotPassword && <span className='message-error'>{errorsForgotPassword.usuario?.message}</span>}
                            <Button width="50%" type='submit' label="Recuperar senha" typeButton="primary"/>
                        </form>
                        <div className='divider'></div>
                        <span className='text-help' style={{cursor: "pointer"}} onClick={() => setStepper(1)}>Retornar ao login</span>
                    </WrapperForm>
                )}
            </ContentForm>
            <ReactModal
                isOpen={modalRequestContact}
                onRequestClose={() => setModalRequestContact(false)}
                style={modalEstilo}
            >
                <ContainerModal>
                    <div className='content-head'>
                        <p>Preencha seus dados abaixo que entraremos em contato com você!</p>
                        <Button
                            icon={<AiOutlineCloseCircle color='#002868' />}
                            typeButton="outline"
                            noBorder
                            width='40px'
                            onClick={handleCLoseModalRequestContact}
                        />
                    </div>
                    <form onSubmit={handleSubmitRequestContact(onSubmitRequestContact)}>
                        <input placeholder="Nome" required {...registerRequestContact("nome")} />
                        <Controller
                            control={controlRequestContact}
                            name="telefone"
                            render={({field}) => <InputMask mask="(99) 99999-9999" placeholder="Telefone" {...field} />}
                        />
                        <Button width="50%" type='submit' label="Confirmar" typeButton="primary"/>
                    </form>
                </ContainerModal>
            </ReactModal>
        </ContainerDesktop>
    )
}

export default Login