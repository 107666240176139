import styled from "styled-components"

export const ContainerDesktop = styled.div`
  /* padding: 24px 30px; */
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ContentCards = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`

export const ContentButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .group-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: relative;
  }
`

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  /* height: 100%; */
`

type LabelProps = {
  fontSize: string;
  color: string;
}

export const Label = styled.span<LabelProps>`
  font-family: ${({theme}) => theme.font.family};
  font-weight: ${({theme}) => theme.font.weigth700};
  font-size: ${({fontSize}) => fontSize ?? "14px"};
  color: ${({color}) => color ?? "unset"};
`

export const PeriodValues = styled.div`
  width: 1000px;
  > span {
    font-size: 12px;
  }
  > div {
    display: flex;
    border: 1px solid #002868;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    > div {
      span {
        font-size: 13px;
        font-family: ${({theme}) => theme.font.family};
        font-weight: ${({theme}) => theme.font.weigth700};
      }
      > div:nth-child(1) {
        padding-right: 0.5rem;
        margin-right: 0.5rem;
        border-right: 1px solid grey;
      }
    }
  }
`