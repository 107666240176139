/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { ContainerForm, ContentForm, ContentTitle, Row, Title } from "../styles"
import ReactLoading from "react-loading";
import Button from "../../../components/Button/Button";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ReactModal from "react-modal";
import { UpdatebankData } from "./UpdateBankData";

type FormBank = {
  bank: string,
  accountType: string,
  agency: string,
  account: string,
  digit: string
}

type BackType = {
  bank: string,
  accountType: string,
  agency: string,
  account: string,
  digit: string
  loading: boolean,
  banks: Array<{
    label: string,
    value: string
  }>
}

const BankDataForm = ({
  bank,
  accountType,
  account,
  digit,
  agency,
  loading,
  banks
}: BackType) => {
  const [openForm, setOpenForm] = useState(false)
  const [viewUpdateBankData, setViewUpdateBankData] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      bank: "",
      accountType: "",
      agency: "",
      account: "",
      digit: ""
    }
  });

  const onSubmit = (data: FormBank) => console.log(data);

  useEffect(() => {
    setValue("agency", agency)
    setValue("account", account)
    setValue("digit", digit)
    setValue("bank", bank)
    setValue("accountType", accountType)
  },[account, accountType, bank, digit, agency])


  return (
    <>
    <ContainerForm>
    <ContentTitle>
      <Title>Dados bancários</Title>
      {/* <Description>Edite seus dados bancários</Description> */}
    </ContentTitle>
    <ContentForm>
      {!openForm && (
        <>
          {loading ? (
            <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'5%'}/>
          ) : (
            <Button width="35%" height="40px" onClick={() => setOpenForm(true)} label="Visualizar" typeButton="outline" icon={<BsEye color="#002868" />}/>
          )}
      </>
      )}
      {openForm && (
        <form onSubmit={handleSubmit(onSubmit)} >
          <Row>
            <select placeholder="Escolha seu banco" disabled {...register("bank")}>
              <option value="">Escolha seu banco</option>
              {banks?.map((bank: any, index: number) => (
                <option key={index} value={bank?.label}>{bank?.label}</option>
              ))}
            </select>
            <select placeholder="Tipo de conta" disabled {...register("accountType")}>
              <option value="">Tipo de conta</option>
              <option value="POUPANCA">Poupança</option>
              <option value="CORRENTE">Corrente</option>
            </select>
          </Row>
          <Row>
            <input placeholder="Agência" disabled {...register("agency")} />
            <input placeholder="Conta" disabled {...register("account")} />
            <input placeholder="Dig." disabled {...register("digit")} />
          </Row>
          <div className="content-buttons">
            <Button width="35%" height="40px" icon={<BsEyeSlash color="#002868" />} onClick={() => setOpenForm(false)} label="Fechar" typeButton="outline"/>
            {/* <Button type="submit" width="30%" label="Solicitar alteração" typeButton="primary" onClick={() => {setViewUpdateBankData(true)}}/> */}
            {/* <Button type="submit" width="30%" label="Salvar" typeButton="primary" disabled/> */}
          </div>
        </form>
      )}

    </ContentForm>
  </ContainerForm>
  <ReactModal isOpen={viewUpdateBankData} onRequestClose={() => {}} style={{content: {
      maxWidth: "600px",
      margin: 'auto',
      maxHeight: 'min-content',
    }}}>
      <div className='content-head' style={{alignItems: 'center', marginBottom: '1rem', display: "flex", justifyContent: "space-between"}}>
          <p style={{margin: '0'}}></p>
          <Button
              icon={<AiOutlineCloseCircle color='#002868' />}
              typeButton="outline"
              noBorder
              width='40px'
              onClick={() => {setViewUpdateBankData(false)}}
          />
      </div>
      <UpdatebankData onClose={() => {setViewUpdateBankData(false)}}/>
    </ReactModal>
  </>
  )
}

export default BankDataForm