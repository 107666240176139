import axios from 'axios'
import config from '../config'
import useAppDispatch from '../hooks/useAppDispatch'
import { logoutUser } from '../store/user'


const api = axios.create({
  baseURL: config.PAYMENT_LINK_API_URL
})

api.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('token')
    if(token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    if (error?.response && error?.response?.data?.message === 'jwt expired') {
      const dispatch = useAppDispatch()
      dispatch(logoutUser({ isAuthenticated: false, token: '' }))
    }

    return Promise.reject(error)
  }
)

export default api
