import { css, styled } from "styled-components";
import { InputProps } from ".";

type WrapperProps = Pick<InputProps, 'width' | 'value'>

export const Wrapper = styled.div<WrapperProps>`
  ${({theme, width, value}) => css`
    display: flex;
    flex-direction: column;
    text-align: start;
    width: ${width || '250px'};

    label {
      margin-left: 0.5rem;
      color: ${theme.colors.textBlue};
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.xxsmall};
    }
  `}
`

export const TextAreaInput = styled.textarea`
  ${({theme}) => css`
    width: 100%;
    color: ${theme.colors.blue};
    border: 1px solid ${theme.colors.blueBorder};
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 10px;
    background-color: ${theme.colors.white};
    padding-left: 14px;
    outline: none;
  `}
`