/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonMenuDesktop, ContentButtons, ContentModal, NavContainer } from '../styles'
import { useLocation, useNavigate } from 'react-router-dom'
import recebimentosIcon from '../../../assets/recebimentos-icon-menu.svg'
import recebimentosWhiteIcon from '../../../assets/recebimentos-white-icon.svg'
import vendasIcon from '../../../assets/vendas-new-icon.svg'
import emprestimosIcon from '../../../assets/emprestimos-new-icon.svg'
import linkIconWhite from '../../../assets/linkpagamento-icon-white.svg'
import meusDadosWhiteIcon from '../../../assets/meus-dados-white-icon.svg'
import linkIcon from '../../../assets/linkpagamento-new-icon.svg'
import cobrancasIconWhite from '../../../assets/cobrancas-icon-white.svg'
import boletosIcon from '../../../assets/boletos-icon-menu.svg'
import cobrancasIcon from '../../../assets/cobrancas-new-icon.svg'
import meusDadosIcon from '../../../assets/meus-dados-icon-menu.svg'
import ParceiroMouraBanner from './ParceiroMouraBanner'
import ReactModal from 'react-modal'
import ReactLoading from 'react-loading'
import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import { optionType } from '../../../constants'
import SwitchButtons from '../../SwitchButtons'
import Button from '../../Button/Button'
import { PermissionsProps } from '../../../store/user'
import { generateShortToken, getCustomizedPopupPermission } from '../../../store/actions/functionalitiesActions'
import { changeShowBoard, openBoard } from '../../../store/board'
import { IoIosArrowRoundBack, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoLocationOutline } from 'react-icons/io5'
import { allowSwitchButtons } from '../../../utils/functions'

const MenuDesktop: React.FC = () => {
  const dispatch = useAppDispatch()
  const { showBoard } = useAppSelector((store) => store.board)
  const { user: { profile, document: userDocument, permissions}, token } = useAppSelector(state => state.user)
  const option = useAppSelector(state => state.switchButtons.option)

  const navigate = useNavigate()
  const location = useLocation()

  const [openMenu, setOpenMenu] = useState('')
  const [modalOpenBankSlip, setModalOpenBankSlip] = useState({
    isOpen: false,
    imgContent: ""
  })

  const permissionsMoura = useMemo(() => {
    if(permissions) {
        const userPermissions: PermissionsProps = JSON.parse(permissions)
        return userPermissions?.moura?.funcionalidades
    }
    return []
  },[permissions])

    const permissionsLivre = useMemo(() => {
        if(permissions) {
            const userPermissions: PermissionsProps = JSON.parse(permissions)
            return userPermissions?.livre?.funcionalidades
        }
        return []
    },[permissions])

    const isMoura = useMemo(() => {
        return option === optionType.MOURA
    },[option])

    const handleRedirect = (url: string) => navigate(url)

    const handleOpenBankSlip = useCallback((document: string) => {
        checkPermissionToRedirect(document)
    }, [token])

    const checkPermissionToRedirect = async (document: string) => {
        const response = await dispatch(getCustomizedPopupPermission())
        if(response?.meta?.requestStatus === "fulfilled") {
            if(response?.payload?.userWithoutMouraAccess) {
                setModalOpenBankSlip({
                    isOpen: true,
                    imgContent: response?.payload?.userWithoutMouraAccess?.imageUrl
                })
            } else if (response?.payload?.userFirstMouraAccess) {
                setModalOpenBankSlip({
                    isOpen: true,
                    imgContent: response?.payload?.userFirstMouraAccess?.imageUrl
                })
            } else {
                tokenShort(document)
            }
        }
    }

    const tokenShort = async (document: string) => {
        const response = await dispatch(generateShortToken({originUrl: "https://portaldocliente.propig.com.br/shell/sales"}))
        if(response?.payload?.token) {
            window.location.assign(`https://www.parceiromoura.com.br/login/LoginLTIPropig?CNPJ=${document}&acao=Financeiro&_KeyLTI=6X2eKW4Aa0jBuUOEPBws1pDiN1aSeQfgCt-RFO07E662_g8HTCQJ5VZW4VZLx4OTaYQVlorac1vHkFL953Bnbnp5L-MtgmyzWxE5FGKC4wUSjiKEskS36z8xGTna_sIK_uCoZSN99KeFU7Rl4mFb8q6sXeYUz8uJXcUK03BkNyJssORhHsexko3I06dHJ-_CYeYYL9e7LFL_zjddNW59gv50q7a1r3dD17nAS_olanc`)
        }
    }

    const handleOpenMenu = useCallback((title: string) => {
        if(openMenu) {
            setOpenMenu('')
            return
        }
        setOpenMenu(title)
        dispatch(openBoard())
    },[openMenu])

    useEffect(() => {
        if(!showBoard) {
            setOpenMenu('')
            return
        }
    },[showBoard])

    useEffect(() => {
        if(['/resumo-vendas', '/transacoes-detalhadas'].includes(location.pathname)) {
            setOpenMenu('Vendas')
        }
    },[location.pathname])

  const menu = useMemo(() => {
    return [
        {
            url: '/meus-dados',
            title: 'Meus Dados',
            imageIcon: <img src={location.pathname === '/meus-dados' ? meusDadosWhiteIcon : meusDadosIcon} alt='Ícone Meus Dados'/>,
            icon: null,
            active: location.pathname === '/meus-dados',
            canView: true,
            children: [],
            onClick: () => {},
        },
        {
            url: '/recebimentos',
            title: 'Recebimentos',
            imageIcon: <img src={location.pathname === '/recebimentos' ? recebimentosWhiteIcon : recebimentosIcon} alt='Ícone Recebimentos'/>,
            icon: null,
            active: location.pathname === '/recebimentos',
            canView: true,
            children: [],
            onClick: () => {},
        },
        {
            url: '',
            title: 'Vendas',
            imageIcon: <img src={vendasIcon} alt=''/>,
            icon: null,
            active: false,
            canView: true,
            onClick: () => handleOpenMenu('Vendas'),
            children: [
                {
                    url: '/resumo-vendas',
                    title: 'Resumo de Vendas',
                    active: location.pathname === '/resumo-vendas',
                    onClick: () => {},
                },
                {
                    url: '/transacoes-detalhadas',
                    title: 'Vendas Detalhadas',
                    active: location.pathname === '/transacoes-detalhadas',
                    onClick: () => {},
                },
            ],
        },
        {
            url: '/cobrancas',
            title: 'Cobranças',
            imageIcon: <img src={location.pathname === "/cobrancas" ? cobrancasIconWhite : cobrancasIcon} alt='Ícone Cobranças'/>,
            icon: null,
            active: location.pathname === '/cobrancas',
            canView: true,
            children: [],
            onClick: () => {},
        },
        {
            url: '/rastreio-maquina',
            title: 'Rastreio',
            imageIcon: null,
            icon: <IoLocationOutline color={location.pathname === '/rastreio-maquina' ? '#FFFFFF' :'#737D8C'} size={25} />,
            active: location.pathname === '/rastreio-maquina',
            canView: false,
            children: [],
            onClick: () => {},
        },
        {
            url: '',
            title: 'Parceiro Moura',
            imageIcon: <img src={boletosIcon} alt='Ícone Parceiro Moura'/>,
            icon: null,
            active: false,
            canView: !!(isMoura && permissionsMoura?.includes("Parceiro Moura")),
            children: [],
            onClick: () => handleOpenBankSlip(userDocument),
        },
        {
            url: '/links-de-pagamento',
            title: 'Link de Pagamento',
            imageIcon: <img src={location.pathname === '/links-de-pagamento' ? linkIconWhite : linkIcon} alt='Ícone Link de Pagamento'/>,
            icon: null,
            active: location.pathname === '/links-de-pagamento',
            canView: !!(isMoura && permissionsMoura?.includes("Link de Pagamento")) || permissionsLivre?.includes("Link de Pagamento"),
            children: [],
            onClick: () => {},
        },
        {
            url: '',
            title: 'Consulta de Recebíveis',
            imageIcon: <img src={emprestimosIcon} alt='Ícone consulta de recebíveis'/>,
            icon: null,
            active: false,
            canView: !!(isMoura && permissionsMoura?.includes("Emprestimo")) || permissionsLivre?.includes("Emprestimo"),
            children: [],
            onClick: () => {
                window.location.href = 'https://propig.mova.vc/'
            },
        },
    ]
  },[
        location.pathname,
        isMoura,
        permissionsMoura,
        userDocument,
        handleOpenMenu,
    ])
  
    const handleShowBoardChange = () => {
      dispatch(changeShowBoard())
    }

  return (
    <>
        <NavContainer $showBoard={showBoard}>
            <button className="button" onClick={handleShowBoardChange}>
                <IoIosArrowRoundBack />
            </button>

            <div>
                {allowSwitchButtons(profile) && (
                    <div className='container-switch-buttons'>
                        {showBoard && (
                            <span className='label-switch-buttons'>Selecione o lado desejado:</span>
                        )}
                        <div className='content-switch-buttons'>
                            <SwitchButtons view={showBoard ? 'BOTH' : option as 'LOJA' | 'MOURA'} />
                        </div>
                    </div>
                )}
                <ContentButtons>
                    {menu?.map((item, idx) => (
                        <React.Fragment key={idx}>
                            {item?.canView && (
                                <>
                                    <ButtonMenuDesktop
                                        key={`button-${idx}`}
                                        $active={item?.active}
                                        $hide={showBoard}
                                        onClick={item?.url ? () => handleRedirect(item?.url) : item?.onClick}
                                    >
                                        {item?.icon ? item?.icon : item?.imageIcon}
                                        <span>{item?.title}</span>
                                        {item?.children?.length > 0 && (
                                            <>
                                                {openMenu === item?.title && <div className='ms-auto arrow'><IoIosArrowUp color='#737D8C' /></div>}
                                                {openMenu !== item?.title && <div className='ms-auto arrow'><IoIosArrowDown color='#737D8C' /></div>}
                                            </>
                                        )}
                                    </ButtonMenuDesktop>
                                    {openMenu === item?.title && (
                                        <>
                                            {item?.children?.map((children, idxChildren) => (
                                                <ButtonMenuDesktop
                                                    key={`button-children-${idxChildren}`}
                                                    $active={children?.active}
                                                    $isSubButton
                                                    $hide={showBoard}
                                                    onClick={() => handleRedirect(children?.url)}
                                                >
                                                    <span>{children?.title}</span>
                                                </ButtonMenuDesktop>
                                            ))}
                                        </>
                                    )}
                                </>
                                
                            )}
                        </React.Fragment>
                    ))}
                </ContentButtons>
            </div>
            {permissionsMoura?.includes("Parceiro Moura") && showBoard && (
                <div onClick={() => handleOpenBankSlip(userDocument)} style={{cursor: 'pointer'}}>
                    <ParceiroMouraBanner />
                </div>
            )}
        </NavContainer>
      <ReactModal
        isOpen={modalOpenBankSlip?.isOpen}
        onRequestClose={() => setModalOpenBankSlip({...modalOpenBankSlip, isOpen: false})}
        contentLabel="Buscar Cliente"
        style={{
            content: {
                width: "80%",
                height: "max-content",
                margin: "auto"
            }
        }}
      >
        <ContentModal>
            {modalOpenBankSlip?.imgContent ? (
                <img width="100%" src={modalOpenBankSlip?.imgContent} alt="" />
            ) : (
                <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'}/>
            )}
            <Button onClick={() => setModalOpenBankSlip({...modalOpenBankSlip, isOpen: false})} typeButton="outline" width="15%" label="Fechar" />
        </ContentModal>
      </ReactModal>
    </>
  )
}

export default MenuDesktop