/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import ReactLoading from 'react-loading';

import useAppSelector from '../../../hooks/useAppSelector';
import { PermissionsProps } from '../../../store/user';
import { getBannerApp } from '../../../store/actions/bannerPopupActions';
import useAppDispatch from '../../../hooks/useAppDispatch';


const BannerMobile: React.FC = () => {
  const dispatch = useAppDispatch();
  const [imagesBanners, setImagesBanners] = useState<Array<string>>([]);
  const { user: { permissions, tipoAgenda }, token } = useAppSelector(state => state.user)
  const { loadingBannerApp } =  useAppSelector(state => state.bannersPopup)


  const permissionsBannerApp = useMemo(() => {
    if(permissions) {
        const userPermissions: PermissionsProps = JSON.parse(permissions)
        return userPermissions[tipoAgenda as keyof PermissionsProps].banner_app
    }
    return null;
  },[permissions, tipoAgenda]);

  useEffect(() => {
    if (!!permissionsBannerApp?.bannerAppId?.length) {
      permissionsBannerApp.bannerAppId?.forEach((item) => {
        (async function () {
          const response = await dispatch(getBannerApp({bannerId: item}))
          setImagesBanners((prevState: string[]) => [...prevState, response.payload.imageUrl]);
        })()
      })
    } else {
      setImagesBanners([]);
    }
  },[permissionsBannerApp, token])

  return (
    <>
      {loadingBannerApp ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ReactLoading type="spin" color="#002868" height="fit-content" width="10%" />
        </div>
      ) : (
        <>
        {imagesBanners.length > 0 && (
          <>
            {imagesBanners.map((item, index) => (
              <img key={index} src={item} alt={`banner ${index}`} />
            ))}
          </>
        )}
        </>
      )}
    </>
  )
}

export default BannerMobile;
