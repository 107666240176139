import axios from 'axios'
import config from '../config'

export const findSaleTicket = async (nsu: any, authorizatioNumber: any, confirmationDate: any) => {

    const response = await axios.get(`${config.BASE_URL}/sales-dashboard/findTicketData?nsu=${nsu}&authorizatioNumber=${authorizatioNumber}&confirmationDate=${confirmationDate}`, {
        headers: {
            'x-api-key': config.API_KEY, 
            'authorization': `Bearer ${window.localStorage.getItem('token')}`
        },
    })
  
    return response?.data
}

