import styled, { css } from 'styled-components'
import { InputProps } from '.'

type WrapperProps = Pick<InputProps, 'width' | 'height' | 'value'> & {
  $error?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  ${({theme, width, height, value, $error}) => css`
    display: flex;
    flex-direction: column;
    text-align: start;

    label {
      margin-left: 0.5rem;
      color: ${theme.colors.textBlue};
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.xxsmall};
    }

    .content-input {
      width: ${width || '250px'};
      height: ${height || '48px'};
      position: relative;

      input {
        width: 100%;
        height: 100%;
        padding-left: 14px;
        border-radius: 8px;
        outline: none;
        color: ${theme.colors.blue};
        font-size: ${theme.font.sizes.xxsmall};
        font-family: ${theme.font.family};
        border: 1px solid ${theme.colors.blueBorder};
      }

      input:disabled {
        background-color: ${theme.colors.gray};
        opacity: 0.7;
      }

      input::placeholder {
        color: ${theme.colors.placeholder};
        font-family: ${theme.font.family};
        font-size: ${theme.font.sizes.xxsmall};
      }
      input::-webkit-input-placeholder {
        color: ${theme.colors.placeholder};
      }
      input:-ms-input-placeholder {
        color: ${theme.colors.placeholder};
      }

      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      p {
        font-family: ${theme.font.family};
        font-weight: ${theme.font.normal};
        font-size: ${theme.font.sizes.xxsmall};
        color: ${theme.colors.placeholder};
        margin-left: 2rem;
        margin-top: 0.5rem;
      }
    }
  `}
`

export const MessagegObservation = styled.span`
  ${({theme}) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.xxlsmall};
    color: ${theme.colors.placeholder};
    margin-left: 0.5rem;
  `}
`

export const MessageError = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.danger};
    font-size: ${theme.font.sizes.xxlsmall};
    padding-left: 0.5rem;
  `}
`