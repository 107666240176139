import { createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import api from '../../services/api';
import apiAdmin from '../../services/apiAdmin';
import { parseToFormattedDate } from '../../utils/functions';



export const getSales = createAsyncThunk(
  'sales/getSales',
  async (
    data: {
      offset: number,
      limit: number,
      startDate: string,
      endDate: string,
      terminal?: any,
      brands?: string,
      productName?: string | null,
    }
    , { rejectWithValue }) => {
    try {

      const response = await api.get(`/sales-dashboard/findAll?offset=${data.offset}&limit=${data.limit}&startDate=${data.startDate}&endDate=${data.endDate}&terminal=${data?.terminal ? data?.terminal : null}&brands=${data.brands ? data?.brands : ""}&productName=${data?.productName ? data?.productName : ""}&orderCol=confirmationDate&orderDir=desc`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})

export const getLastFiveSales = createAsyncThunk(
  'sales/getLastFiveSales',
  async (
    data: {
      schedule: 'MOURA' | 'LOJA',
      cacheLayer: '1' | '2'
    }
    , { rejectWithValue }) => {
    try {

      const response = await apiAdmin.get(`/sales/lastfive?schedule=${data.schedule}`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})

export const getSalesByPeriod = createAsyncThunk(
  'sales/getSalesByPeriod',
  async (
    data: {
      startDate: string,
      endDate: string,
      terminal: any
    }
    , { rejectWithValue }) => {
    try {

      const response = await api.get(`/salesPerDay?offset=0&limit=1000000&startDate=${data.startDate}&endDate=${data.endDate}&terminal=${data.terminal}`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})

export const getSalesOfTheDay = createAsyncThunk(
  'sales/getSalesOfTheDay',
  async (_, { rejectWithValue }) => {
    try {

      const today = parseToFormattedDate(dayjs() as unknown as Date)

      const response = await api.get(`/sales-dashboard/findAll?offset=0&limit=1000000&startDate=${today}&endDate=${today}&terminal=${null}&brands=&orderCol=confirmationDate&orderDir=desc`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})

export const getSalesOfTheMonth = createAsyncThunk(
  'sales/getSalesOfTheMonth',
  async (_, { rejectWithValue }) => {
    try {

      const date = new Date()
      const fistDayOfTheMonthFormated = parseToFormattedDate(new Date(date.getFullYear(), date.getMonth(), 1))
      const lastDayOfTheMonthFormated = parseToFormattedDate(new Date(date.getFullYear(), date.getMonth() + 1, 0))

      const response = await api.get(`/sales-dashboard/findAll?offset=0&limit=1000000&startDate=${fistDayOfTheMonthFormated}&endDate=${lastDayOfTheMonthFormated}&terminal=${null}&brands=&orderCol=confirmationDate&orderDir=desc`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})

export const getTerminals = createAsyncThunk(
  'sales/getTerminals',
  async (_, { rejectWithValue }) => {
    try {      

      const response = await api.get(`/terminal-dashboard/findAll`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})

export const getBrands = createAsyncThunk(
  'sales/getBrands',
  async (_, { rejectWithValue }) => {
    try {      

      const response = await api.get(`/sales-dashboard/brand`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error);
    }
})

export const exportSales = createAsyncThunk(
  'sales/exportSales',
  async (
    data: {
      startDate: string,
      endDate: string,
      terminal?: any,
      brands?: string,
      productName?: string | null,
    }
    , { rejectWithValue }) => {
    try {

      const response = await api.get(`/sales-dashboard/exportSales?startDate=${data?.startDate}&endDate=${data?.endDate}&terminal=${data?.terminal}&brands=${data.brands ? data?.brands : ""}&productName=${data?.productName ? data?.productName : ""}`)
      return response?.data
    } catch (error: any) {
      return rejectWithValue(error)
    }
})