import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api";

export const getPopupDesktop = createAsyncThunk(
  'bannersPopup/gerPopupDesktop',
  async(data: {
    popupId: number
  }, {rejectWithValue}) => {
    try {
      const response = await api.get(`/user/getBannerPopupById?item=popup&id=${data.popupId}`)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error)
    }
})

export const getPopupApp = createAsyncThunk(
  'bannersPopup/gerPopupApp',
  async(data: {
    popupId: number
  }, {rejectWithValue}) => {
    try {
      const response = await api.get(`/user/getBannerPopupById?item=popup_app&id=${data.popupId}`)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error)
    }
})

export const getBannerDesktop = createAsyncThunk(
  'bannersPopup/getBannerDesktop',
  async(data: {
    bannerId: number
  }, {rejectWithValue}) => {
    try {
      const response = await api.get(`/user/getBannerPopupById?item=banner&id=${data.bannerId}`)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error)
    }
})

export const getBannerApp = createAsyncThunk(
  'bannersPopup/getBannerApp',
  async(data: {
    bannerId: number
  }, {rejectWithValue}) => {
    try {
      const response = await api.get(`/user/getBannerPopupById?item=banner_app&id=${data.bannerId}`)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error)
    }
})