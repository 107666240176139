/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import useAppSelector from "../../../hooks/useAppSelector"
import ReactLoading from "react-loading"
import { useLocation, useNavigate } from "react-router-dom"
import useAppDispatch from "../../../hooks/useAppDispatch"
import { endOfWeek, startOfWeek } from "date-fns"


import { BiSolidUserCircle } from "react-icons/bi"
import Button from "../../Button/Button"
import { ContainerMobile, ContentSSwitchButtons, ContentSales, LogoMobile, NameUser } from "../styles"
import SwitchButtons from "../../SwitchButtons"
import { allowProfilesSwitchButtons, optionLabel, optionType, profiles, timeRange, timeRangeLabel } from "../../../utils/constants"
import { formatToCurrencyBRL, parseToFormattedDate } from "../../../utils/functions"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

import logoPropigWhite from '../../../assets/logo-propig-white.svg'
import logoCredMouraWhite from '../../../assets/logo-credmoura-white.svg'

import { getSales, getSalesOfTheDay, getSalesOfTheMonth } from "../../../store/actions/salesActions"
import { SalesItemType } from "../../../store/sales"
import dayjs from "dayjs"

interface Props {
  toogleModal: () => void
}

const HeaderMobile: React.FC<Props> = ({toogleModal}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation();

  const option = useAppSelector(state => state.switchButtons.option)
  const { user: { profile, loginType, name: longName}, token } = useAppSelector(state => state.user)
  const {
    loadingBalanceSalesOfTheDay,
    balanceSalesOfTheDay,
    balanceSalesOfTheMonth,
    loadingBalanceSalesOfTheMonth
  } = useAppSelector(state => state.sales)

  const date = new Date()
  const today = parseToFormattedDate(dayjs() as unknown as Date)
  const firstDayWeekFormated = parseToFormattedDate(startOfWeek(date, {weekStartsOn: 1}))
  const lastDayWeekFormated =  parseToFormattedDate(endOfWeek(date, {weekStartsOn: 1}))

  const [timeRangeCode, setTimeRangeCode] = useState(1)
  const [salesOfTheWeek, setSalesOfTheWeek] = useState(0)

  const firstName = useMemo(() => {
    return longName.split(" ")[0]
  },[longName])

  const salesMobile: {[k: string]: number} = useMemo(() => {
    return {
        day: balanceSalesOfTheDay,
        week: salesOfTheWeek,
        month: balanceSalesOfTheMonth
    }
  },[balanceSalesOfTheDay, salesOfTheWeek, balanceSalesOfTheMonth])

  const redirectToVendas = () => {
    navigate(`/vendas`)
  }

  const handleTimeRangeUp = () => {
    if (timeRangeCode !== 3) setTimeRangeCode(timeRangeCode + 1)
    if(timeRangeCode === 3) setTimeRangeCode(1)
  }

  const handleTimeRangeDown = () => {
      if (timeRangeCode !== 1) setTimeRangeCode(timeRangeCode - 1)
      if(timeRangeCode === 1) setTimeRangeCode(3)
  }

  useEffect(() => {
    ;(async function() {
       await dispatch(getSalesOfTheDay())
    })()

    ;(async function() {
        await dispatch(getSalesOfTheMonth())
    })()

    ;(async function() {
        const response = await dispatch(getSales({offset: 0, limit: 10000000, startDate: firstDayWeekFormated, endDate: lastDayWeekFormated, terminal: null}))
       if(response?.meta?.requestStatus === "fulfilled") {
           const confirmedSalesOfWeek = response?.payload?.rows?.filter((r : SalesItemType) => r.status === 'CONFIRMADA')
           const balanceSalesOfTheWeek = confirmedSalesOfWeek.reduce((acc: number, r: SalesItemType) => (acc + r.value), 0)
           setSalesOfTheWeek(balanceSalesOfTheWeek)
       }
    })()
  },[
      token,
      today,
      option,
      firstDayWeekFormated,
      lastDayWeekFormated,
  ])

  return (
    <>
    {location.pathname === '/home' && (
      <ContainerMobile>
        <div className='content-logo'>
          <LogoMobile
            src={option === optionType.LOJA ? logoPropigWhite : logoCredMouraWhite}
            alt='Logo'
          />
          <div className='content-user'>
            <div>
              <NameUser>Olá, </NameUser>
              <NameUser $bolder>{firstName}</NameUser>
            </div>
            {loginType && loginType !== 'FILIAL' && (
              <Button
                onClick={toogleModal}
                icon={<BiSolidUserCircle color='#FFFFFF' />}
              />
            )}
          </div>
        </div>
        <ContentSales>
            <Button onClick={handleTimeRangeDown} icon={<IoIosArrowBack color='#FFFFFF' />} />
            <div className='content-sales'>
                <Button
                    typeButton="outline"
                    onClick={redirectToVendas}
                    label={`Vendas ${timeRangeLabel[timeRangeCode]} ${profile === profiles.MULTI_AGENDA ? optionLabel[option as any] : ""}`}
                    width={"100%"}
                    padding="4px 18px"
                />
                {(loadingBalanceSalesOfTheDay || loadingBalanceSalesOfTheMonth)
                    ?
                        <ReactLoading type={'spin'} color={'#FFFFFF'} width={'10px'} height={'10px'}/>
                    :
                        <p>{formatToCurrencyBRL(salesMobile[timeRange[timeRangeCode]])}</p>
                }
            </div>
            <Button onClick={handleTimeRangeUp} icon={<IoIosArrowForward color='#FFFFFF' />} />
        </ContentSales>
        {allowProfilesSwitchButtons.includes(profile!) && (
            <ContentSSwitchButtons>
                <SwitchButtons/>
            </ContentSSwitchButtons>
        )}
      </ContainerMobile>

    )}
    </>
  )
}
export default HeaderMobile