import { createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import * as bannersPopupActions from './actions/bannerPopupActions'

interface InitialState {
  popupDesktop: {
    email: string,
    fileType: 'video' | 'image',
    imageUrl: string,
    link: string | null
    buttons: {
      email: string | null,
      link: string | null,
      text_button: string | null,
      type: 'E' |'L'
    }[]
  } | null
  loadingPopupDesktop: boolean

  popupApp: {
    email: string,
    fileType: 'video' | 'image',
    imageUrl: string,
    link: string | null
    buttons: {
      email: string | null,
      link: string | null,
      text_button: string | null,
      type: 'E' |'L'
    }[]
  } | null
  loadingPopupApp: boolean

  bannerDesktop: {
    email: string,
    fileType: 'video' | 'image',
    imageUrl: string,
    link: string | null
  } | null,
  loadingBannerDesktop: boolean,

  bannerApp: any,
  loadingBannerApp: boolean
}

const initialState: InitialState = {
  popupDesktop: null,
  loadingPopupDesktop: false,

  popupApp: null,
  loadingPopupApp: false,

  bannerDesktop: null,
  loadingBannerDesktop: false,

  bannerApp: [],
  loadingBannerApp: false

}

export const bannersPopupSlice = createSlice({
  name: 'bannersPopup',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isPending(bannersPopupActions.getPopupDesktop),
    (state) => {
      state.loadingPopupDesktop = true
    })
    builder.addMatcher(isFulfilled(bannersPopupActions.getPopupDesktop),
    (state, action) => {
      state.loadingPopupDesktop = false
      state.popupDesktop = action.payload
    })
    builder.addMatcher(isRejected(bannersPopupActions.getPopupDesktop),
    (state) => {
      state.loadingPopupDesktop = false
    })

    builder.addMatcher(isPending(bannersPopupActions.getPopupApp),
    (state) => {
      state.loadingPopupApp = true
    })
    builder.addMatcher(isFulfilled(bannersPopupActions.getPopupApp),
    (state, action) => {
      state.popupApp = action.payload
      state.loadingPopupApp = false
    })
    builder.addMatcher(isRejected(bannersPopupActions.getPopupApp),
    (state) => {
      state.loadingPopupApp = false
    })

    builder.addMatcher(isPending(bannersPopupActions.getBannerDesktop),
    (state) => {
      state.loadingBannerDesktop = true
    })
    builder.addMatcher(isFulfilled(bannersPopupActions.getBannerDesktop),
    (state, action) => {
      state.loadingBannerDesktop = false
      state.bannerDesktop = action.payload
    })
    builder.addMatcher(isRejected(bannersPopupActions.getBannerDesktop),
    (state) => {
      state.loadingBannerDesktop = false
    })

    builder.addMatcher(isPending(bannersPopupActions.getBannerApp),
    (state) => {
      
    })
    builder.addMatcher(isFulfilled(bannersPopupActions.getBannerApp),
    (state, action) => {

    })
    builder.addMatcher(isRejected(bannersPopupActions.getBannerApp),
    (state) => {

    })
  }
})

export default bannersPopupSlice.reducer