import React from 'react'
import vendasIcon from '../../assets/vendas-app.svg'
import recebimentosIcon from '../../assets/recebimentos-white-icon.svg'
import meusDadosIcon from '../../assets/meus-dados-white-icon.svg'
import salesIcon from "../../assets/sales-icon.svg"
import cobrancasIcon from "../../assets/cobrancas-icon-white.svg"
import { ContainerTitle, Image } from './styles'
import linkIcon from '../../assets/linkpagamento-icon-white.svg'
import { IoLocationOutline } from 'react-icons/io5'

interface Props {
  title: string
  lastUpdate?: string
}

const TitlePage: React.FC<Props> = ({ title, lastUpdate }) => {

  const handleIcon = (title: string) => {
    switch (title) {
      case "Recebimentos":
        return <Image src={recebimentosIcon} alt="Ícone título da página" />
      case "Meus Dados":
        return <Image src={meusDadosIcon} alt="Ícone título da página" />
      case "Link de Pagamento":
        return <Image src={linkIcon} alt="Ícone título da página" />
      case "Vendas":
        return <Image src={salesIcon} alt="Ícone título da página" />
      case "Cobranças":
          return <Image src={cobrancasIcon} alt="Ícone título da página" />
      case "Rastreio":
          return <IoLocationOutline color='#FFFFFF' size={30} />
      default:
        return vendasIcon
    }
  }

  return (
    <ContainerTitle>
      <div className='icon'>
        {handleIcon(title)}
      </div>
      <div>
        <p>{title}</p>
        {lastUpdate && (
          <span>Última atualização: {lastUpdate}</span>
        )}
      </div>
    </ContainerTitle>
  )
}

export default TitlePage;