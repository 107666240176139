import React, { useMemo, useState } from "react"
import useAppSelector from "../../../hooks/useAppSelector"
import { extractAmountOfInstallments, formatToCurrencyBRL, handleIconCard } from "../../../utils/functions"
import { Pagination } from "../../../components/Pagination/Pagination"
import dayjs from "dayjs"
import { ItemOperationProp } from "../../../store/types/TypesReceivements"
import { optionType } from "../../../utils/constants"
import Table2 from "../../../components/Table2"

interface Props {
  startDate: Date
  endDate: Date
  stepper: number
}

const TableCredtStatement: React.FC<Props> = ({
    stepper,
    startDate,
    endDate,
  }) => {

  const option = useAppSelector(state => state.switchButtons.option)
  const { operacoesArray, loadingOperacoes } = useAppSelector(state => state.receivements)

  const [offsetOperations, setOffsetOperations] = useState(0)
  const [pagePaginationOperations, setPagePaginationOperations] = useState(1)
  const [totalDataOperations, setTotalDataOperations] = useState(0)

  
  //MOURA: DADOS DA TABELA EXTRATO
  const dataTableExtract = useMemo(() => {
    const extract: ItemOperationProp[] = []
    const operationsFiltered = operacoesArray?.filter(
    (operation) => operation.date >= startDate && operation.date <= endDate
    )

    operationsFiltered?.forEach((item) => {
        extract.push(...item?.items)
    })
    setTotalDataOperations(extract?.length)

    return extract?.slice(offsetOperations, (10 * pagePaginationOperations))
    
  },[
    startDate,
    endDate,
    operacoesArray,
    offsetOperations,
    pagePaginationOperations
  ])

  return (
    <>
    {stepper === 1 && option === optionType.MOURA && (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem'
        }}
      >
        <Table2
          loading={loadingOperacoes}
          data={dataTableExtract}
          columns={[
            {
              key: 'ActualTransDate',
              header: 'Data de Lançamento',
              format: ({ActualTransDate, TransDate}) => dayjs((ActualTransDate ?? TransDate).toString().slice(0, 10)).format("DD/MM/YYYY")
            },
            {
              key: 'ActualTransDate',
              header: 'Data de Transação',
              format: ({ActualTransDate, TransDate}) => dayjs((ActualTransDate ?? TransDate).toString().slice(0, 10)).format("DD/MM/YYYY"),
            },
            {
              key: 'Descricao',
              header: 'Modalidade',
              format: ({Descricao, Valor}) => (
                <>
                  {Valor > 0 ?
                    <>
                      <img src={handleIconCard(Descricao)} alt="Card Icon" style={{marginRight: "5px", maxWidth: "40px"}} />
                      {extractAmountOfInstallments(Descricao).includes("x") && "Crédito "}
                      {extractAmountOfInstallments(Descricao)}
                    </>
                  :
                    'Debitado do Saldo CREDMOURA'
                  }
                </>
              )
            },
            {
              key: 'Valor',
              header: 'Valor',
              format: ({Valor}) => formatToCurrencyBRL(Valor)
            },
          ]}
        />

        {totalDataOperations > 0 && (
          <Pagination limit={10} offset={offsetOperations} total={totalDataOperations} setOffset={setOffsetOperations} manualPagination setPage={setPagePaginationOperations}/>
        )}
      </div>
    )}
    </>
  )
}

export default TableCredtStatement