/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { ContainerDetails, ContainerList, ContentBalance } from '../styles'
import Card from '../../../components/Card/Card'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import useAppSelector from '../../../hooks/useAppSelector'
import { optionType } from '../../../constants'
import { getEvent } from '../../../store/actions/receivementsActions'
import useAppDispatch from '../../../hooks/useAppDispatch'
import HeaderDetails from '../../../components/Header/Components/HeaderDetails'
import { formatToCurrencyBRL, parseToFormattedDate } from '../../../utils/functions'

interface TransactionProps {
    netValue: number,
    credit: number,
    debit: number,
    machine: number,
    receivementIn: number
}

const DetailsMobile = () => {

    const dispatch = useAppDispatch()
    const { search } = useLocation()
    const [_, date] = search.split('=')
    const option = useAppSelector(state => state.switchButtons.option)
    const { operacoesObject } = useAppSelector(state => state.receivements)
    const [transaction, setTransaction] = useState<TransactionProps>({ netValue: 0, credit: 0, debit: 0, machine: 0, receivementIn: 0}) 

    useEffect(() => {
        if(option === optionType.MOURA) {
            handleDataMoura()
        } else {
            handleDataLoja()
        }
    },[option])

    const handleDataMoura = () => {
        const value = operacoesObject[date].reduce((acc: any, cur: any) => acc + cur.Valor, 0)
   
        const transaction = {
            netValue: value,
            credit: operacoesObject[date].reduce((acc: any, cur: any) => {
                if (cur.Valor > 0) {
                    return acc + cur.Valor
                }
                return acc
            }, 0),
            debit: operacoesObject[date].reduce((acc: any, cur: any) => {
                if (cur.Valor < 0) {
                    return acc + cur.Valor
                }
                return acc
            }, 0),
            machine: 0,
            receivementIn: value > 0 ? value : 0,
        }

        setTransaction(transaction)
    }

    const handleDataLoja = async () => {
        const dateFilter = parseToFormattedDate(date as any)
        const response = await dispatch(getEvent({date: dateFilter}))
        if(response?.meta?.requestStatus === "fulfilled") {
            const transaction = {
                netValue: response?.payload[0].clientNetValue,
                credit: response?.payload[0].creditAjust,
                debit: response?.payload[0].debitAjust,
                machine: response?.payload[0].machineRental,
                receivementIn: response?.payload[0].clientNetValue + response?.payload[0].machineRental
            }

            setTransaction(transaction)
        }
    }

    return (
        <>
            <HeaderDetails title='Recebimento Detalhado' />
            {/* <RecebimentoDetails/> */}
            <ContainerDetails>
                <div className='content-card'>
                    <Card
                        title='Valor Líquido do Recebimento'
                        balance={transaction.receivementIn}
                        description={dayjs(date).locale('pt-br').format('DD/MM/YYYY')}
                        loading={false}
                        typeCard="tertiary"
                    />
                </div>
                <ContainerList>
                    <div className='row-Mobile'>
                        <span className='text-details'>Valor líquido do dia</span>
                        <span>{formatToCurrencyBRL(transaction.netValue)}</span>
                    </div>
                    <div className='row-details'>
                        <span className='text-details'>Ajuste a crédito</span>
                        <span>{formatToCurrencyBRL(transaction.credit)}</span>
                    </div>
                    <div className='row-details'>
                        <span className='text-details'>Ajuste a débito</span>
                        <span>{formatToCurrencyBRL(transaction.debit)}</span>
                    </div>
                    {option === optionType.LOJA && (
                        <div className='row-details'>
                            <span className='text-details'>Aluguel de máquinas</span>
                            <span>{formatToCurrencyBRL(transaction.machine)}</span>
                        </div>
                    )}
                </ContainerList>
                <ContentBalance>
                    {option === optionType.MOURA ? (
                        <div>
                            <span>Recebido em Saldo CREDMOURA</span>
                            <span>{formatToCurrencyBRL(transaction.receivementIn)}</span>
                        </div>
                    ) : (
                        <div>
                            <span>Recebido em Saldo</span>
                            <span>{formatToCurrencyBRL(transaction.receivementIn)}</span>
                        </div>
                    )}
                </ContentBalance>
            </ContainerDetails>
        </>
    )
}

export default DetailsMobile