import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { Provider } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './index.scss'

import App from './App'
import { ThemeProvider } from 'styled-components'
import Theme from './theme/theme'
import store from './store/configureStore'
import GlobalStyles from './theme/global'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  // </React.StrictMode>
)
