import React, { ReactNode, useEffect } from 'react'
import useAppSelector from '../../hooks/useAppSelector'
import { Container, Layout, RightBoard } from './styles'
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import useMedia from '../../hooks/useMedia'
import ButtonWhatsApp from '../../components/ButtonWhatsApp'
import useAppDispatch from '../../hooks/useAppDispatch'
import { onDemand } from '../../store/actions/userActions'

type LayoutProps = {
  children: ReactNode
}

const DashboardLayout = ({ children }: LayoutProps) => {
  const { showBoard } = useAppSelector((store) => store.board)
  const mobile = useMedia('(max-width: 40rem)')

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(onDemand())
  }, [dispatch])

  return (
    <>
      {mobile === true && (
        <>
          <Header />
          <Menu />
          {children}
          <ButtonWhatsApp />
        </>
      )}
      {mobile === false && (
        <Layout>
            <Header />
            <Menu />
            <RightBoard $showBoard={showBoard}>
              <Container>{children}</Container>
            </RightBoard>
            <ButtonWhatsApp />
          </Layout>
      )}
    </>
  )
}

export default DashboardLayout
