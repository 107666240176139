import React from "react"
import { IoLogoWhatsapp } from "react-icons/io";
import { ButtonWhatsAppWrapper } from './styles'
import useMedia from "../../hooks/useMedia";

const ButtonWhatsApp: React.FC = () => {
  const mobile = useMedia('(max-width: 40rem)')

  const redirectToWhatsApp = () => {
    window.open('https://api.whatsapp.com/send?phone=8132991248', '_blank')
  }
  return (
    <ButtonWhatsAppWrapper $isMobile={mobile} onClick={redirectToWhatsApp}>
      <IoLogoWhatsapp color="#2aad01" size={40}/>
    </ButtonWhatsAppWrapper>
  )
}

export default ButtonWhatsApp