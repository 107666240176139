/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import TitlePage from "../../components/TitlePage";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import {
  ContainerDesktop,
  Description,
  ContentFooter,
  ContainerModal,
} from "./styles";
import { useMemo } from "react";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { getClientAddress, getDetails, getBanks } from "../../store/actions/myDataActions";
import { CiPhone } from "react-icons/ci";
import ReactModal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { changePassword, requestContact } from "../../store/actions/userActions";
import { RiLockPasswordLine } from "react-icons/ri";
import PersonalDataForm from "./Forms/PersonalData";
import AddressForm from "./Forms/Address";
import BankDataForm from "./Forms/BankData";
import DistributorForm from "./Forms/Distributor";
import { TokenType } from "../../store/user";
import jwt_decode from 'jwt-decode'
import { profiles } from '../../constants'

type FormRequestContact = {
  nome: string;
  telefone: string;
}

type FormResetPassword = {
  oldPassword: string;
  newPassword: string;
  rewriteNewPassword: string;
}


const MeusDados = () => {
  const dispatch = useAppDispatch()
  const {token, user: { profile }, loadingChangePassword } = useAppSelector((state) => state.user)

  const { loadingDetails, loadingClientAddress, loadingBanks, details } = useAppSelector((state) => state.myData)

  const [modalRequestContact, setModalRequestContact] = useState(false)
  const [requestContactSuccess, setRequestContactSuccess] = useState(false)
  const [modalResetPassword, setModalResetPassword] = useState(false)
  const [viewOldPassword, setViewOldPassword] = useState(false)
  const [viewNewPassword, setViewNewPassword] = useState(false)
  const [viewRewritePassword, setViewRewritePassword] = useState(false)

  const {documento, documentoPai } = jwt_decode<{user: TokenType}>(token).user

  useEffect(() => {
    ;(async function() {
      await dispatch(getDetails())
    })()

    ;(async function() {
      await dispatch(getClientAddress())
    })()
    
    ;(async function() {
      await dispatch(getBanks())
    })()
  }, [token])

  const {
    register: registerRequestContact,
    handleSubmit: handleSubmitRequestContact,
    control: controlRequestContact,
    reset: resetRequestContact
    } = useForm({
    defaultValues: {
        nome: "",
        telefone: "",
    }
  });

  const {
    register: registerResetPassword,
    handleSubmit: handleSubmitResetPassword,
    reset: resetResetPassword,
    formState: { errors: errorsResetPassword },
    watch: watchResetPassword,
    setError: setErrorResetPassword,
    clearErrors: clearErrorsResetPassword
    } = useForm({
    defaultValues: {
        oldPassword: "",
        newPassword: "",
        rewriteNewPassword: ""
    }
  });

  const [newPassword, rewriteNewPassword] = watchResetPassword(["newPassword", "rewriteNewPassword"])


  const onSubmitRequestContact = async (data: FormRequestContact ) => {
    const body = {
        name: data?.nome,
        phone: data?.telefone?.match(/\d+/g)?.join("")
    }
    const response = await dispatch(requestContact(body))

    if(response?.meta?.requestStatus === "fulfilled") {
      setRequestContactSuccess(true)
    } else {
      toast.error("Problema ao atender solicitação")
    }
  }

  useEffect(() => {
    if(newPassword.length && rewriteNewPassword.length && newPassword !== rewriteNewPassword) {
      setErrorResetPassword("rewriteNewPassword", { message: "As senhas não são iguais!"})
      return
    }
    clearErrorsResetPassword("rewriteNewPassword")

  },[newPassword, rewriteNewPassword])

  const onSubmitResetPassword = async (data: FormResetPassword ) => {
    const body = {
        oldPassword: data?.oldPassword,
        newPassword: data?.newPassword
    }
    await dispatch(changePassword(body))

  }


  // const isPropig = useMemo(() => {
  //   return loginType === "PROPIG"
  // },[loginType])

  // const headTable = [
  //   {
  //     titleColumn: "Bandeira",
  //   },
  //   {
  //     titleColumn: "Débito",
  //   },
  //   {
  //     titleColumn: "Débito",

  //   },
  //   {
  //     titleColumn: "Débito",

  //   },
  //   {
  //     titleColumn: "Débito",
  //   },
  //   {
  //     titleColumn: "Débito",
  //   },
  // ]

  const handleCLoseModalRequestContact = () => {
    setModalRequestContact(false)
    setRequestContactSuccess(false)
    resetRequestContact({
        nome: "",
        telefone: ""
    })
}

  const handleCLoseModalResetPassword = () => {
    setModalResetPassword(false)
    resetResetPassword({
        oldPassword: "",
        newPassword: "",
        rewriteNewPassword: ""
    })
  }

  const loading = useMemo(() => {
    return loadingBanks && loadingClientAddress && loadingDetails
  }, [loadingBanks, loadingClientAddress, loadingDetails])

  const modalEstilo = {
    content: {
        width: '80%', // Defina a largura desejada do modal
        maxWidth: "500px",
        maxHeight: 'min-content', // Defina a altura máxima desejada do modal
        margin: 'auto', // Centralize o modal horizontalmente
        borderRadius: "15px",
        boxShadow: "0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.212)",
        borderColor: "#F6F6F6",
    }
  };

  return (
    <ContainerDesktop>
      <TitlePage title={"Meus Dados"}/>
      <PersonalDataForm
        name={details?.nome_contato}
        email={details?.email_contato}
        phone={details?.ddd_telefone_contato + details?.telefone_contato}
        loading={loading}
      />

      <AddressForm
        loading={loading}
      />

      {profile !== profiles.CESSAO && <BankDataForm
        account={details?.conta}
        accountType={details?.tipo_conta}
        bank={details?.banco}
        digit={details?.digito_conta}
        agency={details?.agencia}
        banks={[]}
        loading={loading}
      />}

      <DistributorForm
        cnpj={details?.distribuidor_cnpj}
        cep={details?.distribuidor_cep}
        address={details?.distribuidor_logradouro}
        city={details?.distribuidor_cidade}
        uf={details?.distribuidor_uf}
        razaoSocial={details?.distribuidor}
        phone={details?.distribuidor_telefone}
        loading={loading}
      />

      {/* <ShopkeepersForm
        loading={loading}
      /> */}

      {/* <Table headList={headTable} hasData={false} ></Table> */}

      <ContentFooter>
        <div className="description">
          <Description>Para dúvidas ou ajuste nos dados, fale conosco pelo número </Description>
          <Description>(81) 3299 -1248</Description>
        </div>
        <Button
          typeButton="outline"
          label="Solicite um contato agora"
          icon={<CiPhone color='#002868' />}
          onClick={() => setModalRequestContact(true)}
        />
        <Button
          width="200px"
          typeButton="outline"
          label="Alterar senha"
          icon={<RiLockPasswordLine color='#002868' />}
          disabled={documento !== documentoPai}
          onClick={() => setModalResetPassword(true)}
        />
      </ContentFooter>
      
      <ReactModal
          isOpen={modalRequestContact}
          onRequestClose={() => setModalRequestContact(false)}
          style={modalEstilo}
      >
          <ContainerModal>
              {!requestContactSuccess && <>
                <div className='content-head'>
                  <p>Preencha seus dados abaixo que entraremos em contato com você!</p>
                  <Button
                      icon={<AiOutlineCloseCircle color='#002868' />}
                      typeButton="outline"
                      noBorder
                      width='40px'
                      onClick={handleCLoseModalRequestContact}
                  />
                </div>
                <form onSubmit={handleSubmitRequestContact(onSubmitRequestContact)}>
                    <input placeholder="Nome" required {...registerRequestContact("nome")} />
                    <Controller
                        control={controlRequestContact}
                        name="telefone"
                        render={({field}) => <InputMask mask="(99) 99999-9999" placeholder="Telefone" {...field} />}
                    />
                    <Button width="50%" type='submit' label="Confirmar" typeButton="primary"/>
                </form>
              </>}

              {requestContactSuccess && <>
                <div className='content-head'>
                  <span>
                  </span>
                  <Button
                      icon={<AiOutlineCloseCircle color='#002868' />}
                      typeButton="outline"
                      noBorder
                      width='40px'
                      onClick={handleCLoseModalRequestContact}
                  />
                </div>
                <span>
                    Logo logo estaremos entrando em contato com você!
                  </span>
                <div style={{display: "flex", justifyContent: "center", marginTop: "2rem"}}>
                  
                  <Button width="50%" label="Ok" typeButton="primary" onClick={handleCLoseModalRequestContact} />
                </div>
              </>}
          </ContainerModal>
      </ReactModal>

      <ReactModal
          isOpen={modalResetPassword}
          onRequestClose={() => setModalResetPassword(false)}
          style={modalEstilo}
      >
          <ContainerModal>
              <div className='content-head' style={{alignItems: 'center', marginBottom: '1rem'}}>
                  <p style={{margin: '0'}}>Alterar senha</p>
                  <Button
                      icon={<AiOutlineCloseCircle color='#002868' />}
                      typeButton="outline"
                      noBorder
                      width='40px'
                      onClick={handleCLoseModalResetPassword}
                  />
              </div>
              <form onSubmit={handleSubmitResetPassword(onSubmitResetPassword)}>
                <div className='input-password'>
                    <input placeholder="Senha atual" required type={viewOldPassword ? "text" : "password"} {...registerResetPassword("oldPassword")} />
                    {viewOldPassword ? <BsEyeSlash color="#002868" onClick={() => setViewOldPassword(false)} /> : <BsEye color="#002868" onClick={() => setViewOldPassword(true)} />}
                </div>
                <div className='input-password'>
                    <input placeholder="Nova senha" required autoComplete="off" type={viewNewPassword ? "text" : "password"} {...registerResetPassword("newPassword")} />
                    {viewNewPassword ? <BsEyeSlash color="#002868" onClick={() => setViewNewPassword(false)} /> : <BsEye color="#002868" onClick={() => setViewNewPassword(true)} />}
                </div>
                <div className='input-password'>
                    <input placeholder="Reescreva a nova senha" required autoComplete="off" type={viewRewritePassword ? "text" : "password"} {...registerResetPassword("rewriteNewPassword")} />
                    {viewRewritePassword ? <BsEyeSlash color="#002868" onClick={() => setViewRewritePassword(false)} /> : <BsEye color="#002868" onClick={() => setViewRewritePassword(true)} />}
                </div>
                {errorsResetPassword.rewriteNewPassword?.message && <span className='message-error'>{errorsResetPassword.rewriteNewPassword?.message}</span>}
                <Button
                  width="50%"
                  type='submit'
                  label="Confirmar"
                  loading={loadingChangePassword}
                  disabled={!!errorsResetPassword.rewriteNewPassword?.message || loadingChangePassword}/>
              </form>
          </ContainerModal>
      </ReactModal>
    </ContainerDesktop>

  )
}

export default MeusDados;