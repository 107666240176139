/* eslint-disable no-useless-escape */
/* eslint-disable no-empty-pattern */
import * as receivementsActions from './actions/receivementsActions'
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit'
import {  ReceivementsProps } from './types/TypesReceivements';
import { formatDate, formatToCurrencyBRL, parseOperationsToArray } from '../utils/functions';

export interface Events {
  antecipation: number,
  clientNetValue: number,
  dailyReceivement: number,
  netValue: number,
  receivementDate: string,
  sub: string
}

const initialState: ReceivementsProps = {
  balanceCredmoura: 0,
  loadingBalanceCredmoura: false,
  balanceCreditToRelease: 0,
  loadingBalanceCreditToRelease: false,
  balanceOpenBankSlip: 0,
  loadingBalanceOpenBankSlip: false,

  operacoesArray: [],
  operacoesObject: {},
  loadingOperacoes: false,

  receivements: [],
  loadingReceivements: false,
  loadingLastFiveReceivements: false,

  balanceReceivementsOfTheDay: 0,
  loadingBalanceReceivementsOfTheDay: false,

  balanceReceivementsOfTheMonth: 0,
  loadingBalanceReceivementsOfTheMonth: false,

  balanceReceivementsByPeriod: 0,
  loadingBalanceReceivementsByPeriod: false,

  credits: {
    rows: [],
    total: 0,
    limit: 10,
    offset: 0
  },
  loadingCredits: false,

  eventsReceivementsByDay: [],
  loadingEventsReceivementsByDay: false,

  receivementsByDay: {
    rows: [],
    total: 0,
    limit: 10,
    offset: 0
  },
  loadingReceivementsByDay: false,

  receivementsByTransaction: {
    rows: [],
    total: 0,
    limit: 10,
    offset: 0
  },
  loadingReceivementsByTransaction: false,

  lastFiveReceivements: [],
  lastFiveReceivementsScheduleRequested: "MOURA",

  loadingReceivementsMobileLoja: false,

  loadingNsuReceivements: false,
  arrayNsuReceivements: [],

  receivementsByDayAndNSU: []
}

export const receivementsSlice = createSlice({
  name: 'receivements',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(isPending(receivementsActions.getBalanceCredmoura),
      (state) => {
        state.loadingBalanceCredmoura = true
        state.loadingOperacoes = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getBalanceCredmoura),
      (state, action) => {
        state.loadingBalanceCredmoura = false
        state.loadingOperacoes = false
        state.balanceCredmoura = action.payload?.SaldoFinalTotal
        state.operacoesArray = parseOperationsToArray(action.payload?.Operacoes)
        state.operacoesObject = action.payload?.Operacoes
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getBalanceCredmoura),
      (state) => {
        state.loadingBalanceCredmoura = false
        state.loadingOperacoes = false
      }
    )

    builder.addMatcher(isPending(receivementsActions.getBalanceCreditsToRelease),
      (state) => {
        state.loadingBalanceCreditToRelease = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getBalanceCreditsToRelease),
      (state, action) => {
        state.loadingBalanceCreditToRelease = false
        state.balanceCreditToRelease = action.payload
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getBalanceCreditsToRelease),
      (state) => {
        state.loadingBalanceCreditToRelease = false
      }
    )

    builder.addMatcher(isPending(receivementsActions.getBalanceOpenBankSlip),
      (state) => {
        state.loadingBalanceOpenBankSlip = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getBalanceOpenBankSlip),
      (state, action) => {
        state.loadingBalanceOpenBankSlip = false
        state.balanceOpenBankSlip = action.payload
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getBalanceOpenBankSlip),
      (state) => {
        state.loadingBalanceOpenBankSlip = false
      }
    )

    builder.addMatcher(isPending(receivementsActions.getReceivements),
      (state) => {
        state.loadingReceivements = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getReceivements),
      (state, action) => {
        state.loadingReceivements = false
        state.receivements = action.payload?.rows
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getReceivements),
      (state) => {
        state.loadingReceivements = false
      }
    )
    
    builder.addMatcher(isPending(receivementsActions.getReceivementsOfTheDay),
      (state) => {
        state.loadingBalanceReceivementsOfTheDay = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getReceivementsOfTheDay),
      (state, action) => {
        state.loadingBalanceReceivementsOfTheDay = false
        state.balanceReceivementsOfTheDay = action.payload
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getReceivementsOfTheDay),
      (state) => {
        state.loadingBalanceReceivementsOfTheDay = false
      }
    )

    builder.addMatcher(isPending(receivementsActions.getReceivementsOfTheMonth),
      (state) => {
        state.loadingBalanceReceivementsOfTheMonth = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getReceivementsOfTheMonth),
      (state, action) => {
        state.loadingBalanceReceivementsOfTheMonth = false
        state.balanceReceivementsOfTheMonth = action.payload
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getReceivementsOfTheMonth),
      (state) => {
        state.loadingBalanceReceivementsOfTheMonth = false
      }
    )

    builder.addMatcher(isPending(receivementsActions.getReceivementsByPeriod),
      (state) => {
        state.loadingBalanceReceivementsByPeriod = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getReceivementsByPeriod),
      (state, action) => {
        state.loadingBalanceReceivementsByPeriod = false
        state.balanceReceivementsByPeriod = action.payload
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getReceivementsByPeriod),
      (state) => {
        state.loadingBalanceReceivementsByPeriod = false
      }
    )

    builder.addMatcher(isPending(receivementsActions.getCredits),
      (state) => {
        state.loadingCredits = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getCredits),
      (state, action) => {
        state.loadingCredits = false
        state.credits.limit = Number(action.payload?.limit)
        state.credits.offset = Number(action.payload?.offset)
        state.credits.total = Number(action.payload?.total)
        state.credits.rows = action.payload?.rows
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getCredits),
      (state) => {
        state.loadingCredits = false
      }
    )

    builder.addMatcher(isPending(receivementsActions.getEventsByDayReceivements),
      (state) => {
        state.loadingEventsReceivementsByDay = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getEventsByDayReceivements),
      (state, action) => {
        state.loadingEventsReceivementsByDay = false
        const events = action.payload?.map((item: Events) => {
          return {
              title: formatToCurrencyBRL(item?.dailyReceivement),
              date: formatDate(item?.receivementDate),
              backgroundColor: item?.dailyReceivement > 0 ? "#0033AB" : "#DC3737"
          }
      })
        state.eventsReceivementsByDay = events
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getEventsByDayReceivements),
      (state) => {
        state.loadingEventsReceivementsByDay = false
      }
    )

    builder.addMatcher(isPending(receivementsActions.getReceivementsByDay),
      (state) => {
        state.loadingReceivementsByDay = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getReceivementsByDay),
      (state, action) => {
        state.loadingReceivementsByDay = false
        state.receivementsByDay.limit = Number(action.payload?.limit)
        state.receivementsByDay.offset = Number(action.payload?.offset)
        state.receivementsByDay.total = Number(action.payload?.total)
        state.receivementsByDay.rows = action.payload?.rows
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getReceivementsByDay),
      (state) => {
        state.loadingReceivementsByDay = false
      }
    )

    builder.addMatcher(isPending(receivementsActions.getReceivementsByTransaction),
      (state) => {
        state.loadingReceivementsByTransaction = true
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getReceivementsByTransaction),
      (state, action) => {
        state.loadingReceivementsByTransaction = false
        state.receivementsByTransaction.limit = Number(action.payload?.limit)
        state.receivementsByTransaction.offset = Number(action.payload?.offset)
        state.receivementsByTransaction.total = Number(action.payload?.total)
        state.receivementsByTransaction.rows = action.payload?.rows
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getReceivementsByTransaction),
      (state) => {
        state.loadingReceivementsByTransaction = false
      }
    )
    builder.addMatcher(isPending(receivementsActions.getLastFiveReceivements),
      (state, data) => {
        if (data.meta.arg.cacheLayer === '2') {
          state.loadingLastFiveReceivements = true
        }
        state.lastFiveReceivementsScheduleRequested = data.meta.arg.schedule;
      }
    )
    builder.addMatcher(isFulfilled(receivementsActions.getLastFiveReceivements),
      (state, action) => {
        if (state.lastFiveReceivementsScheduleRequested !== action.meta.arg.schedule) {
          return;
        }
        state.loadingLastFiveReceivements = false
        state.lastFiveReceivements = action.payload
      }
    )
    builder.addMatcher(isRejected(receivementsActions.getLastFiveReceivements),
      (state) => {
        state.loadingLastFiveReceivements = false
        // state.lastFiveReceivements = []
      }
    )

    builder.addMatcher(isPending(receivementsActions.getReceivementsMobileLoja),
    (state) => {
      state.loadingReceivementsMobileLoja = true
    }
  )
  builder.addMatcher(isFulfilled(receivementsActions.getReceivementsMobileLoja),
    (state, action) => {
      state.loadingReceivementsMobileLoja = false
    }
  )
  builder.addMatcher(isRejected(receivementsActions.getReceivementsMobileLoja),
    (state) => {
      state.loadingReceivementsMobileLoja = false
    }
  )

  builder.addMatcher(isPending(receivementsActions.getNsuReceivements),
    (state) => {
      state.loadingNsuReceivements = true
    }
  )
  builder.addMatcher(isFulfilled(receivementsActions.getNsuReceivements),
    (state, action) => {
      state.loadingNsuReceivements = false
      state.arrayNsuReceivements = action.payload
    }
  )
  builder.addMatcher(isRejected(receivementsActions.getNsuReceivements),
    (state) => {
      state.loadingNsuReceivements = false
    }
  )

  builder.addMatcher(isPending(receivementsActions.getReceivementsByDateAndNSU), (state, data) => {

    state.receivementsByDayAndNSU.map(receivement => receivement.open = false)

    if (state.receivementsByDayAndNSU.some(date => date.day === data.meta.arg.date.replace(/([^\/]*)\/([^\/]*)\/([^\/]*)/g, '$3-$2-$1') && date.schedule === data.meta.arg.schedule)) {
      return
    }
    
    state.receivementsByDayAndNSU.push({
      day: data.meta.arg.date.replace(/([^\/]*)\/([^\/]*)\/([^\/]*)/g, '$3-$2-$1'),
      loading: true,
      open: data.meta.arg.open,
      receivements: [],
      schedule: data.meta.arg.schedule
    })
  })

  builder.addMatcher(isFulfilled(receivementsActions.getReceivementsByDateAndNSU), (state, data) => {
    if (data.payload) {
      state.receivementsByDayAndNSU.find(receivement => data.meta.arg.date === receivement.day && data.meta.arg.schedule === receivement.schedule)!.receivements = data.payload
    }

    state.receivementsByDayAndNSU.map(receivement => receivement.open = false)
    state.receivementsByDayAndNSU.find(receivement => data.meta.arg.date === receivement.day && data.meta.arg.schedule === receivement.schedule)!.loading = false
    
    if (data.payload && data.payload.length === 0) {
      return;
    }
  
    state.receivementsByDayAndNSU.find(receivement => data.meta.arg.date === receivement.day && data.meta.arg.schedule === receivement.schedule)!.open = data.meta.arg.open
  })

  },
  
})

export const { } = receivementsSlice.actions

export default receivementsSlice.reducer