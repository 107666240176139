import React from "react"
import { WrapperFooter } from "../styles"
import Button from "../../../components/Button/Button"
import { CiPhone } from "react-icons/ci"
import useAppDispatch from "../../../hooks/useAppDispatch"
import { handleOpenModal } from "../../../store/modals"

const Footer = () => {
  const dispatch = useAppDispatch()

  const openModal = () => {
    dispatch(handleOpenModal('Solicite um contado'))
  }

  return (
    <WrapperFooter>
      <span className="description">
        Para dúvidas ou ajuste nos dados, fale conosco pelo número <strong>(81) 3299 -1248</strong>
      </span>
      <Button
        typeButton="outline"
        label="Solicite um contato agora"
        icon={<CiPhone color='#002868' />}
        onClick={openModal}
      />
    </WrapperFooter>
  )
}

export default Footer