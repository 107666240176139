/* eslint-disable no-empty-pattern */
/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as myDataActions from './actions/myDataActions'
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit'

export interface AddressNContact {
  address: string,
  city: string,
  country: string,
  distributor: boolean,
  email: string,
  phoneNumber: string,
  state: string,
  zipCode: string,
}

export interface MyDataProps {
  loadingClientAddress: boolean,
  loadingDetails: boolean,
  loadingBanks: boolean,
  loadingAddressNContact: boolean,
  addressNContact: AddressNContact | null,
  loadingShopkeeperMCC: boolean,
  mcc: number | null,
  details: {
    agencia: string,
    banco: string,
    conta: string,
    ddd_telefone_contato: string,
    digito_conta: string,
    distribuidor: string,
    distribuidor_cep: string,
    distribuidor_cidade: string,
    distribuidor_cnpj: string,
    distribuidor_logradouro: string,
    distribuidor_uf: string,
    distribuidor_telefone: string,
    email_contato: string,
    nome_contato: string,
    telefone_contato: string,
    tipo_conta: string,
  },
  clienteAddress: {
    bairro: string,
    cep: string,
    cidade: string,
    documento: string,
    estado: string,
    id: number
    logradouro: string,
    nome: string,
    numero: string,
  },
  banks: Array<{
    label: string,
    value: number
  }>
}

const initialState: MyDataProps = {
  loadingDetails: false,
  loadingClientAddress: false,
  loadingBanks: false,
  loadingAddressNContact: false,
  addressNContact : null,
  loadingShopkeeperMCC: false,
  mcc: null,
  details: {
    agencia: "",
    banco: "",
    conta: "",
    ddd_telefone_contato: "",
    digito_conta: "",
    distribuidor: "",
    distribuidor_cep: "",
    distribuidor_cidade: "",
    distribuidor_cnpj: "",
    distribuidor_logradouro: "",
    distribuidor_uf: "",
    distribuidor_telefone: "",
    email_contato: "",
    nome_contato: "",
    telefone_contato: "",
    tipo_conta: "",
  },
  clienteAddress: {
    bairro: "",
    cep: "",
    cidade: "",
    documento: "",
    estado: "",
    id: 0,
    logradouro: "",
    nome: "",
    numero: "",
  },
  banks: []
}

export const myDataSlice = createSlice({
  name: 'myData',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addMatcher(isPending(myDataActions.getDetails),
      (state) => {
        state.loadingDetails = true
      }
    )
    builder.addMatcher(isFulfilled(myDataActions.getDetails),
      (state, action) => {
        state.loadingDetails = false
        state.details = action.payload
      }
    )
    builder.addMatcher(isRejected(myDataActions.getDetails),
      (state) => {
        state.loadingDetails = false
      }
    ),

    builder.addMatcher(isPending(myDataActions.getClientAddress),
      (state) => {
        state.loadingClientAddress = true
      }
    )
    builder.addMatcher(isFulfilled(myDataActions.getClientAddress),
      (state, action) => {
        state.loadingClientAddress = false
        state.clienteAddress = action.payload
      }
    )
    builder.addMatcher(isRejected(myDataActions.getClientAddress),
      (state) => {
        state.loadingClientAddress = false
      }
    ),

    builder.addMatcher(isPending(myDataActions.getBanks),
      (state) => {
        state.loadingBanks = true
      }
    )
    builder.addMatcher(isFulfilled(myDataActions.getBanks),
      (state, action) => {
        state.loadingBanks = false
        state.banks = action.payload
      }
    )
    builder.addMatcher(isRejected(myDataActions.getBanks),
      (state) => {
        state.loadingBanks = false
      }
    )

    builder.addMatcher(isPending(myDataActions.getAddressNContact),
    (state) => {
      state.loadingAddressNContact = true
    }
    )
    builder.addMatcher(isFulfilled(myDataActions.getAddressNContact),
      (state, action) => {
        state.loadingAddressNContact = false
        state.addressNContact = action.payload
      }
    )
    builder.addMatcher(isRejected(myDataActions.getAddressNContact),
      (state) => {
        state.loadingAddressNContact = false
      }
    )
    
    builder.addMatcher(isPending(myDataActions.getShopkeeperMCC),
    (state) => {
      state.loadingShopkeeperMCC = true
    }
    )
    builder.addMatcher(isFulfilled(myDataActions.getShopkeeperMCC),
      (state, action) => {
        state.loadingShopkeeperMCC = false
        state.mcc = action.payload.mcc
      }
    )
    builder.addMatcher(isRejected(myDataActions.getShopkeeperMCC),
      (state) => {
        state.loadingShopkeeperMCC = false
      }
    )
  },
})

export const {  } = myDataSlice.actions

export default myDataSlice.reducer