import axios from "axios"
import config from "../config"

export const paymentLinks = async (status: 'PAID' | 'NOTPAID', page: number, token: any, document: string) => {
  const response = await axios.get(`${config.PAYMENT_LINK_API_URL}/v1/links/${document}?pagina=${page}&itens_por_pagina=0${status === 'PAID' ? '&pago=true' : '&pago=false'}`, {
      headers: {
          'authorization': `Bearer ${window.localStorage.getItem('token')}`
      },
  })

  return response?.data
}

export const getShopkeeperMCC = async () => {
  const token = window.localStorage.getItem('token')
  const response = await axios.get(`${config.BASE_URL}/client/shopkeeperMccCode`, {
    headers: {
      'x-api-key': config.API_KEY, 
      'authorization': `Bearer ${token}`
    }
  })

  return response.data.mcc;
}

export const getAddressNContact = async () => {
  const token = window.localStorage.getItem('token')
  const response = await axios.get(`${config.BASE_URL}/client/shopkeeperAddressAndContact`, {
    headers: {
      'x-api-key': config.API_KEY, 
      'authorization': `Bearer ${token}`
    }
  })

  return response.data;
}

export const generatePaymentLink = async (amount: number, installments: number, title: string, amount_per_installment: number, client_document: string, nome_lojista: string, descricao_cliente: string, addressNContact: any, mcc: string ) => {  
  const response = await axios.post(`${config.PAYMENT_LINK_API_URL}/v1/links/`, {
    amount,
    installments,
    title,
    amount_per_installment,
    client_document,
    nome_lojista,
    descricao_cliente: `${descricao_cliente}`,
    endereco: addressNContact.address,
    cidade: addressNContact.city,
    uf: addressNContact.state,
    pais: addressNContact.country,
    cep: addressNContact.zipCode,
    telefone: addressNContact.phoneNumber,
    email: addressNContact.email,
    merchant_category_code: mcc
  },
  {
    headers: {
        'authorization': `Bearer ${window.localStorage.getItem('token')}`
    },
  })

  return response.data.link
}