import React from 'react'
import { WrapperItem } from '../styles'
import Button from '../../../components/Button/Button'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { formatCNPJ } from '../../../utils/functions'

interface Props {
  item: {
    nome: string
    documento: string
    pedido: string
  }
  setId(arg: string): void
}

const Item: React.FC<Props> = ({ item, setId }) => {
  return (
    <WrapperItem>
      <div className='content-data'>
        <h2 className='name'>{item?.nome?.toLocaleUpperCase()}</h2>
        <h3 className='cnpj'>{formatCNPJ(item?.documento)}</h3>
      </div>
      <Button
        width='20%'
        icon={<IoDocumentTextOutline />}
        label='Nota Fiscal'
        onClick={() => setId(item?.pedido)}
      />
    </WrapperItem>
  )
}

export default Item