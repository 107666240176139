import React, { ReactNode } from 'react'
import DashboardLayout from '../template/Dashboard'
import { Navigate } from 'react-router-dom'

interface Props {
  isAutheticated?: boolean
  redirectPath?: string
  havePermission?: boolean
  children: ReactNode
}

const RouteGuard: React.FC<Props> = ({
  isAutheticated,
  redirectPath = '/login',
  havePermission = true,
  children,
}) => {

  if (!isAutheticated) {
    return <Navigate to={redirectPath} replace />
  } else{
    if(havePermission) {
      return <DashboardLayout>{children}</DashboardLayout> 
    }
    return <Navigate to={redirectPath} replace />
  }

}

export default RouteGuard