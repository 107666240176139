import DatePicker from 'react-datepicker'
import {BsCalendarCheck} from 'react-icons/bs'
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs"
// import './styles.scss'
import React from 'react';
import { ButtonDatePicker, ContentDatePicker } from './styles';
import useMedia from '../../hooks/useMedia';
import { useLocation } from 'react-router-dom';

const DatePickerRange: React.FC<any> = ({toogleDate, endDate, startDate, onChange, dateOpen, style, onClickOutside}) => {
  const mobile = useMedia('(max-width: 40rem)')
  const location = useLocation()

  const beforeYesterday = dayjs().locale('pt-br').subtract(1, 'day').toDate()
  const today = dayjs().locale('pt-br').toDate()

  return (
    <ButtonDatePicker style={{...style}}>
      <div className='label' onClick={toogleDate}>
          <BsCalendarCheck />
          <span>{dayjs(startDate?.toString()).format('DD/MM/YYYY')} - {dayjs(endDate?.toString()).format('DD/MM/YYYY')}</span>
      </div>
        {dateOpen &&
          <ContentDatePicker $isMobile={mobile}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                locale="pt"
                selectsRange
                inline
                maxDate={location.pathname === '/recebimentos' ? beforeYesterday : today}
                // onClickOutside={onClickOutside}
              />
          </ContentDatePicker>}
    </ButtonDatePicker>
  )
}

export default DatePickerRange