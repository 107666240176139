import React, { ReactNode } from 'react'
// import questionIcon from '../../assets/question-icon.svg'
import ReactLoading from 'react-loading'
import './styles.scss'

type TableProps = {
  headList: any,
  hasData?: boolean,
  loading?: boolean,
  children?: ReactNode
  maximize?: boolean
}

const Table: React.FC<TableProps> = ({headList, hasData, loading,children, maximize}) => {
  return (
    <div className='col-md-12 table-responsive'>
      <table className='w-100'>
        <thead>
          <tr>
            {headList?.map((item: any, index: any) => (
              <React.Fragment key={index}>
                {item?.subColumn?.length > 0 ? (
                  <th scope="col" colSpan={2} style={{minWidth: maximize ? 'auto' : '130px'}}>
                    {/* <img className='me-1' src={questionIcon} alt="" /> */}
                    {item?.titleColumn}
                  </th>
                ) : (
                  <th scope="row" rowSpan={2} style={{minWidth: maximize ? 'auto' : '130px'}}>
                    {/* <img className='me-1' src={questionIcon} alt="" /> */}
                    {item?.titleColumn}
                  </th>
                )}
              </React.Fragment>
            ))}
          </tr>
          <tr>
              {headList?.map((item: any, index: any) => (
                <React.Fragment key={index}>
                  {item?.subColumn?.length > 0 && (
                    <>
                      {item?.subColumn?.map((subColumn: any, indexSubColumn: any) => (
                        <th key={indexSubColumn} scope="col" style={{minWidth: maximize ? 'auto' : '130px'}}>{subColumn}</th>
                      ))}
                    </>
                  )}
                </React.Fragment>
              ))}
          </tr>
        </thead>
        <tbody>
          {!loading && (
            <>
              {children}
            </>
          )}
        </tbody>
      </table>
      {(!hasData && !loading) && (
        <div className='message-not-found'>
          Não foram encontrados registros para o período selecionado!
        </div>
      )}
      {loading && (
        <div className='loading-content'>
          <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'5%'}/>
        </div>
      )}
  </div>
  )
}

export default Table