/**
 * Do not edit directly
 * Generated on Mon, 04 Sep 2023 00:25:45 GMT
 */

export const ColorBaseGrayMedium = "#767676";
export const ColorBaseBlueLight = "#5a6f88";
export const ColorBaseBlueDark = "#0033ab";
export const ColorBaseBlueDark2 = "#002868";
export const ColorPaymentStatusPaid = "#0033ab";
export const ColorPaymentStatusNotPaid = "#ab0000";
