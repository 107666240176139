/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { ContainerForm, ContentForm, ContentTitle, Row, Title } from "../styles"
import Button from "../../../components/Button/Button"
import { useForm } from "react-hook-form";
import { getBanks, updateBankData } from "../../../store/actions/myDataActions";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import ReactLoading from "react-loading";
import { ColorBaseBlueDark2 } from "../../../theme/tokens";
import check from "../../../assets/check_hight.png"

export const UpdatebankData: React.FC<{
  onClose: () => void;
}> = ({onClose}) => {
  const dispatch = useAppDispatch()
  const {token } = useAppSelector((state) => state.user)
  const { banks } = useAppSelector((state) => state.myData)
  const [sendingData, SetSendingData] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch
  } = useForm({
    defaultValues: {
      bank: "",
      accountType: "",
      agency: "",
      account: "",
      digit: "",
      filename: ""
    }
  });

  const onSubmit = async (data: any) => {
    if (data.agency.match(/[a-zA-Z]/g) !== null) {
      setFormProblem("Agência inválida")
      return;
    }

    SetSendingData(true)

    try {
      await dispatch(updateBankData({
        accountDigit: data.digit,
        accountNumber: data.account,
        accountType: data.accountType,
        agency: data.agency,
        bankName: data.bank,
        document: (document.getElementById('comprovanteFile')! as any).files[0]
      }))
      setSuccess(true)
    } catch (e) {

    }
    SetSendingData(false)
  }

  useEffect(() => {
    ;(async function() {
      await dispatch(getBanks())
    })()
  }, [token])

  useEffect(() => {
    document.getElementById('comprovanteFile')!.onchange = (e => {
      setValue("filename", (e.target as any).value.replace(/.*\\(.*)/g, "$1"))
    })
  }, [])

  const [formProblem, setFormProblem] = useState("");
  const canSubmit = useMemo(() => {
      return getValues("agency") !== "" && getValues("bank") !== "" && getValues("accountType") !== "" && getValues("account") !== "" && getValues("filename") !== ""
  }, [watch(), getValues])

  return (<>
  <ContainerForm style={{flexDirection: "column"}}>
    <ContentTitle style={{width: "90%"}}>
      <Title>Solicitação de alteração de dados dados bancários</Title>
    </ContentTitle>
    {success && <>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", padding: "5rem 0", flexDirection: "column"}}>
          <img src={check} alt="check" />
          <br />
          <br />
          <p style={{color: ColorBaseBlueDark2, fontSize: "14px"}}>Alteração solicitada com sucesso! Dentro dos próximos dias iremos analisar e dar prosseguimento à sua solicitação</p>
      </div>
    </>}
    {sendingData && <>
      <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'}/>
      <br />
      Aguarde, enviando dados
      </>}
    <ContentForm style={{width: "90%", marginTop: "3rem", display: (sendingData || success) ? "none" : "unset"}}>
      <form onSubmit={handleSubmit(onSubmit)} >
          <Row>
            <select placeholder="Escolha seu banco" {...register("bank")} style={{backgroundColor: "white"}}>
              <option value="">Escolha seu banco</option>
              {banks?.map((bank: any, index: number) => (
                <option key={index} value={bank?.label}>{bank?.label}</option>
              ))}
            </select>
          </Row>
          <Row>
          <select placeholder="Tipo de conta" {...register("accountType")} style={{backgroundColor: "white"}}>
              <option value="">Tipo de conta</option>
              <option value="POUPANCA">Poupança</option>
              <option value="CORRENTE">Corrente</option>
            </select>
          </Row>
          <Row>
            <input placeholder="Agência" {...register("agency")} />
            <input placeholder="Conta" {...register("account")} />
            <input placeholder="Dig." {...register("digit")} />
          </Row>
          <Row>
            <div style={{display: "flex", width: "100%"}}>
              <input accept="image/jpeg, image/png" type="file" id="comprovanteFile" style={{display: "none"}} />
              <input placeholder="Comprovante" disabled {...register("filename")} style={{width: "70%", borderRadius: "10px 0 0 10px"}}/>
              <Button typeButton="primary" label="Selecionar" style={{width: "30%", borderRadius: "0 10px 10px 0"}} onClick={() => {document.getElementById('comprovanteFile')!.click()}} />
            </div>
          </Row>
          <br />
          {formProblem !== "" && <Row style={{display: "inline", textAlign: "right"}}>
            <span style={{color: "red", fontSize: "12px"}}>{formProblem}</span>
          </Row>}
          <div className="content-buttons" style={{marginTop: "4rem"}}>
            <Button typeButton="outline" label="Cancelar" onClick={() => {onClose()}} />
            <Button type="submit" typeButton="primary" label="Enviar solicitação" disabled={!canSubmit} />
          </div>
        </form>
        </ContentForm>
      </ContainerForm>
  </>)
}