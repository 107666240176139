/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { paymentLinks as paymentsLinksRequest } from "../../services/paymentlinks"
import { DateTime } from "luxon";
import useMedia from '../../hooks/useMedia'
import LinkPagamentoDesktop from './Components/Desktop'
import LinkPagamentoMobile from './Components/Mobile'
import { exportAsExcelFile, formatToCurrencyBRL } from '../../utils/functions';

export interface PaymentsLinksProps {
    attemp: boolean
    dataCriacao: string
    dataAtualizacao: string
    description: string
    link: string
    nsu: string
    payments: number
    title: string
    value: number
    status: 'PAID' | 'EXPIRED' | 'PENDING',
}

export interface Props {
    data: PaymentsLinksProps[]
    loading: boolean
  
    currentPage: number
    totalItems: number
    setCurrentPage(arg: number): void

    lastUpdate?: string

    statusFilter: 'PAID' | 'NOTPAID'
    setStatusFilter(arg: 'PAID' | 'NOTPAID'): void

    exportS(): void
    fetchData(): void
  }

const LinksDePagamento = () => {
    const mobile = useMedia('(max-width: 40rem)')
    const [statusFilter, setStatusFilter] = useState<'PAID' | 'NOTPAID'>('PAID');
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false)
    const [paymentLinks, setPaymentLinks] = useState<PaymentsLinksProps[]>([])
    const [rawPaymentLinks, setRawPaymentLinks] = useState([])

    async function fetchData() {
        setLoading(true)
        setPaymentLinks([])
        const documento = window.localStorage.getItem('documento')
        const response = await paymentsLinksRequest(statusFilter, currentPage + 1, undefined, documento!);
        setRawPaymentLinks(response.links);
        setPaymentLinks(response.links.map((link: any) => {
            return {
                title: link.title,
                description: link.descricao_cliente || '',
                value: link.amount,
                payments: link.installments,
                status: link.pago ? 'PAID' : link.cancelado ? 'EXPIRED' : 'PENDING',
                link: link.link,
                attemp: Object.keys(link.detalhes_pagador).length > 0 && link.detalhes_pagador.detalhes_pagamento?.result_code !== null && link.detalhes_pagador.detalhes_pagamento?.result_code !== undefined,
                nsu: link.detalhes_pagador?.detalhes_pagamento?.authorization_nsu || "",
                dataCriacao: link?.data_criacao,
                dataAtualizacao: link?.data_atualizacao
            }
        }))
        setTotalItems(response.total);
        setLastUpdate(new Date())
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [statusFilter, currentPage])

    const exportS = useCallback(() => {
        const rows = rawPaymentLinks?.map((item: any) => {
            return {
                Valor: formatToCurrencyBRL(item?.amount),
                Parcelas: item?.installments,
                'Título': item?.title || '',
                'Documento cliente': item?.client_document,
                'Nome lojista': item?.nome_lojista,
                'Valor por parcela': formatToCurrencyBRL(item?.amount_per_installment),
                'Descrição cliente': item?.descricao_cliente || '',
                'Endereço': (item?.endereco || "").replace(',', '-'),
                Cidade: item?.cidade || '',
                'UF': item?.uf || '',
                'País': item?.pais || '',
                Cep: item?.cep || '',
                Telefone: item?.telefone || '',
                Email: item?.email || '',
                Id: item?.id || '',
                Pago: item?.pago ? 'Sim' : 'Não',
                Cancelado: item?.cancelado ? 'Sim' : 'Não',
                Link: item?.link,
                'Data criação': item?.data_criacao ? DateTime.fromISO(item?.data_criacao).toFormat("dd/MM/yyyy hh:mm:ss") : "",
                'Data atualização': item?.data_atualizacao ? DateTime.fromISO(item?.data_atualizacao).toFormat("dd/MM/yyyy hh:mm:ss") : "",
                'Id cupom fiscal': item?.cupom_fiscal_id || '',
            }
        })

        exportAsExcelFile(
            {rows: rows},
            `link-de-pagamento`
        );
    }, [rawPaymentLinks])

    return (
        <>
            {mobile === true && (
                <LinkPagamentoMobile
                    data={paymentLinks}
                    loading={loading}
                    currentPage={currentPage}
                    totalItems={totalItems}
                    setCurrentPage={setCurrentPage}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    exportS={exportS}
                    fetchData={fetchData}
                />
            )}
            {mobile === false && (
                <LinkPagamentoDesktop
                    data={paymentLinks}
                    loading={loading}
                    currentPage={currentPage}
                    totalItems={totalItems}
                    setCurrentPage={setCurrentPage}
                    lastUpdate={lastUpdate ? DateTime.fromJSDate(lastUpdate).toFormat('HH:mm:ss') : ''}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    exportS={exportS}
                    fetchData={fetchData}
                />
            )}
        </>
    )
}

export default LinksDePagamento