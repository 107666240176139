/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import ReactModal from 'react-modal';

import { PermissionsProps, dismissWelcomePopup } from '../../../store/user';
import { ContentModal } from '../styles';
import useAppSelector from '../../../hooks/useAppSelector';
import useAppDispatch from '../../../hooks/useAppDispatch';
import Button from '../../../components/Button/Button';
import { getPopupApp } from '../../../store/actions/bannerPopupActions';

const WelcomeModalMobile: React.FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const { user: { permissions, tipoAgenda }, token } = useAppSelector(state => state.user)
  const { welcomePopupDismissed } = useAppSelector(state => state.user);
  const { popupApp } =  useAppSelector(state => state.bannersPopup)

  const permissionsPopup = useMemo(() => {
    if(permissions) {
        const userPermissions: PermissionsProps = JSON.parse(permissions)
        return userPermissions[tipoAgenda as keyof PermissionsProps]?.popup_app
    }
    return null;
  },[permissions, tipoAgenda]);

  const closeModal = () => {
    setOpen(false);
    dispatch(dismissWelcomePopup());
  };

  const handleClickButtonLink = (link: string) => {
    window.open(link, "_blank");
  }

  const handleClickButtonEmail = (email: string) => {
    window.location.href = `mailto:${email}`
  }

  useEffect(() => {
    if (!!permissionsPopup?.popupAppId?.length && !welcomePopupDismissed) {
      permissionsPopup?.popupAppId?.forEach((item) => {
        (async function () {
        const response = await dispatch(getPopupApp({popupId: item}))
        if(response.meta.requestStatus === 'fulfilled') {
          setOpen(true)
        }
        })()
      })
    }
  },[permissionsPopup, welcomePopupDismissed, token])

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      style={{
        content: {
          width: '95%',
          height: "max-content",
          padding: "10px",
          transform: 'translate(-50%, -50%)',
          inset: '50% 0 0 50%'
        }
      }}
    >
      <ContentModal>
        {popupApp?.fileType === "video" && <video src={popupApp?.imageUrl} controls style={{ marginBottom: '10px', maxWidth: "100%" }} />}
        {popupApp?.fileType !== "video" && <img src={popupApp?.imageUrl} alt="" style={{ marginBottom: '10px' }} />}
        <div style={{
          display: 'flex',
          gap: '1rem',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {popupApp?.buttons?.map((item, index) => (
            <Button
              key={index}
              onClick={() => item?.type === 'L' ? handleClickButtonLink(item?.link!) : handleClickButtonEmail(item?.email!)}
              width={"15%"}
              label={item?.text_button || 'Clique aqui'}
            />
          ))}
          <Button
            onClick={closeModal}
            typeButton="outline"
            width={"35%"}
            label="Fechar"
          />
        </div>
      </ContentModal>
    </ReactModal>
  );
}

export default WelcomeModalMobile;
