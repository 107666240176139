export const optionType = {
    MOURA: 'MOURA',
    LOJA: 'LOJA'
}

export const optionLabel: any = {
    MOURA: '(Moura)',
    LOJA: '(Loja)',
}

export const profiles = {
    MULTI_AGENDA: 'multi-agenda',
    FLEX: 'flex',
    CESSAO: 'cessao',
    MAQUINA: 'maquina'
}

export const allowProfilesSwitchButtons = [ 'multi-agenda', 'flex']

export const daysOptions = [
    {
        value: 30,
        label: '30 dias'
    },
    {
        value: 60,
        label: '60 dias'
    },
    {
        value: 90,
        label: '90 dias'
    },
    {
        value: 120,
        label: '4 meses'
    },
    {
        value: 150,
        label: '5 meses'
    },
    {
        value: 180,
        label: '6 meses'
    }

]

export const terminalOptions =[
    {
        value: '',
        label: 'Terminal'
    },
    {
        value: 'ES000001',
        label: 'Link de pagamento'
    },
    {
        value: '5B663125',
        label: 'PAX D200 - cod: 5B663125'
    }
]

export const months = {
    1: 'Jan',
    2: 'Fev',
    3: 'Mar',
    4: 'Abr',
    5: 'Mai',
    6: 'Jun',
    7: 'Jul',
    8: 'Ago',
    9: 'Set',
    10: 'Out',
    11: 'Nov',
    12: 'Dez'
}