/* eslint-disable no-empty-pattern */
import * as salesActions from './actions/salesActions'
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit'

export interface SalesItemType {
  acquirerNsu: string,
  authorizatioNumber: string,
  brand: string,
  confirmationDate: string,
  confirmationHour: string,
  parcels: number,
  productName: string,
  startDate: string,
  status: string,
  terminal: string,
  value: number
}

export interface SalesProps {
  loadingBalanceSalesOfTheDay: boolean,
  balanceSalesOfTheDay: number,
  totalTransactionsSalesOfTheDay: number,

  loadingTotalSalesByPeriod: boolean,
  totalSalesByPeriod: number,
  netTotalSalesByPeriod: number,
  amountSalesByPeriod: number,
  
  loadingSales: boolean,
  sales: Array<SalesItemType>,
  totalPagination: number,

  balanceSalesOfTheMonth: number,
  loadingBalanceSalesOfTheMonth: boolean,

  terminals: Array<{
    serialNumber: string,
    terminalCode: string,
    terminalModel: string
  }>,
  loadingTerminals: boolean,
  brands: Array<{
    name: string
  }>
  loadingBrands: boolean,

  lastFiveSales: {
    "date": string,
		"clientGrossValue": number,
		"installments": number,
		"nsu": string,
		"terminal": string,
		"authorizationCode": string,
		"paymentType": string,
		"scheduleType": string,
		"cardBrand": string
  }[],
  loadingLastFiveSales: boolean;
  loadingExportSales: boolean
}

const initialState: SalesProps = {
  loadingBalanceSalesOfTheDay: false,
  balanceSalesOfTheDay: 0,
  totalTransactionsSalesOfTheDay: 0,

  loadingTotalSalesByPeriod: false,
  amountSalesByPeriod:0,
  totalSalesByPeriod: 0,
  netTotalSalesByPeriod: 0,
  
  loadingSales: false,
  sales: [],
  totalPagination: 0,

  balanceSalesOfTheMonth: 0,
  loadingBalanceSalesOfTheMonth: false,

  terminals: [],
  loadingTerminals: false,
  brands: [],
  loadingBrands: false,

  lastFiveSales: [],
  loadingLastFiveSales: false,
  loadingExportSales: false
}

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addMatcher(isPending(salesActions.getSales),
      (state) => {
        state.loadingSales = true
      }
    )
    builder.addMatcher(isFulfilled(salesActions.getSales),
      (state, action) => {
        state.loadingSales = false
        state.sales = action.payload?.rows
        state.totalPagination = Number(action.payload?.total)
      }
    )
    builder.addMatcher(isRejected(salesActions.getSales),
      (state) => {
        state.loadingSales = false
      }
    )

    builder.addMatcher(isPending(salesActions.getSalesOfTheDay),
      (state) => {
        state.loadingBalanceSalesOfTheDay = true
      }
    )
    builder.addMatcher(isFulfilled(salesActions.getSalesOfTheDay),
      (state, action) => {
        state.loadingBalanceSalesOfTheDay = false
        const confirmedSales = action.payload?.rows?.filter((item: SalesItemType) => item?.status === "CONFIRMADA")
        const totalSalesOfTheDay = confirmedSales?.reduce((acc: number, curr: SalesItemType) => {
          return acc + curr.value;
        }, 0)
        state.balanceSalesOfTheDay = totalSalesOfTheDay
        state.totalTransactionsSalesOfTheDay = confirmedSales?.length
      }
    )
    builder.addMatcher(isRejected(salesActions.getSalesOfTheDay),
      (state) => {
        state.loadingBalanceSalesOfTheDay = false
      }
    )

    builder.addMatcher(isPending(salesActions.getSalesByPeriod),
      (state) => {
        state.loadingTotalSalesByPeriod = true
      }
    )
    builder.addMatcher(isFulfilled(salesActions.getSalesByPeriod),
      (state, action) => {
        state.loadingTotalSalesByPeriod = false
        const salesConfirmed = action.payload?.rows?.filter((item: any) => item?.transactionStatus === null)
        const totalSalesByPeriod = salesConfirmed?.reduce((acc: number, curr: SalesItemType & {grossValue: number}) => {
          return acc + curr.grossValue;
        }, 0)
        const netTotalSalesByPeriod = salesConfirmed?.reduce((acc: number, curr: SalesItemType & {grossValue: number, netValue: number}) => {
          return acc + curr.netValue;
        }, 0)
        state.totalSalesByPeriod = totalSalesByPeriod
        state.netTotalSalesByPeriod = netTotalSalesByPeriod
        state.amountSalesByPeriod = salesConfirmed?.length
      }
    )
    builder.addMatcher(isRejected(salesActions.getSalesByPeriod),
      (state) => {
        state.loadingTotalSalesByPeriod = false
      }
    )

    builder.addMatcher(isPending(salesActions.getSalesOfTheMonth),
      (state) => {
        state.loadingBalanceSalesOfTheMonth = true
      }
    )
    builder.addMatcher(isFulfilled(salesActions.getSalesOfTheMonth),
      (state, action) => {
        state.loadingBalanceSalesOfTheMonth = false
        const totalSalesOfTheMonth = action.payload?.rows?.filter((item: SalesItemType) => item?.status === "CONFIRMADA")?.reduce((acc: number, curr: SalesItemType) => {
          return acc + curr.value;
        }, 0)
        state.balanceSalesOfTheMonth = totalSalesOfTheMonth
      }
    )
    builder.addMatcher(isRejected(salesActions.getSalesOfTheMonth),
      (state) => {
        state.loadingBalanceSalesOfTheMonth = false
      }
    )

    builder.addMatcher(isPending(salesActions.getTerminals),
      (state) => {
        state.loadingTerminals = true
      }
    )
    builder.addMatcher(isFulfilled(salesActions.getTerminals),
      (state, action) => {
        state.loadingTerminals = false
        state.terminals = action.payload
      }
    )
    builder.addMatcher(isRejected(salesActions.getTerminals),
      (state) => {
        state.loadingTerminals = false
      }
    )

    builder.addMatcher(isPending(salesActions.getBrands),
      (state) => {
        state.loadingBrands = true
      }
    )
    builder.addMatcher(isFulfilled(salesActions.getBrands),
      (state, action) => {
        state.loadingBrands = false
        state.brands = action.payload
      }
    )
    builder.addMatcher(isRejected(salesActions.getBrands),
      (state) => {
        state.loadingBrands = false
      }
    )

    builder.addMatcher(isFulfilled(salesActions.getLastFiveSales),
      (state, action) => {
        state.loadingLastFiveSales = false
        state.lastFiveSales = action.payload
      }
    )
    builder.addMatcher(isPending(salesActions.getLastFiveSales),
      (state, data) => {
        if (data.meta.arg.cacheLayer === '2') {
          state.loadingLastFiveSales = true
        }
      }
    )
    builder.addMatcher(isRejected(salesActions.getLastFiveSales),
      (state) => {
        state.loadingLastFiveSales = false
      }
    )
    builder.addMatcher(isPending(salesActions.exportSales),
      (state) => {
        state.loadingExportSales = true
      }
    )
    builder.addMatcher(isFulfilled(salesActions.exportSales),
      (state) => {
        state.loadingExportSales = false
      }
    )
    builder.addMatcher(isRejected(salesActions.exportSales),
      (state) => {
        state.loadingExportSales = false
      }
    )
  }
})

export const {  } = salesSlice.actions

export default salesSlice.reducer