import { css, keyframes, styled } from "styled-components";

export const WrapperPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const WrapperList = styled.div`
  ${({theme}) => css`
    background-color: ${theme.colors.white};
    padding: 2rem 0rem;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .loading {
      margin: auto;
    }
  `}
`

export const WrapperItem = styled.div`
  ${({theme}) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;

    &:nth-child(odd) {
      background-color: rgba(199, 203, 209, 0.10);
    }

    .name {
      font-size: ${theme.font.sizes.xxsmall};
      font-weight: ${theme.font.bold};
      color: ${theme.colors.blue};
      margin-bottom: 0.2rem;
    }

    .cnpj {
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.xxsmall};
      color: ${theme.colors.placeholder};
      margin: 0;
    }

    @media (max-width: 640px) {
      padding: 0.5rem 1rem;

      .content-data {
        width: 50%;
      }
    }
  `}
`

export const WrapperFooter = styled.div`
  ${({theme}) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1rem;

    .description {
      color: ${theme.colors.textBlue}
    }

    @media (max-width: 640px) {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      text-align: center;
    }
  `}
`

export const DisplayGrid = styled.div`
    width: 100%;

    @media (max-width: 640px) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }
    @media (min-width: 640px) {
      display: grid;
      grid-template-columns: 30% 65%;
      justify-content: space-between;
    }
`

export const CardWrapper = styled.div`
  ${({theme}) => css`
    background-color: ${theme.colors.white};
    border-radius: 1rem;
    padding: 1rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content-date {
      span {
        color: ${theme.colors.textBlue};
      }
      h3 {
        color: ${theme.colors.blue};
        margin: 0;
      }
    }

    @media (max-width: 640px) {
      padding: 1rem;
    }
  `}
`

export const WrapperIcons = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;

    &::after {
      content: '';
      width: calc(100% - 5rem);
      height: 0.3rem;
      background-color: ${theme.colors.blueNeon};
      position: absolute;
      left: 50%;
      top: 33%;
      transform: translate(-50%, -100%);
    }
  `}
`

const Animation = keyframes`
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
`

export const LabelIcon = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.placeholder};

    @media (max-width: 640px) {
      font-size: ${theme.font.sizes.xxlsmall};
    }
  `}
`

export const CircleIcon = styled.div<{
  $hasAnimation?: boolean
  $showIcon: boolean
}>`
  ${({ theme, $hasAnimation, $showIcon }) => css`
    border-radius: 50%;

    ${$showIcon
      ? css`
          height: 4rem;
          border: ${$hasAnimation
            ? `2px solid ${theme.colors.blueNeon}`
            : 'none'};
          background-color: ${theme.colors.white};
          position: relative;
          z-index: 1;

          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            color: ${theme.colors.blueNeon};
          }

          ${$hasAnimation &&
          css`
            width: 4rem;
            animation: ${Animation} 2s infinite;
          `}
        `
      : css`
          width: 4rem;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;

          &::after {
            content: '';
            display: flex;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            background-color: ${theme.colors.blueNeon};
          }

          svg {
            display: none;
          }
        `}
  `}
`

export const WrapperMobile = styled.div`
  width: 100%;

  .content {
    padding: 5rem 1rem 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    gap: 1rem;
    overflow-y: auto;
  }
`