/* eslint-disable no-useless-escape */
import React from 'react'
import ReactLoading from 'react-loading'
import { ReceivementsByDayAndNSUProps } from '../../../store/types/TypesReceivements'
import { formatToCurrencyBRL } from '../../../utils/functions'
import useAppSelector from '../../../hooks/useAppSelector'
import { IoIosArrowBack } from 'react-icons/io'
import { Body, Container, Header, LoadingContent } from '../../../components/TableList/styles'

interface Props {
  loading: boolean
  detailsReceivements: ReceivementsByDayAndNSUProps
  loadReceivementsByDayAndNSU(date: string, option: 'MOURA' | 'LOJA', open: boolean): void
}

const TableDetailsReceivement: React.FC<Props> = ({
  loading,
  detailsReceivements,
  loadReceivementsByDayAndNSU,
  }) => {
  const { user: { profile } } = useAppSelector(state => state.user)
  const option = useAppSelector(state => state.switchButtons.option)

  return (
    <div
      style={{
        marginBottom: "1rem",
        marginTop: "-10px"
      }}
    >
      <div
        style={{
          left: 0,
          top: 0,
          backgroundColor: "white",
          width: "100%",
          height: "100%"
        }}
      >
        <Container style={{border: "unset"}}>
          <Header>
            <p className="title">
              <IoIosArrowBack
                style={{cursor: 'pointer'}}
                onClick={() => {loadReceivementsByDayAndNSU('2099-01-01', option, false)}}
                color='#002868'
              /> {"Recebimento Detalhado"}
            </p>
            {/* <p className="balance">{balance}</p> */}
            <p className="date">
              {detailsReceivements?.day.replace(/([^-]*)-([^-]*)-([^-]*)/g, "$3/$2/$1")}
            </p>
          </Header>
          <Body>
            {loading ? (
              <LoadingContent>
                  <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'} />
              </LoadingContent>
              ) : (
              <>
                <div className="table-details">
                    <table>
                        <thead>
                        <tr>
                            <th>NSU</th>
                            <th>Data da Venda</th>
                            {profile === "flex" && (
                            <th>Porcentagem</th>
                            )}
                            <th>Valor</th>
                        </tr>
                        </thead>
                        <tbody>
                            {detailsReceivements?.receivements.map((receivement, idx) => (
                              <tr key={idx}>
                                <td>{receivement.nsu}</td>
                                <td>{receivement.transactionDate}</td>
                                {profile === "flex" && (
                                    <td>{receivement.percentReceived}%</td>
                                )}
                                <td style={{fontWeight: 'bold'}}>{formatToCurrencyBRL(receivement.netValue)}</td>
                              </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
              </>)}
          </Body>
        </Container>
      </div>
    </div>
  )
}

export default TableDetailsReceivement