import {styled} from "styled-components"

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 10px;

  .icon {
    background-color: ${({theme}) => theme.color.primary};
    border-radius: 25px;
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    margin: 0;
    color: ${({theme}) => theme.color.titleCard};
    font-family: ${({theme}) => theme.font.family};
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 1;
  }

  span {
    color: ${({theme}) => theme.color.primary};
    font-family: ${({theme}) => theme.font.family};
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`

export const Image = styled.img`
  margin: auto;
  width: 60%;
`