/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from  'react'
import { optionType } from '../../constants'
import ReactLoading from 'react-loading'
import useMedia from '../../hooks/useMedia'
import { ContentModal } from './styles'
import Button from '../../components/Button/Button'
import dayjs from 'dayjs'
import { getLastFiveSales, getSales, getSalesOfTheMonth } from '../../store/actions/salesActions'
import useAppDispatch from '../../hooks/useAppDispatch'
import useAppSelector from '../../hooks/useAppSelector'
import { getLastFiveReceivements, getBalanceCreditsToRelease, getBalanceCredmoura, getBalanceOpenBankSlip, getReceivements, getReceivementsOfTheMonth } from '../../store/actions/receivementsActions'
import { generateShortToken, getCustomizedPopupPermission } from '../../store/actions/functionalitiesActions'
import ReactModal from 'react-modal'
import HomeMobile from './Components/Mobile'
import HomeDesktop from './Components/Desktop'
import { parseToFormattedDate } from '../../utils/functions'


const Home = () => {
    const mobile = useMedia('(max-width: 40rem)')
    const dispatch = useAppDispatch()

    const option = useAppSelector(state => state.switchButtons.option)
    const { user: { document: userDocument }, token } = useAppSelector(state => state.user)

    const [modalOpenBankSlip, setModalOpenBankSlip] = useState({
        isOpen: false,
        imgContent: ""
    })

    const startDate = dayjs().startOf('month').toDate();
    const endDate = dayjs().toDate();

    const isMoura = useMemo(() => {
        return option === optionType.MOURA
    },[option])

    useEffect(() => {
        if(!mobile && mobile !== null) {
            ;(async function() {
                await dispatch(getSales({offset: 0, limit: 10, startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate), terminal: null}))
            })()

            ;(async function() {
                await dispatch(getReceivements({offset: 0, startDate: parseToFormattedDate(startDate), endDate: parseToFormattedDate(endDate)}))
            })()
        }

        if(isMoura) {
            ;(async function() {
                await dispatch(getBalanceCredmoura())
                
            })()

            ;(async function() {
                await dispatch(getLastFiveReceivements({schedule: 'MOURA', cacheLayer: '2'}))
                await dispatch(getLastFiveReceivements({schedule: 'MOURA', cacheLayer: '1'}))
            })()
            ;(async function() {
                await dispatch(getLastFiveSales({schedule: 'MOURA', cacheLayer: '2'}))
                await dispatch(getLastFiveSales({schedule: 'MOURA', cacheLayer: '1'}))
            })()

            ;(async function() {
                await dispatch(getBalanceCreditsToRelease())
            })()

            ;(async function() {
                await dispatch(getBalanceOpenBankSlip())
            })()
        } else {
            ;(async function() {
                await dispatch(getLastFiveReceivements({schedule: 'LOJA', 'cacheLayer': '2'}))
                await dispatch(getLastFiveReceivements({schedule: 'LOJA', 'cacheLayer': '1'}))
            })()
            ;(async function() {
                await dispatch(getLastFiveSales({schedule: 'LOJA', cacheLayer: '2'}))
                await dispatch(getLastFiveSales({schedule: 'LOJA', cacheLayer: '1'}))
            })()
            

            ;(async function() {
                await dispatch(getReceivementsOfTheMonth())
            })()
            
            if(mobile && mobile !== null) {
                // ;(async function() {
                //     await dispatch(getReceivementsOfTheDay())
                // })()
            }
            if(!mobile && mobile !== null) {    
                ;(async function() {
                    await dispatch(getSalesOfTheMonth())
                })()

            }
        }
    },[token, mobile, isMoura])

    const handleOpenBankSlip = useCallback((document: string) => {
        checkPermissionToRedirect(document)
    }, [token])

    const checkPermissionToRedirect = async (document: string) => {
        const response = await dispatch(getCustomizedPopupPermission())
        if(response?.meta?.requestStatus === "fulfilled") {
            if(response?.payload?.userWithoutMouraAccess) {
                setModalOpenBankSlip({
                    isOpen: true,
                    imgContent: response?.payload?.userWithoutMouraAccess?.imageUrl
                })
            } else if (response?.payload?.userFirstMouraAccess) {
                setModalOpenBankSlip({
                    isOpen: true,
                    imgContent: response?.payload?.userFirstMouraAccess?.imageUrl
                })
            } else {
                tokenShort(document)
            }
        }
    }

    const tokenShort = async (document: string) => {
        const response = await dispatch(generateShortToken({originUrl: "https://portaldocliente.propig.com.br/shell/sales"}))
        if(response?.payload?.token) {
            window.location.assign(`https://www.parceiromoura.com.br/login/LoginLTIPropig?CNPJ=${document}&acao=Financeiro&_KeyLTI=6X2eKW4Aa0jBuUOEPBws1pDiN1aSeQfgCt-RFO07E662_g8HTCQJ5VZW4VZLx4OTaYQVlorac1vHkFL953Bnbnp5L-MtgmyzWxE5FGKC4wUSjiKEskS36z8xGTna_sIK_uCoZSN99KeFU7Rl4mFb8q6sXeYUz8uJXcUK03BkNyJssORhHsexko3I06dHJ-_CYeYYL9e7LFL_zjddNW59gv50q7a1r3dD17nAS_olanc`)
        }
    }

    return (
        <>
            {mobile === true && <HomeMobile handleOpenBankSlip={() => handleOpenBankSlip(userDocument)} />}
            {mobile === false && <HomeDesktop handleOpenBankSlip={() => handleOpenBankSlip(userDocument)} />}
            <ReactModal
                isOpen={modalOpenBankSlip?.isOpen}
                onRequestClose={() => setModalOpenBankSlip({...modalOpenBankSlip, isOpen: false})}
                contentLabel="Buscar Cliente"
                style={{
                    content: {
                        width: "80%",
                        height: "max-content",
                        margin: "auto",
                        padding: mobile ? "10px" : "20px"
                    }
                }}
            >
                <ContentModal>
                    {modalOpenBankSlip?.imgContent ? (
                        <img width="100%" src={modalOpenBankSlip?.imgContent} alt="" />
                    ) : (
                        <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'}/>
                    )}
                    <Button onClick={() => setModalOpenBankSlip({...modalOpenBankSlip, isOpen: false})} typeButton="outline" width={mobile ? "35%" : "15%"} label="Fechar" />
                </ContentModal>
            </ReactModal>
        </>
    )
}

export default Home