import React from "react"
import { WrapperDesktop } from "../styles"
import TitlePage from "../../../components/TitlePage"
import { PaymentLinkGenerator } from "./PaymentLinkGenerator"
import Button from "../../../components/Button/Button"
import { SlReload } from "react-icons/sl"
import { HiOutlineDownload } from "react-icons/hi"
import { LinksTable } from "./LinksTable"
import { Pagination } from "../../../components/Pagination/Pagination"
import { Props } from ".."

const LinkPagamentoDesktop: React.FC<Props> = ({
  data,
  loading,
  currentPage,
  totalItems,
  setCurrentPage,
  lastUpdate,
  statusFilter,
  setStatusFilter,
  exportS,
  fetchData
  }) => {

  return (
    <WrapperDesktop>
      <TitlePage title={"Link de Pagamento"} lastUpdate={lastUpdate}/>
      <PaymentLinkGenerator />
      <div className='content-buttons'>
          <div
              style={{
                  display: "flex",
                  gap: '10px',
                  width: '50%'
          }}>
              <Button
                  width='50%'
                  onClick={() => {setStatusFilter('PAID')}}
                  label="Links pagos"
                  typeButton={statusFilter === "PAID" ? 'primary' : 'outline'}
              />
              <Button
                  width='50%'
                  onClick={() => {setStatusFilter('NOTPAID')}}
                  label="Links não pagos"
                  typeButton={statusFilter === "NOTPAID" ? 'primary' : 'outline'}
              />
          </div>
          <div
              style={{
                  width: '50%',
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'flex-end'
          }}>
              <Button
                  width="40px"
                  icon={<SlReload color='#002868' />}
                  typeButton='secondary'
                  onClick={fetchData}
              />
              <Button
                  width="30%"
                  label="Exportar"
                  icon={<HiOutlineDownload color='#002868' />}
                  typeButton='secondary'
                  onClick={exportS}
              />
          </div>
      </div>
      <LinksTable links={data} loading={loading} />
      {data?.length > 0 && (
          <Pagination
              limit={10}
              offset={currentPage}
              total={totalItems}
              setOffset={setCurrentPage}
          />
      )}
  </WrapperDesktop>
  )
}

export default LinkPagamentoDesktop