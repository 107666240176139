/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  ContainerDesktop,
  ContentButtons,
  ContentCalendarTable,
  ContentModal,
  ContentModalTable,
  WrapperTable } from "../styles"
import { 
  exportAsExcelFile,
  getLastUpdate,
  handleLabelFirstButton,
  handleLabelSecondButton,
  parseToFormattedDate } from "../../../utils/functions"
import Card from "../../../components/Card/Card"
import Button from "../../../components/Button/Button"
import DatePickerRange from "../../../components/DatePickerRange/DatePickerRange"
import ReactLoading from "react-loading"
import useAppSelector from "../../../hooks/useAppSelector"
import { mockPaymentsByDayLoja, optionType, profiles } from "../../../utils/constants"
import dayjs from "dayjs"
import useAppDispatch from "../../../hooks/useAppDispatch"
import { useLocation } from "react-router-dom"
import TitlePage from "../../../components/TitlePage"
import Calendar from "./Calendar"
import TableList from "../../../components/TableList/TableList"
import TableCredtStatement from "./TableCreditStatement"
import TableCreditAnalysis from "./TableCreditAnalysis"
import TableReceivementsByDay from "./TableReceivementsByDay"
import TableReceivementsByTransaction from "./TableReceivementsByTransaction"
import { PermissionsProps } from "../../../store/user"
import { SlReload } from "react-icons/sl"
import { HiOutlineDownload } from "react-icons/hi"
import { FiMaximize2 } from "react-icons/fi"
import { ItemOperationProp } from "../../../store/types/TypesReceivements"
import { generateShortToken, getCustomizedPopupPermission } from "../../../store/actions/functionalitiesActions"
import { EventDayFormatedProps } from ".."
import jwt_decode from 'jwt-decode'
import {
  exportSpreadsheet,
  getBalanceCreditsToRelease,
  getBalanceCredmoura,
  getBalanceOpenBankSlip,
  getCredits,
  getEvent,
  getEventsByDayReceivements,
  getNsuReceivements,
  getReceivementsByDay,
  getReceivementsByPeriod,
  getReceivementsByTransaction } from "../../../store/actions/receivementsActions"
import { filteredRows, handleDataPaymentsPerTransaction, handleExportCreditsToRelease } from "../utils/functions"
import ReactModal from "react-modal"

interface Props {
  lastEventCalendarLoja: { value: number, date: string }
  lastEventCalendarMoura: {value: number, date: string}
  dateLastEvent: string
}


const RecebimentosDesktop: React.FC<Props> = ({
  lastEventCalendarLoja,
  lastEventCalendarMoura,
  dateLastEvent
  }) => {

  const dispatch = useAppDispatch()
  const location = useLocation()
  const tableCreditsToRealeaseRef = useRef<any>(null)

  const { token, user: { profile, document: userDocument, permissions }} = useAppSelector(state => state.user)
  const option = useAppSelector(state => state.switchButtons.option)
  const { percentual_split } = jwt_decode<any>(token).user

  const {
    balanceCredmoura,
    balanceCreditToRelease,
    balanceOpenBankSlip,
    loadingBalanceCredmoura,
    loadingBalanceCreditToRelease,
    loadingBalanceOpenBankSlip,
    loadingBalanceReceivementsOfTheDay,
    operacoesArray,
    loadingOperacoes,
    balanceReceivementsByPeriod,
    loadingBalanceReceivementsByPeriod,
    credits,
    receivementsByDay,
    receivementsByTransaction,
    operacoesObject,
  } = useAppSelector(state => state.receivements)

  const [dateOpen, setDateOpen] = useState(false)
  const [stepper, setStepper] = useState(1)
  const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState(dayjs().locale('pt-br').subtract(1, 'day').toDate());
  const [daySelect, setDaySelect] = useState(dayjs().format("YYYY-MM-DD"))
  const [firstDayOfMonthSelected, setFirstDayOfMonthSelected] = useState<Date>(dayjs().startOf('month').toDate())
  const [lastDayOfMonthSelected, setLastDayOfMonthSelected] = useState<Date>(dayjs().endOf('month').toDate())
  const [modalOpenBankSlip, setModalOpenBankSlip] = useState({
    isOpen: false,
    imgContent: ""
  })
  const [offsetCreditsToRelease, setOffsetCreditsToRelease] = useState(0)
  const [offsetReceivementsByDay, setOffsetReceivementsByDay] = useState(0)
  const [offsetByTransaction, setOffsetByTransaction] = useState(0)
  const [paymentsByDayLoja, setPaymentsByDayLoja] = useState<Array<EventDayFormatedProps>>(mockPaymentsByDayLoja)
  const [detailsEventByDayLojaLoading, setDetailsEventByDayLojaLoading] = useState(false)
  const [openMaximeModal, setOpenMaximeModal] = useState(false);

  useEffect(() => {
    if(option === optionType.MOURA) {
      //SALDOS
      fetchCredmoura()
      fetchCreditToRelease()
      fetchOpenBankSlip()

      //MOURA: TABELA CRÉDITOS A LIBERAR
      fetchCreditsToReleaseMoura(parseToFormattedDate(startDate), parseToFormattedDate(endDate), offsetCreditsToRelease)
    } else {
      //LOJA: TABELA POR DIA
      fetchReceivementsByDayLoja(parseToFormattedDate(startDate), parseToFormattedDate(endDate), offsetReceivementsByDay)
      //LOJA: TABELA POR TRANSAÇÃO
      fetchReceivementsByTransactionLoja(parseToFormattedDate(startDate), parseToFormattedDate(endDate), offsetByTransaction)
      //LOJA: RECEBIMENTOS POR PERÍODO
      fetchReceivementsByPeriod(parseToFormattedDate(startDate), parseToFormattedDate(endDate))
      //LOJA: EVENTOS DO CALENDARIO
      const dateForThreeMonthsAgo = dayjs().locale('pt-br').startOf('month').subtract(12, 'month').toDate()
      fetchReceivementsByMonthLoja(parseToFormattedDate(dateForThreeMonthsAgo), parseToFormattedDate(endDate), token)
    }
  }, [token, option])

  useEffect(() => {
    const selectedDayElement = document.querySelector('.fc-day[data-date="' + daySelect + '"]')
    
    const allDayElements = document.querySelectorAll('.fc-day');
    allDayElements.forEach(function(dayElement) {
      dayElement.classList.remove('selected-day');
    });

    selectedDayElement?.classList.add('selected-day')
  }, [daySelect])

  //LOJA: DETALHES PAGAMENTO DIA SELECIONADO
  useEffect(() => {
    if(option === optionType.LOJA) {
      fetchDetailsEventByDayLoja(daySelect)
    }
  },[daySelect, token])

  const canViewCard = (profile: any, option: any) => {
    if(profile === profiles.MULTI_AGENDA && option === optionType.MOURA) return true
    if(profile === profiles.FLEX && option === optionType.MOURA) return true
    if(profile === profiles.CESSAO) return true
    return false
  }

  const toogleDate = useCallback(() =>{
    setDateOpen(!dateOpen)
  },[dateOpen])

  useEffect(() => {
    const { state } = location
    if(state?.toCreditsToRelease) {
        handleClickCreditsToRelease()
    }
  },[location])

  const handleClickCreditsToRelease = () => {
    tableCreditsToRealeaseRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    setStepper(2)
  }

  const handleTable = (tableIndex: number) => {
    setStepper(tableIndex)
  }

  const lastUpdate = useMemo(() => {
    return getLastUpdate(operacoesObject)
  },[operacoesObject])
  
  const permissionsMoura = useMemo(() => {
    if(permissions) {
        const userPermissions: PermissionsProps = JSON.parse(permissions)
        return userPermissions?.moura?.funcionalidades
    }
    return []
  },[permissions])

  //MOURA: QUANTIDADE DE DADOS TABELA EXTRATO
  const dataTableExtractLength = useMemo(() => {
    const extract: ItemOperationProp[] = []
    const operationsFiltered = operacoesArray?.filter(
    (operation) => operation.date >= startDate && operation.date <= endDate
    )

    operationsFiltered?.forEach((item) => {
        extract.push(...item?.items)
    })

    return extract?.length
  },[
    startDate,
    endDate,
    operacoesArray,
  ])

  const handleHasData = useMemo(() => {
    if(stepper === 1) {
        if(option === optionType.MOURA) {
            return dataTableExtractLength > 0
        } else {
            return receivementsByDay?.total > 0
        }
    } else {
        if(option === optionType.MOURA) {
            return credits?.total > 0
        } else {
         return receivementsByTransaction?.total > 0   
        }
    }
  },[option, stepper, dataTableExtractLength, credits, receivementsByTransaction, receivementsByDay])


  //MOURA: DETALHES EVENTO POR DIA
  const eventDayMoura = useMemo(() => {
    const date = new Date(`${daySelect}T00:00:00`)
    return operacoesArray?.filter(
        (operation: any) => {
            const operationDate = new Date(operation.date);
            operationDate.setHours(0, 0, 0, 0);
            return operationDate.getTime() === date.getTime();
        }
    ).reduce((accumlator: any, operation: any) => {
        const content = { ...operation };

        content.balance = content.items.reduce((acc: any, item: any) => {
          if (item.Type === "Entrada") {
            acc -= item.Valor;
          } else {
            acc += item.Valor * 1;
          }
          return acc;
        }, balanceCredmoura);

        accumlator.push(content);
  
        return accumlator;
      }, [])
  },[daySelect, operacoesArray, balanceCredmoura])

  //FUNÇÃO QUE FORMATA OS VALORES DE CRÉDITO OU DÉBITO POR DIA
  const formatedEventDayMoura = useCallback(() => {
    let extratoResumidoPerDate: any = [];

    eventDayMoura?.forEach((operation: any) => {
    let debito = operation.items
        .filter((item: any) => item.Type === "Saída")
        .map((item: any) => item.Valor);
    if (debito.length > 0) {
        debito = debito.reduce((total: any, item: any) => total + item);
    } else {
        debito = 0;
    }

    let credito = operation.items
        .filter((item: any) => item.Type === "Entrada")
        .map((item: any) => item.Valor);
    if (credito.length > 0) {
        credito = credito.reduce((total: any, item: any) => total + item);
    } else {
        credito = 0;
    }

    // agrupamento por código da fatura para ficar coerente com visualização do portal antigo onde o código da fatura do desconto aparece apenas uma vez
    let operationsDebitoGroupedByDescricao = operation.items?.filter((item: any) => item?.Type === 'Saída').map((item: any) => {
      return {
          Descricao: item.NotaFiscal, //item.Descricao
          Valor: item.Valor,
      }
    })
    
    operationsDebitoGroupedByDescricao = operationsDebitoGroupedByDescricao.map((item: any) => {
        return {
            Descricao: item.Descricao,
            Valor: item.Valor,
        }
    })

    extratoResumidoPerDate.push({
        date: operation.date,
        debito: debito,
        credito: credito,
        operationsCredito: operation.items?.filter((item: any) => item?.Type === 'Entrada')?.map((item: any) => {
            return  {
                nsu: item?.CreditCard_AuthorizationCode,
                percentage: profile === profiles.FLEX ? (option === optionType.MOURA ? 100 - percentual_split : percentual_split) : null,
                value: item?.Valor
            }
        }),
        operationsDebito: operationsDebitoGroupedByDescricao?.map((item: any) => {
            return  {
                description: item?.Descricao,
                value: item?.Valor
            }
        })
    });
    });

    return extratoResumidoPerDate
  }, [eventDayMoura])

  
  //MOURA: EVENTOS DO CALENDARIO
  const eventsCalendarMoura = useMemo(() => {
    return operacoesArray?.filter(
        (operation: any) => operation.date >= firstDayOfMonthSelected && operation.date <= lastDayOfMonthSelected
      ).reduce((accumlator: any, operation: any) => {
        const content = { ...operation };

        content.balance = content.items.reduce((acc: any, item: any) => {
          if (item.Type === "Entrada") {
            acc -= item.Valor;
          } else {
            acc += item.Valor * 1;
          }
          return acc;
        }, balanceCredmoura);
  
        accumlator.push(content);
  
        return accumlator;
      }, [])
  },[firstDayOfMonthSelected, lastDayOfMonthSelected, operacoesArray, balanceCredmoura])

  //FUNÇÃO QUE CALCULA O TOTAL DE CRÉDITOS OU DÉBITOS DO MÊS
  const getValues = (operations: any, type: any) => {
    return operations.reduce((accumulator: any, operation: any) => {
      const credits = operation.items
        .filter((item: any) => item.Type === type)
        .map((item: any) => item.Valor)
        .reduce((acc: any, value: any) => acc + value, 0);

      accumulator += credits;
      return accumulator;
    }, 0);
  }

  //MOURA: TOTAL DE CRÉDITOS
  const creditEventMoura = useMemo(() => {
    if(eventsCalendarMoura) {
        return getValues(eventsCalendarMoura, "Entrada")
    }
    return []
  }, [eventsCalendarMoura])

  //MOURA: TOTAL DE DÉBITOS
  const debitEventMoura = useMemo(() => {
    if(eventsCalendarMoura) {
        return getValues(eventsCalendarMoura, "Saída")
    }
    return []
  }, [eventsCalendarMoura])

  //MOURA: EVENTO DO DIA SELECIONADO FORMATADO
  const eventDayMouraFormated: Array<EventDayFormatedProps> = useMemo(() => {
    return  [
        {
            title: "Crédito em Saldo CREDMOURA",
            amount: formatedEventDayMoura()[0]?.credito ?? 0,
            colorAmount: "#0033AB",
            operations: formatedEventDayMoura()[0]?.operationsCredito ?? []
        },
        {
            title: "Débito em Saldo CREDMOURA",
            amount: formatedEventDayMoura()[0]?.debito ?? 0,
            colorAmount: "#DC3737",
            operations: formatedEventDayMoura()[0]?.operationsDebito ?? []
        },
        {
            title: "Total de créditos do mês",
            amount: creditEventMoura
        },
        {
            title: "Total de débitos do Mês",
            amount: debitEventMoura
        },
    ]
  }, [eventDayMoura, creditEventMoura, debitEventMoura, daySelect])

  const receivementsByPeriodMoura = useMemo(() => {
    const extract: Array<any> = []
    const operationsFiltered = operacoesArray?.filter(
    (operation: any) => operation.date >= startDate && operation.date <= endDate
    )

    operationsFiltered?.forEach((item: any) => {
        extract.push(...item?.items)
    })

    return extract.reduce((acc: any, curr: any) => {
        if(curr?.Type === "Entrada") {
            return acc + curr?.Valor
        }
        return acc 
    }, 0)
    
  },[operacoesArray, startDate, endDate])

  //REATIVO A PAGINAÇÃO
  useEffect(() => {
    if(stepper === 1) {
      if(option === optionType.LOJA) {
        //LOJA: TABELA POR DIA
        fetchReceivementsByDayLoja(parseToFormattedDate(startDate), parseToFormattedDate(endDate), offsetReceivementsByDay)
      }
    } else {
      if(option === optionType.MOURA) {
        fetchCreditsToReleaseMoura(parseToFormattedDate(startDate), parseToFormattedDate(endDate), offsetCreditsToRelease)
      } else {
        //LOJA: TABELA POR TRANSAÇÃO
        fetchReceivementsByTransactionLoja(parseToFormattedDate(startDate), parseToFormattedDate(endDate), offsetByTransaction)
      }
    }
  },[offsetByTransaction, offsetReceivementsByDay, offsetCreditsToRelease])

  const handleOpenBankSlip = useCallback((document: string) => {
    checkPermissionToRedirect(document)
  }, [token])

  const checkPermissionToRedirect = async (document: string) => {
      const response = await dispatch(getCustomizedPopupPermission())
      if(response?.meta?.requestStatus === "fulfilled") {
          if(response?.payload?.userWithoutMouraAccess) {
              setModalOpenBankSlip({
                  isOpen: true,
                  imgContent: response?.payload?.userWithoutMouraAccess?.imageUrl
              })
          } else if (response?.payload?.userFirstMouraAccess) {
              setModalOpenBankSlip({
                  isOpen: true,
                  imgContent: response?.payload?.userFirstMouraAccess?.imageUrl
              })
          } else {
              tokenShort(document)
          }
      }
  }

  const tokenShort = async (document: string) => {
      const response = await dispatch(generateShortToken({originUrl: "https://portaldocliente.propig.com.br/shell/sales"}))
      if(response?.payload?.token) {
          window.location.assign(`https://www.parceiromoura.com.br/login/LoginLTIPropig?CNPJ=${document}&acao=Financeiro&_KeyLTI=6X2eKW4Aa0jBuUOEPBws1pDiN1aSeQfgCt-RFO07E662_g8HTCQJ5VZW4VZLx4OTaYQVlorac1vHkFL953Bnbnp5L-MtgmyzWxE5FGKC4wUSjiKEskS36z8xGTna_sIK_uCoZSN99KeFU7Rl4mFb8q6sXeYUz8uJXcUK03BkNyJssORhHsexko3I06dHJ-_CYeYYL9e7LFL_zjddNW59gv50q7a1r3dD17nAS_olanc`)
      }
  }

  const refreshDataTable = useCallback(() => {
    if(stepper === 1) {
        if(option === optionType.MOURA) {
            fetchCredmoura()
        } else {
            //LOJA: TABELA POR DIA
            fetchReceivementsByDayLoja(parseToFormattedDate(startDate), parseToFormattedDate(endDate), offsetReceivementsByDay)
        }
    } else {
        if(option === optionType.MOURA) {
            fetchCreditsToReleaseMoura(parseToFormattedDate(startDate), parseToFormattedDate(endDate), offsetCreditsToRelease)
        } else {
            //LOJA: TABELA POR TRANSAÇÃO
            fetchReceivementsByTransactionLoja(parseToFormattedDate(startDate), parseToFormattedDate(endDate), offsetByTransaction)
        }
    }
  }, [
      stepper,
      option,
      token,
      offsetReceivementsByDay,
      offsetCreditsToRelease,
      offsetByTransaction,
      startDate,
      endDate
  ])

  const downloadSpreadsheet = async () => {
    let startFormated = dayjs(startDate?.toString()).format('YYYY-MM-DD')
    let endFormated = dayjs(endDate?.toString()).format('YYYY-MM-DD')
    if(stepper === 1) {
        if(option === optionType.LOJA) {
            const url = `/exportPaymentsPerDay?&startDate=${startFormated}&endDate=${endFormated}`
            const response = await dispatch(exportSpreadsheet({url: url}))
            if( response.meta.requestStatus === 'fulfilled' ) {
                exportAsExcelFile(
                    response.payload,
                    `rendimentos-por-dia-${startFormated}-${endFormated}.xlsx`
                  );
            }
        } else {
            const fromDate = new Date(`${startFormated}T00:00:00`);
            const toDate = new Date(`${endFormated}T00:00:00`);
            const url = `/affiliate-program/exportStatements?&startDate=${startFormated}&endDate=${endFormated}`
            const response = await dispatch(exportSpreadsheet({url: url}))
            if( response.meta.requestStatus === 'fulfilled' ) {
                const data = filteredRows(response.payload, fromDate, toDate)
                exportAsExcelFile(
                    { rows: data },
                    `extrato-CREDMOURA-${startFormated}-${endFormated}.xlsx`
                );
            }
        }
    } else {
        if(option === optionType.LOJA) {
            const url = `/exportPaymentsPerTransaction?&startDate=${startFormated}&endDate=${endFormated}`
            const response = await dispatch(exportSpreadsheet({url: url}))
            if( response.meta.requestStatus === 'fulfilled' ) {
                const data = handleDataPaymentsPerTransaction(response.payload, option)

                exportAsExcelFile(
                    data,
                    `rendimentos-por-transacao-${startFormated}-${endFormated}.xlsx`
                );
            }
        } else {
            const url = `/affiliate-program/exportCredits?&startDate=${startFormated}&endDate=${endFormated}`
            const response = await dispatch(exportSpreadsheet({url: url}))
            if( response.meta.requestStatus === 'fulfilled' ) {
                const data = handleExportCreditsToRelease(response.payload)
                exportAsExcelFile(
                    data,
                    `análise-de-créditos-CREDMOURA-${startFormated}-${endFormated}.xlsx`
                )
            }
        }
    }
  }

  //LOJA: DADOS EVENTO DO DIA SELECIONADO
  const fetchDetailsEventByDayLoja = async (day: string) => {
    setDetailsEventByDayLojaLoading(true)
    const response = await dispatch(getEvent({date: day}))
    const responseNsu = await dispatch(getNsuReceivements({date: day}))
    if(response?.meta?.requestStatus === "fulfilled" && responseNsu.meta.requestStatus === 'fulfilled') {
        const eventSelect = [
            {
                title: "Valor Líquido Cliente",
                amount: response?.payload[0]?.clientNetValue,
                colorAmount: "#0033AB",
                operations: responseNsu.payload
            },
            {
                title: "Ajuste a Crédito",
                amount: response?.payload[0]?.creditAjust
            },
            {
                title: "Ajuste a Débito",
                amount: response?.payload[0]?.debitAjust
            },
            {
                title: "Aluguel de Máquinas",
                amount: response?.payload[0]?.machineRental,
                colorAmount: "#DC3737"
            },
            {
                title: "Recebido do Dia",
                amount: response?.payload[0]?.dailyReceivement,
                colorTitle: "#0033AB",
                colorAmount: "#0033AB"
            }
        ]
        setPaymentsByDayLoja(eventSelect)
    }
        setDetailsEventByDayLojaLoading(false)
  }

  const onChange = useCallback((dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
        // toogleDate()
        setDateOpen(false)
        
        if(option === optionType.LOJA) {
            //LOJA: TABELA POR DIA
            fetchReceivementsByDayLoja(parseToFormattedDate(start), parseToFormattedDate(end), offsetReceivementsByDay)
            //LOJA: TABELA POR TRANSAÇÃO
            fetchReceivementsByTransactionLoja(parseToFormattedDate(start), parseToFormattedDate(end), offsetByTransaction)
            //LOJA: RECEBIMENTOS POR PERÍODO
            fetchReceivementsByPeriod(parseToFormattedDate(start), parseToFormattedDate(end))
        } else {
            //MOURA: TABELA CRÉDITOS A LIBERAR
            fetchCreditsToReleaseMoura(parseToFormattedDate(start), parseToFormattedDate(end), offsetCreditsToRelease)
        }
    }
  }, [token, offsetReceivementsByDay, offsetByTransaction, offsetCreditsToRelease, option, dateOpen])

  //MOURA: SALDO CREDMOURA E DADOS PARA CALENDARIO
  const fetchCredmoura = async () => {
    await dispatch(getBalanceCredmoura())
  }

  const fetchCreditToRelease = async () => {
    await dispatch(getBalanceCreditsToRelease())
  }

  const fetchOpenBankSlip = async () => {
    await dispatch(getBalanceOpenBankSlip())
  }

  //LOJA: TABELA POR DIA
  const fetchReceivementsByDayLoja = async (startDate: string, endDate: string, offset: number) => {
    await dispatch(getReceivementsByDay({startDate: startDate, endDate: endDate, offset: offset}))
  }

  //MOURA: TABELA CRÉDITOS A LIBERAR
  const fetchCreditsToReleaseMoura = async (startDate: string, endDate: string, offset: number) => {
    await dispatch(getCredits({startDate: startDate, endDate: endDate, offset: offset}))
  }

  //LOJA: TABELA POR TRANSAÇÃO
  const fetchReceivementsByTransactionLoja = async (startDate: string, endDate: string, offset: number) => {
    await dispatch(getReceivementsByTransaction({startDate: startDate, endDate: endDate, offset: offset}))
  }

  //LOJA: RECEBIMENTOS POR PERÍODO
  const fetchReceivementsByPeriod = async (startDate: any, endDate: any) => {
    await dispatch(getReceivementsByPeriod({startDate: startDate, endDate: endDate}))
  }

  //LOJA: EVENTOS DO CALENDARIO
  const fetchReceivementsByMonthLoja = async (startDate: any, endDate: any, token: any) => {
    await dispatch(getEventsByDayReceivements({startDate: startDate, endDate: endDate}))
  }
  

  return (
    <>
      <ContainerDesktop>
        {canViewCard(profile, option) && (
            <div className='content-cards'>
                <Card title={"Saldo CREDMOURA"} description={`Última atualização: ${lastUpdate}`} balance={balanceCredmoura} loading={loadingBalanceCredmoura}/>
                <Card title={"Créditos a Liberar"} balance={balanceCreditToRelease} loading={loadingBalanceCreditToRelease} onClick={handleClickCreditsToRelease}/>
                {permissionsMoura?.includes("Parceiro Moura") && (
                    <Card title={"Boletos Moura"} balance={balanceOpenBankSlip} loading={loadingBalanceOpenBankSlip} onClick={() => handleOpenBankSlip(userDocument)}/>
                )}
            </div>
        )}
        <TitlePage title={"Recebimentos"}/>

        <ContentCalendarTable>
            <div className='table-calendar' style={{ width: "50%", height: "50%"}}>
                <Calendar
                    setDaySelect={setDaySelect}
                    firstDayOfMonthSelected={firstDayOfMonthSelected}
                    setFirstDayOfMonthSelected={setFirstDayOfMonthSelected}
                    lastDayOfMonthSelected={lastDayOfMonthSelected}
                    setLastDayOfMonthSelected={setLastDayOfMonthSelected}
                />
            </div>
            <div style={{width: "50%"}}>
                <TableList title="Recebimento Detalhado" date={daySelect} balance={option === optionType.MOURA ? 10 : 200} data={option === optionType.MOURA ? eventDayMouraFormated : paymentsByDayLoja} loading={option === optionType.MOURA ? loadingOperacoes : detailsEventByDayLojaLoading } />
            </div>
        </ContentCalendarTable>
        <ContentButtons ref={tableCreditsToRealeaseRef}>
            <Button width="20%" label={handleLabelFirstButton(profile, option)} typeButton={stepper === 1 ? "primary" : "outline"} onClick={() => handleTable(1)}/>
            <Button width="20%" label={handleLabelSecondButton(profile, option)} typeButton={stepper === 2 ? "primary" : "outline"} onClick={() => handleTable(2)}/>
            <div className='group-buttons'>
                <Button width="40px" icon={<SlReload color='#002868' />} typeButton='secondary' onClick={refreshDataTable}/>
                {handleHasData && (
                    <Button width="20%" label="Exportar" icon={<HiOutlineDownload color='#002868' />} typeButton='secondary' onClick={downloadSpreadsheet}/>
                )}
                <DatePickerRange startDate={startDate} endDate={endDate} toogleDate={toogleDate} onChange={onChange} dateOpen={dateOpen} onClickOutside={() => {setDateOpen(false)}} />
                <Button width="20%" label="Maximizar" icon={<FiMaximize2 color='#002868' />} typeButton='secondary' onClick={() => setOpenMaximeModal(true)}/>
            </div>
        </ContentButtons>
        <div className='content-cards'>
            <Card
                title={"Recebimentos no período"}
                description={`De ${startDate ? dayjs(startDate).locale('pt-br').format('DD/MM/YYYY') : ''} Até ${endDate ? dayjs(endDate).locale('pt-br').format('DD/MM/YYYY') : ''}`}
                balance={option === optionType.MOURA ? receivementsByPeriodMoura : balanceReceivementsByPeriod}
                loading={loadingBalanceReceivementsByPeriod}
                typeCard="secondary"
            />
            <Card
                title={"Último recebimento"}
                description={dateLastEvent}
                balance={option === optionType.MOURA ? lastEventCalendarMoura?.value : lastEventCalendarLoja.value}
                loading={loadingBalanceReceivementsOfTheDay}
                typeCard="secondary"
            />
        </div>

        <WrapperTable>
            <TableCredtStatement
                startDate={startDate}
                endDate={endDate}
                stepper={stepper}
            />
            <TableCreditAnalysis
                stepper={stepper}
                setOffsetCreditsToRelease={setOffsetCreditsToRelease}
            />
            <TableReceivementsByDay
                stepper={stepper}
                setOffsetReceivementsByDay={setOffsetReceivementsByDay}
            />
            <TableReceivementsByTransaction
                stepper={stepper}
                setOffsetByTransaction={setOffsetByTransaction}
            />
        </WrapperTable>
      </ContainerDesktop>

      <ReactModal
        isOpen={modalOpenBankSlip?.isOpen}
        onRequestClose={() => setModalOpenBankSlip({...modalOpenBankSlip, isOpen: false})}
        contentLabel="Buscar Cliente"
        style={{
            content: {
                width: "80%",
                height: "max-content",
                margin: "auto"
            }
        }}
      >
        <ContentModal>
          {modalOpenBankSlip?.imgContent ? (
            <img width="100%" src={modalOpenBankSlip?.imgContent} alt="" />
          ) : (
            <ReactLoading type={'spin'} color={'#002868'} height={'fit-content'} width={'10%'}/>
          )}
            <Button onClick={() => setModalOpenBankSlip({...modalOpenBankSlip, isOpen: false})} typeButton="outline" width="15%" label="Fechar" />
        </ContentModal>
      </ReactModal>

      <ReactModal
        isOpen={openMaximeModal}
        onRequestClose={() => setOpenMaximeModal(false)}
        style={{
            content: {
            width: "95%",
            height: "max-content",
            maxHeight: '90%',
            margin: "auto",
            padding: "10px"
            }
        }}
      >
        <ContentModalTable>
          <ContentButtons style={{width: '100%'}}>
            <Button width="20%" label={handleLabelFirstButton(profile, option)} typeButton={stepper === 1 ? "primary" : "outline"} onClick={() => handleTable(1)}/>
            <Button width="20%" label={handleLabelSecondButton(profile, option)} typeButton={stepper === 2 ? "primary" : "outline"} onClick={() => handleTable(2)}/>
            <div className='group-buttons'>
              <Button width="40px" icon={<SlReload color='#002868' />} typeButton='secondary' onClick={refreshDataTable}/>
              {handleHasData && (
                <Button width="20%" label="Exportar" icon={<HiOutlineDownload color='#002868' />} typeButton='secondary' onClick={downloadSpreadsheet}/>
              )}
              <DatePickerRange startDate={startDate} endDate={endDate} toogleDate={toogleDate} onChange={onChange} dateOpen={dateOpen} onClickOutside={() => {setDateOpen(false)}} />
            </div>
          </ContentButtons>

          <TableCredtStatement
            startDate={startDate}
            endDate={endDate}
            stepper={stepper}
          />
          <TableCreditAnalysis
            stepper={stepper}
            setOffsetCreditsToRelease={setOffsetCreditsToRelease}
          />
          <TableReceivementsByDay
            stepper={stepper}
            setOffsetReceivementsByDay={setOffsetReceivementsByDay}
          />
          <TableReceivementsByTransaction
            stepper={stepper}
            setOffsetByTransaction={setOffsetByTransaction}
          />
          <Button
            onClick={() => setOpenMaximeModal(false)}
            typeButton="outline"
            width={"15%"}
            label="Fechar"
          />
        </ContentModalTable>
      </ReactModal>
    </>
  )
}

export default RecebimentosDesktop