import React from 'react'
import dayjs from "dayjs"
import Table from "../../../components/Table2"
import useAppSelector from "../../../hooks/useAppSelector"
import { formatToCurrencyBRL, handleIconCard } from "../../../utils/functions"
import Button from '../../../components/Button/Button'
import Ticket from '../../../assets/icon-ticket.svg'
import { SalesItemType } from '../../../store/sales'
import { WrapperStatus } from '../styles'
import { Pagination } from '../../../components/Pagination/Pagination'

interface Props {
  download(nsu: string, sale: SalesItemType): void
  offset: number
  setOffset(page: number): void
}

const IconTicket = () => {
  return (
      <img src={Ticket} alt="ticket" />
  )
}

const ResumeSalesTable: React.FC<Props> = ({download, offset, setOffset}) => {
  const {
    sales,
    loadingSales,
    totalPagination
} = useAppSelector((state) => state.sales)

const handleStatus = (status: string) => {
  if(status === "CONFIRMADA") return "confirmed"
  if(status === "NEGADA") return "rejected"
  if(status === "PENDENTE") return "pending"
  return ""
}

  return (
    <>
      <Table
        loading={loadingSales}
        data={sales}
        columns={[
          {
            key: 'terminal',
            header: 'Terminal',
            format: ({terminal}) => terminal
          },
          {
            key: 'startDate',
            header: 'Data de Venda',
            format: ({startDate}) => dayjs(startDate).format('DD/MM/YYYY')
          },
          {
            key: 'acquirerNsu',
            header: 'Código NSU',
            format: ({acquirerNsu}) => acquirerNsu
          },
          {
            key: 'authorizatioNumber',
            header: 'Autorização',
            format: ({authorizatioNumber}) => authorizatioNumber
          },
          {
            key: 'brand',
            header: 'Modalidade',
            format: ({brand, productName, parcels}) => (
              <>
                <img src={handleIconCard(brand)} alt="Card Icon" style={{marginRight: "5px", maxWidth: "40px"}} />
                {productName.includes("Credito")?"Crédito "+parcels+"x":"Débito"}
              </>
            )
          },
          {
            key: 'status',
            header: 'Status',
            format: ({status}) => (
              <WrapperStatus className={handleStatus(status)}><div className='ball'></div>{status}</WrapperStatus>
            )
          },
          {
            key: 'value',
            header: 'Valor Bruto',
            format: ({value}) => formatToCurrencyBRL(value)
          },
          {
            key: 'value',
            header: 'Filipeta',
            format: (row) => (
              <Button
                typeButton='outline'
                disabled={row.status === "NEGADA"}
                noBorder
                width='40px'
                backgroundColor='rgba(199, 203, 209, 0.30)'
                height='40px'
                icon={<IconTicket />}
                onClick={() => download(row.acquirerNsu, row)} />
            )
          },
        ]}
      />
      <Pagination limit={10} offset={offset} total={totalPagination} setOffset={setOffset} />
    </>
  )
}

export default ResumeSalesTable