/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import TitlePage from '../../../components/TitlePage'
import { CardWrapper, DisplayGrid, WrapperList, WrapperPage } from '../styles'
import Item from './Item'
import Button from '../../../components/Button/Button'
import useAppDispatch from '../../../hooks/useAppDispatch'
import StatusAnimation from './StatusAnimation'
import useAppSelector from '../../../hooks/useAppSelector'
import { getOrderById } from '../../../store/actions/trackingActions'
import Footer from './Footer'
import Table2 from '../../../components/Table2'
import ReactLoading from 'react-loading'
import { EventProps, clearOrder } from '../../../store/tracking'

interface Props {
  loading: boolean
  orders: {
    nome: string
    documento: string
    pedido: string
  }[]
}

const RastreioDesktop: React.FC<Props> = ({loading, orders}) => {
  const dispatch = useAppDispatch()
  const {loadingOrder, order} = useAppSelector(state => state.tracking)

  const [idSelected, setIdSelected] = useState<string | null>(null)

  const handleDate = (date: string) => {
    return date.split('/').reverse().join('-')
  }

  useEffect(() => {
    if(idSelected) {
      ;(async function () {
        await dispatch(getOrderById({id: idSelected}))    
      })()
    }
  },[idSelected])

  const events = useMemo(() => {
    const objEventsFiltered: { [k: string]: EventProps } = {}

    const arrayFiltered: EventProps[] | undefined = order?.eventos?.reduce(
      (acc: EventProps[], curr) => {
        const key = curr?.evento

        if (
          !objEventsFiltered[key] ||
          new Date(handleDate(curr?.ocorrencia)) >
            new Date(handleDate(objEventsFiltered[key]?.ocorrencia))
        ) {
          objEventsFiltered[key] = curr
          acc.push(curr)
        }

        return acc
      },
      [],
    )

    return arrayFiltered || []
  }, [order])

  const handleBack = () => {
    setIdSelected(null)
    dispatch(clearOrder())
  }

  return (
    <WrapperPage>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <TitlePage title={"Rastreio"}/>
        {idSelected && (
          <Button
            typeButton="outline"
            label="Voltar"
            width='20%'
            onClick={handleBack}
          />
        )}
      </div>
      {!idSelected && (
        <>
          <WrapperList>
            {loading ?
              <ReactLoading
                type={'spin'}
                color={'#002868'}
                height={'fit-content'}
                width={'5%'}
                className='loading'
              />
            :
            <>
              {orders?.map((item) => (
                <Item key={item?.pedido} item={item} setId={setIdSelected} />
              ))}
              {orders?.length === 0 && (<span style={{margin: 'auto'}}>Não foram encontrados dados para rastreamento!</span>)}
            </>
            }
          </WrapperList>
        </>
      )}
      {idSelected && (
        <>
          <DisplayGrid>
            <CardWrapper>
              <div className='content-date'>
                <span>Previsão de Entrega: </span>
                {loadingOrder ?
                  <ReactLoading
                    type={'spin'}
                    color={'#002868'}
                    height={'fit-content'}
                    width={'15%'}
                    className='loading'
                  />
                :
                  <h3>{order?.dtSla && order?.dtSla?.substring(0, 10)}</h3>
                }
              </div>
            </CardWrapper>
            <CardWrapper>
              {loadingOrder ?
                <ReactLoading
                  type={'spin'}
                  color={'#002868'}
                  height={'fit-content'}
                  width={'10%'}
                  className='loading'
                />
              :
                <StatusAnimation data={events} />
              }
            </CardWrapper>
          </DisplayGrid>
          <Table2
            loading={loadingOrder}
            data={events}
            columns={[
              {
                key: 'ocorrencia',
                header: 'Data',
                format: ({ocorrencia}) => ocorrencia && ocorrencia
              },
              {
                key: 'evento',
                header: 'Status',
                format: ({evento}) => evento && evento
              },
              {
                key: 'local',
                header: 'Local',
                format: ({local}) => local && local
              }
            ]}
          />
        </>
      )}
      <Footer />
    </WrapperPage>
  )
}

export default RastreioDesktop