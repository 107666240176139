/* eslint-disable no-useless-escape */
import React from 'react'
import { Divider, TableBody, TableHead } from '../styles'
import ReactLoading from 'react-loading'
import Button from '../../../components/Button/Button'
import { LastFiveReceivementsProps, ReceivementsByDayAndNSUProps } from '../../../store/types/TypesReceivements'
import { formatToCurrencyBRL } from '../../../utils/functions'
import useAppSelector from '../../../hooks/useAppSelector'
import { IoIosArrowForward, IoIosArrowUp } from 'react-icons/io'

interface Props {
  loading: boolean
  lastFiveReceivements: LastFiveReceivementsProps[]
  receivementsByDayAndNSU: ReceivementsByDayAndNSUProps[]
  loadReceivementsByDayAndNSU(date: string, option: 'MOURA' | 'LOJA', open: boolean): void
}

const TableReceivements: React.FC<Props> = ({
  loading,
  lastFiveReceivements,
  receivementsByDayAndNSU,
  loadReceivementsByDayAndNSU,
  }) => {

  const option = useAppSelector(state => state.switchButtons.option)

  return (
    <>
      <TableHead>
        <span>Data</span>
        <Divider />
        <span>Quantidade de transações</span>
        <Divider />
        <span>Recebidos do dia</span>
      </TableHead>
      <TableBody>
        {loading ? (
          <ReactLoading
            type={'spin'}
            className="loading"
            color={'#002868'}
            height={'fit-content'}
            width={'10%'}
          />
        ) : (
          <>
            {lastFiveReceivements?.map((item, index) => (
              <React.Fragment key={index}>
                <div className='row-table'>
                  {/* <span>{item?.paymentDate.substring(0, 5)}</span> */}
                  <span>{item?.receivementDate.substring(0, 5)}</span>
                  <Divider />
                  <span>
                    <span>
                      {item?.transactions}
                    </span>
                  </span>
                  <Divider />
                  <span style={{position: "relative"}}>{formatToCurrencyBRL(item?.netValue)}
                    <Button
                      style={{
                        position: "absolute",
                        "right": 0,
                        top: "-10px"
                      }}
                      onClick={() => loadReceivementsByDayAndNSU(item.receivementDate.replace(/([^\/]*)\/([^\/]*)\/([^\/]*)/g, '$3-$2-$1'), option, true)}
                        typeButton="outline"
                        width='40px'
                        noBorder
                        backgroundColor='transparent'
                        icon={receivementsByDayAndNSU.find(receivements => receivements.schedule === option && receivements.open === true && receivements.day === item.receivementDate.replace(/([^\/]*)\/([^\/]*)\/([^\/]*)/g, '$3-$2-$1')) ? <IoIosArrowUp color='#002868' /> : <IoIosArrowForward color='#002868' />}
                    />
                  </span>
              </div>
            </React.Fragment>
            ))}
          </>
        )}
      </TableBody>
    </>
  )
}

export default TableReceivements