const envs = {
    local: {
      BASE_URL: 'https://portaldocliente-api-prd.propig.com.br/prd',
      // BASE_URL: 'https://portaldocliente-api-prd.propig.com.br/prd',
      // BASE_URL: 'https://portaldocliente-api-qa.propig.com.br/qa',
      // BASE_URL_ADMIN: 'https://api-adminportalclientepropig-pre.credmoura.com.br',
      // BASE_URL_ADMIN: 'https://api-adminportalclientepropig-qa.credmoura.com.br',
      BASE_URL_ADMIN: 'https://api-adminportalclientepropig.credmoura.com.br',
      // API_KEY: process.env.REACT_APP_API_KEY,
      // SALT: process.env.REACT_APP_SALT
      API_KEY: '5d489d9cd5cc56ee448ba0c4e57a49a2',
      SALT: 'pa8uxbxv73!ca42v43h6',
      PAYMENT_LINK_BASE_URL: 'https://generate-payment-link.propig.com.br',
      PAYMENT_LINK_API_URL: 'http://localhost:3011'
    },
    hml: {
      BASE_URL: 'https://portaldocliente-api-hml.propig.com.br/hml',
      BASE_URL_ADMIN: 'https://api-adminportalclientepropig-qa.credmoura.com.br',
      // API_KEY: process.env.REACT_APP_API_KEY,
      // SALT: process.env.REACT_SALT,
      API_KEY: '66bf8f65c9f95b4894f3bb28f8e4f16f',
      SALT: 'pa8uxbxv73!ca42v43h6',
      PAYMENT_LINK_BASE_URL: 'https://generate-payment-link.propig.com.br',
      PAYMENT_LINK_API_URL: 'https://link-pagamento.propig.com.br'
    },
    qa: {
      BASE_URL: 'https://portaldocliente-api-qa.propig.com.br/qa',
      BASE_URL_ADMIN: 'https://api-adminportalclientepropig-qa.credmoura.com.br',
      // API_KEY: process.env.REACT_APP_API_KEY,
      // SALT: process.env.REACT_SALT,
      API_KEY: '5d489d9cd5cc56ee448ba0c4e57a49a2',
      SALT: 'pa8uxbxv73!ca42v43h6',
      PAYMENT_LINK_BASE_URL: 'https://generate-payment-link.propig.com.br',
      PAYMENT_LINK_API_URL: 'https://link-pagamento.propig.com.br'
    },
    pre: {
      BASE_URL: 'https://portaldocliente-api-pre.propig.com.br/prd',
      BASE_URL_ADMIN: 'https://api-adminportalclientepropig-pre.credmoura.com.br',
      // API_KEY: process.env.REACT_APP_API_KEY,
      // SALT: process.env.REACT_SALT,
      API_KEY: '66bf8f65c9f95b4894f3bb28f8e4f16f',
      SALT: 'pa8uxbxv73!ca42v43h6',
      PAYMENT_LINK_BASE_URL: 'https://generate-payment-link.propig.com.br',
      PAYMENT_LINK_API_URL: 'https://link-pagamento.propig.com.br'
    },
    prod: {
      BASE_URL: 'https://portaldocliente-api-prd.propig.com.br/prd',
      BASE_URL_ADMIN: 'https://api-adminportalclientepropig.credmoura.com.br',
      // API_KEY: process.env.REACT_APP_API_KEY,
      // SALT: process.env.REACT_SALT,
      API_KEY: '66bf8f65c9f95b4894f3bb28f8e4f16f',
      SALT: 'pa8uxbxv73!ca42v43h6',
      PAYMENT_LINK_BASE_URL: 'https://generate-payment-link.propig.com.br',
      PAYMENT_LINK_API_URL: 'https://link-pagamento.propig.com.br'
    },
    dev: {
      BASE_URL: 'https://portaldocliente-api-dev.propig.com.br/dev',
      BASE_URL_ADMIN: 'https://api-adminportalclientepropig-dev.credmoura.com.br',
      API_KEY: '5d489d9cd5cc56ee448ba0c4e57a49a2',
      SALT: 'pa8uxbxv73!ca42v43h6',
      PAYMENT_LINK_BASE_URL: 'https://generate-payment-link.propig.com.br',
      PAYMENT_LINK_API_URL: 'https://link-pagamento.propig.com.br'
    }
}
    
const config = envs[process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE as "local" | "hml" | "qa" | "pre" | "prod" | "dev" : "local"]
    
export default {
    ...config
}

