import styled, { css } from 'styled-components'

export const ButtonWhatsAppWrapper = styled.button<{$isMobile: boolean | null}>`
  ${({theme, $isMobile}) => css`
    position: fixed;
    bottom: ${$isMobile ? '5rem' : '1rem'};
    right: 1rem;

    outline: none;
    border: none;
    background-color: transparent;
    transition: all ease 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  `}
`