import { getProfile } from '../utils/functions';
import * as userActions from './actions/userActions'
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit';

export type ProfileProps = 'multi-agenda' | 'flex' | 'cessao' | 'maquina' | ''

interface FuncionalidadePopup {
  programa?: string;
  popupId?: number;
}

interface FuncionalidadeBanner {
  programa?: string;
  bannerId?: number;
}

interface FuncionalidadeBannerApp {
  programa?: string;
  bannerAppId?: number[];
}

interface FuncionalidadePopupApp {
  programa?: string;
  popupAppId?: number[];
}

interface Funcionalidades {
  funcionalidades: Array<string> | null;
  popup: FuncionalidadePopup;
  popup_app: FuncionalidadePopupApp;
  banner: FuncionalidadeBanner;
  banner_app: FuncionalidadeBannerApp;
}

export interface PermissionsProps {
  loja: Funcionalidades,
  moura: Funcionalidades,
  livre: Funcionalidades,
  todos: Funcionalidades
}


export interface TokenType {
  cessao: boolean,
  cpf: string,
  "distribuidor.cnpj": string,
  "distribuidor.razaoSocial": string,
  documento: string,
  documentoPai: string,
  emailCliente: string,
  heranca: Array<{
    nome: string,
    documento: string,
    nome_razao_favorecido: string
  }>,
  last_login :string,
  loginType : string,
  migrated : boolean
  nome : string
  nomeResponsavel : string
  nome_razao_favorecido : string
  percentual_split : number,
  permissions: Array<any>,
  produto: string,
  propig: boolean,
  sub: string,
  tipoAgenda: string,
  usuario: string,
}

export interface UserProps {
  user: {
    profile: ProfileProps | undefined,
    name: string,
    loginType: string,
    document:string,
    permissions: string,
    username: string,
    tipoAgenda: string,
    lastLogin: Date | null,
  },
  isAuthenticated: boolean | string,
  token: string,
  loadingUpdateLogin: boolean,
  loadingGetUserByDocument: boolean,

  loadingLogin: boolean,
  loadingLoginPropig: boolean,
  loadingForgotPassword: boolean,
  loadingRequestContact: boolean,

  welcomePopupDismissed: boolean,

  loadingChangePassword: boolean
}

const initialState: UserProps = {
  user: {
    profile: window.localStorage.getItem('profile') as ProfileProps ?? '',
    name: window.localStorage.getItem('name') ?? '',
    loginType: window.localStorage.getItem('loginType') ?? '',
    document: window.localStorage.getItem('documento') ?? '',
    permissions: window.localStorage.getItem('permissions') ?? "",
    username: window.localStorage.getItem('username') ?? "",
    tipoAgenda: window.localStorage.getItem('tipoAgenda') ?? "",
    lastLogin: window.localStorage.getItem('lastLogin') ? new Date(window.localStorage.getItem('lastLogin') as string) : null
  },
  isAuthenticated: window.localStorage.getItem('isAuthenticated') ?? false,
  token: window.localStorage.getItem('token') ?? '',
  loadingUpdateLogin: false,
  loadingGetUserByDocument: false,

  loadingLogin: false,
  loadingLoginPropig: false,
  loadingForgotPassword: false,
  loadingRequestContact: false,

  welcomePopupDismissed: false,

  loadingChangePassword: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action) => {
      const profile = getProfile(action.payload)
      const loginType = action.payload.loginType
      const documento = action.payload.documento
      const name = action.payload.nome
      const permissions = JSON.stringify(action.payload.permissions)
      const username = action.payload.username
      const tipoAgenda = action.payload.tipoAgenda
      console.log(action.payload)

      window.localStorage.setItem('profile', profile)
      window.localStorage.setItem('name', name)
      window.localStorage.setItem('loginType', loginType)
      window.localStorage.setItem('documento', documento)
      window.localStorage.setItem('permissions', permissions)
      window.localStorage.setItem('username', username)
      window.localStorage.setItem('tipoAgenda', tipoAgenda)
      window.localStorage.setItem("lastLogin", action.payload.last_login)
      state.user = { ...state.user, profile, name, loginType, document: documento, permissions: permissions, username, tipoAgenda, lastLogin: action.payload.last_login ? new Date(action.payload.last_login) : null }
    },
    authenticator: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated
      state.token = action.payload.token
      window.localStorage.setItem('token', action.payload.token)
      window.localStorage.setItem('isAuthenticated', action.payload.isAuthenticated)
    },
    logoutUser: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated
      state.token = action.payload.token
      state.user = {
        profile: '',
        loginType: '',
        name: '',
        document: '',
        permissions: '',
        username: '',
        tipoAgenda: '',
        lastLogin: null
      }
      window.localStorage.removeItem('profile')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('isAuthenticated')
      window.localStorage.removeItem('name')
      window.localStorage.removeItem('loginType')
      window.localStorage.removeItem('documento')
      window.localStorage.removeItem('permissions')
      window.localStorage.removeItem('tipoAgenda')
      window.localStorage.removeItem('lastLogin')
    },
    dismissWelcomePopup: (state) => {
      state.welcomePopupDismissed = true;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(isPending(userActions.updateLogin),
      (state) => {
        state.loadingUpdateLogin = true
      }
    )
    builder.addMatcher(isFulfilled(userActions.updateLogin),
      (state, action) => {
        state.loadingUpdateLogin = false
      }
    )
    builder.addMatcher(isRejected(userActions.updateLogin),
      (state) => {
        state.loadingUpdateLogin = false
      }
    )

    builder.addMatcher(isPending(userActions.getUserByDocument),
      (state) => {
        state.loadingGetUserByDocument = true
      }
    )
    builder.addMatcher(isFulfilled(userActions.getUserByDocument),
      (state, action) => {
        state.loadingGetUserByDocument = false
      }
    )
    builder.addMatcher(isRejected(userActions.getUserByDocument),
      (state) => {
        state.loadingGetUserByDocument = false
      }
    )

    builder.addMatcher(isPending(userActions.loginUser),
      (state) => {
        state.loadingLogin = true
      }
    )
    builder.addMatcher(isFulfilled(userActions.loginUser),
      (state, action) => {
        state.loadingLogin = false
      }
    )
    builder.addMatcher(isRejected(userActions.loginUser),
      (state) => {
        state.loadingLogin = false
      }
    )

    builder.addMatcher(isPending(userActions.loginUserPropig),
    (state) => {
      state.loadingLoginPropig = true
    }
  )
  builder.addMatcher(isFulfilled(userActions.loginUserPropig),
    (state, action) => {
      state.loadingLoginPropig = false
    }
  )
  builder.addMatcher(isRejected(userActions.loginUserPropig),
    (state) => {
      state.loadingLoginPropig = false
    }
  )

    builder.addMatcher(isPending(userActions.forgotPassword),
      (state) => {
        state.loadingForgotPassword = true
      }
    )
    builder.addMatcher(isFulfilled(userActions.forgotPassword),
      (state, action) => {
        state.loadingForgotPassword = false
      }
    )
    builder.addMatcher(isRejected(userActions.forgotPassword),
      (state) => {
        state.loadingForgotPassword = false
      }
    )

    builder.addMatcher(isPending(userActions.requestContact),
      (state) => {
        state.loadingRequestContact = true
      }
    )
    builder.addMatcher(isFulfilled(userActions.requestContact),
      (state, action) => {
        state.loadingRequestContact = false
      }
    )
    builder.addMatcher(isRejected(userActions.requestContact),
      (state) => {
        state.loadingRequestContact = false
      }
    )

    builder.addMatcher(isPending(userActions.changePassword),
      (state) => {
        state.loadingChangePassword = true
      }
    )
    builder.addMatcher(isFulfilled(userActions.changePassword),
      (state, action) => {
        state.loadingChangePassword = false
      }
    )
    builder.addMatcher(isRejected(userActions.changePassword),
      (state) => {
        state.loadingChangePassword = false
      }
    )
  }
})

export const { authenticator, saveUser, logoutUser, dismissWelcomePopup } = userSlice.actions

export default userSlice.reducer