import React from "react"
import Table2 from "../../../components/Table2"
import useAppSelector from "../../../hooks/useAppSelector"
import { formatToCurrencyBRL, handleIconCard, handleTypeBuy } from "../../../utils/functions"
import { Pagination } from "../../../components/Pagination/Pagination"
import { optionType } from "../../../utils/constants"
import { WrapperStatus } from "../styles"

interface Props {
  stepper: number
  setOffsetCreditsToRelease(arg: number): void
}

const TableCreditAnalysis: React.FC<Props> = ({
    stepper,
    setOffsetCreditsToRelease,
  }) => {

  const option = useAppSelector(state => state.switchButtons.option)
  const { credits, loadingCredits } = useAppSelector(state => state.receivements)

  const handleStatus = (status: string) => {
    if(status === "Liberado") return "confirmed"
    if(status === "Em Processamento") return "processing"
    if(status === "A Liberar") return "pending"
    return ""
}

  return (
    <>
      {stepper === 2 && option === optionType.MOURA && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <Table2
            loading={loadingCredits}
            data={credits?.rows}
            columns={[
              {
                key: 'transactionDate',
                header: 'Data de Venda',
                format: ({transactionDate}) => transactionDate && transactionDate
              },
              {
                key: 'cardBrand',
                header: 'Modalidade',
                format: ({cardBrand, paymentType, installments}) => cardBrand && (
                  <>
                    <img
                      src={handleIconCard(cardBrand)}
                      alt="Card Icon"
                      style={{marginRight: "5px", maxWidth: "40px"}}
                    />
                    {paymentType.toLowerCase().includes("credito") ? "Crédito " + installments + "x" : "Débito" }
                  </>
                )
              },
              {
                key: 'status',
                header: 'Status',
                format: ({status}) => status && (
                  <WrapperStatus className={handleStatus(status)}>
                    <div className='ball'></div>{status}
                  </WrapperStatus>
                )
              },
              {
                key: 'nsu',
                header: 'Código NSU',
                format: ({nsu}) => nsu && nsu
              },
              {
                key: 'authorizationCode',
                header: 'Autorização',
                format: ({authorizationCode}) => authorizationCode && authorizationCode
              },
              {
                key: 'pos',
                header: 'Terminal',
                format: ({pos}) => pos && pos
              },
              {
                key: 'grossAmount',
                header: 'Valor Bruto',
                format: ({grossAmount}) => grossAmount && formatToCurrencyBRL(grossAmount)
              },
              {
                key: 'netAmount',
                header: 'Valor Líquido',
                format: ({netAmount}) => netAmount && formatToCurrencyBRL(netAmount)
              },
            ]}
          />

          {credits?.total > 0 && (
            <Pagination limit={credits?.limit} offset={credits?.offset} total={credits?.total} setOffset={setOffsetCreditsToRelease} />
          )}
        </div>
      )}
    </>
  )
}

export default TableCreditAnalysis