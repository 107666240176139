import * as functionalitiesActions from './actions/functionalitiesActions'
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit'

export interface FunctionalitiesProps {
  loadingPopupPermission: boolean,
  loadingShortToken: boolean
}

const initialState: FunctionalitiesProps = {
    loadingPopupPermission: false,
    loadingShortToken: false
}

export const functionalitiesSlice = createSlice({
  name: 'functionalities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isPending(functionalitiesActions.getCustomizedPopupPermission),
      (state) => {
        state.loadingPopupPermission = true
      }
    )
    builder.addMatcher(isFulfilled(functionalitiesActions.getCustomizedPopupPermission),
      (state, action) => {
        state.loadingPopupPermission = false
      }
    )
    builder.addMatcher(isRejected(functionalitiesActions.getCustomizedPopupPermission),
      (state) => {
        state.loadingPopupPermission = false
      }
    )

    builder.addMatcher(isPending(functionalitiesActions.generateShortToken),
      (state) => {
        state.loadingShortToken = true
      }
    )
    builder.addMatcher(isFulfilled(functionalitiesActions.generateShortToken),
      (state, action) => {
        state.loadingShortToken = false
      }
    )
    builder.addMatcher(isRejected(functionalitiesActions.generateShortToken),
      (state) => {
        state.loadingShortToken = false
      }
    )

  }
})

export const { } = functionalitiesSlice.actions

export default functionalitiesSlice.reducer