import styled, { css } from "styled-components"

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
  }
`

export const ContainerDesktop = styled.div`
  /* padding: 24px 30px; */
  display: flex;
  flex-direction: column;
  gap: 24px;

  .content-cards {
    display: flex;
    gap: 24px;
  }

  .container-table-data {
    td {
      .status-box{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
      }

      .ball{
          border-radius: 25px;
          width: 14px;
          height: 14px;
      }

      &.confirmed{
          color: #1AB057;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;

          .ball{
              background-color: #1AB057;
          }
      }
      &.pending{
          color: #737D8C;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          .ball{
              background-color: #737D8C;
          }
      }

      &.processing{
          color: #D48001;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;

          .ball{
              background-color: #D48001;
          }
      }
    }
  }
`

export const ContentCalendarTable = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;

  .table-calendar {
    table {
      border: none !important;
      background-color: transparent;

      thead {
        height: 20px;
      }

      thead tr th {
          background-color: rgba(0, 40, 104, 0.15);
          border-radius: 0 !important;
          border: none;
      }

      tbody tr td {
          border: none;
      }

      tbody tr:nth-child(odd) {
          background-color: rgba(199, 203, 209, 0.20);
      }

      tbody tr:nth-child(even) .fc-day {
          background-color: rgba(248, 247, 247, 0.20);
      }
    }
  }

  .fc-scrollgrid-sync-table, .fc-daygrid-body {
    width: 100% !important;
  }

  .fc-daygrid-day-number {
      color: #737D8C;
      text-decoration: none;
  }

  .fc .fc-toolbar.fc-header-toolbar{
      margin-bottom: 0em;
      padding: 0.5rem 1rem;
      background-color: rgba(0, 40, 104, 0.15);
      border-radius: 25px 25px 0 0;
  }

  .fc-col-header {
      background-color: transparent;
      width: 100% !important;
  }

  .fc-col-header-cell {
      background-color: transparent;
      border-radius: 0px !important;
  }

  .fc-col-header-cell-cushion {
      text-decoration: none;
      color: #737D8C;
  }

  .fc .fc-daygrid-day.fc-day-today {
      background-color: transparent;
  }

  .fc-toolbar-chunk {

    h2 {
        font-size: 16px !important;
        font-family: ${({theme}) => theme.font.family};
        color: #737D8C !important;
        text-transform: capitalize;
    }

    button {
        background-color: transparent !important;
        border: none !important;
        color: #737D8C !important;
        outline: none;

        &:focus {
            box-shadow: none !important;
        }
    }

  }

  .fc-event {
      border-radius: 0px;
      padding: 1px;
      width: min-content;
  }

  .selected-day {
    .fc-daygrid-day-frame {
      border: 2px solid #0033AB !important;
      border-radius: 15px !important;
      background-color: rgba(0, 51, 171, 0.10) !important;

    }
  }
`

export const ContentButtons = styled.div`
  display: flex;
  gap: 10px;

  .group-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: relative;
    margin-left: auto;
  }
`

export const ContainerMobile = styled.div`
  width: 100%;

  .switch-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 84px;
  }
`

export const ContainerList = styled.div`
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  background-color: #FFFFFF;
  padding: 15px;
  border: 0.5px solid rgba(199, 203, 209, 0.60);

  .content-filters {
    display: flex;
    position: relative;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
  }

  .row-details:nth-child(even) {
    background-color: rgba(199, 203, 209, 0.10);
  }

  .row-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0px 15px;

    span {
      font-family: ${({theme}) => theme.font.family};
      color: #002868;
    }

    .text-details {
      color: rgba(115, 125, 140, 1);
    }
  }
`

export const HeadTable = styled.div`
  background-color: rgba(0, 40, 104, 0.15);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;

  span {
    width: 100%;
    font-family: ${({theme}) => theme.font.family};
    text-align: center;
    color: #0033AB;
    font-weight: bold;
  }
`

export const BodyTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  height: 50vh;
  margin-bottom: 70px;
  scroll-behavior: smooth;
  
  .row-table {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      background-color: #FFFFFF;
      width: 100%;
      font-family: ${({theme}) => theme.font.family};
      text-align: center;
      color: #002868;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }

  .content-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
  }

  .message-not-found {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    img {
      width: 80px;
    }
    span {
      text-align: center;
    }
  }

`

export const ContainerDetails = styled.div`
  width: 100%;
  margin-top: 64px;
  
  .content-card {
    padding: 15px;

  }
`

export const ContentBalance = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  height: calc(100vh - 385px);

  div {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 40, 104, 0.15);
    padding: 15px;

    span {
      font-family: ${({theme}) => theme.font.family};
      color: #0033AB;
      font-weight: bold;
    }
  }
`


export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;

  .message-not-found {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    img {
      width: 80px;
    }
    span {
      text-align: center;
    }
  }
`

export const ButtonBackToTop = styled.a`
`

export const ContentModalTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  /* height: 100%; */
`

export const WrapperStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .ball{
      border-radius: 25px;
      width: 14px;
      height: 14px;
  }

  &.confirmed{
      color: #1AB057;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;

      .ball{
          background-color: #1AB057;
      }
  }
  &.pending{
      color: #737D8C;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      .ball{
          background-color: #737D8C;
      }
  }

  &.processing{
      color: #D48001;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;

      .ball{
          background-color: #D48001;
      }
  }
`

export const WrapperTable = styled.div`
  ${({theme}) => css`
  td {
      .status-box{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
      }

      .ball{
          border-radius: 25px;
          width: 14px;
          height: 14px;
      }

      &.confirmed{
          color: #1AB057;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;

          .ball{
              background-color: #1AB057;
          }
      }
      &.pending{
          color: #737D8C;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          .ball{
              background-color: #737D8C;
          }
      }

      &.processing{
          color: #D48001;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;

          .ball{
              background-color: #D48001;
          }
      }
    }
  `}
`