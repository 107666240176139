import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  border-radius: 25px;
  height: 100%;
  background-color: #FFFFFF;
  border: 0.5px solid rgba(199, 203, 209, 0.30);

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`

export const Header = styled.div`
  /* background-color: rgba(0, 40, 104, 0.15); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px 25px 0 0;
  padding: 20px 0;

  p {
    margin: 0;
    font-family: ${({theme}) => theme.font.family};
    color: #737D8C;
  }

  .title {
    color: #0033AB;
    font-size: ${({theme}) => theme.font.size16};
    font-weight: ${({theme}) => theme.font.weigth700};
  }

  .balance {
    font-size: 32px;
    color: #002868;
    font-weight: ${({theme}) => theme.font.weigth700};
  }

  .date {
    color: #737D8C;
    font-size: 12px;
    font-weight: normal;
  }
`

export const Body = styled.div`
  .row-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;

    p {
      margin: 0;
    }

    p, span {
      font-family: ${({theme}) => theme.font.family};
      font-size: 14px;
      color: #737D8C;
    }

    span {
      font-weight: 700;
      margin-right: 5px;
    }

    svg {
      cursor: pointer;
    }
  }

  .row-details:nth-child(even) {
    background-color: rgba(199, 203, 209, 0.20);
  }

  .row-details.background-gray {
    background-color: rgba(0, 40, 104, 0.15);
  }

  .details-credits {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 40px;

    font-size: 14px;
    font-family: ${({theme}) => theme.font.family};

    background-color: rgba(0, 40, 104, 0.15);
    font-weight: 700;
    color: rgba(0, 51, 171, 1);
  }

  .table-details {
    max-height: 180px;
    overflow-y: auto;

    table {
      width: 100%;
  
      tr {
        th, td {
          font-size: 14px !important;
          border-radius: 0px !important;
        }
      }
  
      thead {
        height: 44px;

        th {
          color: rgba(0, 51, 171, 1);
        }
      }

      tbody {
        td {
          font-weight: normal;
          padding: 13.5px 0px !important;
        }
      }
    }
  }

`

export const LoadingContent = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Info = styled.div`
  padding: 20px 10px;
  display: flex;
  width: 100%;
  margin-top: auto;
  
  span {
    font-size: 12px;
    text-align: center;
    color: #737D8C;
  }
`