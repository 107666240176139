/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Infos } from "../styles"
import Button from "../../../components/Button/Button"
import { useForm } from "react-hook-form"
import Input from "../../../components/Input"
import TextArea from "../../../components/TextArea"
import { SelectComponent } from "../../../components/Select/Select"
import { optionsInstallments } from "./contants"
import { formatCurrencyInput, formatStringToNumber, maxValue } from "../../../utils/functions"
import { useNavigate } from "react-router-dom"
import useAppDispatch from "../../../hooks/useAppDispatch"
import { getAddressNContact, getShopkeeperMCC } from "../../../store/actions/myDataActions"
import useAppSelector from "../../../hooks/useAppSelector"
import { toast } from "react-toastify"

interface Props {
  setOpen(arg: boolean): void
  setIsTransitioning(arg: boolean): void
}

interface Form {
  sell_identity: string,
  value: string,
  installments: string,
  description: string
}

const FormLinkPaymentMobile: React.FC<Props> = ({ setOpen, setIsTransitioning }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { addressNContact, mcc } = useAppSelector(state => state.myData)
  const { user: { document: useCnpj } } = useAppSelector(state => state.user)
  const [name, setName] = useState<string>('')

  useEffect(() => {
    (async function () {
      await dispatch(getAddressNContact())
    })();
    (async function () {
      await dispatch(getShopkeeperMCC())
    })();
    setName(`PROPIG*${window.localStorage.getItem('name') || ""}`);
  }, [])

  const maxValueToUser = useMemo(() => {
    return maxValue(addressNContact, useCnpj)
  }, [addressNContact, useCnpj])

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm<Form>({
    defaultValues: {
      value: '',
      installments: '1'
    }
  });

  const [description, value, installments] = watch(['description', 'value', 'installments'])

  const onSubmit = useCallback((data: Form) => {
    if (!addressNContact || !mcc) {
      toast.error("Problema ocorrido durante geração de link de pagamento")
      return
    }
    const body = {
      ...data,
      value: formatStringToNumber(data?.value),
      createdAt: new Date(),
      addressNContact,
      mcc
    }
    navigate(`novo-link-de-pagamento`, { state: body })
  }, [addressNContact, mcc])

  const handleClose = () => {
    setIsTransitioning(true)
    setOpen(false)
    reset()
  }

  const handleValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const onlyNumber = formatStringToNumber(formatCurrencyInput(value.replace(/\D/g, '') || '0'))
    if (onlyNumber <= maxValueToUser) {
      setValue('value', formatCurrencyInput(value.replace(/\D/g, '') || '0'))
      clearErrors('value')
    } else {
      setError('value', { message: 'Valor máximo R$ 3.000,00' })
    }
  }, [maxValueToUser])

  return (
    <>
      <h3>PREENCHA OS DADOS DA MAQUININHA VIRTUAL</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          id="sell_identity"
          register={register}
          placeHolder="Digite aqui a identificação da venda"
          width="100%"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            e.target.value = name;
            toast.warning("Campo de identificação da venda não pode ser alterado");
          }}
          label="Identificador da venda para o cliente"
          textExample="Como aparecerá na fatura do cliente"
          disabled
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Input
            id="value"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValue(e)}
            placeHolder="R$ 0,00"
            width="100%"
            label="Valor"
            errorMessage={errors.value?.message}
            textExample="Valor máximo R$ 3.000,00"
            required
          />
          <SelectComponent
            options={optionsInstallments}
            value={installments}
            height="48px"
            placeholder="Selecione"
            width="40%"
            noMenuPortalTarget
            onChange={(e) => setValue('installments', e.value)}
            borderRadius="8px"
            label="Parcelas"
          />
        </div>
        <TextArea
          id="description"
          width="100%"
          label="Descrição da venda"
          placeHolder="Digite a descrição aqui (Opcional)"
          value={description}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue('description', e.target.value)}
        />

        <Infos>
          <h5>ATENÇÃO</h5>
          <p>
            Por se tratar de uma venda não presencial, sem o uso do chip ou senha, o CLIQUE PAGUE aumenta o risco de CANCELAMENTO DA VENDA POR SOLICITAÇÃO DO PORTADOR DO CARTÃO junto ao seu emissor (chargeback).
            <br />
            <br />
            Sempre que possível, utilize nossas maquininhas de forma presencial para ter garantia da conclusão da venda.
          </p>
        </Infos>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={handleClose}
            label="Cancelar"
            width="50%"
            typeButton="outline"
            type="button"
          />
          <Button
            type="submit"
            // onClick={handleClose}
            label="Salvar e Continuar"
            width="50%"
          />
        </div>
      </form>
    </>
  )
}

export default FormLinkPaymentMobile